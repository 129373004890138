import React, { useEffect, useState } from 'react';
import { useAppDispatch } from '../../store/store';
import { useSelector } from 'react-redux';
import {
    fetchCommitteeList,
    selectCommitteeList,
} from '../../features/committee-dashboards/data/committeeListSlice';
import { entityListExtra } from '../../interfaces/generic-entity.interface';
import { Breadcrumbs, Button, ButtonGroup, Grow } from '@mui/material';
import Fade from '@mui/material/Fade';
import { Link } from 'react-router-dom';
import SelectionModal from './SelectionModal';

import EditNoteRoundedIcon from '@mui/icons-material/EditNoteRounded';
import {
    fetchSectorList,
    selectSectorList,
} from '../../features/sector-dashboards/store/sectorListSlice';
import {
    fetchInstitutionList,
    selectInstitutionList,
} from '../../store/reducers/institutionListSlice';
import {
    selectUserGracePeriod,
    selectUserLoading,
    selectUserPermissions,
} from '../../store/reducers/userSlice';
import LoadPage from '../misc/LoadPage';
import { SubscriptionRequired } from '../misc/SubscriptionRequired';
import {
    Selection,
    fetchCommitteesSelections,
    fetchInstitutionsSelections,
    fetchNewsletterSelections,
    fetchSectorsSelections,
    selectChosenCommittees,
    selectChosenInstitutions,
    selectChosenSectors,
} from '../../store/reducers/customSelectionSlice';
import { replace_underscores_capitalize } from '../../helpers/string_formatters';

const SelectionsPage = () => {
    const dispatch = useAppDispatch();

    const userLoading = useSelector(selectUserLoading);
    const userGracePeriod = useSelector(selectUserGracePeriod);
    const permissions = useSelector(selectUserPermissions);
    const sectors = useSelector(selectSectorList);
    const institutions = useSelector(selectInstitutionList);
    const committees = useSelector(selectCommitteeList);
    const selectedSectors = useSelector(selectChosenSectors);
    const selectedInstitutions = useSelector(selectChosenInstitutions);
    const selectedCommittees = useSelector(selectChosenCommittees);
    // const selectedNewsletters = useSelector(selectChosenNewsletters);

    const [type, setType] = useState<'sectors' | 'institutions' | 'committees'>('committees');

    const [selectionOpen, setSelectionOpen] = useState<boolean>(false);

    const owned: {
        sectors: Selection[] | null;
        institutions: Selection[] | null;
        committees: Selection[] | null;
    } = {
        sectors: selectedSectors,
        institutions: selectedInstitutions,
        committees: selectedCommittees,
    };

    const available: {
        sectors: entityListExtra[] | null;
        institutions: entityListExtra[] | null;
        committees: entityListExtra[] | null;
    } = {
        sectors,
        institutions,
        committees,
    };

    useEffect(() => {
        dispatch(fetchSectorList());
        dispatch(fetchInstitutionList());
        dispatch(fetchCommitteeList());
        dispatch(fetchSectorsSelections());
        dispatch(fetchInstitutionsSelections());
        dispatch(fetchCommitteesSelections());
        dispatch(fetchNewsletterSelections());
    }, [dispatch]);

    const canEditSelection = () => {
        if (userGracePeriod && Date.parse(userGracePeriod) > Date.now()) {
            return true;
        }
        if (type === 'sectors' && permissions) {
            return (
                !selectedSectors.find(
                    (sector) =>
                        sector.monthLastModified !== undefined &&
                        sector.monthLastModified === new Date().getMonth()
                ) || selectedSectors.length < permissions[type]
            );
        } else if (type === 'committees' && permissions) {
            return (
                !selectedCommittees.find(
                    (committee) =>
                        committee.monthLastModified !== undefined &&
                        committee.monthLastModified === new Date().getMonth()
                ) || selectedCommittees.length < permissions[type]
            );
        } else if (type === 'institutions' && permissions) {
            return (
                !selectedInstitutions.find(
                    (institution) =>
                        institution.monthLastModified !== undefined &&
                        institution.monthLastModified === new Date().getMonth()
                ) || selectedInstitutions.length < permissions[type]
            );
        }
    };

    // Disabled until newsletters are active

    // const handleNewsletterChange = (codeToFlip: string) => {
    //     const existingLetter = selectedNewsletters.find(
    //         (item) => item.code === codeToFlip
    //     );

    //     if (existingLetter) {
    //         dispatch(
    //             updateNewsletterSelections(
    //                 selectedNewsletters.filter(
    //                     (item) => item.code !== codeToFlip
    //                 )
    //             )
    //         );
    //     } else {
    //         dispatch(
    //             updateNewsletterSelections(
    //                 selectedSectors.filter(
    //                     (sector) =>
    //                         sector.code === codeToFlip ||
    //                         selectedNewsletters.find(
    //                             (letter) => letter.code === sector.code
    //                         )
    //                 )
    //             )
    //         );
    //     }
    // };

    return userLoading ? (
        <LoadPage />
    ) : !permissions ? (
        <SubscriptionRequired />
    ) : (
        <Fade in={true}>
            <div className="lg:py-32 py-20 grid grid-cols-12">
                <Breadcrumbs className="col-start-2 col-end-12" separator=">">
                    <Link to="/" className="hover:underline">
                        Lobby<b>IQ</b>
                    </Link>
                    <Link to="/account" className="hover:underline">
                        My Account
                    </Link>
                    <div>Dashboard Selections</div>
                </Breadcrumbs>

                <SelectionModal
                    open={selectionOpen}
                    availableList={available[type] || []}
                    ownedList={owned[type] || []}
                    maxEntitlements={permissions[type]}
                    type={type}
                    handleClose={() => setSelectionOpen(false)}
                />

                <div className="col-start-3 col-end-11 mt-16">
                    <div className="text-2xl flex flex-row gap-2 items-center">
                        Dashboard Selections
                    </div>
                </div>

                <div className="col-start-3 col-end-11 mt-6">
                    <div className="flex flex-row justify-between items-center">
                        <div className="text-lg flex flex-row gap-2 items-center">
                            Your Dashboards
                        </div>
                        <ButtonGroup
                            disableElevation
                            size="small"
                            color={
                                type === 'sectors'
                                    ? 'success'
                                    : type === 'institutions'
                                    ? 'info'
                                    : type === 'committees'
                                    ? 'secondary'
                                    : 'error'
                            }
                        >
                            {permissions.sectors < 100 && (
                                <Button
                                    onClick={() => setType('sectors')}
                                    variant={type === 'sectors' ? 'contained' : 'outlined'}
                                >
                                    Sectors
                                </Button>
                            )}
                            {permissions.institutions < 100 && (
                                <Button
                                    onClick={() => setType('institutions')}
                                    variant={type === 'institutions' ? 'contained' : 'outlined'}
                                >
                                    Institutions
                                </Button>
                            )}
                            {permissions.committees < 100 && (
                                <Button
                                    onClick={() => setType('committees')}
                                    variant={type === 'committees' ? 'contained' : 'outlined'}
                                >
                                    Committees
                                </Button>
                            )}
                        </ButtonGroup>
                    </div>
                    <hr className="h-0.5 my-3 bg-slate-100 rounded" />
                    <div className="flex flex-row justify-between items-center py-2 text-md text-slate-500">
                        {canEditSelection() ? (
                            <>
                                Click Edit to select {permissions[type]} {type}
                            </>
                        ) : (
                            <p>
                                {replace_underscores_capitalize(type.slice(0, -1))} selections may
                                be changed again after the 1st day of each month
                            </p>
                        )}
                        <Button
                            onClick={() => setSelectionOpen(true)}
                            endIcon={<EditNoteRoundedIcon />}
                            size="medium"
                            variant="contained"
                            color={
                                type === 'sectors'
                                    ? 'success'
                                    : type === 'institutions'
                                    ? 'info'
                                    : type === 'committees'
                                    ? 'secondary'
                                    : 'error'
                            }
                            disabled={!canEditSelection()}
                        >
                            Edit
                        </Button>
                    </div>
                    <div>
                        {owned[type]?.map((entity) => (
                            <Grow in={true}>
                                <div className="flex flex-row justify-between bg-white shadow-sm rounded-md p-3 my-2 text-sm text-blue-400 font-medium">
                                    <Link
                                        to={`/${type}/${entity.code}?name=${encodeURIComponent(
                                            entity.title
                                        )}`}
                                        className="hover:underline underline-offset-2 my-auto"
                                    >
                                        {entity.code} - {entity.title}
                                    </Link>
                                    {/* {type === 'sectors' && (
                                        <FormControlLabel
                                            control={
                                                <Checkbox
                                                    checked={
                                                        !!selectedNewsletters.find(
                                                            (newsletter) =>
                                                                entity.code ===
                                                                newsletter.code
                                                        )
                                                    }
                                                    onChange={() =>
                                                        handleNewsletterChange(
                                                            entity.code
                                                        )
                                                    }
                                                />
                                            }
                                            label={
                                                <div className="text-black text-sm">
                                                    Include in Newsletters
                                                </div>
                                            }
                                        />
                                    )} */}
                                </div>
                            </Grow>
                        ))}
                        {[...Array(permissions[type] - (owned[type] || []).length)].map(() => (
                            <Grow in={true}>
                                <div className="border border-1 border-slate-300 rounded-md p-3 my-2 text-sm text-slate-300 font-medium">
                                    Add another {type.slice(0, -1)}
                                </div>
                            </Grow>
                        ))}
                    </div>
                </div>
            </div>
        </Fade>
    );
};

export default SelectionsPage;
