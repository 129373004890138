import MenuList from '@mui/material/MenuList';
import { Link } from 'react-router-dom';
import { Box, Divider, MenuItem, Menu } from '@mui/material';

import ExpandLessRoundedIcon from '@mui/icons-material/ExpandLessRounded';
import ExpandMoreRoundedIcon from '@mui/icons-material/ExpandMoreRounded';

const menuStyles = {
    padding: '10px',
    borderRadius: '50px',
    display: 'grid',
    border: 'none !important',
    outline: 'none !important',
};

const link_tran = 'text-slate-600 hover:text-black';

interface Props {
    open: boolean;
    handleClick: (event: React.MouseEvent<HTMLButtonElement>) => void;
    handleClose: () => void;
    anchorEl: HTMLElement | null;
}

const FeaturesButton = ({ open, handleClick, handleClose, anchorEl }: Props) => {
    return (
        <div className={`${open ? 'text-liq-gray-blue' : ''}`}>
            <button
                className="py-1 flex flex-row justify-center"
                id="features-button"
                aria-controls={open ? 'basic-menu' : undefined}
                aria-haspopup="true"
                aria-expanded={open ? 'true' : undefined}
                onClick={handleClick}
            >
                Features
                {open ? <ExpandLessRoundedIcon /> : <ExpandMoreRoundedIcon />}
            </button>
            <Menu
                sx={{
                    marginTop: '14px',
                    '& .MuiMenu-paper': {
                        backgroundColor: 'white',
                        borderTopWidth: 6,
                        borderTopColor: '#67A8CC',
                        borderRadius: '0px 0px 10px 10px',
                        boxShadow: 'rgba(0, 0, 0, 0.2) 0px 4px 4px -2px',
                    },
                }}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'center',
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'center',
                }}
                anchorEl={anchorEl}
                open={open}
                onClose={handleClose}
                disableScrollLock={true}
            >
                <MenuList sx={menuStyles}>
                    <MenuItem component={Link} to="/features/monitor" onClick={handleClose}>
                        <Box className={link_tran}>
                            <p className="heading">Committee Monitoring</p>
                        </Box>
                    </MenuItem>

                    <Divider sx={{ marginY: 1 }} />
                    <MenuItem component={Link} to="/features/stakeholder" onClick={handleClose}>
                        <Box className={link_tran}>
                            <p className="heading">Stakeholder Monitoring</p>
                        </Box>
                    </MenuItem>

                    <Divider sx={{ marginY: 1 }} />
                    <MenuItem component={Link} to="/features/tracking" onClick={handleClose}>
                        <Box className={link_tran}>
                            <p className="heading">Issue Tracking</p>
                        </Box>
                    </MenuItem>

                    <Divider sx={{ marginY: 1 }} />

                    <MenuItem component={Link} to="/features/updates" onClick={handleClose}>
                        <Box className={link_tran}>
                            <p className="heading">Stay Ahead Updates</p>
                        </Box>
                    </MenuItem>

                    <Divider sx={{ marginY: 1 }} />

                    <MenuItem component={Link} to="/features/research" onClick={handleClose}>
                        <Box className={link_tran}>
                            <p className="heading link_tran">Research Hub</p>
                        </Box>
                    </MenuItem>

                    <Divider sx={{ marginY: 1 }} />

                    <MenuItem component={Link} to="/features/gr-insider" onClick={handleClose}>
                        <Box className={link_tran}>
                            <p className="heading">GR Insider</p>
                        </Box>
                    </MenuItem>
                </MenuList>
            </Menu>
        </div>
    );
};

export default FeaturesButton;
