import React, { ReactNode, useEffect, useState } from 'react';
import Backdrop from '@mui/material/Backdrop';
import MUIModal from '@mui/material/Modal';
import IconButton from '@mui/material/IconButton';
import Close from '@mui/icons-material/CloseRounded';

// Title and description are optional components with defaults specified in function signature below.
interface modalProps {
    open: boolean;
    width?: number;
    onClose: () => void;
    children?: ReactNode;
    title?: string;
}

/*
    Component: Modal.tsx
    Params: title - title of the modal window
    Author: Will Brewer
    Desc: Modal menu to open on click. Acts as a wrapper for any preferred child components
*/
const Modal = ({
    open,
    width = 75,
    onClose,
    children = <></>,
    title = '',
}: modalProps) => {
    width = width % 100;

    // Mobile breakpoint is set to 1024 pixels - the "lg" breakpoint of tailwind for consistency
    const mobile_breakpoint = 1024;

    // Manage current desktop state. True if wiewport width is greater than the mobile breakpoint.
    const [isDesktop, setDesktop] = useState<boolean>(
        window.innerWidth > mobile_breakpoint
    );

    // Listen for viewport resize. Update media if necessary
    useEffect(() => {
        window.addEventListener('resize', updateMedia);
        return () => window.removeEventListener('resize', updateMedia);
    });

    // Report new viewport width as determined by useEffect.
    const updateMedia = () => {
        setDesktop(window.innerWidth > mobile_breakpoint);
    };

    return (
        <div className="z-10">
            <MUIModal
                open={open}
                onClose={onClose}
                closeAfterTransition
                slots={{ backdrop: Backdrop }}
                slotProps={{ backdrop: { timeout: 200 } }}
            >
                <div
                    style={{ width: isDesktop ? width + '%' : '95%' }}
                    className={`bg-slate-50 p-6 rounded-md absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2`}
                >
                    <div className="flex flex-row justify-between items-center">
                        <div className="text-lg font-bold">{title}</div>
                        <IconButton onClick={onClose} aria-label="close">
                            <Close color="error" />
                        </IconButton>
                    </div>
                    <div className="py-4">{children}</div>
                </div>
            </MUIModal>
        </div>
    );
};

export default Modal;
