import React from 'react';
import { mpSummary } from '../interface/dpoh-entity.interface';
import LoadingUpdates from '../../../components/generic-dashboards/loading/LoadingUpdates';
import LiqCard from '../../shared/UI/layout/LiqCard';
import LiqExhibitTitleContainer from '../../shared/UI/layout/LiqExhibitTitleContainer';
import { MissingData } from '../../../components/misc/MissingData';

const summary = (data: mpSummary | null, loading: boolean) => {
    return (
        <div>
            {loading ? (
                <LoadingUpdates />
            ) : !loading && !data ? (
                <MissingData />
            ) : data ? (
                <ul className="list-inside list-disc flex flex-col gap-2">
                    {data.summary.map((entry: string) => {
                        return <li>{entry}</li>;
                    })}
                    {data.summary.length === 0 && 'No summary available'}
                </ul>
            ) : (
                <></>
            )}
        </div>
    );
};

const MPSummary = ({ data, loading }: { data: mpSummary | null; loading: boolean }) => {
    return (
        <div>
            <LiqExhibitTitleContainer
                title="Summary of MP Discourse in Hansard, Committees and Social Media"
                subtitle={`Last 4 weeks ${
                    data !== null && data.start_date && data.end_date
                        ? `(${data.start_date.split('T')[0]} - ${data.end_date.split('T')[0]})`
                        : ''
                }`}
            />
            <hr className="h-0.5 my-3 bg-slate-100 rounded" />
            {loading ? <LoadingUpdates /> : <LiqCard>{summary(data, loading)}</LiqCard>}
        </div>
    );
};

export default MPSummary;
