import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { RootState } from '../store';
import { globalAPI } from '../../API/globalAPI';
import { entityOverviews, entityCluster } from '../../interfaces/generic-entity.interface';
import axios from 'axios';
import saveAs from 'file-saver';

interface GlobalState {
    overview: entityOverviews | null;
    overview_loading: boolean;
    overview_fail: boolean;

    org_cluster: entityCluster | null;
    org_cluster_loading: boolean;
    org_cluster_fail: boolean;

    sector_cluster: entityCluster | null;
    sector_cluster_loading: boolean;
    sector_cluster_fail: boolean;

    inst_cluster: entityCluster | null;
    inst_cluster_loading: boolean;
    inst_cluster_fail: boolean;

    firm_cluster: entityCluster | null;
    firm_cluster_loading: boolean;
    firm_cluster_fail: boolean;

    dpoh_cluster: entityCluster | null;
    dpoh_cluster_loading: boolean;
    dpoh_cluster_fail: boolean;

    subject_cluster: entityCluster | null;
    subject_cluster_loading: boolean;
    subject_cluster_fail: boolean;

    export_fail: boolean;
    export_loading: boolean;
}

const initialState: GlobalState = {
    overview: null,
    overview_loading: false,
    overview_fail: false,

    org_cluster: null,
    org_cluster_loading: false,
    org_cluster_fail: false,

    sector_cluster: null,
    sector_cluster_loading: false,
    sector_cluster_fail: false,

    inst_cluster: null,
    inst_cluster_loading: false,
    inst_cluster_fail: false,

    firm_cluster: null,
    firm_cluster_loading: false,
    firm_cluster_fail: false,

    dpoh_cluster: null,
    dpoh_cluster_loading: false,
    dpoh_cluster_fail: false,

    subject_cluster: null,
    subject_cluster_loading: false,
    subject_cluster_fail: false,

    export_fail: false,
    export_loading: false,
};

// Fetch the yearly overview data for the current global
export const fetchGlobalOverview = createAsyncThunk(
    'global/fetchGlobalOverviewYearly',
    async (date: string): Promise<entityOverviews> => globalAPI.fetchOverview(date)
);

// Fetch the organization cluster data
export const fetchOrganizationCluster = createAsyncThunk(
    'global/fetchOrganizationCluster',
    async (date: string | undefined): Promise<entityCluster> =>
        globalAPI.fetchCluster('organizations', date)
);

// Fetch the sector cluster data
export const fetchSectorCluster = createAsyncThunk(
    'global/fetchSectorCluster',
    async (date: string | undefined): Promise<entityCluster> =>
        globalAPI.fetchCluster('sectors', date)
);

// Fetch the institution cluster data
export const fetchInstitutionCluster = createAsyncThunk(
    'global/fetchInstitutionCluster',
    async (date: string | undefined): Promise<entityCluster> =>
        globalAPI.fetchCluster('institutions', date)
);

// Fetch the lobby firm cluster data
export const fetchFirmCluster = createAsyncThunk(
    'global/fetchFirmCluster',
    async (date: string | undefined): Promise<entityCluster> =>
        globalAPI.fetchCluster('lobbyfirms', date)
);

// Fetch the lobby firm cluster data
export const fetchDPOHCluster = createAsyncThunk(
    'global/fetchDPOHCluster',
    async (date: string | undefined): Promise<entityCluster> => globalAPI.fetchCluster('dpoh', date)
);

// Fetch the subject cluster data
export const fetchSubjectCluster = createAsyncThunk(
    'global/fetchSubjectCluster',
    async (date: string | undefined): Promise<entityCluster> =>
        globalAPI.fetchCluster('subject', date)
);

export const exportGlobalDashboard = createAsyncThunk(
    'global/exportDashboard',
    async (data: any): Promise<void> => {
        const response = await axios.post(
            `${process.env.REACT_APP_PDF_SERVICE_URL}/global/report`,
            data,
            {
                responseType: 'blob',
            }
        );

        const pdfEncoding = response.data;

        const blob = new Blob([pdfEncoding], { type: 'application/pdf' });

        saveAs(blob, 'LobbyIQ-Global');
    }
);

const globalSlice = createSlice({
    name: 'globalReducer',
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(fetchGlobalOverview.pending, (state) => {
                state.overview_loading = true;
            })
            .addCase(fetchGlobalOverview.fulfilled, (state, action) => {
                state.overview_loading = false;
                state.overview_fail = false;
                state.overview = action.payload;
            })
            .addCase(fetchGlobalOverview.rejected, (state) => {
                state.overview_loading = false;
                state.overview_fail = true;
                state.overview = null;
            })
            .addCase(fetchOrganizationCluster.pending, (state) => {
                state.org_cluster_loading = true;
            })
            .addCase(fetchOrganizationCluster.fulfilled, (state, action) => {
                state.org_cluster_loading = false;
                state.org_cluster_fail = false;
                state.org_cluster = action.payload;
            })
            .addCase(fetchOrganizationCluster.rejected, (state) => {
                state.org_cluster_loading = false;
                state.org_cluster_fail = true;
                state.org_cluster = null;
            })
            .addCase(fetchSectorCluster.pending, (state) => {
                state.sector_cluster_loading = true;
            })
            .addCase(fetchSectorCluster.fulfilled, (state, action) => {
                state.sector_cluster_loading = false;
                state.sector_cluster_fail = false;
                state.sector_cluster = action.payload;
            })
            .addCase(fetchSectorCluster.rejected, (state) => {
                state.sector_cluster_loading = false;
                state.sector_cluster_fail = true;
                state.sector_cluster = null;
            })
            .addCase(fetchInstitutionCluster.pending, (state) => {
                state.inst_cluster_loading = true;
            })
            .addCase(fetchInstitutionCluster.fulfilled, (state, action) => {
                state.inst_cluster_loading = false;
                state.inst_cluster_fail = false;
                state.inst_cluster = action.payload;
            })
            .addCase(fetchInstitutionCluster.rejected, (state) => {
                state.inst_cluster_loading = false;
                state.inst_cluster_fail = true;
                state.inst_cluster = null;
            })
            .addCase(fetchFirmCluster.pending, (state) => {
                state.firm_cluster_loading = true;
            })
            .addCase(fetchFirmCluster.fulfilled, (state, action) => {
                state.firm_cluster_loading = false;
                state.firm_cluster_fail = false;
                state.firm_cluster = action.payload;
            })
            .addCase(fetchFirmCluster.rejected, (state) => {
                state.firm_cluster_loading = false;
                state.firm_cluster_fail = true;
                state.firm_cluster = null;
            })
            .addCase(fetchDPOHCluster.pending, (state) => {
                state.dpoh_cluster_loading = true;
            })
            .addCase(fetchDPOHCluster.fulfilled, (state, action) => {
                state.dpoh_cluster_loading = false;
                state.dpoh_cluster_fail = false;
                state.dpoh_cluster = action.payload;
            })
            .addCase(fetchDPOHCluster.rejected, (state) => {
                state.dpoh_cluster_loading = false;
                state.dpoh_cluster_fail = true;
                state.dpoh_cluster = null;
            })
            .addCase(fetchSubjectCluster.pending, (state) => {
                state.subject_cluster_loading = true;
            })
            .addCase(fetchSubjectCluster.fulfilled, (state, action) => {
                state.subject_cluster_loading = false;
                state.subject_cluster_fail = false;
                state.subject_cluster = action.payload;
            })
            .addCase(fetchSubjectCluster.rejected, (state) => {
                state.subject_cluster_loading = false;
                state.subject_cluster_fail = true;
                state.subject_cluster = null;
            }).addCase(exportGlobalDashboard.pending, (state) => {
                state.export_loading = true;
            })
            .addCase(exportGlobalDashboard.fulfilled, (state, action) => {
                state.export_loading = false;
                state.export_fail = false;
            })
            .addCase(exportGlobalDashboard.rejected, (state) => {
                state.export_loading = false;
                state.export_fail = true;
            });
    },
});

export const selectGlobalOverview = (state: RootState) => state.globalData.overview;
export const selectGlobalOverviewLoading = (state: RootState) => state.globalData.overview_loading;

export const selectOrganizationCluster = (state: RootState) => state.globalData.org_cluster;
export const selectOrganizationClusterLoading = (state: RootState) =>
    state.globalData.org_cluster_loading;

export const selectSectorCluster = (state: RootState) => state.globalData.sector_cluster;
export const selectSectorClusterLoading = (state: RootState) =>
    state.globalData.sector_cluster_loading;

export const selectInstitutionCluster = (state: RootState) => state.globalData.inst_cluster;
export const selectInstitutionClusterLoading = (state: RootState) =>
    state.globalData.inst_cluster_loading;

export const selectFirmCluster = (state: RootState) => state.globalData.firm_cluster;
export const selectFirmClusterLoading = (state: RootState) => state.globalData.firm_cluster_loading;

export const selectDPOHCluster = (state: RootState) => state.globalData.dpoh_cluster;
export const selectDPOHClusterLoading = (state: RootState) => state.globalData.dpoh_cluster_loading;

export const selectSubjectCluster = (state: RootState) => state.globalData.subject_cluster;
export const selectSubjectClusterLoading = (state: RootState) =>
    state.globalData.subject_cluster_loading;

export const selectGlobalData = (state: RootState) => state.globalData;

export const selectGlobalExportLoading = (state: RootState) =>
    state.globalData.export_loading;
export const selectGlobalExportFail = (state: RootState) =>
    state.globalData.export_fail;

export default globalSlice.reducer;
