import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import {
    entityOrg,
    entityOverviews,
    entityRegistration,
} from '../../../interfaces/generic-entity.interface';
import { useSelector } from 'react-redux';
import RegistrationDashboard from '../UI/RegistrationDashboard';
import { useAppDispatch } from '../../../store/store';
import {
    fetchRegistrationActivity,
    fetchRegistrationInfo,
    fetchRegistrationOverview,
    fetchRegistrationTopInstitutions,
    fetchRegistrationTopSubjects,
    selectRegistrationActivity,
    selectRegistrationActivityLoading,
    selectRegistrationInfo,
    selectRegistrationInfoLoading,
    selectRegistrationOverview,
    selectRegistrationOverviewLoading,
    selectRegistrationTopInstitutions,
    selectRegistrationTopInstitutionsLoading,
    selectRegistrationTopSubjects,
    selectRegistrationTopSubjectsLoading,
    selectRegistrationExportFail,
    selectRegistrationExportLoading,
} from '../store/RegistrationSlice';
import { RegistrationActivityCombo } from '../../../interfaces/organization-entity.interface';

const RegistrationDataLayer = () => {
    const dispatch = useAppDispatch();

    // Get the id of the current registration
    const url_params = useParams();
    const reg_id: number = url_params.regId !== undefined ? Number(url_params.regId) : 0;

    const registration_overview: entityOverviews | null = useSelector(selectRegistrationOverview);
    const registration_overview_loading: boolean = useSelector(selectRegistrationOverviewLoading);

    const registration_info: entityRegistration | null = useSelector(selectRegistrationInfo);
    const registration_info_loading: boolean = useSelector(selectRegistrationInfoLoading);

    const activity: RegistrationActivityCombo | null = useSelector(selectRegistrationActivity);
    const activity_loading: boolean = useSelector(selectRegistrationActivityLoading);

    const top_institutions: entityOrg[] | null = useSelector(selectRegistrationTopInstitutions);
    const top_institutions_loading: boolean = useSelector(selectRegistrationTopInstitutionsLoading);

    const top_subjects: entityOrg[] | null = useSelector(selectRegistrationTopSubjects);
    const top_subjects_loading: boolean = useSelector(selectRegistrationTopSubjectsLoading);
    const export_fail = useSelector(selectRegistrationExportFail);
    const export_loading = useSelector(selectRegistrationExportLoading);

    const [date, setDate] = useState<string>(moment.utc(moment()).format('YYYY-MM-DD'));

    useEffect(() => {
        dispatch(
            fetchRegistrationOverview({
                reg_id: reg_id,
            })
        );
        dispatch(
            fetchRegistrationInfo({
                reg_id: reg_id,
            })
        );
        dispatch(
            fetchRegistrationActivity({
                reg_id: reg_id,
                date: date,
            })
        );
        dispatch(
            fetchRegistrationTopInstitutions({
                reg_id: reg_id,
                date: date,
            })
        );
        dispatch(
            fetchRegistrationTopSubjects({
                reg_id: reg_id,
                date: date,
            })
        );
    }, [dispatch, date, reg_id]);

    const overview_headers = ['count'];

    // Get the current year
    const currentYear = moment(date, 'YYYY-MM-DD').year();

    // Populate the list with the last 8 years
    for (let i = currentYear; i >= currentYear - 6; i--) {
        overview_headers.push(String(i));
    }

    return (
        <RegistrationDashboard
            date={date}
            handleDateChange={setDate}
            registrationOverview={registration_overview}
            registrationOverviewLoading={registration_overview_loading}
            registrationOverviewHeaders={overview_headers}
            registrationOverviewIndent={['by Consultants', 'of Ministers or DMs']}
            registrationInfo={registration_info}
            registrationInfoLoading={registration_info_loading}
            activity={activity}
            activityLoading={activity_loading}
            topInstitutions={top_institutions}
            topInstitutionsLoading={top_institutions_loading}
            topSubjects={top_subjects}
            topSubjectsLoading={top_subjects_loading}
            exportFail={export_fail}
            exportLoading={export_loading}
        />
    );
};

export default RegistrationDataLayer;
