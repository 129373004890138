import React from 'react';
import LoadSpinner from '../misc/LoadSpinner';
import LinkIcon from '@mui/icons-material/OpenInNewRounded';
import { Link } from 'react-router-dom';
import { entityListExtra } from '../../interfaces/generic-entity.interface';
import Modal from '../misc/Modal';

interface entityListProps {
    list: entityListExtra[] | null;
    type: 'sectors' | 'institutions' | 'committees' | 'issues' | 'subjects';
    open: boolean;
    handleClose: () => void;
}

/*
    Component: EntityList.tsx
    Params: N/A
    Author: William Brewer
    Desc: Provides a list of entities (sectors, institutions, committees, etc.)
*/
const ModalEntityList = ({ type, list, open, handleClose }: entityListProps) => {
    return (
        <Modal open={open} onClose={handleClose} title={`Select ${type}`}>
            <div className="lg:max-h-[75vh] max-h-72 overflow-y-auto">
                {list === null ? (
                    <LoadSpinner />
                ) : (
                    list.map((entity, idx) => (
                        <Link
                            key={idx}
                            to={`/${type}/${entity.code}?name=${entity.title}`}
                            className={entity.available ? '' : 'text-slate-400'}
                        >
                            <div
                                onClick={handleClose}
                                className="bg-white flex flex-row items-center justify-between p-2 my-2 mr-2 shadow-sm rounded-md text-md"
                            >
                                <div>{entity.title}</div>
                                <LinkIcon style={{ fontSize: 15 }} />
                            </div>
                        </Link>
                    ))
                )}
            </div>
        </Modal>
    );
};

export default ModalEntityList;
