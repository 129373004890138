import React, { useEffect, useState } from 'react';
import {
    Button,
    FormControl,
    FormControlLabel,
    FormLabel,
    Radio,
    RadioGroup,
    TextField,
} from '@mui/material';
import { useAppDispatch } from '../store/store';
import {
    createUser,
    selectCompany,
    selectFirstName,
    selectIndustry,
    selectLastName,
    selectJobTitle,
    selectPhone,
    selectActionLoading,
} from '../store/reducers/userSlice';
import { useSelector } from 'react-redux';
import LicenseAgreementModal from '../components/misc/LicenseAgreementModal';
import { useNavigate, useSearchParams } from 'react-router-dom';
import LoadSpinner from '../components/misc/LoadSpinner';

const BillingPage = () => {
    const dispatch = useAppDispatch();
    const navigate = useNavigate();
    const [searchParams] = useSearchParams();
    const selectedProduct = searchParams.get('product');

    const existingFirstName = useSelector(selectFirstName);
    const existingLastName = useSelector(selectLastName);
    const existingCompany = useSelector(selectCompany);
    const existingJobTitle = useSelector(selectJobTitle);
    const existingIndustry = useSelector(selectIndustry);
    const existingPhone = useSelector(selectPhone);
    const loading = useSelector(selectActionLoading);

    const [firstName, setFirstName] = useState(existingFirstName || '');
    const [lastName, setLastName] = useState(existingLastName || '');
    const [company, setCompany] = useState(existingCompany || '');
    const [jobTitle, setJobTitle] = useState(existingJobTitle || '');
    const [industry, setIndustry] = useState(existingIndustry || '');
    const [phone, setPhone] = useState(existingPhone || '');
    const [didSubmit, setDidSubmit] = useState(false);

    const [productId, setProductId] = useState('');
    const [selectedPeriod, setSelectedPeriod] = useState('annual');

    const [licenseModalOpen, setLicenseModalOpen] = useState<boolean>(false);

    useEffect(() => {
        existingFirstName && setFirstName(existingFirstName);
        existingLastName && setLastName(existingLastName);
        existingCompany && setCompany(existingCompany);
        existingJobTitle && setJobTitle(existingJobTitle);
        existingIndustry && setIndustry(existingIndustry);
        existingPhone && setPhone(existingPhone);
    }, [
        existingFirstName,
        existingLastName,
        existingCompany,
        existingJobTitle,
        existingIndustry,
        existingPhone,
    ]);

    const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault();

        setDidSubmit(true);

        if (
            firstName !== '' &&
            lastName !== '' &&
            company !== '' &&
            jobTitle !== '' &&
            industry !== '' &&
            phone !== ''
        ) {
            dispatch(
                createUser({
                    firstName,
                    lastName,
                    company,
                    job_title: jobTitle,
                    industry,
                    phone,
                })
            )
                .then(() => {
                    setDidSubmit(false);
                    if (selectedPeriod !== 'annual') {
                        // corperate semi-annual
                        setProductId('price_1Ni81BJJ9Ay28yyNWl70HU70');
                    } else if (selectedProduct === 'Professional') {
                        // professional
                        setProductId('price_1Ni82EJJ9Ay28yyNCdT39LIP');
                    } else {
                        // corperate annual
                        setProductId('price_1Ni81BJJ9Ay28yyNqvmGVUiP');
                    }
                    setLicenseModalOpen(true);
                })
                .catch((error: any) => {
                    console.log(error);
                });
        }
    };

    return (
        <div className="lg:p-16 lg:pt-32 pt-24 p-10">
            <div className="flex flex-col justify-center items-center text-center mb-10">
                <div className="font-bold mb-5 lg:text-2xl">
                    Contact Information
                </div>
            </div>
            <form autoComplete="off" onSubmit={handleSubmit}>
                <div className="flex lg:flex-row md:flex-row flex-col justify-center items-center lg:pb-8 pb-6">
                    <div className="mb-5 lg:mb-0 md:mb-0">
                        <div className="p-3">First Name</div>
                        <div className="lg:w-96 w-72 lg:pr-4 md:pr-4">
                            <TextField
                                fullWidth
                                id="first-name"
                                placeholder="First Name"
                                variant="outlined"
                                required
                                onChange={(e) => setFirstName(e.target.value)}
                                value={firstName}
                                error={didSubmit && firstName === ''}
                            />
                        </div>
                    </div>
                    <div>
                        <div className="lg:pl-6 md:pl-6 p-3">Last Name</div>
                        <div className="lg:w-96 w-72 lg:pl-4 md:pl-4">
                            <TextField
                                fullWidth
                                id="last-name"
                                placeholder="Last Name"
                                variant="outlined"
                                required
                                onChange={(e) => setLastName(e.target.value)}
                                value={lastName}
                                error={didSubmit && lastName === ''}
                            />
                        </div>
                    </div>
                </div>
                <div className="flex lg:flex-row md:flex-row flex-col justify-center items-center lg:pb-8 pb-6">
                    <div className="mb-5 lg:mb-0 md:mb-0">
                        <div className="p-3">Industry</div>
                        <div className="lg:w-96 w-72 lg:pr-4 md:pr-4">
                            <TextField
                                fullWidth
                                id="industry"
                                placeholder="Oil, Telecoms, etc."
                                variant="outlined"
                                required
                                onChange={(e) => setIndustry(e.target.value)}
                                value={industry}
                                error={didSubmit && industry === ''}
                            />
                        </div>
                    </div>
                    <div>
                        <div className="lg:pl-6 md:pl-6 p-3">Phone Number</div>
                        <div className="lg:w-96 w-72 lg:pl-4 md:pl-4">
                            <TextField
                                fullWidth
                                id="phone-num"
                                placeholder="+1 123-456-7890"
                                variant="outlined"
                                required
                                onChange={(e) =>
                                    setPhone(e.target.value.replace(/\D/g, ''))
                                }
                                value={phone}
                                error={didSubmit && phone === ''}
                            />
                        </div>
                    </div>
                </div>
                <div className="flex lg:flex-row md:flex-row flex-col justify-center items-center lg:pb-12 md:pb-10 pb-6">
                    <div className="mb-5 lg:mb-0 md:mb-0">
                        <div className="p-3">Company/Organization</div>
                        <div className="lg:w-96 w-72 lg:pr-4 md:pr-4">
                            <TextField
                                fullWidth
                                id="comp-org"
                                placeholder="Company/Organization"
                                variant="outlined"
                                required
                                onChange={(e) => setCompany(e.target.value)}
                                value={company}
                                error={didSubmit && company === ''}
                            />
                        </div>
                    </div>
                    <div>
                        <div className="lg:pl-6 md:pl-6 p-3">Job Title</div>
                        <div className="lg:w-96 w-72 lg:pl-4 md:pl-4">
                            <TextField
                                fullWidth
                                id="occupation"
                                placeholder="Head of Marketing"
                                variant="outlined"
                                required
                                onChange={(e) => setJobTitle(e.target.value)}
                                value={jobTitle}
                                error={didSubmit && jobTitle === ''}
                            />
                        </div>
                    </div>
                </div>
                <div className="flex flex-col items-center mb-3 text-xl font-bold mb-5 lg:text-2xl">
                    {`${selectedProduct || 'Professoinal'} Subscription`}
                </div>

                <div className="flex flex-col items-center">
                    <FormControl variant="standard">
                        <div className="flex flex-col items-center">
                            <FormLabel id="demo-error-radios">
                                Available billing periods:
                            </FormLabel>
                            <RadioGroup
                                defaultValue="annual"
                                onChange={(event) =>
                                    setSelectedPeriod(
                                        event.target.value as string
                                    )
                                }
                            >
                                <FormControlLabel
                                    value="annual"
                                    control={<Radio />}
                                    label="Annual"
                                />
                                {selectedProduct === 'Corporate' && (
                                    <FormControlLabel
                                        value="semi-annual"
                                        control={<Radio />}
                                        label="Semi Annual "
                                    />
                                )}
                            </RadioGroup>
                        </div>

                        {loading ? (
                            <LoadSpinner />
                        ) : (
                            <div className="flex flex-row justify-center items-center mt-4">
                                <Button
                                    variant="outlined"
                                    size="large"
                                    onClick={() => navigate('/pricing')}
                                    disabled={loading}
                                >
                                    <div className="font-sans not-italic font-bold text-normal">
                                        Back to Pricing
                                    </div>
                                </Button>
                                <div className="mx-2" />
                                <Button
                                    className="bg-slate-600"
                                    variant="contained"
                                    size="large"
                                    type="submit"
                                    disabled={loading}
                                >
                                    <div className="font-sans not-italic font-bold text-normal">
                                        Proceed
                                    </div>
                                </Button>
                            </div>
                        )}
                    </FormControl>
                </div>
            </form>
            <LicenseAgreementModal
                open={licenseModalOpen}
                handleClose={() => setLicenseModalOpen(false)}
                selectedProductId={productId}
            />
        </div>
    );
};

export default BillingPage;
