import React, { useState } from 'react';
import validator from 'validator';

import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import SendIcon from '@mui/icons-material/Send';
import { useSelector } from 'react-redux';
import {
    selectFirstName,
    selectLastName,
    selectCompany,
    selectJobTitle,
    selectPhone,
    selectEmail,
    selectIndustry,
    selectUserLoading,
} from '../store/reducers/userSlice';
import LoadPage from './misc/LoadPage';
import LoadSpinner from '../components/misc/LoadSpinner';
import axios from 'axios';

const RequestDemonstrationPage = () => {

    const userLoading = useSelector(selectUserLoading);

    const existingEmail = useSelector(selectEmail);
    const existingFirstName = useSelector(selectFirstName);
    const existingLastName = useSelector(selectLastName);
    const existingCompany = useSelector(selectCompany);
    const existingJobTitle = useSelector(selectJobTitle);
    const existingIndustry = useSelector(selectIndustry);
    const existingPhone = useSelector(selectPhone);

    const [email, setEmail] = useState(existingEmail || '');
    const [firstName, setFirstName] = useState(existingFirstName || '');
    const [lastName, setLastName] = useState(existingLastName || '');
    const [company, setCompany] = useState(existingCompany || '');
    const [industry, setIndustry] = useState(existingIndustry || '');
    const [jobTitle, setJobTitle] = useState(existingJobTitle || '');
    const [phone, setPhone] = useState(existingPhone || '');

    const [emailError, setEmailError] = useState(false);
    const [firstNameError, setFirstNameError] = useState(false);
    const [lastNameError, setLastNameError] = useState(false);
    const [industryError, setIndustryError] = useState(false);
    const [companyError, setCompanyError] = useState(false);
    const [phoneError, setPhoneError] = useState(false);
    const [jobTitleError, setJobTitleError] = useState(false);

    const [sendError, setSendError] = useState(0);
    const [loading, setLoading] = useState(false);

    const [resultModalOpen, setResultModalOpen] = React.useState(false);

    const handleClickOpen = () => {
        setResultModalOpen(true);
    };

    const handleClose = () => {
        setResultModalOpen(false);
    };

    const sendMessage = async () => {
        const url = process.env.REACT_APP_CONTACT_URL || '';

        const data = {
            first_name: firstName,
            last_name: lastName,
            company_name: company,
            email,
            body: "User is requesting a call.",
        };

        return await axios.post(url, data);
    };

    const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault();

        setSendError(0);
        setFirstNameError(false);
        setLastNameError(false);
        setIndustryError(false);
        setCompanyError(false);
        setPhoneError(false);
        setJobTitleError(false);
        setEmailError(false);

        // check for required fields
        if (!validator.isEmail(email)) {
            setEmailError(true);
        }
        if (firstName === '') {
            setFirstNameError(true);
        }
        if (lastName === '') {
            setLastNameError(true);
        }
        if (company === '') {
            setCompanyError(true);
        }
        if (existingEmail && industry === '') {
            setIndustryError(true);
        }
        if (phone === '') {
            setPhoneError(true);
        }
        if (jobTitle === '') {
            setJobTitleError(true);
        }

        if (
            validator.isEmail(email) &&
            firstName !== '' &&
            lastName !== '' &&
            company !== '' &&
            phone !== '' &&
            jobTitle !== ''
        ) {
            setLoading(true);
            handleClickOpen();

            try {
                await sendMessage();
            } catch (error: any) {
                console.log(error);
                setSendError(error.response.status);
            }
            setLoading(false);
        }
    };

    return userLoading ? (
        <LoadPage />
    ) : (
        <div className="lg:p-16 lg:pt-32 pt-24 p-10 mb-16">
            <Dialog
                open={resultModalOpen}
                onClose={loading ? () => {} : handleClose}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title">
                    {loading
                        ? 'Submitting Request'
                        : sendError
                        ? 'Error'
                        : 'Request Sent'}
                </DialogTitle>
                <DialogContent>
                    {loading ? (
                        <div className="flex flex row justify-center items-center">
                            <LoadSpinner />
                        </div>
                    ) : (
                        <DialogContentText id="alert-dialog-description">
                            {sendError === 500
                                ? 'Error submitting request. Please check entered info and try again.'
                                : sendError === 400 ? 'Please check the submitted email is correct and try again.' :
                                'Thank you for requesting a call with LobbyIQ. We will be in touch soon.'}
                        </DialogContentText>
                    )}
                </DialogContent>
                <DialogActions>
                    <Button
                        onClick={handleClose}
                        autoFocus
                        disabled={loading}
                    >
                        Close
                    </Button>
                </DialogActions>
            </Dialog>
            <div className="flex flex-col justify-center items-center text-center mb-12">
                <div className="mb-5 lg:text-2xl">
                    Please fill out this form to request a call or demonstration
                </div>
            </div>
            <form autoComplete="off" onSubmit={handleSubmit}>
                <div className="flex lg:flex-row md:flex-row flex-col justify-center items-center lg:pb-8 pb-6">
                    <div className="mb-5 lg:mb-0 md:mb-0">
                        <div className="p-3">First Name</div>
                        <div className="lg:w-96 w-72 lg:pr-4 md:pr-4">
                            <TextField
                                fullWidth
                                id="first-name"
                                placeholder="First Name"
                                variant="outlined"
                                required
                                onChange={(e) => setFirstName(e.target.value)}
                                value={firstName}
                                error={firstNameError}
                            />
                        </div>
                    </div>
                    <div>
                        <div className="lg:pl-6 md:pl-6 p-3">Last Name</div>
                        <div className="lg:w-96 w-72 lg:pl-4 md:pl-4">
                            <TextField
                                fullWidth
                                id="last-name"
                                placeholder="Last Name"
                                variant="outlined"
                                required
                                onChange={(e) => setLastName(e.target.value)}
                                value={lastName}
                                error={lastNameError}
                            />
                        </div>
                    </div>
                </div>
                <div className="flex lg:flex-row md:flex-row flex-col justify-center items-center lg:pb-8 pb-6">
                    <div className="mb-5 lg:mb-0 md:mb-0">
                        <div className="p-3">
                            {existingEmail ? 'Industry' : 'E-mail'}
                        </div>
                        {existingEmail ? (
                            <div className="lg:w-96 w-72 lg:pr-4 md:pr-4">
                                <TextField
                                    fullWidth
                                    id="industry"
                                    placeholder="Industry"
                                    variant="outlined"
                                    {...(industryError
                                        ? { label: 'Invalid industry' }
                                        : {})}
                                    required
                                    onChange={(e) =>
                                        setIndustry(e.target.value)
                                    }
                                    value={industry}
                                    error={industryError}
                                />
                            </div>
                        ) : (
                            <div className="lg:w-96 w-72 lg:pr-4 md:pr-4">
                                <TextField
                                    fullWidth
                                    id="email"
                                    placeholder="E-mail"
                                    variant="outlined"
                                    {...(emailError
                                        ? { label: 'Invalid email' }
                                        : {})}
                                    required
                                    onChange={(e) => setEmail(e.target.value)}
                                    value={email}
                                    error={emailError}
                                />
                            </div>
                        )}
                    </div>
                    <div>
                        <div className="lg:pl-6 md:pl-6 p-3">
                            Phone Number
                        </div>
                        <div className="lg:w-96 w-72 lg:pl-4 md:pl-4">
                            <TextField
                                fullWidth
                                id="phone-num"
                                placeholder="+1 123-456-7890"
                                variant="outlined"
                                onChange={(e) => setPhone(e.target.value.trim())}
                                required
                                value={phone}
                                error={phoneError}
                            />
                        </div>
                    </div>
                </div>
                <div className="flex lg:flex-row md:flex-row flex-col justify-center items-center lg:pb-12 md:pb-10 pb-6">
                    <div className="mb-5 lg:mb-0 md:mb-0">
                        <div className="p-3">Company/Organization</div>
                        <div className="lg:w-96 w-72 lg:pr-4 md:pr-4">
                            <TextField
                                fullWidth
                                id="comp-org"
                                placeholder="Company/Organization"
                                variant="outlined"
                                required
                                onChange={(e) => setCompany(e.target.value)}
                                value={company}
                                error={companyError}
                            />
                        </div>
                    </div>
                    <div>
                        <div className="lg:pl-6 md:pl-6 p-3">
                            Job Title
                        </div>
                        <div className="lg:w-96 w-72 lg:pl-4 md:pl-4">
                            <TextField
                                fullWidth
                                id="occupation"
                                placeholder="Head of Marketing"
                                variant="outlined"
                                onChange={(e) => setJobTitle(e.target.value)}
                                required
                                value={jobTitle}
                                error={jobTitleError}
                            />
                        </div>
                    </div>
                </div>
                <div className="flex flex-row justify-center items-center lg:mt-16 md:mt-10 mt-4">
                    <Button
                        className="bg-slate-600"
                        variant="contained"
                        size="large"
                        type="submit"
                        endIcon={<SendIcon />}
                    >
                        <div className="font-sans not-italic font-bold text-normal">
                            Request
                        </div>
                    </Button>
                </div>
            </form>
        </div>
    )
};

export default RequestDemonstrationPage;
