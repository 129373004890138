import axios from 'axios';
import React, { useState } from 'react';
import FileOpenRoundedIcon from '@mui/icons-material/FileOpenRounded';
import { bumperSuperList } from '../../../interfaces/bumper-types.interface';
import Modal from '../../misc/Modal';
import moment from 'moment';
import { EntityRepo } from '../../../features/config/EntityRepo';
import { EntityType } from '../../../features/config/Entities';

const FileButton = ({
    primaryText,
    primaryAction,
}: {
    primaryText: string;
    primaryAction: () => void;
}) => (
    <button
        className="flex flex-row items-center hover:underline underline-offset-2 gap-1"
        onClick={primaryAction}
    >
        {primaryText}
        <FileOpenRoundedIcon fontSize="inherit" />
    </button>
);

const CmteBumperDownload = ({ data, type }: { data: bumperSuperList; type: EntityRepo }) => {
    const [errorOpen, setErrorOpen] = useState<boolean>(false);
    const [docType, setDocType] = useState<'summary' | 'transcript'>('summary');

    let resPath = type === EntityType.Committee ? 'hoc' : 'senate';

    let shouldShowTranscript = true;
    const meetingDate: moment.Moment = moment(data.date, 'YYYY-MM-DD');
    const today = moment();

    // Don't show transcript button if its been two weeks since the meeting
    if (today.isSameOrAfter(meetingDate.add(14, 'days'), 'day')) shouldShowTranscript = false;

    const handleClick = async (type: 'summary' | 'transcript') => {
        setDocType(type);
        try {
            const filename = `${data.parliament}-${data.session}/${resPath}/${data.committee}_${data.meetingNo}.pdf`;
            const response = await axios.get(
                `${process.env.REACT_APP_API_BASE_URL}/download/blob?filename=${type}/${filename}`,
                {
                    responseType: 'blob',
                }
            );

            const blob = new Blob([response.data], { type: 'application/pdf' });
            const url = window.URL.createObjectURL(blob);
            const link = document.createElement('a');

            link.href = url;
            link.setAttribute('download', filename);
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
        } catch (err: any) {
            setErrorOpen(true);
        }
    };

    return (
        <div className="text-sky-600 text-sm font-medium">
            <div className="flex flex-row items-center gap-2">
                <FileButton
                    primaryText="Meeting Summary"
                    primaryAction={() => handleClick('summary')}
                />
                {shouldShowTranscript && (
                    <>
                        <hr className="w-0.5 h-3 mx-2 bg-slate-200 rounded" />
                        <FileButton
                            primaryText="Meeting Transcript"
                            primaryAction={() => handleClick('transcript')}
                        />
                    </>
                )}
            </div>
            <Modal
                title={`Meeting ${docType} Not found`}
                open={errorOpen}
                width={35}
                onClose={() => setErrorOpen(false)}
            >
                <div className="flex flex-col items-center mt-12 gap-12">
                    <FileOpenRoundedIcon sx={{ fontSize: 64 }} />
                    <p>
                        Unfortunately, we don't currently have a {docType} on file for{' '}
                        {data.committee} meeting number {data.meetingNo}
                    </p>
                </div>
            </Modal>
        </div>
    );
};

export default CmteBumperDownload;
