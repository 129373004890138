import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { RootState } from '../../../store/store';
import {
    entityOverviews,
    entityLobbyingActivity,
    entityOrg,
} from '../../../interfaces/generic-entity.interface';
import { DPOHAPI } from '../API/DPOHAPI';
import { entityActivity } from '../../../interfaces/organization-entity.interface';
import axios from 'axios';
import saveAs from 'file-saver';
import { bumperSuperList } from '../../../interfaces/bumper-types.interface';
import { mpBiography, mpSummary } from '../interface/dpoh-entity.interface';
import { Tweet } from '../../issue-dashboards/interface/issue-entity.interface';

interface DPOHState {
    overview: entityOverviews | null;
    overview_loading: boolean;
    overview_fail: boolean;

    summary: mpSummary | null;
    summary_loading: boolean;
    summary_fail: boolean;

    bio: mpBiography | null;
    bio_loading: boolean;
    bio_fail: boolean;

    sentences: bumperSuperList[] | null;
    sentences_loading: boolean;
    sentences_fail: boolean;

    top_sectors: entityOrg[] | null;
    top_sectors_loading: boolean;
    top_sectors_fail: boolean;

    tweets: Tweet[] | null;
    tweets_loading: boolean;
    tweets_fail: boolean;

    top_partners: entityOrg[] | null;
    top_partners_loading: boolean;
    top_partners_fail: boolean;

    top_subjects: entityOrg[] | null;
    top_subjects_loading: boolean;
    top_subjects_fail: boolean;

    lobbying_activity: entityLobbyingActivity[] | null;
    lobbying_activity_loading: boolean;
    lobbying_activity_fail: boolean;

    org_activity: entityActivity[] | null;
    org_activity_loading: boolean;
    org_activity_fail: boolean;

    export_loading: boolean;
    export_fail: boolean;
}

const initialState: DPOHState = {
    overview: null,
    overview_loading: false,
    overview_fail: false,

    summary: null,
    summary_loading: false,
    summary_fail: false,

    bio: null,
    bio_loading: false,
    bio_fail: false,

    sentences: null,
    sentences_loading: false,
    sentences_fail: false,

    tweets: null,
    tweets_loading: false,
    tweets_fail: false,

    top_sectors: null,
    top_sectors_loading: false,
    top_sectors_fail: false,

    top_partners: null,
    top_partners_loading: false,
    top_partners_fail: false,

    top_subjects: null,
    top_subjects_loading: false,
    top_subjects_fail: false,

    lobbying_activity: null,
    lobbying_activity_loading: false,
    lobbying_activity_fail: false,

    org_activity: null,
    org_activity_loading: false,
    org_activity_fail: false,

    export_loading: false,
    export_fail: false,
};

// Fetch the yearly overview data for the current DPOH
export const fetchDPOHOverview = createAsyncThunk(
    'dpoh/fetchDPOHOverview',
    async ({
        inst,
        dpoh,
        date,
        includeHOC,
        includeMinisterPosition,
    }: {
        inst: string;
        dpoh: string;
        date: string;
        includeHOC: boolean;
        includeMinisterPosition: boolean;
    }): Promise<entityOverviews> =>
        DPOHAPI.fetchDPOHOverview(inst, dpoh, date, includeHOC, includeMinisterPosition)
);

// Fetch MP AI Summary
export const fetchMPSummary = createAsyncThunk(
    'dpoh/fetchMPSummary',
    async ({ mpFullName, date }: { mpFullName: string; date: string }): Promise<mpSummary> =>
        DPOHAPI.fetchMPSummary(mpFullName, date)
);

// Fetch MP Biography
export const fetchMPBio = createAsyncThunk(
    'dpoh/fetchMPBio',
    async ({ mpFullName, date }: { mpFullName: string; date: string }): Promise<mpBiography> =>
        DPOHAPI.fetchMPBio(mpFullName, date)
);

// Fetch HANS meeting sentences from the given MP
export const fetchMPHansSentences = createAsyncThunk(
    'dpoh/fetchMPHansSentences',
    async ({
        inst,
        mpFullName,
        date,
    }: {
        inst: string;
        mpFullName: string;
        date: string;
    }): Promise<bumperSuperList[]> => DPOHAPI.fetchMPHansSentences(inst, mpFullName, date)
);

// Fetch Tweets from the given MP
export const fetchMPTweets = createAsyncThunk(
    'dpoh/fetchMPTweets',
    async ({
        inst,
        mpFullName,
        date,
    }: {
        inst: string;
        mpFullName: string;
        date: string;
    }): Promise<Tweet[]> => DPOHAPI.fetchMPTweets(inst, mpFullName, date)
);

// Fetch the top lobbying sectors for the current DPOH
export const fetchDPOHTopSectors = createAsyncThunk(
    'dpoh/fetchDPOHTopSectors',
    async ({
        inst,
        dpoh,
        date,
        includeHOC,
        includeMinisterPosition,
    }: {
        inst: string;
        dpoh: string;
        date: string;
        includeHOC: boolean;
        includeMinisterPosition: boolean;
    }): Promise<entityOrg[]> =>
        DPOHAPI.fetchDPOHTopSectors(inst, dpoh, date, includeHOC, includeMinisterPosition)
);

// Fetch the top lobbying partners (firms) for the current DPOH
export const fetchDPOHInternalExternalSplit = createAsyncThunk(
    'dpoh/fetchDPOHTopPartners',
    async ({
        inst,
        dpoh,
        date,
        includeHOC,
        includeMinisterPosition,
    }: {
        inst: string;
        dpoh: string;
        date: string;
        includeHOC: boolean;
        includeMinisterPosition: boolean;
    }): Promise<entityOrg[]> =>
        DPOHAPI.fetchDPOHInternalExternalSplit(
            inst,
            dpoh,
            date,
            includeHOC,
            includeMinisterPosition
        )
);

// Fetch the top lobbying partners (firms) for the current DPOH
export const fetchDPOHTopSubjects = createAsyncThunk(
    'dpoh/fetchDPOHTopSubjects',
    async ({
        inst,
        dpoh,
        date,
        includeHOC,
        includeMinisterPosition,
    }: {
        inst: string;
        dpoh: string;
        date: string;
        includeHOC: boolean;
        includeMinisterPosition: boolean;
    }): Promise<entityOrg[]> =>
        DPOHAPI.fetchDPOHTopSubjects(inst, dpoh, date, includeHOC, includeMinisterPosition)
);

// Fetch the recent lobbying activity for the current DPOH
export const fetchDPOHRecentLobbyingActivity = createAsyncThunk(
    'dpoh/fetchDPOHRecentLobbyingActivity',
    async ({
        inst,
        dpoh,
        date,
        includeHOC,
        includeMinisterPosition,
    }: {
        inst: string;
        dpoh: string;
        date: string;
        includeHOC: boolean;
        includeMinisterPosition: boolean;
    }): Promise<entityLobbyingActivity[]> =>
        DPOHAPI.fetchDPOHRecentLobbyingActivity(
            inst,
            dpoh,
            date,
            includeHOC,
            includeMinisterPosition
        )
);

// Fetch the organization activity for the current DPOH
export const fetchDPOHOrgActivity = createAsyncThunk(
    'dpoh/fetchDPOHOrgActivity',
    async ({
        inst,
        dpoh,
        date,
        includeHOC,
        includeMinisterPosition,
    }: {
        inst: string;
        dpoh: string;
        date: string;
        includeHOC: boolean;
        includeMinisterPosition: boolean;
    }): Promise<entityActivity[]> =>
        DPOHAPI.fetchDPOHOrgActivity(inst, dpoh, date, includeHOC, includeMinisterPosition)
);

export const exportDPOHDashboard = createAsyncThunk(
    'dpoh/exportDashboard',
    async (data: any): Promise<void> => {
        const response = await axios.post(
            `${process.env.REACT_APP_PDF_SERVICE_URL}/dpoh/report`,
            data,
            {
                responseType: 'blob',
            }
        );

        const pdfEncoding = response.data;

        const blob = new Blob([pdfEncoding], { type: 'application/pdf' });

        saveAs(blob, 'LobbyIQ-DPOH');
    }
);

const sectorSlice = createSlice({
    name: 'institutionReducer',
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(fetchDPOHOverview.pending, (state) => {
                state.overview_loading = true;
            })
            .addCase(fetchDPOHOverview.fulfilled, (state, action) => {
                state.overview_loading = false;
                state.overview_fail = false;
                state.overview = action.payload;
            })
            .addCase(fetchDPOHOverview.rejected, (state) => {
                state.overview_loading = false;
                state.overview_fail = true;
                state.overview = null;
            })
            .addCase(fetchMPSummary.pending, (state) => {
                state.summary_loading = true;
            })
            .addCase(fetchMPSummary.fulfilled, (state, action) => {
                state.summary_loading = false;
                state.summary_fail = false;
                state.summary = action.payload;
            })
            .addCase(fetchMPSummary.rejected, (state) => {
                state.summary_loading = false;
                state.summary_fail = true;
                state.summary = null;
            })
            .addCase(fetchMPBio.pending, (state) => {
                state.bio_loading = true;
            })
            .addCase(fetchMPBio.fulfilled, (state, action) => {
                state.bio_loading = false;
                state.bio_fail = false;
                state.bio = action.payload;
            })
            .addCase(fetchMPBio.rejected, (state) => {
                state.bio_loading = false;
                state.bio_fail = true;
                state.bio = null;
            })
            .addCase(fetchMPHansSentences.pending, (state) => {
                state.sentences_loading = true;
            })
            .addCase(fetchMPHansSentences.fulfilled, (state, action) => {
                state.sentences_loading = false;
                state.sentences_fail = false;
                state.sentences = action.payload;
            })
            .addCase(fetchMPHansSentences.rejected, (state) => {
                state.sentences_loading = false;
                state.sentences_fail = true;
                state.sentences = null;
            })
            .addCase(fetchMPTweets.pending, (state) => {
                state.tweets_loading = true;
            })
            .addCase(fetchMPTweets.fulfilled, (state, action) => {
                state.tweets_loading = false;
                state.tweets_fail = false;
                state.tweets = action.payload;
            })
            .addCase(fetchMPTweets.rejected, (state) => {
                state.tweets_loading = false;
                state.tweets_fail = true;
                state.tweets = null;
            })
            .addCase(fetchDPOHTopSectors.pending, (state) => {
                state.top_sectors_loading = true;
            })
            .addCase(fetchDPOHTopSectors.fulfilled, (state, action) => {
                state.top_sectors_loading = false;
                state.top_sectors_fail = false;
                state.top_sectors = action.payload;
            })
            .addCase(fetchDPOHTopSectors.rejected, (state) => {
                state.top_sectors_loading = false;
                state.top_sectors_fail = true;
                state.top_sectors = null;
            })
            .addCase(fetchDPOHInternalExternalSplit.pending, (state) => {
                state.top_partners_loading = true;
            })
            .addCase(fetchDPOHInternalExternalSplit.fulfilled, (state, action) => {
                state.top_partners_loading = false;
                state.top_partners_fail = false;
                state.top_partners = action.payload;
            })
            .addCase(fetchDPOHInternalExternalSplit.rejected, (state) => {
                state.top_partners_loading = false;
                state.top_partners_fail = true;
                state.top_partners = null;
            })
            .addCase(fetchDPOHTopSubjects.pending, (state) => {
                state.top_subjects_loading = true;
            })
            .addCase(fetchDPOHTopSubjects.fulfilled, (state, action) => {
                state.top_subjects_loading = false;
                state.top_subjects_fail = false;
                state.top_subjects = action.payload;
            })
            .addCase(fetchDPOHTopSubjects.rejected, (state) => {
                state.top_subjects_loading = false;
                state.top_subjects_fail = true;
                state.top_subjects = null;
            })
            .addCase(fetchDPOHRecentLobbyingActivity.pending, (state) => {
                state.lobbying_activity_loading = true;
            })
            .addCase(fetchDPOHRecentLobbyingActivity.fulfilled, (state, action) => {
                state.lobbying_activity_loading = false;
                state.lobbying_activity_fail = false;
                state.lobbying_activity = action.payload;
            })
            .addCase(fetchDPOHRecentLobbyingActivity.rejected, (state) => {
                state.lobbying_activity_loading = false;
                state.lobbying_activity_fail = true;
                state.lobbying_activity = null;
            })
            .addCase(fetchDPOHOrgActivity.pending, (state) => {
                state.org_activity_loading = true;
            })
            .addCase(fetchDPOHOrgActivity.fulfilled, (state, action) => {
                state.org_activity_loading = false;
                state.org_activity_fail = false;
                state.org_activity = action.payload;
            })
            .addCase(fetchDPOHOrgActivity.rejected, (state) => {
                state.org_activity_loading = false;
                state.org_activity_fail = true;
                state.org_activity = null;
            })
            .addCase(exportDPOHDashboard.pending, (state) => {
                state.export_loading = true;
            })
            .addCase(exportDPOHDashboard.fulfilled, (state, action) => {
                state.export_loading = false;
                state.export_fail = false;
            })
            .addCase(exportDPOHDashboard.rejected, (state) => {
                state.export_loading = false;
                state.export_fail = true;
            });
    },
});

export const selectDPOHOverview = (state: RootState) => state.DPOHData.overview;
export const selectDPOHOverviewLoading = (state: RootState) => state.DPOHData.overview_loading;

export const selectDPOHBio = (state: RootState) => state.DPOHData.bio;
export const selectDPOHBioLoading = (state: RootState) => state.DPOHData.bio_loading;

export const selectDPOHSummary = (state: RootState) => state.DPOHData.summary;
export const selectDPOHSummaryLoading = (state: RootState) => state.DPOHData.summary_loading;

export const selectDPOHSentences = (state: RootState) => state.DPOHData.sentences;
export const selectDPOHSentencesLoading = (state: RootState) => state.DPOHData.sentences_loading;

export const selectDPOHTweets = (state: RootState) => state.DPOHData.tweets;
export const selectDPOHTweetsLoading = (state: RootState) => state.DPOHData.tweets_loading;

export const selectDPOHTopSectors = (state: RootState) => state.DPOHData.top_sectors;
export const selectDPOHTopSectorsLoading = (state: RootState) => state.DPOHData.top_sectors_loading;

export const selectDPOHTopPartners = (state: RootState) => state.DPOHData.top_partners;
export const selectDPOHTopPartnersLoading = (state: RootState) =>
    state.DPOHData.top_partners_loading;

export const selectDPOHTopSubjects = (state: RootState) => state.DPOHData.top_subjects;
export const selectDPOHTopSubjectsLoading = (state: RootState) =>
    state.DPOHData.top_subjects_loading;

export const selectDPOHLobbyingActivity = (state: RootState) => state.DPOHData.lobbying_activity;
export const selectDPOHLobbyingActivityLoading = (state: RootState) =>
    state.DPOHData.lobbying_activity_loading;

export const selectDPOHOrgActivity = (state: RootState) => state.DPOHData.org_activity;
export const selectDPOHOrgActivityLoading = (state: RootState) =>
    state.DPOHData.org_activity_loading;

export const selectDPOHExportLoading = (state: RootState) => state.DPOHData.export_loading;
export const selectDPOHExportFail = (state: RootState) => state.DPOHData.export_fail;

export const institutionData = (state: RootState) => state.institutionData;

export default sectorSlice.reducer;
