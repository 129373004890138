import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { RootState } from '../../../store/store';
import { issueAPI } from '../API/issueAPI';
import {
    entityCluster,
    entityLobbyingActivity,
    entityOverviews,
    entityRegistration,
} from '../../../interfaces/generic-entity.interface';
import {
    issueInfo,
    keywordMentions,
    topicOverview,
    Tweet,
} from '../interface/issue-entity.interface';
import axios from 'axios';
import saveAs from 'file-saver';
import { recentUpdates } from '../../shared/interfaces/whats-new.interface';

interface IssueState {
    updates: recentUpdates | null;
    updates_loading: boolean;
    updates_fail: boolean;

    mentions: keywordMentions[] | null;
    mentions_loading: boolean;
    mentions_fail: boolean;

    issue_info: issueInfo | null;
    issue_info_loading: boolean;
    issue_info_fail: boolean;

    overview: entityOverviews | null;
    overview_loading: boolean;
    overview_fail: boolean;

    sentences: any[] | null;
    sentences_loading: boolean;
    sentences_fail: boolean;

    recent_tweets: Tweet[] | null;
    recent_tweets_loading: boolean;

    lobbying_activity: entityLobbyingActivity[] | null;
    lobbying_activity_loading: boolean;
    lobbying_activity_fail: boolean;

    recent_reg: entityRegistration[] | null;
    recent_reg_loading: boolean;
    recent_reg_fail: boolean;

    recent_dereg: entityRegistration[] | null;
    recent_dereg_loading: boolean;
    recent_dereg_fail: boolean;

    mentions_cluster: entityCluster | null;
    mentions_cluster_loading: boolean;
    mentions_cluster_fail: boolean;

    org_cluster: entityCluster | null;
    org_cluster_loading: boolean;
    org_cluster_fail: boolean;

    sector_cluster: entityCluster | null;
    sector_cluster_loading: boolean;
    sector_cluster_fail: boolean;

    inst_cluster: entityCluster | null;
    inst_cluster_loading: boolean;
    inst_cluster_fail: boolean;

    firm_cluster: entityCluster | null;
    firm_cluster_loading: boolean;
    firm_cluster_fail: boolean;

    dpoh_cluster: entityCluster | null;
    dpoh_cluster_loading: boolean;
    dpoh_cluster_fail: boolean;

    subject_cluster: entityCluster | null;
    subject_cluster_loading: boolean;
    subject_cluster_fail: boolean;

    export_fail: boolean;
    export_loading: boolean;
    create_loading: boolean;
}

const initialState: IssueState = {
    updates: null,
    updates_loading: false,
    updates_fail: false,

    mentions: null,
    mentions_loading: false,
    mentions_fail: false,

    issue_info: null,
    issue_info_loading: false,
    issue_info_fail: false,

    overview: null,
    overview_loading: false,
    overview_fail: false,

    sentences: null,
    sentences_loading: false,
    sentences_fail: false,

    recent_tweets: null,
    recent_tweets_loading: false,

    lobbying_activity: null,
    lobbying_activity_loading: false,
    lobbying_activity_fail: false,

    recent_reg: null,
    recent_reg_loading: false,
    recent_reg_fail: false,

    recent_dereg: null,
    recent_dereg_loading: false,
    recent_dereg_fail: false,

    mentions_cluster: null,
    mentions_cluster_loading: false,
    mentions_cluster_fail: false,

    org_cluster: null,
    org_cluster_loading: false,
    org_cluster_fail: false,

    sector_cluster: null,
    sector_cluster_loading: false,
    sector_cluster_fail: false,

    inst_cluster: null,
    inst_cluster_loading: false,
    inst_cluster_fail: false,

    firm_cluster: null,
    firm_cluster_loading: false,
    firm_cluster_fail: false,

    dpoh_cluster: null,
    dpoh_cluster_loading: false,
    dpoh_cluster_fail: false,

    subject_cluster: null,
    subject_cluster_loading: false,
    subject_cluster_fail: false,

    export_fail: false,
    export_loading: false,
    create_loading: false,
};

// Fetch the recent updates for the current issue
export const fetchIssueUpdates = createAsyncThunk(
    'issue/fetchIssueUpdates',
    async ({ code, date }: { code: string | undefined; date: string }): Promise<recentUpdates> =>
        issueAPI.fetchUpdates(code, date)
);

// Fetch the recent tweets for the current issue
export const fetchIssueTweets = createAsyncThunk(
    'issue/fetchIssueTweets',
    async ({ code, date }: { code: string; date: string }): Promise<Tweet[]> =>
        issueAPI.fetchTweets(code, date)
);

// Fetch the yearly overview data for the current issue
export const fetchKeywordMentions = createAsyncThunk(
    'issue/fetchKeywordMentions',
    async ({ code, date }: { code: string | undefined; date: string }): Promise<any[]> =>
        issueAPI.fetchKeywordMentions(code, date)
);

// Fetch the issue info
export const fetchIssueInfo = createAsyncThunk(
    'issue/fetchIssueInfo',
    async ({ code }: { code: string | undefined }): Promise<issueInfo> => issueAPI.fetchInfo(code)
);

// Fetch the yearly overview data for the current issue
export const fetchIssueOverview = createAsyncThunk(
    'issue/fetchIssueOverviewYearly',
    async ({ code, date }: { code: string | undefined; date: string }): Promise<entityOverviews> =>
        issueAPI.fetchOverview(code, date)
);

// Fetch the topic overview data for the current issue
export const fetchIssueTopicOverview = createAsyncThunk(
    'issue/fetchIssueTopicOverview',
    async ({ code, date }: { code: string | undefined; date: string }): Promise<topicOverview> =>
        await issueAPI.fetchTopicOverview(code, date)
);

// Fetch the issue sentences
export const fetchSentences = createAsyncThunk(
    'issue/fetchSentences',
    async ({ code, date }: { code: string; date: string | undefined }): Promise<any[]> =>
        issueAPI.fetchSentences(code, date)
);

// Fetch the recent lobbying activity data for the current issue
export const fetchIssueRecentLobbying = createAsyncThunk(
    'issue/fetchIssueRecentLobbying',
    async ({
        code,
        date,
    }: {
        code: string;
        date: string | undefined;
    }): Promise<entityLobbyingActivity[]> => issueAPI.fetchRecentLobbyingActivity(code, date)
);

// Fetch the recent lobbying registrations in the current issue
export const fetchIssueRegistrations = createAsyncThunk(
    'issue/fetchIssueRegistrations',
    async ({
        code,
        date,
    }: {
        code: string;
        date: string | undefined;
    }): Promise<entityRegistration[]> => issueAPI.fetchRecentRegistrations(code, date)
);

// Fetch the recent lobbying registrations in the current issue
export const fetchIssueDeregistrations = createAsyncThunk(
    'issue/fetchIssueDeregistrations',
    async ({
        code,
        date,
    }: {
        code: string;
        date: string | undefined;
    }): Promise<entityRegistration[]> => issueAPI.fetchRecentDeregistrations(code, date)
);

// Fetch the organization cluster data
export const fetchOrganizationCluster = createAsyncThunk(
    'issue/fetchOrganizationCluster',
    async ({
        code,
        date,
    }: {
        code: string | undefined;
        date: string | undefined;
    }): Promise<entityCluster> => issueAPI.fetchCluster(code, 'organizations', date)
);

// Fetch the mentions cluster data
export const fetchMentionCluster = createAsyncThunk(
    'issue/fetchMentionCluster',
    async ({
        code,
        date,
    }: {
        code: string | undefined;
        date: string | undefined;
    }): Promise<entityCluster> => issueAPI.fetchCluster(code, 'mentions', date)
);

// Fetch the sector cluster data
export const fetchSectorCluster = createAsyncThunk(
    'issue/fetchSectorCluster',
    async ({
        code,
        date,
    }: {
        code: string | undefined;
        date: string | undefined;
    }): Promise<entityCluster> => issueAPI.fetchCluster(code, 'sectors', date)
);

// Fetch the institution cluster data
export const fetchInstitutionCluster = createAsyncThunk(
    'issue/fetchInstitutionCluster',
    async ({
        code,
        date,
    }: {
        code: string | undefined;
        date: string | undefined;
    }): Promise<entityCluster> => issueAPI.fetchCluster(code, 'institutions', date)
);

// Fetch the lobby firm cluster data
export const fetchFirmCluster = createAsyncThunk(
    'issue/fetchFirmCluster',
    async ({
        code,
        date,
    }: {
        code: string | undefined;
        date: string | undefined;
    }): Promise<entityCluster> => issueAPI.fetchCluster(code, 'lobbyfirms', date)
);

// Fetch the lobby firm cluster data
export const fetchDPOHCluster = createAsyncThunk(
    'issue/fetchDPOHCluster',
    async ({
        code,
        date,
    }: {
        code: string | undefined;
        date: string | undefined;
    }): Promise<entityCluster> => issueAPI.fetchCluster(code, 'dpoh', date)
);

// Fetch the subject cluster data
export const fetchSubjectCluster = createAsyncThunk(
    'issue/fetchSubjectCluster',
    async ({
        code,
        date,
    }: {
        code: string | undefined;
        date: string | undefined;
    }): Promise<entityCluster> => issueAPI.fetchCluster(code, 'subject', date)
);

// Create new or update existing custom issue
export const createOrUpdateIssue = createAsyncThunk(
    'issue/createOrUpdateIssue',
    async ({
        name,
        keywords,
        id,
    }: {
        name: string;
        keywords: string[];
        id: number | null;
    }): Promise<any> => {
        const response = await issueAPI.createOrUpdateIssue(name, keywords, id);
        return response.data;
    }
);

// Create new or update existing custom issue
export const deleteIssue = createAsyncThunk(
    'issue/deleteIssue',
    async (id: number): Promise<void> => {
        await issueAPI.deleteIssue(id);
    }
);

export const exportIssueDashboard = createAsyncThunk(
    'issue/exportDashboard',
    async (data: any): Promise<void> => {
        const response = await axios.post(
            `${process.env.REACT_APP_PDF_SERVICE_URL}/issue/report`,
            data,
            {
                responseType: 'blob',
            }
        );

        const pdfEncoding = response.data;

        const blob = new Blob([pdfEncoding], { type: 'application/pdf' });

        saveAs(blob, 'LobbyIQ-Issue');
    }
);

const issueSlice = createSlice({
    name: 'issueReducer',
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(fetchIssueUpdates.pending, (state) => {
                state.updates_loading = true;
                state.updates = null;
            })
            .addCase(fetchIssueUpdates.fulfilled, (state, action) => {
                state.updates_loading = false;
                state.updates_fail = false;
                state.updates = action.payload;
            })
            .addCase(fetchIssueUpdates.rejected, (state) => {
                state.updates_loading = false;
                state.updates_fail = true;
                state.updates = null;
            })
            .addCase(fetchIssueTweets.pending, (state) => {
                state.recent_tweets_loading = true;
                state.recent_tweets = null;
            })
            .addCase(fetchIssueTweets.fulfilled, (state, action) => {
                state.recent_tweets_loading = false;
                state.recent_tweets = action.payload;
            })
            .addCase(fetchIssueTweets.rejected, (state) => {
                state.recent_tweets_loading = false;
                state.recent_tweets = null;
            })
            .addCase(fetchKeywordMentions.pending, (state) => {
                state.mentions_loading = true;
            })
            .addCase(fetchKeywordMentions.fulfilled, (state, action) => {
                state.mentions_loading = false;
                state.mentions_fail = false;
                state.mentions = action.payload;
            })
            .addCase(fetchKeywordMentions.rejected, (state) => {
                state.mentions_loading = false;
                state.mentions_fail = true;
                state.mentions = null;
            })
            .addCase(fetchIssueInfo.pending, (state) => {
                state.issue_info_loading = true;
            })
            .addCase(fetchIssueInfo.fulfilled, (state, action) => {
                state.issue_info_loading = false;
                state.issue_info_fail = false;
                state.issue_info = action.payload;
            })
            .addCase(fetchIssueInfo.rejected, (state) => {
                state.issue_info_loading = false;
                state.issue_info_fail = true;
                state.issue_info = null;
            })
            .addCase(fetchIssueOverview.pending, (state) => {
                state.overview_loading = true;
            })
            .addCase(fetchIssueOverview.fulfilled, (state, action) => {
                state.overview_loading = false;
                state.overview_fail = false;
                state.overview = action.payload;
            })
            .addCase(fetchIssueOverview.rejected, (state) => {
                state.overview_loading = false;
                state.overview_fail = true;
                state.overview = null;
            })
            .addCase(fetchSentences.pending, (state) => {
                state.sentences_loading = true;
            })
            .addCase(fetchSentences.fulfilled, (state, action) => {
                state.sentences_loading = false;
                state.sentences_fail = false;
                state.sentences = action.payload;
            })
            .addCase(fetchSentences.rejected, (state) => {
                state.sentences_loading = false;
                state.sentences_fail = true;
                state.sentences = null;
            })
            .addCase(fetchIssueRecentLobbying.pending, (state) => {
                state.lobbying_activity_loading = true;
                state.lobbying_activity = null;
            })
            .addCase(fetchIssueRecentLobbying.fulfilled, (state, action) => {
                state.lobbying_activity_loading = false;
                state.lobbying_activity_fail = false;
                state.lobbying_activity = action.payload;
            })
            .addCase(fetchIssueRecentLobbying.rejected, (state) => {
                state.lobbying_activity_loading = false;
                state.lobbying_activity_fail = true;
                state.lobbying_activity = null;
            })
            .addCase(fetchIssueRegistrations.pending, (state) => {
                state.recent_reg_loading = true;
                state.recent_reg = null;
            })
            .addCase(fetchIssueRegistrations.fulfilled, (state, action) => {
                state.recent_reg_loading = false;
                state.recent_reg_fail = false;
                state.recent_reg = action.payload;
            })
            .addCase(fetchIssueRegistrations.rejected, (state) => {
                state.recent_reg_loading = false;
                state.recent_reg_fail = true;
                state.recent_reg = null;
            })
            .addCase(fetchIssueDeregistrations.pending, (state) => {
                state.recent_dereg_loading = true;
                state.recent_dereg = null;
            })
            .addCase(fetchIssueDeregistrations.fulfilled, (state, action) => {
                state.recent_dereg_loading = false;
                state.recent_dereg_fail = false;
                state.recent_dereg = action.payload;
            })
            .addCase(fetchIssueDeregistrations.rejected, (state) => {
                state.recent_dereg_loading = false;
                state.recent_dereg_fail = true;
                state.recent_dereg = null;
            })
            .addCase(fetchMentionCluster.pending, (state) => {
                state.mentions_cluster_loading = true;
                state.mentions_cluster = null;
            })
            .addCase(fetchMentionCluster.fulfilled, (state, action) => {
                state.mentions_cluster_loading = false;
                state.mentions_cluster_fail = false;
                state.mentions_cluster = action.payload;
            })
            .addCase(fetchMentionCluster.rejected, (state) => {
                state.org_cluster_loading = false;
                state.org_cluster_fail = true;
                state.org_cluster = null;
            })
            .addCase(fetchOrganizationCluster.pending, (state) => {
                state.org_cluster_loading = true;
                state.org_cluster = null;
            })
            .addCase(fetchOrganizationCluster.fulfilled, (state, action) => {
                state.org_cluster_loading = false;
                state.org_cluster_fail = false;
                state.org_cluster = action.payload;
            })
            .addCase(fetchOrganizationCluster.rejected, (state) => {
                state.org_cluster_loading = false;
                state.org_cluster_fail = true;
                state.org_cluster = null;
            })
            .addCase(fetchSectorCluster.pending, (state) => {
                state.sector_cluster_loading = true;
                state.sector_cluster = null;
            })
            .addCase(fetchSectorCluster.fulfilled, (state, action) => {
                state.sector_cluster_loading = false;
                state.sector_cluster_fail = false;
                state.sector_cluster = action.payload;
            })
            .addCase(fetchSectorCluster.rejected, (state) => {
                state.sector_cluster_loading = false;
                state.sector_cluster_fail = true;
                state.sector_cluster = null;
            })
            .addCase(fetchInstitutionCluster.pending, (state) => {
                state.inst_cluster_loading = true;
                state.inst_cluster = null;
            })
            .addCase(fetchInstitutionCluster.fulfilled, (state, action) => {
                state.inst_cluster_loading = false;
                state.inst_cluster_fail = false;
                state.inst_cluster = action.payload;
            })
            .addCase(fetchInstitutionCluster.rejected, (state) => {
                state.inst_cluster_loading = false;
                state.inst_cluster_fail = true;
                state.inst_cluster = null;
            })
            .addCase(fetchFirmCluster.pending, (state) => {
                state.firm_cluster_loading = true;
                state.firm_cluster = null;
            })
            .addCase(fetchFirmCluster.fulfilled, (state, action) => {
                state.firm_cluster_loading = false;
                state.firm_cluster_fail = false;
                state.firm_cluster = action.payload;
            })
            .addCase(fetchFirmCluster.rejected, (state) => {
                state.firm_cluster_loading = false;
                state.firm_cluster_fail = true;
                state.firm_cluster = null;
            })
            .addCase(fetchDPOHCluster.pending, (state) => {
                state.dpoh_cluster_loading = true;
                state.dpoh_cluster = null;
            })
            .addCase(fetchDPOHCluster.fulfilled, (state, action) => {
                state.dpoh_cluster_loading = false;
                state.dpoh_cluster_fail = false;
                state.dpoh_cluster = action.payload;
            })
            .addCase(fetchDPOHCluster.rejected, (state) => {
                state.dpoh_cluster_loading = false;
                state.dpoh_cluster_fail = true;
                state.dpoh_cluster = null;
            })
            .addCase(fetchSubjectCluster.pending, (state) => {
                state.subject_cluster_loading = true;
                state.subject_cluster = null;
            })
            .addCase(fetchSubjectCluster.fulfilled, (state, action) => {
                state.subject_cluster_loading = false;
                state.subject_cluster_fail = false;
                state.subject_cluster = action.payload;
            })
            .addCase(fetchSubjectCluster.rejected, (state) => {
                state.subject_cluster_loading = false;
                state.subject_cluster_fail = true;
                state.subject_cluster = null;
            })
            .addCase(exportIssueDashboard.pending, (state) => {
                state.export_loading = true;
            })
            .addCase(exportIssueDashboard.fulfilled, (state, action) => {
                state.export_loading = false;
                state.export_fail = false;
            })
            .addCase(exportIssueDashboard.rejected, (state) => {
                state.export_loading = false;
                state.export_fail = true;
            })
            .addCase(createOrUpdateIssue.pending, (state) => {
                state.create_loading = true;
            })
            .addCase(createOrUpdateIssue.fulfilled, (state, action) => {
                state.create_loading = false;
            })
            .addCase(createOrUpdateIssue.rejected, (state) => {
                state.create_loading = false;
            });
    },
});

export const selectIssueUpdates = (state: RootState) => state.issueData.updates;
export const selectIssueUpdatesLoading = (state: RootState) => state.issueData.updates_loading;

export const selectIssueTweets = (state: RootState) => state.issueData.recent_tweets;
export const selectIssueTweetsLoading = (state: RootState) => state.issueData.recent_tweets_loading;

export const selectIssueKeywordMentions = (state: RootState) => state.issueData.mentions;
export const selectIssueKeywordMentionsLoading = (state: RootState) =>
    state.issueData.mentions_loading;

export const selectIssueInfo = (state: RootState) => state.issueData.issue_info;
export const selectIssueInfoLoading = (state: RootState) => state.issueData.issue_info_loading;

export const selectIssueOverview = (state: RootState) => state.issueData.overview;
export const selectIssueOverviewLoading = (state: RootState) => state.issueData.overview_loading;

export const selectIssueSentences = (state: RootState) => state.issueData.sentences;
export const selectIssueSentencesLoading = (state: RootState) => state.issueData.sentences_loading;

export const selectIssueRecentLobbying = (state: RootState) => state.issueData.lobbying_activity;
export const selectIssueRecentLobbyingLoading = (state: RootState) =>
    state.issueData.lobbying_activity_loading;

export const selectIssueRegistrations = (state: RootState) => state.issueData.recent_reg;
export const selectIssueRegistrationsLoading = (state: RootState) =>
    state.issueData.recent_reg_loading;

export const selectIssueDeregistrations = (state: RootState) => state.issueData.recent_dereg;
export const selectIssueDeregistrationsLoading = (state: RootState) =>
    state.issueData.recent_dereg_loading;

export const selectMentionCluster = (state: RootState) => state.issueData.mentions_cluster;
export const selectMentionClusterLoading = (state: RootState) =>
    state.issueData.mentions_cluster_loading;

export const selectOrganizationCluster = (state: RootState) => state.issueData.org_cluster;
export const selectOrganizationClusterLoading = (state: RootState) =>
    state.issueData.org_cluster_loading;

export const selectSectorCluster = (state: RootState) => state.issueData.sector_cluster;
export const selectSectorClusterLoading = (state: RootState) =>
    state.issueData.sector_cluster_loading;

export const selectInstitutionCluster = (state: RootState) => state.issueData.inst_cluster;
export const selectInstitutionClusterLoading = (state: RootState) =>
    state.issueData.inst_cluster_loading;

export const selectFirmCluster = (state: RootState) => state.issueData.firm_cluster;
export const selectFirmClusterLoading = (state: RootState) => state.issueData.firm_cluster_loading;

export const selectDPOHCluster = (state: RootState) => state.issueData.dpoh_cluster;
export const selectDPOHClusterLoading = (state: RootState) => state.issueData.dpoh_cluster_loading;

export const selectSubjectCluster = (state: RootState) => state.issueData.subject_cluster;
export const selectSubjectClusterLoading = (state: RootState) =>
    state.issueData.subject_cluster_loading;

export const selectIssueExportLoading = (state: RootState) => state.issueData.export_loading;
export const selectIssueExportFail = (state: RootState) => state.issueData.export_fail;

export const selectCreateIssueLoading = (state: RootState) => state.issueData.create_loading;

export const issueData = (state: RootState) => state.issueData;

export default issueSlice.reducer;
