import { useAppDispatch } from '../../store/store';
import { logIn } from '../../store/reducers/userSlice';
import ErrorIcon from '@mui/icons-material/Error';

export const SessionLimit = () => {
    const dispatch = useAppDispatch();

    const handleLoginRedirect = async () => {
        dispatch(logIn());
    };

    return (
        <div className="w-[100vw] h-[100vh] flex flex-col items-center justify-center gap-2 p-2 text-xl">
            <ErrorIcon fontSize="large" />
            <p className="text-center">
                There are currently too many devices logged in to this account.
            </p>
            <div className="flex flex-row">
                <p>Please try to</p>
                <div
                    className="mx-1 text-cyan-500 font-bold hover:underline hover:cursor-pointer"
                    onClick={handleLoginRedirect}
                >
                    log in
                </div>
                <p>again.</p>
            </div>
        </div>
    );
};
