enum EntityType {
    Sector = 'sectors',
    Institution = 'institutions',
    DPOH = 'DPOHs',
    SenateMember = 'Senators',
    MP = 'MPs',
    InstitutionMP = 'institution MPs',
    Committee = 'committees',
    SenateCommittee = 'senate committees',
    Subject = 'subjects',
    Issues = 'issues',
    Organization = 'organizations',
    InactiveOrganization = 'inactive organizations',
    Firm = 'firms',
    Consultant = 'consultants',
}

export type LegacyEntityType =
    | 'sectors'
    | 'institutions'
    | 'DPOHs'
    | 'senators'
    | 'MPs'
    | 'committees'
    | 'senate committees'
    | 'subjects'
    | 'issues'
    | 'organizations'
    | 'inactive organizations'
    | 'firms'
    | 'consultants';

namespace EntityType {
    export function getName(type: EntityType) {
        return type as LegacyEntityType;
    }

    export function getEntityTypeFromLegacy(entity: LegacyEntityType): EntityType {
        return entity as EntityType;
    }
}

const EntityMap: Record<LegacyEntityType, EntityType> = {
    sectors: EntityType.Sector,
    institutions: EntityType.Institution,
    DPOHs: EntityType.DPOH,
    senators: EntityType.SenateMember,
    MPs: EntityType.MP,
    committees: EntityType.Committee,
    'senate committees': EntityType.SenateCommittee,
    subjects: EntityType.Subject,
    issues: EntityType.Issues,
    organizations: EntityType.Organization,
    'inactive organizations': EntityType.InactiveOrganization,
    firms: EntityType.Firm,
    consultants: EntityType.Consultant,
};

export { EntityType, EntityMap };
