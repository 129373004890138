import { Tooltip, Typography } from '@mui/material';
import React from 'react';
import { ListChildComponentProps, VariableSizeList } from 'react-window';

const OuterElementContext = React.createContext({});

const OuterElementType = React.forwardRef<HTMLDivElement>((props, ref) => {
    const outerProps = React.useContext(OuterElementContext);
    return <div ref={ref} {...props} {...outerProps} />;
});

function renderRow(props: ListChildComponentProps) {
    const { data, index, style } = props;
    const inlineStyle = {
        ...style,
        textWrap: 'nowrap',
        top: (style.top as number) + 8,
    };

    return (
        <Tooltip
            enterDelay={1000}
            enterNextDelay={1000}
            title={data[index]}
        >
            <Typography style={inlineStyle}>{data[index]}</Typography>
        </Tooltip>
    );
}

// Virtualized list box for MUI Autocomplete
export const VirtualizedListBox = React.forwardRef<
    HTMLDivElement,
    React.HTMLAttributes<HTMLElement>
>(function ListboxComponent(props, ref) {
    const { children, ...other } = props;
    const itemData: React.ReactElement[] = [];
    (children as React.ReactElement[]).forEach(
        (item: React.ReactElement & { children?: React.ReactElement[] }) => {
            itemData.push(item);
            itemData.push(...(item.children || []));
        }
    );

    const itemCount = itemData.length;

    const listHeight = itemCount * 32 + 16;

    return (
        <div ref={ref}>
            <OuterElementContext.Provider value={other}>
                <VariableSizeList
                    itemData={itemData}
                    height={listHeight < 360 ? listHeight : 360}
                    width="100%"
                    innerElementType="ul"
                    itemSize={() => 32}
                    overscanCount={5}
                    itemCount={itemCount}
                    outerElementType={OuterElementType}
                >
                    {renderRow}
                </VariableSizeList>
            </OuterElementContext.Provider>
        </div>
    );
});
