import React, { useCallback, useEffect, useState } from 'react';
import Dashboard from '../../../pages/dashboards/Dashboard';
import { useParams, useSearchParams } from 'react-router-dom';

import {
    entityCluster,
    entityLobbyingActivity,
    entityOverviews,
} from '../../../interfaces/generic-entity.interface';

import { useAppDispatch } from '../../../store/store';
import {
    fetchInstitutionOverview,
    fetchInstitutionRecentLobbying,
    fetchOrganizationCluster,
    fetchSectorCluster,
    fetchDPOHCluster,
    selectInstitutionOverview,
    selectInstitutionRecentLobbying,
    selectOrganizationCluster,
    selectSectorCluster,
    selectDPOHCluster,
    fetchFirmCluster,
    selectFirmCluster,
    fetchSubjectCluster,
    selectSubjectCluster,
    selectInstitutionOverviewLoading,
    selectInstitutionRecentLobbyingLoading,
    selectOrganizationClusterLoading,
    selectSectorClusterLoading,
    selectFirmClusterLoading,
    selectDPOHClusterLoading,
    selectSubjectClusterLoading,
    fetchInstitutionTerms,
    selectInstitutionTerms,
    selectInstitutionTermsLoading,
    selectInstitutionExportFail,
    selectInstitutionExportLoading,
    selectInstitutionUpdates,
    selectInstitutionUpdatesLoading,
    fetchInstitutionUpdates,
} from '../../../store/reducers/institutionSlice';
import { useSelector } from 'react-redux';
import moment from 'moment';
import { DashboardNotLicenced } from '../../../pages/misc/DashboardNotLicenced';
import {
    fetchInstitutionsSelections,
    selectChosenInstitutions,
    selectInstitutionsLoading,
} from '../../../store/reducers/customSelectionSlice';
import { ProductType, selectUserPermissions } from '../../../store/reducers/userSlice';
import { InstitutionOrganizations } from '../../../tooltips/institutions/InstitutionOrganizations';
import { InstitutionSectors } from '../../../tooltips/institutions/InstitutionSectors';
import { InstitutionPublicOffice } from '../../../tooltips/institutions/InstitutionPublicOffice';
import { InstitutionSubjects } from '../../../tooltips/institutions/InstitutionSubjects';
import { InstitutionLobbyFirms } from '../../../tooltips/institutions/InstitutionLobbyFirms';
import { MissingDashboardSelections } from '../../../pages/misc/MissingDashboardSelections';
import { bumperSuperList } from '../../../interfaces/bumper-types.interface';
import { recentUpdates } from '../../shared/interfaces/whats-new.interface';

const SectorDataLayer = () => {
    const dispatch = useAppDispatch();

    // Get the id of the current entity
    const url_params = useParams();
    const [searchParams] = useSearchParams();

    const entity_code = url_params.entityId !== undefined ? url_params.entityId : '';

    const entity_name = searchParams.get('name');

    const updates: recentUpdates | null = useSelector(selectInstitutionUpdates);
    const updates_loading: boolean = useSelector(selectInstitutionUpdatesLoading);

    const overview: entityOverviews | null = useSelector(selectInstitutionOverview);
    const overview_loading: boolean = useSelector(selectInstitutionOverviewLoading);

    const activity: entityLobbyingActivity[] | null = useSelector(selectInstitutionRecentLobbying);
    const activity_loading: boolean = useSelector(selectInstitutionRecentLobbyingLoading);

    const org_cluster: entityCluster | null = useSelector(selectOrganizationCluster);
    const org_cluster_loading: boolean = useSelector(selectOrganizationClusterLoading);

    const sector_cluster: entityCluster | null = useSelector(selectSectorCluster);
    const sector_cluster_loading: boolean = useSelector(selectSectorClusterLoading);

    const firm_cluster: entityCluster | null = useSelector(selectFirmCluster);
    const firm_cluster_loading: boolean = useSelector(selectFirmClusterLoading);

    const dpoh_cluster: entityCluster | null = useSelector(selectDPOHCluster);
    const dpoh_cluster_loading: boolean = useSelector(selectDPOHClusterLoading);

    const subject_cluster: entityCluster | null = useSelector(selectSubjectCluster);
    const subject_cluster_loading: boolean = useSelector(selectSubjectClusterLoading);

    const terms: bumperSuperList[] | null = useSelector(selectInstitutionTerms);
    const terms_loading: boolean = useSelector(selectInstitutionTermsLoading);

    const institutionSelections = useSelector(selectChosenInstitutions);
    const institutionSelectionsLoading = useSelector(selectInstitutionsLoading);
    const userPermissions = useSelector(selectUserPermissions);

    const export_fail = useSelector(selectInstitutionExportFail);
    const export_loading = useSelector(selectInstitutionExportLoading);

    const [date, setDate] = useState<string>(moment.utc(moment()).format('YYYY-MM-DD'));

    const canAccessSelection = useCallback(() => {
        if (
            userPermissions?.productType === ProductType.ENTERPRISE ||
            userPermissions?.productType === ProductType.TRIAL
        ) {
            return true;
        } else return !!institutionSelections.find((selection) => selection.code === entity_code);
    }, [userPermissions, institutionSelections, entity_code]);

    useEffect(() => {
        if (!institutionSelectionsLoading && canAccessSelection()) {
            dispatch(fetchInstitutionUpdates({ code: entity_code, date: date }));
            dispatch(fetchInstitutionOverview({ code: entity_code, date: date }));
            dispatch(
                fetchInstitutionRecentLobbying({
                    code: entity_code,
                    date: date,
                })
            );
            dispatch(fetchOrganizationCluster({ code: entity_code, date: date }));
            dispatch(fetchSectorCluster({ code: entity_code, date: date }));
            dispatch(fetchFirmCluster({ code: entity_code, date: date }));
            dispatch(fetchDPOHCluster({ code: entity_code, date: date }));
            dispatch(fetchSubjectCluster({ code: entity_code, date: date }));
            entity_code !== '103' &&
                dispatch(fetchInstitutionTerms({ code: entity_code, date: date }));
        }
    }, [dispatch, entity_code, date, canAccessSelection, institutionSelectionsLoading]);

    useEffect(() => {
        if (
            !(
                userPermissions?.productType === ProductType.ENTERPRISE ||
                userPermissions?.productType === ProductType.TRIAL
            ) &&
            institutionSelections.length === 0
        ) {
            dispatch(fetchInstitutionsSelections());
        }
    }, [dispatch, institutionSelections.length, userPermissions?.productType]);

    const overview_headers = ['count'];

    // Get the current year
    const currentYear = moment(date, 'YYYY-MM-DD').year();

    // Populate the list with the last 8 years
    for (let i = currentYear; i >= currentYear - 6; i--) {
        overview_headers.push(String(i));
    }

    return canAccessSelection() ? (
        <Dashboard
            type="institutions"
            entity_code={entity_code}
            date={date}
            handleDateChange={setDate}
            includeUpdates
            updates={updates}
            updatesLoading={updates_loading}
            includeOverview
            overview={overview}
            overviewHeaders={overview_headers}
            overviewIndent={['by Consultants', 'of Ministers or DMs']}
            overviewLoading={overview_loading}
            includeRecentLobbying
            recentLobbying={activity}
            recentLobbyingLoading={activity_loading}
            {...(entity_code !== '103' && {
                includeTopKeywords: true,
                bumperData: terms,
                bumperDataLoading: terms_loading,
            })}
            clusters={[
                {
                    cluster_data: org_cluster,
                    title: 'Organizations',
                    tooltipContent: InstitutionOrganizations,
                    loading: org_cluster_loading,
                },
                {
                    cluster_data: sector_cluster,
                    title: 'Sectors',
                    tooltipContent: InstitutionSectors,
                    loading: sector_cluster_loading,
                },
                {
                    cluster_data: dpoh_cluster,
                    title: 'Designated Public Office Holders',
                    tooltipContent: InstitutionPublicOffice,
                    loading: dpoh_cluster_loading,
                },
                {
                    cluster_data: subject_cluster,
                    title: 'Subjects',
                    tooltipContent: InstitutionSubjects,
                    loading: subject_cluster_loading,
                },
                {
                    cluster_data: firm_cluster,
                    title: 'Lobby Firms',
                    tooltipContent: InstitutionLobbyFirms,
                    loading: firm_cluster_loading,
                },
            ]}
            exportFail={export_fail}
            exportLoading={export_loading}
        />
    ) : institutionSelections.length === 0 ? (
        <MissingDashboardSelections type="institutions" />
    ) : (
        <DashboardNotLicenced type="institutions" name={entity_name} />
    );
};

export default SectorDataLayer;
