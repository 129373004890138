import React, { createContext } from 'react';

const TitleContext = createContext({
    defaultTitle: 'LobbyIQ',
});

interface props {
    children: React.ReactNode;
}

export const TitleProvider = ({ children }: props) => {
    const defaultTitle = 'LobbyIQ';

    return <TitleContext.Provider value={{ defaultTitle }}>{children}</TitleContext.Provider>;
};

export default TitleContext;
