import React from 'react';
import LoadingUpdates from '../../../components/generic-dashboards/loading/LoadingUpdates';
import { MissingData } from '../../../components/misc/MissingData';
import { replace_underscores_capitalize } from '../../../helpers/string_formatters';
import SubjectWhatsNew from './SubjectWhatsNew';
import { recentUpdates } from '../../shared/interfaces/whats-new.interface';

const SubjectUpdateTable = ({
    data,
    loading,
    name,
}: {
    data: recentUpdates | null;
    loading: boolean;
    name: string;
}) => {
    return (
        <div>
            <div className="flex lg:flex-row flex-col justify-between">
                <div className="text-xl">
                    Recent Updates for {replace_underscores_capitalize(name)}
                </div>
                <div className="text-lg font-light mr-2">{data?.month}</div>
            </div>
            <hr className="h-0.5 my-3 bg-slate-100 rounded" />
            {loading ? (
                <LoadingUpdates />
            ) : !loading && !data ? (
                <MissingData />
            ) : data ? (
                <SubjectWhatsNew data={data} />
            ) : (
                <></>
            )}
        </div>
    );
};

export default SubjectUpdateTable;
