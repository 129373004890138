import React from 'react';
import LoadSpinner from '../../components/misc/LoadSpinner';

const LoadPage = () => {
    return (
        <div className="w-[100vw] h-[100vh] flex flex-col items-center justify-center gap-2">
            <LoadSpinner />
            <div>Loading...</div>
        </div>
    );
};

export default LoadPage;
