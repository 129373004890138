import { useEffect } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { useAppDispatch } from '../../store/store';
import {
    authenticateUser,
    selectUserLoading,
    selectEmail,
    selectUserErrored,
    logIn,
} from '../../store/reducers/userSlice';
import { useSelector } from 'react-redux';
import { getRedirectLocation } from '../../helpers/sessionStoreUtil';
import LoadPage from './LoadPage';
import ErrorIcon from '@mui/icons-material/Error';

export const RedirectPage = () => {
    const dispatch = useAppDispatch();
    const navigate = useNavigate();
    const [searchParams] = useSearchParams();
    const authCode = searchParams.get('code');
    const state = searchParams.get('state');

    const user = useSelector(selectEmail);
    const userLoading = useSelector(selectUserLoading);
    const userErrored = useSelector(selectUserErrored);
    
    const handleLoginRedirect = async () => {
        dispatch(logIn());
    };

    useEffect(() => {
        if (authCode !== null && state !== null) {
            dispatch(authenticateUser({ authCode, state }));
        }
    }, [authCode, state, dispatch]);

    useEffect(() => {
        if (user && !userLoading) {
            // after login, redirect to page where user clicked login, or root if page not stored
            navigate(getRedirectLocation() || '/');
        }
    }, [user, userLoading, navigate]);

    return userErrored ? (
        <div className="w-[100vw] h-[100vh] flex flex-col items-center justify-center gap-2 text-xl">
            <ErrorIcon fontSize="large" />
            <p>
                An error occurred during login. Please check your network
                connection and
            </p>
            <div
                className="mx-1 text-cyan-500 font-bold hover:underline hover:cursor-pointer"
                onClick={handleLoginRedirect}
            >
                try again
            </div>
        </div>
    ) : ( 
        <LoadPage />
    );
};
