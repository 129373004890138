import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { entityListExtra } from '../../interfaces/generic-entity.interface';
import { useAppDispatch } from '../../store/store';
import ModalEntityList from './ModalEntityList';
import {
    fetchSubjectList,
    selectSubjectList,
} from '../../features/subject-dashboards/store/subjectListSlice';

interface ModalSubjectListProps {
    open: boolean;
    handleClose: () => void;
}

const ModalSubjectListDataLayer = ({ open, handleClose }: ModalSubjectListProps) => {
    const dispatch = useAppDispatch();

    const entity_list: entityListExtra[] | null = useSelector(selectSubjectList);

    useEffect(() => {
        if (!entity_list) dispatch(fetchSubjectList());
    }, [dispatch, entity_list]);

    return (
        <ModalEntityList
            type="subjects"
            list={entity_list}
            // TODO: connect handleClose to modal prop
            open={open}
            handleClose={handleClose}
        />
    );
};

export default ModalSubjectListDataLayer;
