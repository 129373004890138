import axios from 'axios';
import moment from 'moment';
import {
    entityLobbyingActivity,
    entityOverviews,
    entityOverview,
    entityOrg,
    entityCluster,
    entityOrgExtra,
    entityOrgTrail,
    entityOverviewRefined,
    keywordMonthRaw,
} from '../interfaces/generic-entity.interface';
import { percentage_change_num } from '../helpers/percentage_change';
import {
    DPOHLinkFormatter,
    consultantLinkFormatter,
    institutionLinkFormatter,
    lobbyFirmLinkFormatter,
    moverLinkFormatter,
    organizationLinkFormatter,
    sectorLinkFormatter,
} from '../helpers/internal_routing';
import { bumperSuperList } from '../interfaces/bumper-types.interface';
import { recentUpdates } from '../features/shared/interfaces/whats-new.interface';

// Cluster relevant API path names (item 1) and corresponding data object names (item 2)
const topCountAPIPath = {
    organizations: {
        path: 'toporganizations',
        data: 'institutionTopOrganizations',
    },
    sectors: {
        path: 'topsectors',
        data: 'institutionTopSectors',
    },
    lobbyfirms: { path: 'toplobbyfirms', data: 'institutionTopLobbyFirms' },
    dpoh: { path: 'topdpoh', data: 'institutionTopDPOH' },
    subject: { path: 'topsubject', data: 'institutionTopSubject' },
};

const topMoversAPIPath = {
    organizations: { path: 'topmovers', data: 'institutionTopMovers' },
    sectors: {
        path: 'topmoverssectors',
        data: 'institutionTopMoversSectors',
    },
    lobbyfirms: {
        path: 'topmoverslobbyfirms',
        data: 'institutionTopMoversLobbyFirms',
    },
    dpoh: { path: 'topmoversdpoh', data: 'institutionTopMoversDPOH' },
    subject: { path: 'topmoverssubject', data: 'institutionTopMoversSubject' },
};

const TTMAPIPath = {
    organizations: { path: 'org' },
    sectors: { path: 'sector' },
    lobbyfirms: { path: 'firm' },
    dpoh: { path: 'dpoh' },
    subject: { path: 'subject' },
};

// Base URL for accessing the sector API
const baseURL = process.env.REACT_APP_API_BASE_URL;

// Fetch an overview of a given sector
const fetchUpdates = async (code: string | undefined, date: string) => {
    const response = await axios.get(`${baseURL}/inst/${code}/updates?date=${date}`);
    const data: recentUpdates = response.data;
    return data;
};

const invertYearlyOverview = (overview: entityOverviewRefined[]) => {
    const params: (keyof entityOverviewRefined)[] = [
        'Lobbying Reports',
        'by Consultants',
        'of Ministers or DMs',
        'Organizations',
        'Sectors',
    ];

    const output: any = [];

    params.forEach((param: keyof entityOverviewRefined, idx: number) => {
        const param_data: any = { order: idx, count: param };
        overview.forEach((entry: entityOverviewRefined) => {
            param_data[String(entry.year)] = entry[param];
        });
        output.push(param_data);
    });

    return output;
};

// Fetch an overview of a given sector
const fetchOverview = async (code: string | undefined, date: string) => {
    const year = date?.split('-')[0];
    const month = date?.split('-')[1];

    const yearly_response = await axios.get(`${baseURL}/inst/${code}/overview?monthly=false`);
    const yearly_data: entityOverviewRefined[] = yearly_response.data.institutionOverview
        .filter((entry: any) => +entry.year <= +year)
        .map((entry: entityOverview) => {
            return {
                ...entry,
                'Lobbying Reports': entry.count,
                'by Consultants': entry.external,
                'of Ministers or DMs': entry.ministers,
                Organizations: entry.organizations,
                Sectors: entry.sectors,
            };
        });

    const inverted_yearly = invertYearlyOverview(yearly_data);

    const monthly_response = await axios.get(`${baseURL}/inst/${code}/overview?monthly=true`);

    const monthly_data: entityOverview[] = monthly_response.data.institutionOverview
        .filter((entry: any) => {
            return (
                +[entry.year, (entry.month + '').padStart(2, '0')].join('') <=
                +[year, month].join('')
            );
        })
        .slice(0, 18);

    const overview: entityOverviews = {
        yearly: inverted_yearly,
        monthly: monthly_data,
    };

    return overview;
};

// Fetch recent lobbying communications in a given sector
const fetchRecentLobbyingActivity = async (code: string | undefined, date: string | undefined) => {
    const response = await axios.get(`${baseURL}/inst/${code}/activity?limit=25&date=${date}`);
    const data: entityLobbyingActivity[] = response.data.institutionActivity;
    const converted_data: entityLobbyingActivity[] = data.map((entry) => {
        return {
            ...entry,
            'Prior Comms (36M)': entry.three_years_prior,
            date: moment.utc(entry.date).format('YYYY-MM-DD'),
            organization:
                entry.sector_id && entry.sector
                    ? organizationLinkFormatter(entry.sector_id, entry.sector, entry.client)
                    : entry.client,
            dpoh:
                entry.institution_id && entry.institution
                    ? DPOHLinkFormatter(entry.institution_id, entry.institution, entry.dpoh)
                    : entry.dpoh,
            institution: institutionLinkFormatter(entry.institution_id, entry.institution),
            sector:
                entry.sector_id && entry.sector
                    ? sectorLinkFormatter(entry.sector_id, entry.sector)
                    : entry.sector,
            lobby_firm:
                entry.lobby_firm === null || entry.consultant === null
                    ? 'In-House'
                    : lobbyFirmLinkFormatter(entry.lobby_firm),
            consultant:
                entry.lobby_firm === null || entry.consultant === null
                    ? 'In-House'
                    : consultantLinkFormatter(entry.lobby_firm, entry.consultant),
            link: `https://lobbycanada.gc.ca/app/secure/ocl/lrs/do/cmmLgPblcVw?comlogId=${entry.comm}`,
        };
    });
    return converted_data;
};

// Fetch a sector data cluster of the given sector code, under a specific "type"
const fetchCluster = async (
    code: string | undefined,
    type: 'organizations' | 'sectors' | 'lobbyfirms' | 'dpoh' | 'subject',
    date: string | undefined
): Promise<entityCluster> => {
    // Fetch top 6 *type* within the given sector (determined by YTD communication count)
    const top_count_response = await axios.get(
        `${baseURL}/inst/${code}/${topCountAPIPath[type].path}?date=${date}`
    );

    const top_count_data: entityOrg[] =
        // name of data object varies by endpoint, we don't need to know its precise name this way
        top_count_response.data[topCountAPIPath[type].data];

    // Count the total number of comms for orgs outside of the top 6 ytd
    var other_count = 0;
    if (top_count_data.length > 6)
        other_count = top_count_data
            .slice(6)
            .map((item) => item.count)
            .reduce((sum, next) => sum + next);

    const top_six_ytd = top_count_data.slice(0, 6);
    top_six_ytd.push({ name: 'Other', code: 'Other', count: other_count });

    // Get the top 1-4 orgs from the top YTD orgs
    // These will be used for mini bar charts with trailing 12 months of lobbying displayed
    const top_four: entityOrgTrail[] = top_count_data.slice(0, 4).map((entry) => {
        return {
            ...entry,
            ttm: [],
        };
    });

    // Add a trailing twelve months array to the top 1-4 orgs
    await Promise.all(
        top_four.map(async (entry): Promise<void> => {
            const response = await axios.get(
                `${baseURL}/${TTMAPIPath[type].path}/${encodeURIComponent(
                    entry.code
                )}/ttmcount/institution/${code}?date=${date}`
            );
            // Modify the object with the API response
            entry.ttm = response.data.data;
        })
    );

    // Fetch top 10 mover *type* within the given sector
    const top_movers_response = await axios.get(
        `${baseURL}/inst/${code}/${topMoversAPIPath[type].path}?limit=25&date=${date}`
    );
    const top_movers_data: entityOrg[] = top_movers_response.data[topMoversAPIPath[type].data];

    // Take top movers and add a percentage change + empty trialing twelve month array
    // Sort in descending order
    const converted_movers_data: entityOrgExtra[] = top_movers_data.map((entry) => {
        return {
            ...entry,
            name: moverLinkFormatter(entry, type),
            // Calculate with potential nulls replaced by 0
            change_rel: percentage_change_num(entry.tma || 0, entry.oma || 0),
            change_abs: (entry.oma || 0) - (entry.tma || 0),
            // long decimals returned as string by DB, cast to number
            avg: +(entry.avg || 0),
            deviation: +(entry.deviation || 0),
        };
    });

    const cluster: entityCluster = {
        top_movers: converted_movers_data,
        top_orgs: top_six_ytd,
        top_four: top_four,
        movers_meta: top_movers_response.data.movers_meta,
        isInstitutionType: true,
    };

    return cluster;
};

// Fetch recent lobbying communications in a given institution
const fetchTerms = async (code: string | undefined, date: string | undefined) => {
    const keyword_response = await axios.get(`${baseURL}/inst/${code}/terms?limit=6&date=${date}`);
    const keyword_data: keywordMonthRaw[] = keyword_response.data.data;
    const keyword_list: bumperSuperList[] = keyword_data.map((entry: keywordMonthRaw) => {
        const year: string = String(moment(entry.date).year());
        const month_str: string = moment(entry.date).format('MMMM');
        return {
            title: `${month_str}, ${year}`,
            lists: entry.datasets,
        };
    });
    return keyword_list;
};

export const institutionAPI = {
    fetchUpdates,
    fetchOverview,
    fetchRecentLobbyingActivity,
    fetchCluster,
    fetchTerms,
};
