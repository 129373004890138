import React from 'react';
import LoadingBumper from './LoadingBumper';

const LoadingIssueRankings = () => {
    return (
        <div className="flex flex-col">
            <LoadingBumper />
        </div>
    );
};

export default LoadingIssueRankings;
