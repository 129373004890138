import React, { useEffect, useState } from 'react';
import Overview from '../../components/generic-dashboards/Overview';
import DashTable from '../../components/generic-dashboards/DashTable';
import Cluster from '../../components/generic-dashboards/Cluster';
import { Breadcrumbs, Button, Slide } from '@mui/material';
import { Link, useLocation } from 'react-router-dom';
import MenuOpen from '@mui/icons-material/MenuOpenRounded';
import { replace_underscores_capitalize } from '../../helpers/string_formatters';

import Snackbar from '@mui/material/Snackbar';
import { Alert } from '@mui/material';

import { bumperSuperList } from '../../interfaces/bumper-types.interface';
import {
    entityCluster,
    entityLobbyingActivity,
    entityOverviews,
    entityRegistration,
} from '../../interfaces/generic-entity.interface';
import ModalInstitutionListDataLayer from '../../components/lists/ModalInstitutionListDataLayer';
import ModalSectorListDataLayer from '../../components/lists/ModalSectorListDataLayer';

import CalendarMonthRoundedIcon from '@mui/icons-material/CalendarMonthRounded';
import DateSelection from '../../components/misc/DateSelection';
import { COLORS } from '../../constants/colors';
import ModalCommitteeListDataLayer from '../../components/lists/ModalCommitteeListDataLayer';
import { committeeMember } from '../../features/committee-dashboards/interfaces/committee-entity.interface';
import CommitteeMemberships from '../../features/committee-dashboards/UI/CommitteeMemberships';
import KeywordRankings from '../../components/generic-dashboards/KeywordRankings';
import { SectorOverview } from '../../features/sector-dashboards/properties/SecrorOverview';
import { InstitutionOverview } from '../../tooltips/institutions/InstitutionOverview';
import { SectorLobbyingCommunications } from '../../features/sector-dashboards/properties/SectorLobbyingCommunications';
import { InstitutionLobbyingCommunications } from '../../tooltips/institutions/InstitutionLobbyingCommunications';
import { CommitteeLobbyingCommunications } from '../../tooltips/committees/CommitteeLobbyingCommunications';
import { SectorLobbyDeregistrations } from '../../features/sector-dashboards/properties/SectorLobbyDeregistrations';
import { SectorKeyPhrases } from '../../features/sector-dashboards/properties/SectorKeyPrases';
import { CommitteeOverviewTooltip } from '../../tooltips/committees/CommitteeOverviewtooltip';
import { SectorLobbyRegistrations } from '../../features/sector-dashboards/properties/SectorLobbyRegistrations';
import { committeeMeetingKeyphrases } from '../../tooltips/committees/CommitteeMeetingKeyphrases';
import { SubjectOverview } from '../../tooltips/subjects/SubjectOverview';
import { SubjectLobbyingCommunications } from '../../tooltips/subjects/SubjectLobbyingCommunications';
import { SubjectLobbyRegistrations } from '../../tooltips/subjects/SubjectLobbyRegistrations';
import { SubjectLobbyDeregistrations } from '../../tooltips/subjects/SubjectLobbyDeregistrations';
import ModalSubjectListDataLayer from '../../components/lists/ModalSubjectListDataLayer';
import { DashboardDownloadModal } from '../../components/misc/DashboardDownloadModal';
import UpdateTable from '../../components/whats-new/UpdateTable';
import SimCardDownloadRoundedIcon from '@mui/icons-material/SimCardDownloadRounded';
import { EVENT_TYPES, GATrackDashboardLoad } from '../../google_analytics/TrackEvent';
import { useSelector } from 'react-redux';
import { selectEmail, selectUserProductType } from '../../store/reducers/userSlice';
import InstitutionUpdateTable from '../../components/whats-new/InstitutionUpdateTable';
import SubjectUpdateTable from '../../features/subject-dashboards/UI/SubjectUpdateTable';
import { EntityType } from '../../features/config/Entities';

interface clusterProps {
    cluster_data: entityCluster | null;
    title: string;
    tooltipContent: JSX.Element;
    loading: boolean;
}

interface dashboardProps {
    type: 'sectors' | 'institutions' | 'committees' | 'senate committees' | 'subjects';
    entity_code: string;
    date: string;
    handleDateChange: (x: string) => void;

    includeUpdates?: boolean;
    updates?: any | null;
    updatesLoading?: boolean;

    includeOverview?: boolean;
    overview?: entityOverviews | null;
    overviewLoading?: boolean;
    overviewHeaders?: string[];
    overviewIndent?: string[];

    includeRecentLobbying?: boolean;
    recentLobbying?: entityLobbyingActivity[] | null;
    recentLobbyingLoading?: boolean;

    includeRegistrations?: boolean;
    recentRegistrations?: entityRegistration[] | null;
    recentRegistrationsLoading?: boolean;

    includeDeregistrations?: boolean;
    recentDeregistrations?: entityRegistration[] | null;
    recentDeregistrationsLoading?: boolean;

    clusters: clusterProps[];

    includeMemberships?: boolean;
    memberships?: committeeMember[] | null;
    membershipsLoading?: boolean;

    includeTopKeywords?: boolean;
    bumperData?: bumperSuperList[] | null;
    bumperDataLoading?: boolean;

    exportLoading?: boolean;
    exportFail?: boolean;

    hideExport?: boolean;
}

/*
    Component: Dashboard.tsx
    Params: N/A
    Author: Will Brewer
    Desc: Page component for generic, non-custom dashboards
*/
const Dashboard = ({
    type,
    entity_code,
    date,
    handleDateChange,
    includeUpdates = false,
    updates = null,
    updatesLoading = false,
    includeOverview = false,
    overview = { yearly: [], monthly: [] },
    overviewLoading = false,
    overviewHeaders = [],
    overviewIndent = [],
    includeRecentLobbying = false,
    recentLobbying = [],
    recentLobbyingLoading = false,
    recentRegistrations = [],
    recentRegistrationsLoading = false,
    recentDeregistrations = [],
    recentDeregistrationsLoading = false,
    clusters,
    includeRegistrations = false,
    includeDeregistrations = false,
    includeMemberships = false,
    memberships = [],
    membershipsLoading = false,
    includeTopKeywords = false,
    bumperData = [],
    bumperDataLoading = false,
    exportLoading = false,
    exportFail = false,
    hideExport = false,
}: dashboardProps) => {
    // Decide which color from MUI status color scheme to use for buttons
    const type_color =
        type === 'sectors'
            ? 'success'
            : type === 'institutions'
            ? 'info'
            : type === 'subjects'
            ? 'warning'
            : 'secondary';

    const overview_colors =
        type === 'sectors'
            ? COLORS[1]
            : type === 'institutions'
            ? COLORS[0]
            : type === 'subjects'
            ? { primary: '#d9641c', secondary: '#e9935d' }
            : COLORS[2];

    const [modalOpen, setModalOpen] = useState<boolean>(false);
    const [dateOpen, setDateOpen] = useState<boolean>(false);
    const [dateSet, setDateSet] = useState<boolean>(false);
    const [exportModalOpen, setExportModalOpen] = useState<boolean>(false);

    const handleOpen = () => setModalOpen(true);
    const handleClose = () => setModalOpen(false);

    const handleDateOpen = () => setDateOpen(true);
    const handleDateClose = () => {
        setDateOpen(false);
        setDateSet(true);
    };

    const [scrollPosition, setScrollPosition] = useState(0);

    const location = useLocation();
    const entity_name: string | null = new URLSearchParams(location.search).get('name');
    const userEmail = useSelector(selectEmail);
    const userProductType = useSelector(selectUserProductType);

    useEffect(() => {
        GATrackDashboardLoad(
            `${replace_underscores_capitalize(type)}`,
            `${entity_name}`,
            EVENT_TYPES.PAGE_LOAD,
            userEmail ? userEmail : undefined,
            userProductType ? userProductType : undefined
        );
        const handleScroll = () => {
            const scrollPosition = window.scrollY;
            setScrollPosition(scrollPosition);
        };
        window.addEventListener('scroll', handleScroll);
        // Cleanup the event listener when the component is unmounted
        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, [entity_name, location.pathname, type, userEmail, userProductType]);

    const overviewContent = () => {
        switch (type) {
            case 'sectors':
                return SectorOverview;
            case 'institutions':
                return InstitutionOverview;
            case 'committees':
                return CommitteeOverviewTooltip;
            case 'subjects':
                return SubjectOverview;
            default:
                return <></>;
        }
    };

    const lobbyCommContent = () => {
        switch (type) {
            case 'sectors':
                return SectorLobbyingCommunications;
            case 'institutions':
                return InstitutionLobbyingCommunications;
            case 'committees':
                return CommitteeLobbyingCommunications;
            case 'subjects':
                return SubjectLobbyingCommunications;
            default:
                return <></>;
        }
    };

    const lobbyRegContent = () => {
        switch (type) {
            case 'sectors':
                return SectorLobbyRegistrations;
            case 'subjects':
                return SubjectLobbyRegistrations;
            default:
                return <></>;
        }
    };

    const lobbyDeregContent = () => {
        switch (type) {
            case 'sectors':
                return SectorLobbyDeregistrations;
            case 'subjects':
                return SubjectLobbyDeregistrations;
            default:
                return <></>;
        }
    };

    return (
        <div className="py-32 lg:px-20 px-4 grid grid-cols-12">
            <Snackbar
                anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
                open={dateSet}
                onClose={() => setDateSet(false)}
                autoHideDuration={3000}
                message="Date Set"
            >
                <Alert onClose={() => setDateSet(false)} severity="success">
                    Date set to {date}
                </Alert>
            </Snackbar>

            <Slide direction="left" in={scrollPosition >= 50} mountOnEnter unmountOnExit>
                <div
                    className={`fixed z-20 bottom-24 right-5 gap-2 lg:top-20 lg:right-4 flex flex-col h-0`}
                >
                    <Button
                        className="group flex"
                        onClick={handleDateOpen}
                        variant="contained"
                        startIcon={<CalendarMonthRoundedIcon />}
                        color={type_color}
                        sx={{ backgroundColor: overview_colors.primary }}
                    >
                        {date}
                    </Button>

                    {
                        // TODO: Introduce support for senate committee modal list
                        type !== 'senate committees' && (
                            <Button
                                onClick={handleOpen}
                                variant="contained"
                                startIcon={<MenuOpen />}
                                color={type_color}
                                sx={{ backgroundColor: overview_colors.primary }}
                            >
                                {replace_underscores_capitalize(type)}
                            </Button>
                        )
                    }
                    {!hideExport && (
                        <Button
                            variant="contained"
                            color={type_color}
                            sx={{ backgroundColor: overview_colors.primary }}
                            startIcon={<SimCardDownloadRoundedIcon />}
                            onClick={() => setExportModalOpen(true)}
                        >
                            Save PDF
                        </Button>
                    )}
                </div>
            </Slide>

            {type === 'sectors' ? (
                <ModalSectorListDataLayer open={modalOpen} handleClose={handleClose} />
            ) : type === 'institutions' ? (
                <ModalInstitutionListDataLayer open={modalOpen} handleClose={handleClose} />
            ) : type === 'committees' ? (
                <ModalCommitteeListDataLayer open={modalOpen} handleClose={handleClose} />
            ) : type === 'subjects' ? (
                <ModalSubjectListDataLayer open={modalOpen} handleClose={handleClose} />
            ) : (
                <></>
            )}

            <DateSelection
                open={dateOpen}
                handleClose={handleDateClose}
                handleDateChange={handleDateChange}
                views={['year', 'month', 'day']}
            />

            {!hideExport && type === 'subjects' && (
                <DashboardDownloadModal
                    title={entity_name || 'Dashboard'}
                    type={type}
                    modalOpen={exportModalOpen}
                    handleClose={() => setExportModalOpen(false)}
                    overview={overview}
                    recentLobbying={recentLobbying}
                    recentRegistrations={recentRegistrations}
                    recentDeregistrations={recentDeregistrations}
                    sectors={clusters[0].cluster_data}
                    organizations={clusters[1].cluster_data}
                    institutions={clusters[2].cluster_data}
                    publicOfficeHolders={clusters[3].cluster_data}
                    lobbyFirms={clusters[4].cluster_data}
                    exportLoading={exportLoading}
                    exportFail={exportFail}
                />
            )}
            {!hideExport && type === 'institutions' && (
                <DashboardDownloadModal
                    title={entity_name || 'Dashboard'}
                    type={type}
                    modalOpen={exportModalOpen}
                    handleClose={() => setExportModalOpen(false)}
                    updates={updates}
                    overview={overview}
                    recentLobbying={recentLobbying}
                    organizations={clusters[0].cluster_data}
                    sectors={clusters[1].cluster_data}
                    publicOfficeHolders={clusters[2].cluster_data}
                    subjects={clusters[3].cluster_data}
                    keyPhrases={bumperData}
                    lobbyFirms={clusters[4].cluster_data}
                    exportLoading={exportLoading}
                    exportFail={exportFail}
                />
            )}
            {!hideExport && type === 'committees' && (
                <DashboardDownloadModal
                    title={entity_name || 'Dashboard'}
                    type={type}
                    modalOpen={exportModalOpen}
                    handleClose={() => setExportModalOpen(false)}
                    overview={overview}
                    memberships={memberships}
                    recentLobbying={recentLobbying}
                    committee_meetings={bumperData}
                    org_cluster={clusters[0].cluster_data}
                    sector_cluster={clusters[1].cluster_data}
                    dpoh_cluster={clusters[2].cluster_data}
                    subject_cluster={clusters[3].cluster_data}
                    firm_cluster={clusters[4].cluster_data}
                    exportLoading={exportLoading}
                    exportFail={exportFail}
                />
            )}
            {!hideExport && type === 'sectors' && (
                <DashboardDownloadModal
                    title={entity_name || 'Dashboard'}
                    type={type}
                    modalOpen={exportModalOpen}
                    handleClose={() => setExportModalOpen(false)}
                    updates={updates}
                    overview={overview}
                    recentLobbying={recentLobbying}
                    recentRegistrations={recentRegistrations}
                    recentDeregistrations={recentDeregistrations}
                    organizations={clusters[0].cluster_data}
                    institutions={clusters[1].cluster_data}
                    publicOfficeHolders={clusters[2].cluster_data}
                    subjects={clusters[3].cluster_data}
                    keyPhrases={bumperData}
                    lobbyFirms={clusters[4].cluster_data}
                    exportLoading={exportLoading}
                    exportFail={exportFail}
                />
            )}

            <div className=" col-start-1 col-end-13 flex flex-row justify-between">
                <div>
                    <Breadcrumbs className="col-start-2 lg:col-end-12 col-end-12" separator=">">
                        <Link to="/" className="hover:underline">
                            Lobby<b>IQ</b>
                        </Link>
                        {type === 'senate committees' && <div>Senate</div>}
                        {type === 'senate committees' && (
                            <Link to="/senate/committees" className="hover:underline">
                                Committees
                            </Link>
                        )}
                        {type !== 'senate committees' && (
                            <Link to={`/${type}`} className="hover:underline">
                                {replace_underscores_capitalize(type)}
                            </Link>
                        )}
                        <div>
                            {entity_name} {type === 'committees' && `(${entity_code})`}
                        </div>
                    </Breadcrumbs>
                </div>
                <Slide direction="left" in={true}>
                    <div className="lg:flex hidden flex-row gap-4">
                        <Button
                            onClick={handleDateOpen}
                            variant="outlined"
                            startIcon={<CalendarMonthRoundedIcon />}
                            color={type_color}
                            sx={{ color: overview_colors.primary }}
                        >
                            Data From: {date}
                        </Button>
                        {
                            // TODO: Introduce support for senate committee modal list
                            type !== 'senate committees' && (
                                <Button
                                    onClick={handleOpen}
                                    variant="outlined"
                                    startIcon={<MenuOpen />}
                                    color={type_color}
                                    sx={{ color: overview_colors.primary }}
                                >
                                    {replace_underscores_capitalize(type)}
                                </Button>
                            )
                        }
                        {!hideExport && (
                            <Button
                                variant="outlined"
                                color={type_color}
                                sx={{ color: overview_colors.primary }}
                                startIcon={<SimCardDownloadRoundedIcon />}
                                onClick={() => setExportModalOpen(true)}
                            >
                                Save PDF
                            </Button>
                        )}
                    </div>
                </Slide>
            </div>

            {includeUpdates && type === 'sectors' && (
                <div className="mt-4 col-start-1 col-end-13">
                    <UpdateTable data={updates} loading={updatesLoading} name={entity_name!} />
                </div>
            )}

            {includeUpdates && type === 'institutions' && (
                <div className="mt-4 col-start-1 col-end-13">
                    <InstitutionUpdateTable
                        data={updates}
                        loading={updatesLoading}
                        name={entity_name!}
                    />
                </div>
            )}

            {includeUpdates && type === 'subjects' && (
                <div className="mt-4 col-start-1 col-end-13">
                    <SubjectUpdateTable
                        data={updates}
                        loading={updatesLoading}
                        name={entity_name!}
                    />
                </div>
            )}

            {includeTopKeywords && (type === 'committees' || type === 'senate committees') ? (
                <div className="mt-16 col-start-1 col-end-13">
                    <KeywordRankings
                        title="Committee Meeting Tracker"
                        subtitle=""
                        keywordData={bumperData}
                        tooltipContent={committeeMeetingKeyphrases}
                        loading={bumperDataLoading}
                        type={EntityType.getEntityTypeFromLegacy(type)}
                    />
                </div>
            ) : (
                <></>
            )}

            {includeOverview && (
                <div className="mt-16 col-start-1 col-end-13">
                    <Overview
                        dashboard={`${replace_underscores_capitalize(type)}`}
                        topic={`${entity_name}`}
                        title={`${
                            type === 'committees'
                                ? 'Lobbying'
                                : replace_underscores_capitalize(type).slice(0, -1)
                        } Overview ${type === 'committees' ? `(${entity_code})` : ''}`}
                        subtitle={`${entity_name} ${
                            type === 'committees' ? `(${entity_code})` : ''
                        } `}
                        tooltipContent={overviewContent()}
                        barChartTitle="Lobbying Communications (18 months)"
                        primaryColor={overview_colors.primary}
                        secondaryColor={overview_colors.secondary}
                        overviews={overview}
                        loading={overviewLoading}
                        tableHeader={overviewHeaders}
                        overviewIndent={overviewIndent}
                    />
                </div>
            )}

            {includeMemberships && (
                <div className="mt-16 col-start-1 col-end-13">
                    <CommitteeMemberships
                        dashboard={`${replace_underscores_capitalize(type)}`}
                        topic={`${entity_name}`}
                        title="Committee Members"
                        code={entity_code}
                        tableData={memberships}
                        tableHeader={[
                            'name',
                            ...(type !== 'senate committees' ? ['role'] : []),
                            'province_territory',
                            ...(type !== 'senate committees' ? ['constituency'] : []),
                            'affiliation',
                        ]}
                        loading={membershipsLoading}
                    />
                </div>
            )}

            <div className="mt-16 col-start-1 col-end-13">
                <DashTable
                    dashboard={`${replace_underscores_capitalize(type)}`}
                    topic={`${entity_name}`}
                    title={
                        type === 'committees'
                            ? 'Recent Lobbying of Committee Members'
                            : 'Recent Lobbying Communications'
                    }
                    subtitle="Communication Reports"
                    tableData={recentLobbying}
                    loading={recentLobbyingLoading}
                    tableHeader={[
                        'date',
                        'organization',
                        'dpoh',
                        type === 'committees' ? '' : 'title',
                        type === 'sectors' ? 'institution' : 'sector',
                        'lobby_firm',
                        'consultant',
                        'Prior Comms (36M)',
                        'link',
                    ]}
                    tooltipContent={lobbyCommContent()}
                />
            </div>

            {includeRegistrations && (
                <div className="mt-16 col-start-1 col-end-13">
                    <DashTable
                        dashboard={`${replace_underscores_capitalize(type)}`}
                        topic={`${entity_name}`}
                        title="Recent Lobbying Registrations"
                        tableData={recentRegistrations}
                        loading={recentRegistrationsLoading}
                        tableHeader={[
                            'date',
                            'organization',
                            'province',
                            'lobby_firm',
                            'consultant',
                            'history',
                            'dashboard',
                        ]}
                        tooltipContent={lobbyRegContent()}
                    />
                </div>
            )}

            {includeDeregistrations && (
                <div className="mt-16 col-start-1 col-end-13">
                    <DashTable
                        dashboard={`${replace_underscores_capitalize(type)}`}
                        topic={`${entity_name}`}
                        title="Recent Lobbying Deregistrations"
                        tableData={recentDeregistrations}
                        loading={recentDeregistrationsLoading}
                        tableHeader={[
                            'date',
                            'organization',
                            'province',
                            'lobby_firm',
                            'consultant',
                            'dashboard',
                        ]}
                        tooltipContent={lobbyDeregContent()}
                    />
                </div>
            )}

            {clusters.slice(0, -1).map((cluster, idx) => (
                <div key={idx} className="mt-16 col-start-1 col-end-13">
                    <Cluster
                        dashboard={`${replace_underscores_capitalize(type)}`}
                        topic={`${entity_name}`}
                        title={cluster.title}
                        subtitle="Number of Communication Reports"
                        date={date}
                        clusterData={cluster.cluster_data}
                        tooltipContent={cluster.tooltipContent}
                        clusterLoading={cluster.loading}
                    />
                </div>
            ))}

            {includeTopKeywords &&
            type !== 'committees' &&
            type !== 'senate committees' &&
            type !== 'subjects' ? (
                <div className="mt-16 col-start-1 col-end-13">
                    <KeywordRankings
                        title="Key Phrases from Registrations of Recent Lobbying Communications"
                        keywordData={bumperData}
                        tooltipContent={SectorKeyPhrases}
                        loading={bumperDataLoading}
                    />
                </div>
            ) : (
                <></>
            )}

            <div className="mt-16 col-start-1 col-end-13">
                <Cluster
                    dashboard={`${replace_underscores_capitalize(type)}`}
                    topic={`${entity_name}`}
                    title={clusters[clusters.length - 1].title}
                    subtitle="Number of Communication Reports"
                    date={date}
                    clusterData={clusters[clusters.length - 1].cluster_data}
                    tooltipContent={clusters[clusters.length - 1].tooltipContent}
                    clusterLoading={clusters[clusters.length - 1].loading}
                />
            </div>
        </div>
    );
};

export default Dashboard;
