export const percentage_change_string = (
    old_value: number,
    new_value: number
) => {
    if (old_value === 0) {
        return '-';
    } else {
        const percent_change = ((new_value - old_value) / old_value) * 100;
        if (percent_change > 0) {
            return '+' + percent_change.toFixed(0) + '%';
        } else if (percent_change < 0) {
            return percent_change.toFixed(0) + '%';
        } else {
            return '-';
        }
    }
};

export const percentage_change_num = (old_value: number, new_value: number) => {
    let percent;
    if (old_value === 0 && new_value === 0) {
        return 0;
    }
    if (new_value !== 0) {
        if (old_value !== 0) {
            percent = ((new_value - old_value) / old_value) * 100;
        } else {
            percent = 0;
        }
    } else {
        percent = 0;
    }
    return Math.floor(percent);
};
