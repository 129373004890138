import React from 'react';

interface LiqPageContainerProps {
    children: React.ReactNode;
}

const LiqPageContainer = ({ children }: LiqPageContainerProps) => {
    return <div className="lg:py-32 py-20 grid grid-cols-12">{children}</div>;
};

export default LiqPageContainer;
