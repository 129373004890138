import React from 'react';

const LoadingBreakdown = () => {
    return (
        <div className="lg:h-full flex flex-col justify-between bg-white shadow-md rounded-md p-4 animate-pulse">
            <div>
                <div className="bg-slate-100 w-1/3 h-4 mt-2 rounded-md">
                    &nbsp;
                </div>
                <div className="w-full flex lg:flex-row flex-col items-center justify-center mt-6">
                    <div className="bg-slate-100 w-40 h-40 my-8 lg:my-0 rounded-full">
                        &nbsp;
                    </div>
                </div>
            </div>
            <div className="flex flex-col justify-center lg:w-1/2 mr-2 gap-2">
                <div className="w-1/2 h-3 bg-slate-100 rounded-md">&nbsp;</div>
                <div className="w-1/4 h-3 bg-slate-100 rounded-md">&nbsp;</div>
                <div className="w-1/3 h-3 bg-slate-100 rounded-md">&nbsp;</div>
                <div className="w-1/2 h-3 bg-slate-100 rounded-md">&nbsp;</div>
            </div>
        </div>
    );
};

export default LoadingBreakdown;
