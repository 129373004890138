import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { RootState } from '../../../store/store';
// import { bumperSuperList } from '../../../interfaces/bumper-types.interface';
import {
    entityOverviews,
    entityLobbyingActivity,
    entityCluster,
} from '../../../interfaces/generic-entity.interface';
// import axios from 'axios';
// import saveAs from 'file-saver';
import { committeeMember } from '../../committee-dashboards/interfaces/committee-entity.interface';
import { SenateCommitteeAPI } from '../API/SenateCmteAPI';
import { bumperSuperList } from '../../../interfaces/bumper-types.interface';

interface CommitteeState {
    overview: entityOverviews | null;
    overview_loading: boolean;
    overview_fail: boolean;

    members: committeeMember[] | null;
    members_loading: boolean;
    members_fail: boolean;

    lobbying_activity: entityLobbyingActivity[] | null;
    lobbying_activity_loading: boolean;
    lobbying_activity_fail: boolean;

    org_cluster: entityCluster | null;
    org_cluster_loading: boolean;
    org_cluster_fail: boolean;

    sector_cluster: entityCluster | null;
    sector_cluster_loading: boolean;
    sector_cluster_fail: boolean;

    firm_cluster: entityCluster | null;
    firm_cluster_loading: boolean;
    firm_cluster_fail: boolean;

    dpoh_cluster: entityCluster | null;
    dpoh_cluster_loading: boolean;
    dpoh_cluster_fail: boolean;

    subject_cluster: entityCluster | null;
    subject_cluster_loading: boolean;
    subject_cluster_fail: boolean;

    meetings: bumperSuperList[] | null;
    meetings_loading: boolean;
    meetings_fail: boolean;

    // export_loading: boolean;
    // export_fail: boolean;
}

const initialState: CommitteeState = {
    overview: null,
    overview_loading: false,
    overview_fail: false,

    members: null,
    members_loading: false,
    members_fail: false,

    lobbying_activity: null,
    lobbying_activity_loading: false,
    lobbying_activity_fail: false,

    org_cluster: null,
    org_cluster_loading: false,
    org_cluster_fail: false,

    sector_cluster: null,
    sector_cluster_loading: false,
    sector_cluster_fail: false,

    firm_cluster: null,
    firm_cluster_loading: false,
    firm_cluster_fail: false,

    dpoh_cluster: null,
    dpoh_cluster_loading: false,
    dpoh_cluster_fail: false,

    subject_cluster: null,
    subject_cluster_loading: false,
    subject_cluster_fail: false,

    meetings: null,
    meetings_loading: false,
    meetings_fail: false,

    // export_loading: false,
    // export_fail: false,
};

// Fetch the yearly overview data for the current committee
export const fetchCommitteeOverview = createAsyncThunk(
    'senate/committee/fetchCommitteeOverview',
    async ({
        senateCmteId,
        date,
    }: {
        senateCmteId: string;
        date: string;
    }): Promise<entityOverviews> =>
        SenateCommitteeAPI.fetchSenateCommitteeOverview(senateCmteId, date)
);

// Fetch the yearly overview data for the current committee
export const fetchCommitteeMembers = createAsyncThunk(
    'senate/committee/fetchCommitteeMembers',
    async ({
        senateCmteId,
        date,
    }: {
        senateCmteId: string;
        date: string;
    }): Promise<committeeMember[]> => {
        return SenateCommitteeAPI.fetchSenateCommitteeMembers(senateCmteId, date);
    }
);

// Fetch the recent lobbying activity data for the current committee
export const fetchCommitteeRecentLobbying = createAsyncThunk(
    'senate/committee/fetchCommitteeRecentLobbying',
    async ({
        senateCmteId,
        date,
    }: {
        senateCmteId: string;
        date: string;
    }): Promise<entityLobbyingActivity[]> =>
        SenateCommitteeAPI.fetchSenateCommitteeRecentLobbyingActivity(senateCmteId, date)
);

// Fetch the organization cluster data
export const fetchOrganizationCluster = createAsyncThunk(
    'senate/committee/fetchOrganizationCluster',
    async ({
        senateCmteId,
        date,
    }: {
        senateCmteId: string;
        date: string;
    }): Promise<entityCluster> =>
        SenateCommitteeAPI.fetchSenateCommitteeCluster(senateCmteId, 'organizations', date)
);

// Fetch the sector cluster data
export const fetchSectorCluster = createAsyncThunk(
    'senate/committee/fetchSectorCluster',
    async ({
        senateCmteId,
        date,
    }: {
        senateCmteId: string;
        date: string;
    }): Promise<entityCluster> =>
        SenateCommitteeAPI.fetchSenateCommitteeCluster(senateCmteId, 'sectors', date)
);

// Fetch the DPOH cluster data
export const fetchFirmCluster = createAsyncThunk(
    'senate/committee/fetchFirmCluster',
    async ({
        senateCmteId,
        date,
    }: {
        senateCmteId: string;
        date: string;
    }): Promise<entityCluster> =>
        SenateCommitteeAPI.fetchSenateCommitteeCluster(senateCmteId, 'lobbyfirms', date)
);

// Fetch the DPOH cluster data
export const fetchDPOHCluster = createAsyncThunk(
    'senate/committee/fetchDPOHCluster',
    async ({
        senateCmteId,
        date,
    }: {
        senateCmteId: string;
        date: string;
    }): Promise<entityCluster> =>
        SenateCommitteeAPI.fetchSenateCommitteeCluster(senateCmteId, 'dpoh', date)
);

// Fetch the subject cluster data
export const fetchSubjectCluster = createAsyncThunk(
    'senate/committee/fetchSubjectCluster',
    async ({
        senateCmteId,
        date,
    }: {
        senateCmteId: string;
        date: string;
    }): Promise<entityCluster> =>
        SenateCommitteeAPI.fetchSenateCommitteeCluster(senateCmteId, 'subject', date)
);

// Fetch the recent senate committee meetings
export const fetchCommitteeMeetings = createAsyncThunk(
    'senate/committee/fetchCommitteeMeetings',
    async ({
        senateCmteId,
        date,
    }: {
        senateCmteId: string;
        date: string;
    }): Promise<bumperSuperList[]> =>
        SenateCommitteeAPI.fetchSenateCommitteeMeetings(senateCmteId, date)
);

// export const exportCommitteeDashboard = createAsyncThunk(
//     'senate/committee/exportDashboard',
//     async (data: any): Promise<void> => {
//         const response = await axios.post(
//             `${process.env.REACT_APP_PDF_SERVICE_URL}/committee/report`,
//             data,
//             {
//                 responseType: 'blob',
//             }
//         );

//         const pdfEncoding = response.data;

//         const blob = new Blob([pdfEncoding], { type: 'application/pdf' });

//         saveAs(blob, 'LobbyIQ-Committee');
//     }
// );

const senateCommitteeSlice = createSlice({
    name: 'senateCommitteeReducer',
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(fetchCommitteeOverview.pending, (state) => {
                state.overview_loading = true;
            })
            .addCase(fetchCommitteeOverview.fulfilled, (state, action) => {
                state.overview_loading = false;
                state.overview_fail = false;
                state.overview = action.payload;
            })
            .addCase(fetchCommitteeOverview.rejected, (state) => {
                state.overview_loading = false;
                state.overview_fail = true;
                state.overview = null;
            })
            .addCase(fetchCommitteeMembers.pending, (state) => {
                state.members_loading = true;
            })
            .addCase(fetchCommitteeMembers.fulfilled, (state, action) => {
                state.members_loading = false;
                state.members_fail = false;
                state.members = action.payload;
            })
            .addCase(fetchCommitteeMembers.rejected, (state) => {
                state.members_loading = false;
                state.members_fail = true;
                state.members = null;
            })
            .addCase(fetchCommitteeRecentLobbying.pending, (state) => {
                state.lobbying_activity_loading = true;
            })
            .addCase(fetchCommitteeRecentLobbying.fulfilled, (state, action) => {
                state.lobbying_activity_loading = false;
                state.lobbying_activity_fail = false;
                state.lobbying_activity = action.payload;
            })
            .addCase(fetchCommitteeRecentLobbying.rejected, (state) => {
                state.lobbying_activity_loading = false;
                state.lobbying_activity_fail = true;
                state.lobbying_activity = null;
            })
            .addCase(fetchOrganizationCluster.pending, (state) => {
                state.org_cluster_loading = true;
            })
            .addCase(fetchOrganizationCluster.fulfilled, (state, action) => {
                state.org_cluster_loading = false;
                state.org_cluster_fail = false;
                state.org_cluster = action.payload;
            })
            .addCase(fetchOrganizationCluster.rejected, (state) => {
                state.org_cluster_loading = false;
                state.org_cluster_fail = true;
                state.org_cluster = null;
            })
            .addCase(fetchSectorCluster.pending, (state) => {
                state.sector_cluster_loading = true;
            })
            .addCase(fetchSectorCluster.fulfilled, (state, action) => {
                state.sector_cluster_loading = false;
                state.sector_cluster_fail = false;
                state.sector_cluster = action.payload;
            })
            .addCase(fetchSectorCluster.rejected, (state) => {
                state.sector_cluster_loading = false;
                state.sector_cluster_fail = true;
                state.sector_cluster = null;
            })
            .addCase(fetchFirmCluster.pending, (state) => {
                state.firm_cluster_loading = true;
            })
            .addCase(fetchFirmCluster.fulfilled, (state, action) => {
                state.firm_cluster_loading = false;
                state.firm_cluster_fail = false;
                state.firm_cluster = action.payload;
            })
            .addCase(fetchFirmCluster.rejected, (state) => {
                state.firm_cluster_loading = false;
                state.firm_cluster_fail = true;
                state.firm_cluster = null;
            })
            .addCase(fetchDPOHCluster.pending, (state) => {
                state.dpoh_cluster_loading = true;
            })
            .addCase(fetchDPOHCluster.fulfilled, (state, action) => {
                state.dpoh_cluster_loading = false;
                state.dpoh_cluster_fail = false;
                state.dpoh_cluster = action.payload;
            })
            .addCase(fetchDPOHCluster.rejected, (state) => {
                state.dpoh_cluster_loading = false;
                state.dpoh_cluster_fail = true;
                state.dpoh_cluster = null;
            })
            .addCase(fetchSubjectCluster.pending, (state) => {
                state.subject_cluster_loading = true;
            })
            .addCase(fetchSubjectCluster.fulfilled, (state, action) => {
                state.subject_cluster_loading = false;
                state.subject_cluster_fail = false;
                state.subject_cluster = action.payload;
            })
            .addCase(fetchSubjectCluster.rejected, (state) => {
                state.subject_cluster_loading = false;
                state.subject_cluster_fail = true;
                state.subject_cluster = null;
            })
            .addCase(fetchCommitteeMeetings.pending, (state) => {
                state.meetings_loading = true;
            })
            .addCase(fetchCommitteeMeetings.fulfilled, (state, action) => {
                state.meetings_loading = false;
                state.meetings_fail = false;
                state.meetings = action.payload;
            })
            .addCase(fetchCommitteeMeetings.rejected, (state) => {
                state.meetings_loading = false;
                state.meetings_fail = true;
                state.meetings = null;
            });
        // .addCase(exportCommitteeDashboard.pending, (state) => {
        //     state.export_loading = true;
        // })
        // .addCase(exportCommitteeDashboard.fulfilled, (state, action) => {
        //     state.export_loading = false;
        //     state.export_fail = false;
        // })
        // .addCase(exportCommitteeDashboard.rejected, (state) => {
        //     state.export_loading = false;
        //     state.export_fail = true;
        // });
    },
});

export const selectCommitteeOverview = (state: RootState) => state.senateCommitteeData.overview;
export const selectCommitteeOverviewLoading = (state: RootState) =>
    state.senateCommitteeData.overview_loading;

export const selectCommitteeMembers = (state: RootState) => state.senateCommitteeData.members;
export const selectCommitteeMembersLoading = (state: RootState) =>
    state.senateCommitteeData.members_loading;

export const selectCommitteeRecentLobbying = (state: RootState) =>
    state.senateCommitteeData.lobbying_activity;
export const selectCommitteeRecentLobbyingLoading = (state: RootState) =>
    state.senateCommitteeData.lobbying_activity_loading;

export const selectOrganizationCluster = (state: RootState) =>
    state.senateCommitteeData.org_cluster;
export const selectOrganizationClusterLoading = (state: RootState) =>
    state.senateCommitteeData.org_cluster_loading;

export const selectSectorCluster = (state: RootState) => state.senateCommitteeData.sector_cluster;
export const selectSectorClusterLoading = (state: RootState) =>
    state.senateCommitteeData.sector_cluster_loading;

export const selectFirmCluster = (state: RootState) => state.senateCommitteeData.firm_cluster;
export const selectFirmClusterLoading = (state: RootState) =>
    state.senateCommitteeData.firm_cluster_loading;

export const selectDPOHCluster = (state: RootState) => state.senateCommitteeData.dpoh_cluster;
export const selectDPOHClusterLoading = (state: RootState) =>
    state.senateCommitteeData.dpoh_cluster_loading;

export const selectSubjectCluster = (state: RootState) => state.senateCommitteeData.subject_cluster;
export const selectSubjectClusterLoading = (state: RootState) =>
    state.senateCommitteeData.subject_cluster_loading;

export const selectCommitteeMeetings = (state: RootState) => state.senateCommitteeData.meetings;
export const selectCommitteeMeetingsLoading = (state: RootState) =>
    state.senateCommitteeData.meetings_loading;

export const senateCommitteeData = (state: RootState) => state.senateCommitteeData;

// export const selectCommitteeExportLoading = (state: RootState) =>
//     state.senateCommitteeData.export_loading;
// export const selectCommitteeExportFail = (state: RootState) => state.senateCommitteeData.export_fail;

export default senateCommitteeSlice.reducer;
