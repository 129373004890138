import React, { useEffect } from 'react';
import {
    fetchSectorList,
    selectSectorList,
} from '../../features/sector-dashboards/store/sectorListSlice';
import { useSelector } from 'react-redux';
import { entityListExtra } from '../../interfaces/generic-entity.interface';
import { useAppDispatch } from '../../store/store';
import ModalEntityList from './ModalEntityList';

interface ModalSectorListProps {
    open: boolean;
    handleClose: () => void;
}

const ModalSectorListDataLayer = ({ open, handleClose }: ModalSectorListProps) => {
    const dispatch = useAppDispatch();

    const entity_list: entityListExtra[] | null = useSelector(selectSectorList);

    useEffect(() => {
        if (!entity_list) dispatch(fetchSectorList());
    }, [dispatch, entity_list]);

    return (
        <ModalEntityList
            type="sectors"
            list={entity_list}
            // TODO: connect handleClose to modal prop
            open={open}
            handleClose={handleClose}
        />
    );
};

export default ModalSectorListDataLayer;
