import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import logo from '../../assets/vectors/LIQ_SLATE.svg';

import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

import Drawer from '@mui/material/Drawer';
import { Divider, MenuItem } from '@mui/material';

import Menu from '@mui/icons-material/MenuOpenRounded';
import Close from '@mui/icons-material/CloseRounded';
import AccountPanel from './AccountPanel';
import { MP_FN } from '../../features/dpoh-dashboards/constants';

// userEmail and userloading are required parameters to be passed to the account panel component
interface mobileNavbarProps {
    userEmail: string | null;
    userLoading: boolean;
    handleLogin: () => Promise<void>;
    handleSignUp: () => Promise<void>;
    handleLogout: () => void;
}

/*
    Component: MobileNavbar.tsx
    Params: 
        userEmail - Email of current user
        userloading - login loading status of current user
    Author: Will Brewer
    Desc:
    Mobile responsive navbar component. Fits to a dynamic viewport width and reduces visual clutter for
    mobile experience. Built custom with tailwind and conditional rendering. No library dependencies aside
    from MUI-icons used in the menu. Automatically closes on scroll away as well as clicking outside the menu.
*/
const MobileNavbar = ({
    userEmail,
    userLoading,
    handleLogin,
    handleSignUp,
    handleLogout,
}: mobileNavbarProps) => {
    const [open, setOpen] = useState<boolean>(false);
    const handleDrawerOpen = () => {
        setOpen(true);
    };

    const handleDrawerClose = () => {
        setOpen(false);
    };

    return (
        <header className="text-slate-700 fixed z-10 w-full rounded-b-xl bg-white shadow-md">
            <Drawer anchor="top" open={open} onClose={handleDrawerClose}>
                <div className="flex flex-col text-lg space-y-2 justify-center mb-2 px-6 pt-6">
                    <Accordion
                        square={false}
                        sx={{
                            borderRadius: '5px',
                            backgroundColor: '#0284c7',
                            boxShadow: 'none',
                        }}
                    >
                        <AccordionSummary
                            sx={{
                                color: 'white',
                            }}
                            expandIcon={<ExpandMoreIcon sx={{ color: 'white' }} />}
                        >
                            Dashboards
                        </AccordionSummary>
                        <AccordionDetails
                            sx={{
                                backgroundColor: 'rgb(248 250 252)',
                                borderRadius: '0px 0px 4px 4px',
                                padding: 0,
                                paddingTop: 1.5,
                                paddingBottom: 1.5,
                            }}
                        >
                            <MenuItem component={Link} to="/sectors" onClick={handleDrawerClose}>
                                Sectors
                            </MenuItem>
                            <MenuItem
                                component={Link}
                                to="/institutions"
                                onClick={handleDrawerClose}
                            >
                                Institutions
                            </MenuItem>
                            <MenuItem
                                component={Link}
                                to={`/institutions/103/dpoh?institution=${encodeURIComponent(
                                    'House of Commons'
                                )}&title=${encodeURIComponent(MP_FN)}`}
                                onClick={handleDrawerClose}
                            >
                                Members of Parliament
                            </MenuItem>
                            <MenuItem component={Link} to="/committees" onClick={handleDrawerClose}>
                                Parliamentary Committees
                            </MenuItem>
                            <MenuItem component={Link} to="/subjects" onClick={handleDrawerClose}>
                                Subjects
                            </MenuItem>
                            <MenuItem component={Link} to="/issues" onClick={handleDrawerClose}>
                                Issues
                            </MenuItem>
                            <MenuItem component={Link} to="/firms" onClick={handleDrawerClose}>
                                Lobby Firms
                            </MenuItem>
                            <Divider sx={{ marginY: 1, borderColor: 'lightgray' }} />
                            <MenuItem component={Link} to="/in-house" onClick={handleDrawerClose}>
                                In-House
                            </MenuItem>
                            <Divider sx={{ marginY: 1, borderColor: 'lightgray' }} />
                            <MenuItem
                                component={Link}
                                to="/big-picture"
                                onClick={handleDrawerClose}
                            >
                                Big Picture
                            </MenuItem>
                        </AccordionDetails>
                    </Accordion>
                    <Accordion
                        square={false}
                        sx={{
                            borderRadius: '5px',
                            backgroundColor: 'rgb(248 250 252)',
                            boxShadow: 'none',
                        }}
                    >
                        <AccordionSummary expandIcon={<ExpandMoreIcon sx={{ color: 'white' }} />}>
                            Features
                        </AccordionSummary>
                        <AccordionDetails
                            sx={{
                                backgroundColor: 'rgb(248 250 252)',
                                borderRadius: '0px 0px 4px 4px',
                            }}
                        >
                            <MenuItem
                                component={Link}
                                to="/features/monitor"
                                onClick={handleDrawerClose}
                            >
                                Committee Monitoring
                            </MenuItem>
                            <MenuItem
                                component={Link}
                                to="/features/stakeholder"
                                onClick={handleDrawerClose}
                            >
                                Stakeholder Monitoring
                            </MenuItem>
                            <MenuItem
                                component={Link}
                                to="/features/tracking"
                                onClick={handleDrawerClose}
                            >
                                Issue Tracking
                            </MenuItem>
                            <MenuItem
                                component={Link}
                                to="/features/updates"
                                onClick={handleDrawerClose}
                            >
                                Stay Ahead Updates
                            </MenuItem>
                            <MenuItem
                                component={Link}
                                to="/features/research"
                                onClick={handleDrawerClose}
                            >
                                Research Hub
                            </MenuItem>
                            <MenuItem
                                component={Link}
                                to="/features/gr-insider"
                                onClick={handleDrawerClose}
                            >
                                GR Insider
                            </MenuItem>
                        </AccordionDetails>
                    </Accordion>
                    <div className="bg-slate-50 rounded-md">
                        <MenuItem onClick={handleDrawerClose} component={Link} to="/custom-query">
                            Search Data
                        </MenuItem>
                    </div>
                    <div className="bg-slate-50 rounded-md">
                        <MenuItem onClick={handleDrawerClose} component={Link} to="/pricing">
                            Subscriptions
                        </MenuItem>
                    </div>
                    <div className="bg-slate-50 rounded-md">
                        <MenuItem onClick={handleDrawerClose} component={Link} to="/solutions">
                            Solutions
                        </MenuItem>
                    </div>
                    <div className="bg-slate-50 rounded-md">
                        <MenuItem onClick={handleDrawerClose} component={Link} to="/about">
                            About
                        </MenuItem>
                    </div>

                    {userEmail && !userLoading ? (
                        <AccountPanel
                            userEmail={userEmail}
                            userLoading={userLoading}
                            handleLogin={handleLogin}
                            handleLogout={handleLogout}
                            handleClose={handleDrawerClose}
                        />
                    ) : (
                        <div className="flex flex-row pt-5">
                            <button
                                className="w-1/2 mr-2 text-center border border-sky-700 text-sky-700 transition ease-in-out hover:bg-sky-100 rounded-xl py-1"
                                onClick={handleLogin}
                            >
                                Sign In
                            </button>
                            <Divider
                                orientation="vertical"
                                sx={{
                                    borderColor: 'gray',
                                }}
                                flexItem
                            />
                            <button
                                className="w-1/2 ml-2 text-center bg-sky-700 text-white transition ease-in-out hover:bg-sky-600 rounded-xl py-1"
                                onClick={handleSignUp}
                            >
                                Sign Up
                            </button>
                        </div>
                    )}

                    <div className="flex flex-row items-center justify-between px-4 py-4">
                        <Link to="/" onClick={handleDrawerClose}>
                            <img className="w-8" src={logo} alt="LIQ logo" />
                        </Link>
                        <button onClick={handleDrawerClose}>
                            <Close fontSize="large" color="error" />
                        </button>
                    </div>
                </div>
            </Drawer>
            <div className="flex flex-row items-center justify-between px-6 py-3">
                <Link
                    to="/"
                    className="text-xs flex flex-row items-center gap-3 leading-4 text-sky-700"
                >
                    <img className="w-8" src={logo} alt="LIQ logo" />
                    Technology for
                    <br />
                    Government Relations
                </Link>
                <div className="space-x-4">
                    <button onClick={handleDrawerOpen}>
                        <Menu fontSize="medium" />
                    </button>
                </div>
            </div>
        </header>
    );
};

export default MobileNavbar;
