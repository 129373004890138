import React from 'react';

interface LiqCardProps {
    children: React.ReactNode;
}

const LiqCard = ({ children }: LiqCardProps) => {
    return <div className="bg-white shadow-md rounded-md p-4">{children}</div>;
};

export default LiqCard;
