export const DPOHOrganizations = (
    <div className="flex flex-col gap-2">
        <div>
            - Prior Comms (12M) are Total communications between the given
            organization and DPOH, in the last 12 months.
        </div>
        <div>
            - Prior Comms (36M) are Total communications between the given
            organization and DPOH, in the last 12 months.
        </div>
        <div>
            - Percent Comms (12M) represents the portion of all communications
            from the last 12 months by this organization which targeted this
            DPOH
        </div>
    </div>
);
