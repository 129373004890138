import { signUp } from '../../store/reducers/userSlice';
import { useAppDispatch } from '../../store/store';
import logo from '../../assets/vectors/LIQ_badge.png';

export const AccountRequired = () => {
    const dispatch = useAppDispatch();

    const handleSignUpRedirect = async () => {
        dispatch(signUp());
    };

    return (
        <div>
            <div className="flex flex-col sm:flex-row items-center justify-center text-xl py-[50vh]">
                <img src={logo} className="h-20" alt="logo" />
                <div style={{ marginLeft: '20px', marginRight: '20px' }} />
                <p>Please</p>
                <div
                    className="mx-1 text-cyan-500 font-bold hover:underline hover:cursor-pointer"
                    onClick={handleSignUpRedirect}
                >
                    create a LobbyIQ account or log in
                </div>
                <p>to apply for a free trial.</p>
            </div>
        </div>
    );
};
