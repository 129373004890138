import React, { useEffect, useState } from 'react';

import { Link } from 'react-router-dom';
import Modal from '../../components/misc/Modal';

import {
    Alert,
    Button,
    IconButton,
    InputAdornment,
    Snackbar,
    Step,
    StepLabel,
    Stepper,
    TextField,
} from '@mui/material';

import ChevronLeftRoundedIcon from '@mui/icons-material/ChevronLeftRounded';
import ChevronRightRoundedIcon from '@mui/icons-material/ChevronRightRounded';
import SaveRoundedIcon from '@mui/icons-material/SaveRounded';
import { useAppDispatch } from '../../store/store';
import { useSelector } from 'react-redux';
import LoadSpinner from '../../components/misc/LoadSpinner';
import {
    fetchCustomIssueList,
    selectCustomIssueList,
    selectIssueListLoading,
} from '../../features/issue-dashboards/store/issueListSlice';
import { createOrUpdateIssue, selectCreateIssueLoading } from '../../features/issue-dashboards/store/issueSlice';
import AddToPhotosIcon from '@mui/icons-material/AddToPhotos';
import ClearIcon from '@mui/icons-material/Clear';
import { issueEditorItem } from '../../features/issue-dashboards/interface/issue-entity.interface';
import { ProductType, selectFirstName, selectLastName, selectUserPermissions, updateIssueEmailPreferences } from '../../store/reducers/userSlice';

interface issueCreationModalProps {
    open: boolean;
    activeIssueId: number | null;
    handleClose: () => void;
}

const IssueCreationModal = ({ open, activeIssueId, handleClose }: issueCreationModalProps) => {
    const dispatch = useAppDispatch();

    const listLoading = useSelector(selectIssueListLoading);
    const createIssueLoading = useSelector(selectCreateIssueLoading);
    const selectedIssues: issueEditorItem[] = useSelector(selectCustomIssueList);
    const permissions = useSelector(selectUserPermissions);
    const userFirstName = useSelector(selectFirstName);
    const userLastName = useSelector(selectLastName);

    const [activeStep, setActiveStep] = React.useState(0);

    const [maxAlertOpen, setMaxAlertOpen] = useState<boolean>(false);
    const [collisionAlertOpen, setCollisionAlertOpen] = useState<boolean>(false);
    const [name, setName] = useState<string>('');
    const [keywords, setKeywords] = useState<string[]>(['']);

    const loading = listLoading || createIssueLoading;

    // update initial values on first open
    useEffect(() => {
        if (activeIssueId) {
            const selectedIssue = selectedIssues.find((issue) => issue.code === activeIssueId);
            if (selectedIssue) {
                setName(selectedIssue?.title);
                setKeywords(selectedIssue?.keywords);
            }
        } else {
            setName('');
            setKeywords(['']);
            setActiveStep(0);
        }
        // only reset the state when the selected issue changes
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [activeIssueId]);

    const handleAlertClose = (event: React.SyntheticEvent | Event, reason: string) => {
        if (reason !== 'clickaway') {
            setMaxAlertOpen(false);
            setCollisionAlertOpen(false);
        }
    };

    const handleNext = async () => {
        if (activeStep === 0) {
            if (keywords.length !== 0) setActiveStep((prevActiveStep) => prevActiveStep + 1);
        } else if (activeStep === 1) {
            const filteredKeywords = filterKeywords();
            const response = await dispatch(
                createOrUpdateIssue({ name, keywords: filteredKeywords, id: activeIssueId })
            ).unwrap();

            const updateIssueRequest = {
                issueId: response.issueId,
                includeEmail: true,
                issueName: name,
                firstName: userFirstName,
                lastName: userLastName,
                isTrial: permissions?.productType === ProductType.TRIAL,
            };
            dispatch(updateIssueEmailPreferences(updateIssueRequest));
            await dispatch(fetchCustomIssueList()).unwrap();
            handleClose();
        }
    };

    const handleBack = () => {
        setActiveStep((prevActiveStep) => prevActiveStep - 1);
    };

    const addNewKeyword = () => {
        if (!keywords.some((keyword) => keyword === '')) {
            setKeywords(keywords.concat(''));
        }
    };

    const modifyKeywords = (keyword: string, index: number) => {
        const newKeywords = keywords.map((existingKeyword, i) => {
            if (i === index) {
                // replace the intended keyword
                return keyword;
            } else {
                // The rest haven't changed
                return existingKeyword;
            }
        });
        setKeywords(newKeywords);
    };

    const removeKeyword = (index: number) => {
        if (keywords.length === 1) {
            setKeywords(['']);
        } else {
            // filter out specified index
            const newKeywords = keywords.filter((_, i) => i !== index);
            setKeywords(newKeywords);
        }
    };

    const filterKeywords = () => {
        return keywords.filter((keyword) => keyword.trim().length > 0);
    };

    return (
        <Modal open={open} width={60} onClose={handleClose} title={`Create Custom Issue`}>
            <Snackbar
                open={collisionAlertOpen}
                autoHideDuration={3000}
                onClose={(event: React.SyntheticEvent | Event, reason: string) =>
                    handleAlertClose(event, reason)
                }
                anchorOrigin={{ horizontal: 'center', vertical: 'top' }}
            >
                <Alert severity="error" sx={{ width: '100%' }}>
                    Already selected
                </Alert>
            </Snackbar>

            <Snackbar
                open={maxAlertOpen}
                autoHideDuration={3000}
                onClose={(event: React.SyntheticEvent | Event, reason: string) =>
                    handleAlertClose(event, reason)
                }
                anchorOrigin={{ horizontal: 'center', vertical: 'top' }}
            >
                <Alert severity="error" sx={{ width: '100%' }}>
                    Max selections reached
                </Alert>
            </Snackbar>

            <div className="max-h-[75vh] overflow-y-auto">
                <div className="p-3">
                    <Stepper activeStep={activeStep}>
                        <Step>
                            <StepLabel>Create</StepLabel>
                        </Step>
                        <Step>
                            <StepLabel error={keywords.length === 0 ? true : false}>
                                Review
                            </StepLabel>
                        </Step>
                    </Stepper>
                </div>

                <div className="p-2 flex flex-row justify-between">
                    <Button
                        variant="outlined"
                        color="inherit"
                        disabled={activeStep === 0}
                        onClick={handleBack}
                        startIcon={<ChevronLeftRoundedIcon />}
                    >
                        Back
                    </Button>
                    {loading ? (
                        <LoadSpinner />
                    ) : (
                        <Button
                            variant="outlined"
                            onClick={handleNext}
                            disabled={keywords.length === 0 ? true : false}
                            endIcon={
                                activeStep === 1 ? <SaveRoundedIcon /> : <ChevronRightRoundedIcon />
                            }
                        >
                            {activeStep === 1 ? 'Save' : 'Next'}
                        </Button>
                    )}
                </div>

                {activeStep === 0 ? (
                    <div className="p-4">
                        <div className="p-2 flex flex-row justify-between">
                            <div className="text-slate-500 text-xl font-bold">
                                Please enter the title and keywords for this Issue dashboard
                            </div>
                            <Link
                                className="text-blue-600 underline hover:underline-offset-2"
                                to={`/issues`}
                                target="_blank"
                            >
                                Existing Issue Dashboards
                            </Link>
                        </div>
                        <hr className="h-0.5 my-3 bg-slate-100 rounded" />
                        <div className="p-2 font-bold">Title</div>
                        <div className="width-12">
                            <TextField
                                fullWidth
                                id="keyword"
                                placeholder="Enter Title"
                                variant="outlined"
                                autoComplete="off"
                                onChange={(e) => setName(e.target.value)}
                                value={name}
                            />
                        </div>

                        <div className="p-2 mt-8 font-bold">Keywords</div>
                        <div className="pl-2 my-2 text-slate-500">
                            Enter the plural of a keyword as a separate keyword to ensure plurals are included in Issue Tracker emails and dashboards.
                        </div>
                        <div className="pl-2 mb-3 text-slate-500">
                            For example, include “price” and “prices” or “tax” and “taxes”.
                        </div>
                        {keywords.map((keyword, idx) => (
                            <div className="pb-2">
                                <TextField
                                    fullWidth
                                    id={'keyword' + idx}
                                    placeholder="Enter Keyword"
                                    variant="outlined"
                                    autoComplete="off"
                                    onChange={(e) => modifyKeywords(e.target.value, idx)}
                                    value={keyword}
                                    InputProps={{
                                        endAdornment: (
                                            <InputAdornment position="start">
                                                <IconButton onClick={() => removeKeyword(idx)}>
                                                    <ClearIcon />
                                                </IconButton>
                                            </InputAdornment>
                                        ),
                                    }}
                                />
                            </div>
                        ))}
                        {keywords.length < 20 && (
                            <div className="border border-1 border-slate-300 rounded-md p-3 my-2 text-blue-500 font-medium">
                                <div
                                    className="flex flex-row content-center gap-2 hover:cursor-pointer"
                                    onClick={addNewKeyword}
                                >
                                    Add Keyword
                                    <AddToPhotosIcon />
                                </div>
                            </div>
                        )}
                        <Button
                            sx={{ marginTop: '35px' }}
                            variant="contained"
                            fullWidth
                            onClick={handleNext}
                            disabled={filterKeywords().length === 0 || name === '' ? true : false}
                        >
                            continue
                        </Button>
                    </div>
                ) : (
                    <div className="p-4">
                        <div className="p-2 font-bold text-slate-500">Confirm Issue Creation</div>
                        <hr className="h-0.5 mb-8 bg-slate-100 rounded" />
                        <div className="flex flex-row gap-3">
                            <div>Title:</div>
                            <div className="font-bold">{name}</div>
                        </div>
                        <div className="flex flex-row gap-3 mt-3">
                            <div>Keywords:</div>
                            <div className="font-bold">
                                {keywords.filter((keyword) => keyword !== '').join(', ')}
                            </div>
                        </div>

                        <div className="mt-10">
                            *Note it may take up to 48 hours for any changes to be reflected on the
                            dashboard.
                        </div>
                    </div>
                )}
            </div>
        </Modal>
    );
};

export default IssueCreationModal;
