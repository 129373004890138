import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { RootState } from '../store';
import {
    entityOrg,
    entityOverviews,
    entityRegistration,
} from '../../interfaces/generic-entity.interface';
import { lobbyFirmAPI } from '../../API/lobbyFirmAPI';
import axios from 'axios';
import saveAs from 'file-saver';

interface LobbyFirmState {
    overview: entityOverviews | null;
    overview_fail: boolean;
    overview_loading: boolean;

    registrations: entityRegistration[] | null;
    registrations_fail: boolean;
    registrations_loading: boolean;

    deregistrations: entityRegistration[] | null;
    deregistrations_fail: boolean;
    deregistrations_loading: boolean;

    top_partners: entityOrg[] | null;
    top_partners_loading: boolean;
    top_partners_fail: boolean;

    top_sectors: entityOrg[] | null;
    top_sectors_loading: boolean;
    top_sectors_fail: boolean;

    top_institutions: entityOrg[] | null;
    top_institutions_loading: boolean;
    top_institutions_fail: boolean;

    export_loading: boolean;
    export_fail: boolean;
}

const initialState: LobbyFirmState = {
    overview: null,
    overview_fail: false,
    overview_loading: false,

    registrations: null,
    registrations_fail: false,
    registrations_loading: false,

    deregistrations: null,
    deregistrations_fail: false,
    deregistrations_loading: false,

    top_partners: null,
    top_partners_loading: false,
    top_partners_fail: false,

    top_sectors: null,
    top_sectors_loading: false,
    top_sectors_fail: false,

    top_institutions: null,
    top_institutions_loading: false,
    top_institutions_fail: false,

    export_loading: false,
    export_fail: false,
};

// Fetch the recent lobbying activity data for the current organization
export const fetchLobbyFirmOverview = createAsyncThunk(
    'lobbyFirm/fetchLobbyFirmOverview',
    async ({
        firm,
        date,
    }: {
        firm: string;
        date: string;
    }): Promise<entityOverviews | null> =>
        lobbyFirmAPI.fetchLobbyOverview('firm', firm, date)
);

// Fetch the recent registrations for the current house
export const fetchLobbyFirmLatestRegistrations = createAsyncThunk(
    'lobbyfirm/fetchLobbyFirmLatestRegistrations',
    async ({
        firm,
        date,
    }: {
        firm: string;
        date: string;
    }): Promise<entityRegistration[] | null> =>
        lobbyFirmAPI.fetchLobbyLatestRegistrations('firm', firm, date)
);

// Fetch the recent registrations for the current house
export const fetchLobbyFirmLatestDeregistrations = createAsyncThunk(
    'lobbyfirm/fetchLobbyFirmLatestDeregistrations',
    async ({
        firm,
        date,
    }: {
        firm: string;
        date: string;
    }): Promise<entityRegistration[] | null> =>
        lobbyFirmAPI.fetchLobbyLatestDeregistrations('firm', firm, date)
);

// Fetch the top partners lobbied by the current organization
export const fetchLobbyFirmTopPartners = createAsyncThunk(
    'lobbyfirm/fetchLobbyFirmTopPartners',
    async ({
        firm,
        date,
    }: {
        firm: string;
        date: string;
    }): Promise<entityOrg[] | null> =>
        lobbyFirmAPI.fetchLobbyTopPartners('firm', firm, date)
);

// Fetch the top sectors lobbied by the current organization
export const fetchLobbyFirmTopSectors = createAsyncThunk(
    'lobbyFirm/fetchLobbyFirmTopSectors',
    async ({
        firm,
        date,
    }: {
        firm: string;
        date: string;
    }): Promise<entityOrg[] | null> =>
        lobbyFirmAPI.fetchLobbyTopSectors('firm', firm, date)
);

// Fetch the top institutions lobbied by the current organization
export const fetchLobbyFirmTopInstitutions = createAsyncThunk(
    'lobbyFirm/fetchLobbyFirmTopInstitutions',
    async ({
        firm,
        date,
    }: {
        firm: string;
        date: string;
    }): Promise<entityOrg[] | null> =>
        lobbyFirmAPI.fetchLobbyTopInstitutions('firm', firm, date)
);

export const exportLobbyFirmDashboard = createAsyncThunk(
    'lobbyFirm/exportDashboard',
    async (data: any): Promise<void> => {
        const response = await axios.post(
            `${process.env.REACT_APP_PDF_SERVICE_URL}/lobbyFirm/report`,
            data,
            {
                responseType: 'blob',
            }
        );

        const pdfEncoding = response.data;

        const blob = new Blob([pdfEncoding], { type: 'application/pdf' });

        saveAs(blob, 'LobbyIQ-LobbyFirm');
    }
);

const lobbyFirmSlice = createSlice({
    name: 'lobbyFirmReducer',
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(fetchLobbyFirmOverview.pending, (state) => {
                state.overview_loading = true;
            })
            .addCase(fetchLobbyFirmOverview.fulfilled, (state, action) => {
                state.overview_loading = false;
                state.overview = action.payload;
            })
            .addCase(fetchLobbyFirmOverview.rejected, (state) => {
                state.overview_loading = false;
                state.overview_fail = true;
                state.overview = null;
            })
            .addCase(fetchLobbyFirmLatestRegistrations.pending, (state) => {
                state.registrations_loading = true;
            })
            .addCase(
                fetchLobbyFirmLatestRegistrations.fulfilled,
                (state, action) => {
                    state.registrations_loading = false;
                    state.registrations = action.payload;
                }
            )
            .addCase(fetchLobbyFirmLatestRegistrations.rejected, (state) => {
                state.registrations_loading = false;
                state.registrations_fail = true;
                state.registrations = null;
            })
            .addCase(fetchLobbyFirmLatestDeregistrations.pending, (state) => {
                state.deregistrations_loading = true;
            })
            .addCase(
                fetchLobbyFirmLatestDeregistrations.fulfilled,
                (state, action) => {
                    state.deregistrations_loading = false;
                    state.deregistrations = action.payload;
                }
            )
            .addCase(fetchLobbyFirmLatestDeregistrations.rejected, (state) => {
                state.deregistrations_loading = false;
                state.deregistrations_fail = true;
                state.deregistrations = null;
            })
            .addCase(fetchLobbyFirmTopPartners.pending, (state) => {
                state.top_partners_loading = true;
            })
            .addCase(fetchLobbyFirmTopPartners.fulfilled, (state, action) => {
                state.top_partners = action.payload;
                state.top_partners_loading = false;
            })
            .addCase(fetchLobbyFirmTopPartners.rejected, (state) => {
                state.top_partners = null;
                state.top_partners_fail = true;
                state.top_partners_loading = false;
            })
            .addCase(fetchLobbyFirmTopSectors.pending, (state) => {
                state.top_sectors_loading = true;
            })
            .addCase(fetchLobbyFirmTopSectors.fulfilled, (state, action) => {
                state.top_sectors = action.payload;
                state.top_sectors_loading = false;
            })
            .addCase(fetchLobbyFirmTopSectors.rejected, (state) => {
                state.top_sectors = null;
                state.top_sectors_fail = true;
                state.top_sectors_loading = false;
            })
            .addCase(fetchLobbyFirmTopInstitutions.pending, (state) => {
                state.top_institutions_loading = true;
            })
            .addCase(
                fetchLobbyFirmTopInstitutions.fulfilled,
                (state, action) => {
                    state.top_institutions = action.payload;
                    state.top_institutions_loading = false;
                }
            )
            .addCase(fetchLobbyFirmTopInstitutions.rejected, (state) => {
                state.top_institutions = null;
                state.top_institutions_fail = true;
                state.top_institutions_loading = false;
            })
            .addCase(exportLobbyFirmDashboard.pending, (state) => {
                state.export_loading = true;
            })
            .addCase(exportLobbyFirmDashboard.fulfilled, (state, action) => {
                state.export_loading = false;
                state.export_fail = false;
            })
            .addCase(exportLobbyFirmDashboard.rejected, (state) => {
                state.export_loading = false;
                state.export_fail = true;
            });
    },
});

export const selectLobbyFirmOverview = (state: RootState) =>
    state.lobbyFirmData.overview;
export const selectLobbyFirmOverviewLoading = (state: RootState) =>
    state.lobbyFirmData.overview_loading;

export const selectRegistrations = (state: RootState) =>
    state.lobbyFirmData.registrations;
export const selectRegistrationsLoading = (state: RootState) =>
    state.lobbyFirmData.registrations_loading;

export const selectDeRegistrations = (state: RootState) =>
    state.lobbyFirmData.deregistrations;
export const selectDeRegistrationsLoading = (state: RootState) =>
    state.lobbyFirmData.deregistrations_loading;

export const selectLobbyFirmTopPartners = (state: RootState) =>
    state.lobbyFirmData.top_partners;
export const selectLobbyFirmTopPartnersLoading = (state: RootState) =>
    state.lobbyFirmData.top_partners_loading;

export const selectLobbyFirmTopSectors = (state: RootState) =>
    state.lobbyFirmData.top_sectors;
export const selectLobbyFirmTopSectorsLoading = (state: RootState) =>
    state.lobbyFirmData.top_sectors_loading;

export const selectLobbyFirmTopInstitutions = (state: RootState) =>
    state.lobbyFirmData.top_institutions;
export const selectLobbyFirmTopInstitutionsLoading = (state: RootState) =>
    state.lobbyFirmData.top_institutions_loading;

export const selectLobbyFirmExportLoading = (state: RootState) =>
    state.lobbyFirmData.export_loading;
export const selectLobbyFirmExportFail = (state: RootState) =>
    state.lobbyFirmData.export_fail;

export default lobbyFirmSlice.reducer;
