import { Link } from 'react-router-dom';
import MetaTag from '../../components/metatag-component/MetaTag';
import Carousel from 'react-material-ui-carousel';

const StakeholderFeatures = () => {
    return (
        <div className="xl:mx-24 mx-8 py-8">
            <MetaTag
                title="Stakeholder Dashboards"
                keywords="organization,sector,lobbying,emails,institutions,committees,senator dashboards,weekly emails,
                Lobbying Data, Real-Time Metrics, Federal Lobby Registry, Policy Issues, Text Analytics, Lobbying Communications, Organization Dashboards, Sector Dashboards, Weekly Lobby Monitor, Monthly Lobby Roundup, Public Office Holders, Government Institutions, Committee Meetings, Parliamentarians, MPs, Senators"
                description="Monitor lobbying by sector with real-time metrics and text analytics. Track organization and competitor lobbying, coordinate messaging, and stay informed with weekly and monthly lobby monitor emails. Access dashboards for institutions, DPOHs, committees, and MPs to understand lobbying activities, key issues, and legislative drivers."
            />

            <div className="col-12 md:col-7 lg:col-6 py-14 lg:gap-12 gap-y-8 lg:gap-y-0"></div>

            <div className="lg:grid grid-cols-12 grid-rows-auto gap-6 py-8 flex flex-col">
                <div className="col-start-1 row-start-1 col-span-12 text-left">
                    <h1 className="lg:text-4xl text-3xl">Stakeholder Monitoring</h1>
                </div>

                <div className="lg:col-span-4 col-span-12 bg-white shadow-md p-6 rounded-xl flex flex-col justify-between min-h-[15rem]">
                    <div>
                        <div className="text-2xl">
                            <span className="text-liq-ultramarine hover:underline">
                                <Link to="/sectors">Sector and Organization Lobbying</Link>
                            </span>
                        </div>
                        <div className="text-md mt-8 font-light">
                            LobbyIQ’s unique curated dashboards provide detailed real-time analytics
                            on lobbying by Sectors and Organizations, offering instant insights on
                            stakeholder trends and strategies.
                        </div>
                    </div>
                </div>

                <div className="lg:col-span-4 col-span-12 bg-white shadow-md p-6 rounded-xl flex flex-col justify-between min-h-[15rem]">
                    <div>
                        <div className="text-2xl">
                            <span className="text-liq-lavender hover:underline">
                                <a href="https://liqtranscripts.blob.core.windows.net/summary/LobbyIQSampleWeeklyEmail.pdf">
                                    Lobby Update Emails
                                </a>
                            </span>
                        </div>
                        <div className="text-md mt-8 font-light">
                            Clients can opt to receive weekly and monthly email updates on lobbying
                            activity by sector, institution, organization, and DPOH.
                        </div>
                    </div>
                </div>

                <div className="lg:col-span-4 col-span-12 bg-white shadow-md p-6 rounded-xl flex flex-col justify-between min-h-[15rem]">
                    <div>
                        <div className="text-2xl">
                            <span className="text-liq-orange hover:underline">
                                <Link to="/institutions">
                                    Monitor MPs, Institutions, DPOHs, and Committees
                                </Link>
                            </span>
                        </div>
                        <div className="text-md mt-8 font-light">
                            MP, Institution, DPOH, and Committee dashboards provide metrics on who
                            in government is lobbied and by whom.
                        </div>
                    </div>
                </div>
            </div>
            <div className="lg:grid grid-cols-12 grid-rows-auto gap-6 py-8 flex justify-center">
                <div className="lg:col-start-1 lg:col-span-6 col-span-12 flex justify-center">
                    <Carousel className="w-full" animation="slide">
                        <div className="bg-white rounded-xl w-full h-full">
                            <img
                                src={process.env.PUBLIC_URL + '/Stakeholder_Monitoring_1.png'}
                                className="w-full h-full"
                                style={{ objectFit: 'contain' }}
                                alt=""
                            />
                        </div>
                        <div className="bg-white rounded-xl w-full h-full">
                            <img
                                src={process.env.PUBLIC_URL + '/Stakeholder_Monitoring_2.png'}
                                className="w-full h-full"
                                style={{ objectFit: 'contain' }}
                                alt=""
                            />
                        </div>
                        <div className="bg-white rounded-xl w-full h-full">
                            <img
                                src={process.env.PUBLIC_URL + '/Stakeholder_Monitoring_3.png'}
                                className="w-full h-full"
                                style={{ objectFit: 'contain' }}
                                alt=""
                            />
                        </div>
                        <div className="bg-white rounded-xl w-full h-full">
                            <img
                                src={process.env.PUBLIC_URL + '/Stakeholder_Monitoring_4.png'}
                                className="w-full h-full"
                                style={{ objectFit: 'contain' }}
                                alt=""
                            />
                        </div>
                        <div className="bg-white rounded-xl w-full h-full">
                            <img
                                src={process.env.PUBLIC_URL + '/Stakeholder_Monitoring_5.png'}
                                className="w-full h-full"
                                style={{ objectFit: 'contain' }}
                                alt=""
                            />
                        </div>
                        <div className="bg-white rounded-xl w-full h-full">
                            <img
                                src={process.env.PUBLIC_URL + '/Stakeholder_Monitoring_6.png'}
                                className="w-full h-full"
                                style={{ objectFit: 'contain' }}
                                alt=""
                            />
                        </div>
                        <div className="bg-white rounded-xl w-full h-full">
                            <img
                                src={process.env.PUBLIC_URL + '/Stakeholder_Monitoring_7.png'}
                                className="w-full h-full"
                                style={{ objectFit: 'contain' }}
                                alt=""
                            />
                        </div>
                    </Carousel>
                </div>
                <div className="lg:col-start-7 lg:col-span-6 col-span-12 flex flex-col">
                    <div className="text-slate-600 text-lg">
                        Our Platform and Features Explained
                    </div>
                    <hr className="w-full h-0.5 my-3 bg-slate-100 rounded" />
                    <iframe
                        className="w-full h-5/6 rounded-md shadow-md"
                        src="https://www.youtube.com/embed/R-WdxBMfDLY?si=W1YpAAOpShytF-xA"
                        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                        allowFullScreen
                        title="Embedded youtube"
                    />
                </div>
            </div>

            <div className="col-12 md:col-7 lg:col-6 py-14 lg:gap-12 gap-y-8 lg:gap-y-0"></div>
        </div>
    );
};

export default StakeholderFeatures;
