export const OrganizationOverview = (
    <div className="flex flex-col gap-2">
        <div>
            On the left, the histogram shows the monthly count of all
            communication-filings by a consultant lobby-firm
        </div>
        <div>
            - “Lobbying Reports“ is the number of communications filed in one
            year
        </div>
        <div>
            - “of Ministers or DMs“ is the number of communications in a year
            that involved a DPOH that was a minister or deputy minister (DM)
        </div>
        <div>
            - “Institutions Lobbied“ is the number of unique government
            institutions that were lobbied by all organizations in a sector in a
            year
        </div>
        <div>
            - “New Registrations“ is the number of new (i.e. not just renewed)
            registrations by an organization in a year
        </div>
    </div>
);
