export const CommitteeOverviewTooltip = (
    <div className="flex flex-col gap-2">
        <div>
            On the left, the histogram shows the count of all filings of communications with members
            of this committee
        </div>
        <div>- “Lobbying Reports“ is the number of communications filed in one year</div>
        <div>
            - “by Consultants“ is the number of communications in a year filed by an external
            lobbyist
        </div>
        <div>
            - “Organizations“ is the number of unique organizations that lobbied this committee in a
            year
        </div>
        <div>- “Sectors“ is the number of unique sectors that lobbied this committee in a year</div>
    </div>
);
