import React, { useEffect } from 'react';
import {
    fetchOrganizationList,
    selectOrganizationList,
    selectOrganizationListLoading,
} from '../../features/sector-dashboards/store/sectorListSlice';
import { useSelector } from 'react-redux';
import { useAppDispatch } from '../../store/store';
import EntityListPage from './EntityListPage';
import { useParams } from 'react-router-dom';
import { entityListExtra } from '../../interfaces/generic-entity.interface';

const OrganizationListDataLayer = () => {
    const url_params = useParams();

    const entity_code = url_params.entityId !== undefined ? url_params.entityId : '';

    const dispatch = useAppDispatch();

    const entity_list: entityListExtra[] | null = useSelector(selectOrganizationList);
    const dataLoading = useSelector(selectOrganizationListLoading);

    useEffect(() => {
        dispatch(fetchOrganizationList({ code: entity_code }));
    }, [dispatch, entity_code]);

    return <EntityListPage type="organizations" list={entity_list || []} loading={dataLoading} />;
};

export default OrganizationListDataLayer;
