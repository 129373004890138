import React, { useCallback, useEffect, useState } from 'react';
import Overview from '../../components/generic-dashboards/Overview';
import Cluster from '../../components/generic-dashboards/Cluster';
import { Breadcrumbs, Button, Slide } from '@mui/material';
import { Link } from 'react-router-dom';

import Snackbar from '@mui/material/Snackbar';
import { Alert } from '@mui/material';

import { entityCluster, entityOverviews } from '../../interfaces/generic-entity.interface';

import CalendarMonthRoundedIcon from '@mui/icons-material/CalendarMonthRounded';
import DateSelection from '../../components/misc/DateSelection';
import { COLORS } from '../../constants/colors';
import { GlobalOverview } from '../../tooltips/global/GlobalOverview';
import { useSelector } from 'react-redux';
import { ProductType, selectEmail, selectUserPermissions, selectUserProductType } from '../../store/reducers/userSlice';
import { GATrackDashboardLoad, EVENT_TYPES } from '../../google_analytics/TrackEvent';
import { DashboardDownloadModal } from '../../components/misc/DashboardDownloadModal';
import SimCardDownloadRoundedIcon from '@mui/icons-material/SimCardDownloadRounded';

interface clusterProps {
    cluster_data: entityCluster | null;
    title: string;
    tooltipContent: JSX.Element;
    loading: boolean;
}

interface dashboardProps {
    date: string;
    handleDateChange: (x: string) => void;

    includeOverview?: boolean;
    overview?: entityOverviews | null;
    overviewLoading?: boolean;
    overviewHeaders?: string[];
    overviewIndent?: Array<string>;

    clusters: clusterProps[];

    exportLoading: boolean;
    exportFail: boolean;
}

const GlobalDashboard = ({
    date,
    handleDateChange,
    includeOverview = false,
    overview = { yearly: [], monthly: [] },
    overviewLoading = false,
    overviewHeaders = [],
    overviewIndent = [],
    clusters,
    exportLoading = false,
    exportFail = false,
}: dashboardProps) => {
    // Decide which color from MUI status color scheme to use for buttons
    const type_color = 'success';
    const overview_colors = COLORS[1];

    const [dateOpen, setDateOpen] = useState<boolean>(false);
    const [dateSet, setDateSet] = useState<boolean>(false);

    const handleDateOpen = () => setDateOpen(true);
    const handleDateClose = () => {
        setDateOpen(false);
        setDateSet(true);
    };

    const [scrollPosition, setScrollPosition] = useState(0);

    const [exportModalOpen, setExportModalOpen] = useState<boolean>(false);

    const userEmail = useSelector(selectEmail);
    const userProductType = useSelector(selectUserProductType);

    useEffect(() => {
        GATrackDashboardLoad(
            "Big Picture",
            "Big Picture",
            EVENT_TYPES.PAGE_LOAD,
            userEmail ? userEmail : undefined, 
            userProductType ? userProductType : undefined
        );
        const handleScroll = () => {
            const scrollPosition = window.scrollY;
            setScrollPosition(scrollPosition);
        };
        window.addEventListener('scroll', handleScroll);
        // Cleanup the event listener when the component is unmounted
        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, [userEmail, userProductType]);

    const overviewContent = () => {
        return GlobalOverview;
    };

    const userPermissions = useSelector(selectUserPermissions);

    const canAccessTime = useCallback(() => {
        if (
            userPermissions?.productType === ProductType.ENTERPRISE ||
            userPermissions?.productType === ProductType.CORPORATE ||
            userPermissions?.productType === ProductType.PROFESSIONAL ||
            userPermissions?.productType === ProductType.TRIAL
        ) {
            return true;
        } else return false;
    }, [userPermissions]);

    return (
        <div className="py-32 lg:px-20 px-4 grid grid-cols-12">
            <Snackbar
                anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
                open={dateSet}
                onClose={() => setDateSet(false)}
                autoHideDuration={3000}
                message="Date Set"
            >
                <Alert onClose={() => setDateSet(false)} severity="success">
                    Date set to {date}
                </Alert>
            </Snackbar>

            <DashboardDownloadModal
                title={'Big Picture'}
                type={'global'}
                modalOpen={exportModalOpen}
                handleClose={() => setExportModalOpen(false)}
                overview={overview}
                subject_cluster={clusters[0].cluster_data}
                sector_cluster={clusters[1].cluster_data}
                org_cluster={clusters[2].cluster_data}
                institutions={clusters[3].cluster_data}
                dpoh_cluster={clusters[4].cluster_data}
                firm_cluster={clusters[5].cluster_data}
                exportLoading={exportLoading}
                exportFail={exportFail}
            />

            <Slide direction="left" in={scrollPosition >= 50} mountOnEnter unmountOnExit>
                <div className="fixed z-20 bottom-36 right-5 gap-2 lg:top-20 lg:right-4 flex flex-col h-0">
                    <Button
                        disabled={!canAccessTime()}
                        className="group flex"
                        onClick={handleDateOpen}
                        variant="contained"
                        startIcon={<CalendarMonthRoundedIcon />}
                        color={type_color}
                        sx={{ backgroundColor: overview_colors.primary }}
                    >
                        {date}
                    </Button>
                    <Button
                        variant="contained"
                        color={type_color}
                        sx={{ backgroundColor: overview_colors.primary }}
                        startIcon={<SimCardDownloadRoundedIcon />}
                        onClick={() => setExportModalOpen(true)}
                    >
                        Save PDF
                    </Button>
                </div>
            </Slide>

            <DateSelection
                open={dateOpen}
                handleClose={handleDateClose}
                handleDateChange={handleDateChange}
                views={['year', 'month', 'day']}
            />

            <div className=" col-start-1 col-end-13 flex flex-row justify-between">
                <div>
                    <Breadcrumbs className="col-start-2 lg:col-end-12 col-end-12" separator=">">
                        <Link to="/" className="hover:underline">
                            Lobby<b>IQ</b>
                        </Link>
                        <div>Big Picture</div>
                    </Breadcrumbs>
                </div>
                <Slide direction="left" in={true}>
                    <div className="lg:flex hidden flex-row gap-4">
                        <Button
                            disabled={!canAccessTime()}
                            onClick={handleDateOpen}
                            variant="outlined"
                            startIcon={<CalendarMonthRoundedIcon />}
                            color={type_color}
                            sx={{ color: overview_colors.primary }}
                        >
                            Data From: {date}
                        </Button>
                        <Button
                            color={type_color}
                            sx={{ color: overview_colors.primary }}
                            variant="outlined"
                            startIcon={<SimCardDownloadRoundedIcon />}
                            onClick={() => setExportModalOpen(true)}
                        >
                            Save PDF
                        </Button>
                    </div>
                </Slide>
            </div>

            {includeOverview && (
                <div className="mt-4 col-start-1 col-end-13">
                    <Overview
                        dashboard="Big Picture"
                        topic="Big Picture"
                        title="Overview"
                        subtitle="Overview"
                        tooltipContent={overviewContent()}
                        barChartTitle="Lobbying Communications (18 months)"
                        primaryColor={overview_colors.primary}
                        secondaryColor={overview_colors.secondary}
                        overviews={overview}
                        loading={overviewLoading}
                        tableHeader={overviewHeaders}
                        overviewIndent={overviewIndent}
                    />
                </div>
            )}

            {clusters.map((cluster, idx) => (
                <div key={idx} className="mt-16 col-start-1 col-end-13">
                    <Cluster
                        dashboard="Big Picture"
                        topic="Big Picture"
                        title={cluster.title}
                        subtitle="Number of Communication Reports"
                        date={date}
                        clusterData={cluster.cluster_data}
                        tooltipContent={cluster.tooltipContent}
                        clusterLoading={cluster.loading}
                    />
                </div>
            ))}
        </div>
    );
};

export default GlobalDashboard;
