import React, { useEffect, useState } from 'react';
import { entityCluster, entityOverviews } from '../../interfaces/generic-entity.interface';
import { useAppDispatch } from '../../store/store';
import {
    fetchGlobalOverview,
    fetchOrganizationCluster,
    selectOrganizationCluster,
    selectInstitutionCluster,
    fetchInstitutionCluster,
    selectDPOHCluster,
    fetchDPOHCluster,
    fetchFirmCluster,
    selectFirmCluster,
    fetchSubjectCluster,
    selectSubjectCluster,
    selectOrganizationClusterLoading,
    selectFirmClusterLoading,
    selectDPOHClusterLoading,
    selectSubjectClusterLoading,
    selectInstitutionClusterLoading,
    selectGlobalOverview,
    selectGlobalOverviewLoading,
    selectSectorCluster,
    selectSectorClusterLoading,
    fetchSectorCluster,
    selectGlobalExportFail,
    selectGlobalExportLoading,
} from '../../store/reducers/globalSlice';
import { useSelector } from 'react-redux';
import moment from 'moment';
import GlobalDashboard from './GlobalDashboard';
import { GlobalOrganizations } from '../../tooltips/global/GlobalOrganizations';
import { GlobalGovernmentInstitutions } from '../../tooltips/global/GlobalGovernmentInstitutions';
import { GlobalPublicOffice } from '../../tooltips/global/GlobalPublicOffice';
import { GlobalSubjects } from '../../tooltips/global/GlobalSubjects';
import { GlobalLobbyFirms } from '../../tooltips/global/GlobalLobbyFirms';
import { GlobalSectors } from '../../tooltips/global/GlobalSectors';

const GlobalDataLayer = () => {
    const dispatch = useAppDispatch();

    const overview: entityOverviews | null = useSelector(selectGlobalOverview);
    const overview_loading: boolean = useSelector(selectGlobalOverviewLoading);

    const org_cluster: entityCluster | null = useSelector(selectOrganizationCluster);
    const org_cluster_loading: boolean = useSelector(selectOrganizationClusterLoading);

    const sector_cluster: entityCluster | null = useSelector(selectSectorCluster);
    const sector_cluster_loading: boolean = useSelector(selectSectorClusterLoading);

    const institution_cluster: entityCluster | null = useSelector(selectInstitutionCluster);
    const institution_cluster_loading: boolean = useSelector(selectInstitutionClusterLoading);

    const firm_cluster: entityCluster | null = useSelector(selectFirmCluster);
    const firm_cluster_loading: boolean = useSelector(selectFirmClusterLoading);

    const dpoh_cluster: entityCluster | null = useSelector(selectDPOHCluster);
    const dpoh_cluster_loading: boolean = useSelector(selectDPOHClusterLoading);

    const subject_cluster: entityCluster | null = useSelector(selectSubjectCluster);
    const subject_cluster_loading: boolean = useSelector(selectSubjectClusterLoading);

    const export_fail = useSelector(selectGlobalExportFail)
    const export_loading = useSelector(selectGlobalExportLoading)

    const [date, setDate] = useState<string>(moment.utc(moment()).format('YYYY-MM-DD'));

    useEffect(() => {
        dispatch(fetchGlobalOverview(date));
        dispatch(fetchOrganizationCluster(date));
        dispatch(fetchSectorCluster(date));
        dispatch(fetchInstitutionCluster(date));
        dispatch(fetchFirmCluster(date));
        dispatch(fetchDPOHCluster(date));
        dispatch(fetchSubjectCluster(date));
    }, [dispatch, date]);

    const overview_headers = ['count'];

    // Get the current year
    const currentYear = moment(date, 'YYYY-MM-DD').year();

    // Populate the list with the last 8 years
    for (let i = currentYear; i >= currentYear - 6; i--) {
        overview_headers.push(String(i));
    }

    return (
        <GlobalDashboard
            date={date}
            handleDateChange={setDate}
            includeOverview
            overview={overview}
            overviewHeaders={overview_headers}
            overviewLoading={overview_loading}
            overviewIndent={['by Consultants', 'of Ministers or DMs']}
            clusters={[
                {
                    cluster_data: subject_cluster,
                    title: 'Subjects',
                    tooltipContent: GlobalSubjects,
                    loading: subject_cluster_loading,
                },
                {
                    cluster_data: sector_cluster,
                    title: 'Sectors',
                    tooltipContent: GlobalSectors,
                    loading: sector_cluster_loading,
                },
                {
                    cluster_data: org_cluster,
                    title: 'Organizations',
                    tooltipContent: GlobalOrganizations,
                    loading: org_cluster_loading,
                },
                {
                    cluster_data: institution_cluster,
                    title: 'Government Institutions',
                    tooltipContent: GlobalGovernmentInstitutions,
                    loading: institution_cluster_loading,
                },
                {
                    cluster_data: dpoh_cluster,
                    title: 'Designated Public Office Holders',
                    tooltipContent: GlobalPublicOffice,
                    loading: dpoh_cluster_loading,
                },
                {
                    cluster_data: firm_cluster,
                    title: 'Lobby Firms',
                    tooltipContent: GlobalLobbyFirms,
                    loading: firm_cluster_loading,
                },
            ]}
            exportFail={export_fail}
            exportLoading={export_loading}
        />
    );
};

export default GlobalDataLayer;
