import React from 'react';
import { Link, useNavigate } from 'react-router-dom';
import MetaTag from '../../components/metatag-component/MetaTag';
import {
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Paper,
    Typography,
} from '@mui/material';

const CommitteeMonitor = () => {
    const navigate = useNavigate();

    const pricingData = [
        { committees: '1', fee6Months: '$1,000', fee12Months: '$1,500' },
        { committees: '2-5', fee6Months: '$700', fee12Months: '$1,000' },
        { committees: '6-10', fee6Months: '$600', fee12Months: '$850' },
        { committees: '11-20', fee6Months: '$550', fee12Months: '$775' },
        { committees: '>20', fee6Months: '$500', fee12Months: '$700' },
    ];

    const totalFeeData = [
        { committees: '1', totalFee6Months: '$1,000', totalFee12Months: '$1,500' },
        { committees: '5', totalFee6Months: '$3,500', totalFee12Months: '$5,000' },
        { committees: '10', totalFee6Months: '$6,000', totalFee12Months: '$8,500' },
        { committees: '20', totalFee6Months: '$11,000', totalFee12Months: '$15,500' },
    ];

    return (
        <div className="xl:mx-24 mx-8">
            <MetaTag
                title="Committee Monitoring"
                keywords="Monitor Committees, House of Commons, Senate, Committee Dashboards, Lobbying, MPs, Parliamentarians, Key Policy Issues, Committee Meetings, Provincial Committee Summaries, Same-Day Summaries, Transcripts, Meeting Summaries, Critical Committee Minutes, Provincial Committees, ON"
                description="Monitor Committees with House of Commons and Senate committee dashboards tracking lobbying activities of MPs and key policy issues discussed. Access MP and Senator dashboards to understand individual parliamentarians' key issues. Beta feature offers same-day summaries of provincial committee meetings in Ontario. Get tired of waiting for critical committee transcripts? Receive full meeting transcripts and 2-page summaries within the hour of the meeting."
            />
            <div className="col-12 md:col-7 lg:col-6 py-14 lg:gap-12 gap-y-8 lg:gap-y-0"></div>

            <div className="lg:grid grid-cols-12 grid-rows-auto gap-8 py-8 flex flex-col">
                <div className="col-start-1 row-start-1 col-span-12 col-end-12 text-left">
                    <h1 className="lg:text-4xl text-3xl">Committee Monitoring</h1>
                </div>
                <div className="lg:col-span-6 col-span-12 bg-white shadow-md p-6 rounded-xl flex flex-col justify-between min-h-[20rem]">
                    <div>
                        <div className="text-2xl">
                            <span className="text-liq-lime hover:underline">
                                <a href="https://liqtranscripts.blob.core.windows.net/summary/AGRI_2024-02-27.pdf">
                                    Committee Meeting Summaries and Transcripts
                                </a>
                            </span>
                        </div>
                        <div className="text-md mt-8 font-light">
                            LobbyIQ is the only organization that provides summaries and transcripts
                            of House and Senate committee meetings within an hour of the meeting
                            ending.
                            <div className="my-4" />
                            Here are examples of TRAN Meeting 122{' '}
                            <Link
                                target="_blank"
                                to="/Summary_TRAN_122.pdf"
                                className="text-sky-600 hover:underline"
                            >
                                Summary
                            </Link>
                            {' and '}
                            <Link
                                target="_blank"
                                to="/Transcript_TRAN_122.pdf"
                                className="text-sky-600 hover:underline"
                            >
                                Transcript
                            </Link>
                            {', and FINA Meeting 149 '}
                            <Link
                                target="_blank"
                                to="/Summary_FINA_149.pdf"
                                className="text-sky-600 hover:underline"
                            >
                                Summary
                            </Link>
                            {' and '}
                            <Link
                                target="_blank"
                                to="/Transcript_FINA_149.pdf"
                                className="text-sky-600 hover:underline"
                            >
                                Transcript
                            </Link>
                            .
                            <div className="my-4" />
                            Clients can subscribe to monitor any number of committees for periods of
                            6 or 12 months. Summaries and transcripts of each committee meeting are
                            emailed automatically to clients after each meeting.
                        </div>
                    </div>
                </div>
                <div className="col-start-7 col-span-6 flex justify-center items-center">
                    <img
                        src={process.env.PUBLIC_URL + '/Committee_Summary_FINA_149.png'}
                        className="w-full bg-white border border-slate-100 p-6 rounded-xl shadow-md"
                        height="auto"
                        alt="logo"
                    />
                </div>
            </div>
            <Typography variant="h4" gutterBottom className="text-left pb-4 py-8">
                Pricing
            </Typography>
            <Paper elevation={3} className="p-4 pb-6 rounded-2xl shadow-md bg-white">
                <TableContainer>
                    <Table aria-label="pricing table" style={{ marginBottom: '1rem' }}>
                        <TableHead>
                            <TableRow>
                                <TableCell style={{ width: '30%', padding: '8px' }}>
                                    Number of Committees Selected
                                </TableCell>
                                <TableCell align="center" style={{ padding: '8px' }}>
                                    Fee per Committee (6 Months)
                                </TableCell>
                                <TableCell align="center" style={{ padding: '8px' }}>
                                    Fee per Committee (12 Months)
                                </TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {pricingData.map((row, index) => (
                                <TableRow key={index}>
                                    <TableCell style={{ padding: '8px' }}>
                                        {row.committees}
                                    </TableCell>
                                    <TableCell align="center" style={{ padding: '8px' }}>
                                        {row.fee6Months}
                                    </TableCell>
                                    <TableCell align="center" style={{ padding: '8px' }}>
                                        {row.fee12Months}
                                    </TableCell>
                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>
                </TableContainer>
                <TableContainer>
                    <Table aria-label="total fee table">
                        <TableHead>
                            <TableRow>
                                <TableCell style={{ width: '30%', padding: '8px' }}>
                                    Number of Committees Selected
                                </TableCell>
                                <TableCell align="center" style={{ padding: '8px' }}>
                                    Total Fee (6 Months)
                                </TableCell>
                                <TableCell align="center" style={{ padding: '8px' }}>
                                    Total Fee (12 Months)
                                </TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {totalFeeData.map((row, index) => (
                                <TableRow key={index}>
                                    <TableCell style={{ padding: '8px' }}>
                                        {row.committees}
                                    </TableCell>
                                    <TableCell align="center" style={{ padding: '8px' }}>
                                        {row.totalFee6Months}
                                    </TableCell>
                                    <TableCell align="center" style={{ padding: '8px' }}>
                                        {row.totalFee12Months}
                                    </TableCell>
                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>
                </TableContainer>
            </Paper>

            <div className="col-start-2 col-end-12 py-12">
                <div
                    className="flex hover:cursor-pointer rounded-2xl bg-blue-400 p-5 text-2xl text-white content-center justify-center"
                    onClick={() => navigate('/pricing/committee')}
                >
                    Subscribe to Committee Monitoring
                </div>
            </div>
        </div>
    );
};

export default CommitteeMonitor;
