import React, { useState } from 'react';
import { Fade, Grow } from '@mui/material';
import Breakdown from './Breakdown';
import LoadingBreakdown from './loading/LoadingBreakdown';
import { MissingData } from '../misc/MissingData';
import Modal from '../misc/Modal';
import HelpIcon from '@mui/icons-material/Help';

// Title and subtitle are optional components with defaults specified in function signature below.
interface breakdownsProps {
    title?: string;
    subtitle?: string;
    tooltipContent?: JSX.Element;
    breakdownTitle1?: string;
    breakdownTitle2?: string;
    breakdownTitle3?: string;
    breakdownData1: any;
    breakdownData2: any;
    breakdownData3: any;
    loading1: boolean;
    loading2: boolean;
    loading3: boolean;
}

const Breakdowns = ({
    title = '',
    subtitle = '',
    tooltipContent,
    breakdownTitle1 = 'Institutions',
    breakdownTitle2 = 'Subjects',
    breakdownTitle3 = 'Internal / External',
    breakdownData1,
    breakdownData2,
    breakdownData3,
    loading1,
    loading2,
    loading3,
}: breakdownsProps) => {
    const [modalOpen, setModalOpen] = useState<boolean>(false);

    return (
        <Fade in={true}>
            <div>
                <div className="flex lg:flex-row flex-col justify-between">
                    <div className="text-xl">{title}</div>
                    <div className="flex flex-row items-center">
                        <div className="text-lg font-light">{subtitle}</div>
                        <div
                            className="ml-2 hover:cursor-pointer"
                            onClick={() => setModalOpen(true)}
                        >
                            <HelpIcon color="info" />
                        </div>
                    </div>
                </div>
                <Modal
                    title={title}
                    open={modalOpen}
                    width={50}
                    onClose={() => setModalOpen(false)}
                >
                    {tooltipContent}
                </Modal>
                <hr className="h-0.5 my-3 bg-slate-100 rounded" />
                <div className="grid grid-cols-3 gap-4">
                    {loading1 ? (
                        <div className="lg:col-span-1 col-span-3">
                            <LoadingBreakdown />
                        </div>
                    ) : !loading1 && !breakdownData1 ? (
                        <MissingData />
                    ) : breakdownData1 ? (
                        <div className="lg:col-span-1 col-span-3">
                            <Grow in={true}>
                                <div>
                                    <Breakdown
                                        title={breakdownTitle1}
                                        breakdownData={breakdownData1}
                                        dataKey="count"
                                    />
                                </div>
                            </Grow>
                        </div>
                    ) : (
                        <></>
                    )}
                    {loading2 ? (
                        <div className="lg:col-span-1 col-span-3">
                            <LoadingBreakdown />
                        </div>
                    ) : !loading2 && !breakdownData2 ? (
                        <MissingData />
                    ) : breakdownData2 ? (
                        <div className="lg:col-span-1 col-span-3">
                            <Grow in={true}>
                                <div>
                                    <Breakdown
                                        title={breakdownTitle2}
                                        breakdownData={breakdownData2}
                                        dataKey="count"
                                    />
                                </div>
                            </Grow>
                        </div>
                    ) : (
                        <></>
                    )}
                    {loading3 ? (
                        <div className="lg:col-span-1 col-span-3">
                            <LoadingBreakdown />
                        </div>
                    ) : !loading3 && !breakdownData3 ? (
                        <MissingData />
                    ) : breakdownData3 ? (
                        <div className="lg:col-span-1 col-span-3">
                            <Grow in={true}>
                                <div>
                                    <Breakdown
                                        title={breakdownTitle3}
                                        breakdownData={breakdownData3}
                                        dataKey="count"
                                    />
                                </div>
                            </Grow>
                        </div>
                    ) : (
                        <></>
                    )}
                </div>
            </div>
        </Fade>
    );
};

export default Breakdowns;
