import React, { useEffect } from 'react';
import {
    fetchMPList,
    selectMPList,
    selectMPListLoading,
} from '../../features/committee-dashboards/data/committeeListSlice';
import { useSelector } from 'react-redux';
import { entityListExtra } from '../../interfaces/generic-entity.interface';
import { useAppDispatch } from '../../store/store';
import EntityListPage from './EntityListPage';
import { useParams } from 'react-router-dom';

const CommitteeMPListDataLayer = () => {
    const url_params = useParams();

    const entity_code = url_params.entityId !== undefined ? url_params.entityId : '';

    const dispatch = useAppDispatch();

    const entity_list: entityListExtra[] | null = useSelector(selectMPList);
    const dataLoading = useSelector(selectMPListLoading);

    useEffect(() => {
        dispatch(fetchMPList({ code: entity_code }));
    }, [dispatch, entity_code]);

    return <EntityListPage type="MPs" list={entity_list || []} loading={dataLoading} />;
};

export default CommitteeMPListDataLayer;
