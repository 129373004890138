import React, { useEffect, useState } from 'react';
import LobbyFirmDashboard from './LobbyFirmDashboard';
import moment from 'moment';
import { useAppDispatch } from '../../store/store';
import { useSelector } from 'react-redux';
import {
    fetchLobbyFirmOverview,
    fetchLobbyFirmTopInstitutions,
    fetchLobbyFirmTopSectors,
    selectDeRegistrations,
    selectDeRegistrationsLoading,
    selectLobbyFirmOverview,
    selectLobbyFirmOverviewLoading,
    selectLobbyFirmTopPartners,
    selectLobbyFirmTopPartnersLoading,
    selectLobbyFirmTopInstitutions,
    selectLobbyFirmTopInstitutionsLoading,
    selectLobbyFirmTopSectors,
    selectLobbyFirmTopSectorsLoading,
    selectRegistrations,
    selectRegistrationsLoading,
    fetchLobbyFirmLatestRegistrations,
    fetchLobbyFirmLatestDeregistrations,
    fetchLobbyFirmTopPartners,
    selectLobbyFirmExportLoading,
    selectLobbyFirmExportFail,
} from '../../store/reducers/lobbyFirmSlice';
import {
    entityOrg,
    entityOverviews,
    entityRegistration,
} from '../../interfaces/generic-entity.interface';
import { useParams } from 'react-router-dom';

const LobbyFirmDataLayer = () => {
    const dispatch = useAppDispatch();

    const [date, setDate] = useState<string>(
        moment.utc(moment()).format('YYYY-MM-DD')
    );

    // Get the name of the current firms
    const url_params = useParams();
    const firm_name: string | undefined =
        url_params.firmName !== undefined ? url_params.firmName : '';

    const overview: entityOverviews | null = useSelector(
        selectLobbyFirmOverview
    );
    const overview_loading: boolean = useSelector(
        selectLobbyFirmOverviewLoading
    );

    const registrations: entityRegistration[] | null =
        useSelector(selectRegistrations);
    const registrations_loading: boolean = useSelector(
        selectRegistrationsLoading
    );

    const deregistrations: entityRegistration[] | null = useSelector(
        selectDeRegistrations
    );
    const deregistrations_loading: boolean = useSelector(
        selectDeRegistrationsLoading
    );

    const top_partners: entityOrg[] | null = useSelector(
        selectLobbyFirmTopPartners
    );
    const top_partners_loading: boolean = useSelector(
        selectLobbyFirmTopPartnersLoading
    );

    const top_sectors: entityOrg[] | null = useSelector(
        selectLobbyFirmTopSectors
    );
    const top_sectors_loading: boolean = useSelector(
        selectLobbyFirmTopSectorsLoading
    );

    const top_institutions: entityOrg[] | null = useSelector(
        selectLobbyFirmTopInstitutions
    );
    const top_institutions_loading: boolean = useSelector(
        selectLobbyFirmTopInstitutionsLoading
    );

    const export_loading = useSelector(selectLobbyFirmExportLoading);
    const export_fail = useSelector(selectLobbyFirmExportFail);

    useEffect(() => {
        dispatch(
            fetchLobbyFirmLatestRegistrations({
                firm: firm_name,
                date: date,
            })
        );
        dispatch(
            fetchLobbyFirmLatestDeregistrations({
                firm: firm_name,
                date: date,
            })
        );
        dispatch(
            fetchLobbyFirmOverview({
                firm: firm_name,
                date: date,
            })
        );
        dispatch(
            fetchLobbyFirmTopPartners({
                firm: firm_name,
                date: date,
            })
        );
        dispatch(
            fetchLobbyFirmTopSectors({
                firm: firm_name,
                date: date,
            })
        );
        dispatch(
            fetchLobbyFirmTopInstitutions({
                firm: firm_name,
                date: date,
            })
        );
    }, [dispatch, date, firm_name]);

    const overview_headers = ['count'];

    // Get the current year
    const currentYear = moment(date, 'YYYY-MM-DD').year();

    // Populate the list with the last 8 years
    for (let i = currentYear; i >= currentYear - 6; i--) {
        overview_headers.push(String(i));
    }

    return (
        <LobbyFirmDashboard
            name={firm_name}
            date={date}
            type="firms"
            handleDateChange={setDate}
            registrations={registrations || []}
            registrationsLoading={registrations_loading}
            deregistrations={deregistrations || []}
            deregistrationsLoading={deregistrations_loading}
            overview={overview}
            overviewLoading={overview_loading}
            overviewHeaders={overview_headers}
            overviewIndent={['by Consultants', 'of Ministers or DMs']}
            topPartners={top_partners || []}
            topPartnersLoading={top_partners_loading}
            topSectors={top_sectors || []}
            topSectorsLoading={top_sectors_loading}
            topInstitutions={top_institutions || []}
            topInstitutionsLoading={top_institutions_loading}
            exportLoading={export_loading}
            exportFail={export_fail}
        />
    );
};

export default LobbyFirmDataLayer;
