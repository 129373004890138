export const committeeMeetingKeyphrases = (
    <div className="flex flex-col gap-2">
        <div>
            We use machine-learning (ML) algorithms to extract the key-phrases on top of tagged
            topics that characterize each committee meeting. The ML algorithms used for key phrases
            here are different and trained on different data than the “TFIDF” algorithm we apply to
            the lobby registrations texts, and scores can not be compared across these different
            types of text.
        </div>
    </div>
);
