import React, { useState } from 'react';
import Table from './Table';
import BarChart from './BarChart';
import { entityOverviews } from '../../interfaces/generic-entity.interface';
import LoadingOverview from './loading/LoadingOverview';
import { Fade, Grow } from '@mui/material';
import { MissingData } from '../misc/MissingData';
import HelpIcon from '@mui/icons-material/Help';
import Modal from '../misc/Modal';
import { EVENT_TYPES, GATrackDashboardEvent } from '../../google_analytics/TrackEvent';
import { useSelector } from 'react-redux';
import { selectEmail, selectUserProductType } from '../../store/reducers/userSlice';

// Title and subtitle are optional components with defaults specified in function signature below.
interface overviewProps {
    topic?: string;
    dashboard?: string;
    title?: string;
    barChartTitle?: string;
    subtitle?: string | null;
    tooltipContent?: JSX.Element;
    primaryColor?: string;
    secondaryColor?: string;
    overviews: entityOverviews | null;
    loading: boolean;
    tableHeader: string[];
    overviewIndent?: Array<string>;
}

/*
    Component: Overview.tsx
    Params:
        title - title of the row
        subtitle - secondary title of the row
    Author: Will Brewer
    Desc: Wrapper component for overview level dashboard rows, features a table and large bar chart
*/
const Overview = ({
    topic = 'Topic',
    dashboard = 'Dashboard',
    title = 'Title',
    barChartTitle = 'Lobbying Comms (18 months)',
    subtitle = 'Subtitle',
    tooltipContent,
    primaryColor = '#4B8060',
    secondaryColor = '#BAD583',
    overviews,
    loading,
    tableHeader,
    overviewIndent = [],
}: overviewProps) => {
    const [modalOpen, setModalOpen] = useState<boolean>(false);
    const userEmail = useSelector(selectEmail);
    const userProductType = useSelector(selectUserProductType);
    const TrackEvent = (name:string) => {
        GATrackDashboardEvent(name,EVENT_TYPES.CLICK,dashboard,title,topic,userEmail?userEmail:undefined,userProductType?userProductType:undefined);
    };

    return (
        <Fade in={true}>
            <div>
                <div className="flex lg:flex-row flex-col justify-between">
                    <div className="text-xl">{title}</div>
                    <div className="flex flex-row items-center">
                        <div className="text-lg font-light mr-2">
                            {subtitle}
                        </div>
                        <div
                            className="hover: cursor-pointer"
                            onClick={() => setModalOpen(true)}
                        >
                            <HelpIcon color="info" />
                        </div>
                    </div>
                </div>
                <Modal
                    title={title}
                    open={modalOpen}
                    width={50}
                    onClose={() => setModalOpen(false)}
                >
                    {tooltipContent}
                </Modal>
                <hr className="h-0.5 my-3 bg-slate-100 rounded" />
                {loading ? (
                    <LoadingOverview />
                ) : !loading && !overviews ? (
                    <MissingData />
                ) : overviews ? (
                    <Grow in={true}>
                        <div className="flex flex-col lg:grid lg:grid-cols-3 gap-4">
                            <div
                                className={
                                    overviews.yearly.length > 0
                                        ? `col-span-1`
                                        : 'col-span-3'
                                }
                            >
                                <BarChart
                                    title={barChartTitle}
                                    primaryColor={primaryColor}
                                    secondaryColor={secondaryColor}
                                    data={[...overviews.monthly].reverse()}
                                    dataKey="count"
                                    xKey="month"
                                    yKey="count"
                                    average
                                />
                            </div>
                            {overviews.yearly.length > 0 && (
                                <div className="col-span-2">
                                    <Table
                                        rowData={overviews.yearly}
                                        rowHeaders={tableHeader}
                                        alignment="left"
                                        paginated={false}
                                        indentValues={overviewIndent}
                                        defaultSort="order"
                                        defaultSortOrder="asc"
                                        defaultNumRows={10}
                                        trackClickEvent= {TrackEvent}
                                    />
                                </div>
                            )}
                        </div>
                    </Grow>
                ) : (
                    <></>
                )}
            </div>
        </Fade>
    );
};

export default Overview;
