import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { RootState } from '../store';
import { institutionAPI } from '../../API/institutionAPI';
import {
    entityOverviews,
    entityLobbyingActivity,
    entityCluster,
} from '../../interfaces/generic-entity.interface';
import { bumperSuperList } from '../../interfaces/bumper-types.interface';
import axios from 'axios';
import saveAs from 'file-saver';
import { recentUpdates } from '../../features/shared/interfaces/whats-new.interface';

interface InstitutionState {
    updates: recentUpdates | null;
    updates_loading: boolean;
    updates_fail: boolean;

    overview: entityOverviews | null;
    overview_loading: boolean;
    overview_fail: boolean;

    lobbying_activity: entityLobbyingActivity[] | null;
    lobbying_activity_loading: boolean;
    lobbying_activity_fail: boolean;

    org_cluster: entityCluster | null;
    org_cluster_loading: boolean;
    org_cluster_fail: boolean;

    sector_cluster: entityCluster | null;
    sector_cluster_loading: boolean;
    sector_cluster_fail: boolean;

    firm_cluster: entityCluster | null;
    firm_cluster_loading: boolean;
    firm_cluster_fail: boolean;

    dpoh_cluster: entityCluster | null;
    dpoh_cluster_loading: boolean;
    dpoh_cluster_fail: boolean;

    subject_cluster: entityCluster | null;
    subject_cluster_loading: boolean;
    subject_cluster_fail: boolean;

    terms: bumperSuperList[] | null;
    terms_loading: boolean;
    terms_fail: boolean;

    export_fail: boolean;
    export_loading: boolean;
}

const initialState: InstitutionState = {
    updates: null,
    updates_loading: false,
    updates_fail: false,

    overview: null,
    overview_loading: false,
    overview_fail: false,

    lobbying_activity: null,
    lobbying_activity_loading: false,
    lobbying_activity_fail: false,

    org_cluster: null,
    org_cluster_loading: false,
    org_cluster_fail: false,

    sector_cluster: null,
    sector_cluster_loading: false,
    sector_cluster_fail: false,

    firm_cluster: null,
    firm_cluster_loading: false,
    firm_cluster_fail: false,

    dpoh_cluster: null,
    dpoh_cluster_loading: false,
    dpoh_cluster_fail: false,

    subject_cluster: null,
    subject_cluster_loading: false,
    subject_cluster_fail: false,

    terms: null,
    terms_loading: false,
    terms_fail: false,

    export_fail: false,
    export_loading: false,
};

// Fetch the recent updates for the current insititution
export const fetchInstitutionUpdates = createAsyncThunk(
    'sector/fetchInstitutionUpdates',
    async ({ code, date }: { code: string | undefined; date: string }): Promise<recentUpdates> =>
        institutionAPI.fetchUpdates(code, date)
);

// Fetch the yearly overview data for the current institution
export const fetchInstitutionOverview = createAsyncThunk(
    'institution/fetchInstitutionOverview',
    async ({ code, date }: { code: string | undefined; date: string }): Promise<entityOverviews> =>
        institutionAPI.fetchOverview(code, date)
);

// Fetch the recent lobbying activity data for the current institution
export const fetchInstitutionRecentLobbying = createAsyncThunk(
    'institution/fetchInstitutionRecentLobbying',
    async ({
        code,
        date,
    }: {
        code: string | undefined;
        date: string;
    }): Promise<entityLobbyingActivity[]> => institutionAPI.fetchRecentLobbyingActivity(code, date)
);

// Fetch the organization cluster data
export const fetchOrganizationCluster = createAsyncThunk(
    'institution/fetchOrganizationCluster',
    async ({
        code,
        date,
    }: {
        code: string | undefined;
        date: string | undefined;
    }): Promise<entityCluster> => institutionAPI.fetchCluster(code, 'organizations', date)
);

// Fetch the sector cluster data
export const fetchSectorCluster = createAsyncThunk(
    'institution/fetchSectorCluster',
    async ({
        code,
        date,
    }: {
        code: string | undefined;
        date: string | undefined;
    }): Promise<entityCluster> => institutionAPI.fetchCluster(code, 'sectors', date)
);

// Fetch the DPOH cluster data
export const fetchFirmCluster = createAsyncThunk(
    'institution/fetchFirmCluster',
    async ({
        code,
        date,
    }: {
        code: string | undefined;
        date: string | undefined;
    }): Promise<entityCluster> => institutionAPI.fetchCluster(code, 'lobbyfirms', date)
);

// Fetch the DPOH cluster data
export const fetchDPOHCluster = createAsyncThunk(
    'institution/fetchDPOHCluster',
    async ({
        code,
        date,
    }: {
        code: string | undefined;
        date: string | undefined;
    }): Promise<entityCluster> => institutionAPI.fetchCluster(code, 'dpoh', date)
);

// Fetch the subject cluster data
export const fetchSubjectCluster = createAsyncThunk(
    'institution/fetchSubjectCluster',
    async ({
        code,
        date,
    }: {
        code: string | undefined;
        date: string | undefined;
    }): Promise<entityCluster> => institutionAPI.fetchCluster(code, 'subject', date)
);

// Fetch the sector Terms data
export const fetchInstitutionTerms = createAsyncThunk(
    'sector/fetchInstitutionTerms',
    async ({
        code,
        date,
    }: {
        code: string | undefined;
        date: string | undefined;
    }): Promise<bumperSuperList[]> => institutionAPI.fetchTerms(code, date)
);

export const exportInstitutionDashboard = createAsyncThunk(
    'institution/exportDashboard',
    async (data: any): Promise<void> => {
        const response = await axios.post(
            `${process.env.REACT_APP_PDF_SERVICE_URL}/institution/report`,
            data,
            {
                responseType: 'blob',
            }
        );

        const pdfEncoding = response.data;

        const blob = new Blob([pdfEncoding], { type: 'application/pdf' });

        saveAs(blob, 'LobbyIQ-Institution');
    }
);

const sectorSlice = createSlice({
    name: 'institutionReducer',
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(fetchInstitutionUpdates.pending, (state) => {
                state.updates_loading = true;
                state.updates = null;
            })
            .addCase(fetchInstitutionUpdates.fulfilled, (state, action) => {
                state.updates_loading = false;
                state.updates_fail = false;
                state.updates = action.payload;
            })
            .addCase(fetchInstitutionUpdates.rejected, (state) => {
                state.updates_loading = false;
                state.updates_fail = true;
                state.updates = null;
            })
            .addCase(fetchInstitutionOverview.pending, (state) => {
                state.overview_loading = true;
            })
            .addCase(fetchInstitutionOverview.fulfilled, (state, action) => {
                state.overview_loading = false;
                state.overview_fail = false;
                state.overview = action.payload;
            })
            .addCase(fetchInstitutionOverview.rejected, (state) => {
                state.overview_loading = false;
                state.overview_fail = true;
                state.overview = null;
            })
            .addCase(fetchInstitutionRecentLobbying.pending, (state) => {
                state.lobbying_activity_loading = true;
            })
            .addCase(fetchInstitutionRecentLobbying.fulfilled, (state, action) => {
                state.lobbying_activity_loading = false;
                state.lobbying_activity_fail = false;
                state.lobbying_activity = action.payload;
            })
            .addCase(fetchInstitutionRecentLobbying.rejected, (state) => {
                state.lobbying_activity_loading = false;
                state.lobbying_activity_fail = true;
                state.lobbying_activity = null;
            })
            .addCase(fetchOrganizationCluster.pending, (state) => {
                state.org_cluster_loading = true;
            })
            .addCase(fetchOrganizationCluster.fulfilled, (state, action) => {
                state.org_cluster_loading = false;
                state.org_cluster_fail = false;
                state.org_cluster = action.payload;
            })
            .addCase(fetchOrganizationCluster.rejected, (state) => {
                state.org_cluster_loading = false;
                state.org_cluster_fail = true;
                state.org_cluster = null;
            })
            .addCase(fetchSectorCluster.pending, (state) => {
                state.sector_cluster_loading = true;
            })
            .addCase(fetchSectorCluster.fulfilled, (state, action) => {
                state.sector_cluster_loading = false;
                state.sector_cluster_fail = false;
                state.sector_cluster = action.payload;
            })
            .addCase(fetchSectorCluster.rejected, (state) => {
                state.sector_cluster_loading = false;
                state.sector_cluster_fail = true;
                state.sector_cluster = null;
            })
            .addCase(fetchFirmCluster.pending, (state) => {
                state.firm_cluster_loading = true;
            })
            .addCase(fetchFirmCluster.fulfilled, (state, action) => {
                state.firm_cluster_loading = false;
                state.firm_cluster_fail = false;
                state.firm_cluster = action.payload;
            })
            .addCase(fetchFirmCluster.rejected, (state) => {
                state.firm_cluster_loading = false;
                state.firm_cluster_fail = true;
                state.firm_cluster = null;
            })
            .addCase(fetchDPOHCluster.pending, (state) => {
                state.dpoh_cluster_loading = true;
            })
            .addCase(fetchDPOHCluster.fulfilled, (state, action) => {
                state.dpoh_cluster_loading = false;
                state.dpoh_cluster_fail = false;
                state.dpoh_cluster = action.payload;
            })
            .addCase(fetchDPOHCluster.rejected, (state) => {
                state.dpoh_cluster_loading = false;
                state.dpoh_cluster_fail = true;
                state.dpoh_cluster = null;
            })
            .addCase(fetchSubjectCluster.pending, (state) => {
                state.subject_cluster_loading = true;
            })
            .addCase(fetchSubjectCluster.fulfilled, (state, action) => {
                state.subject_cluster_loading = false;
                state.subject_cluster_fail = false;
                state.subject_cluster = action.payload;
            })
            .addCase(fetchSubjectCluster.rejected, (state) => {
                state.subject_cluster_loading = false;
                state.subject_cluster_fail = true;
                state.subject_cluster = null;
            })
            .addCase(fetchInstitutionTerms.pending, (state) => {
                state.terms_loading = true;
            })
            .addCase(fetchInstitutionTerms.fulfilled, (state, action) => {
                state.terms_loading = false;
                state.terms_fail = false;
                state.terms = action.payload;
            })
            .addCase(fetchInstitutionTerms.rejected, (state) => {
                state.terms_loading = false;
                state.terms_fail = true;
                state.terms = null;
            })
            .addCase(exportInstitutionDashboard.pending, (state) => {
                state.export_loading = true;
            })
            .addCase(exportInstitutionDashboard.fulfilled, (state, action) => {
                state.export_loading = false;
                state.export_fail = false;
            })
            .addCase(exportInstitutionDashboard.rejected, (state) => {
                state.export_loading = false;
                state.export_fail = true;
            });
    },
});

export const selectInstitutionUpdates = (state: RootState) => state.institutionData.updates;
export const selectInstitutionUpdatesLoading = (state: RootState) =>
    state.institutionData.updates_loading;

export const selectInstitutionOverview = (state: RootState) => state.institutionData.overview;
export const selectInstitutionOverviewLoading = (state: RootState) =>
    state.institutionData.overview_loading;

export const selectInstitutionRecentLobbying = (state: RootState) =>
    state.institutionData.lobbying_activity;
export const selectInstitutionRecentLobbyingLoading = (state: RootState) =>
    state.institutionData.lobbying_activity_loading;

export const selectOrganizationCluster = (state: RootState) => state.institutionData.org_cluster;
export const selectOrganizationClusterLoading = (state: RootState) =>
    state.institutionData.org_cluster_loading;

export const selectSectorCluster = (state: RootState) => state.institutionData.sector_cluster;
export const selectSectorClusterLoading = (state: RootState) =>
    state.institutionData.sector_cluster_loading;

export const selectFirmCluster = (state: RootState) => state.institutionData.firm_cluster;
export const selectFirmClusterLoading = (state: RootState) =>
    state.institutionData.firm_cluster_loading;

export const selectDPOHCluster = (state: RootState) => state.institutionData.dpoh_cluster;
export const selectDPOHClusterLoading = (state: RootState) =>
    state.institutionData.dpoh_cluster_loading;

export const selectSubjectCluster = (state: RootState) => state.institutionData.subject_cluster;
export const selectSubjectClusterLoading = (state: RootState) =>
    state.institutionData.subject_cluster_loading;

export const selectInstitutionTerms = (state: RootState) => state.institutionData.terms;
export const selectInstitutionTermsLoading = (state: RootState) =>
    state.institutionData.terms_loading;

export const selectInstitutionExportLoading = (state: RootState) =>
    state.institutionData.export_loading;
export const selectInstitutionExportFail = (state: RootState) => state.institutionData.export_fail;

export const institutionData = (state: RootState) => state.institutionData;

export default sectorSlice.reducer;
