import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';
import { RootState } from '../../../store/store';
import { entityList, entityListExtra } from '../../../interfaces/generic-entity.interface';
import { percentage_change_num } from '../../../helpers/percentage_change';

interface SenateListState {
    senatorList: entityListExtra[] | null;
    senatorListLoading: boolean;
    senatecommitteeList: entityListExtra[] | null;
    senatecommitteeListLoading: boolean;
}

const initialState: SenateListState = {
    senatorList: null,
    senatorListLoading: false,
    senatecommitteeList: null,
    senatecommitteeListLoading: false,
};

const baseURL = process.env.REACT_APP_API_BASE_URL;

export const fetchSenatorList = createAsyncThunk(
    'senate/member/fetchSenatorList',
    async (): Promise<entityListExtra[]> => {
        const response = await axios.get(`${baseURL}/senate/member/list?showFields=senatorSocial`);
        const data: entityList[] = response.data.senatorList;
        const converted_data: entityListExtra[] = data.map((entry) => {
            return {
                ...entry,
                // Calculate with potential nulls replaced by 0
                change_abs: (entry.last_month || 0) - (entry.two_months_ago || 0),
                change_rel: percentage_change_num(entry.two_months_ago || 0, entry.last_month || 0),
                title2: undefined,
                available: true,
            };
        });
        return converted_data;
    }
);

export const fetchSenateCommitteeList = createAsyncThunk(
    'senate/committee/fetchSenateCommitteeList',
    async (): Promise<entityListExtra[]> => {
        const response = await axios.get(`${baseURL}/senate/committee/list`);
        const data: entityList[] = response.data.committee;
        const converted_data: entityListExtra[] = data.map((entry) => {
            return {
                ...entry,
                // Calculate with potential nulls replaced by 0
                change_abs: (entry.last_month || 0) - (entry.two_months_ago || 0),
                change_rel: percentage_change_num(entry.two_months_ago || 0, entry.last_month || 0),
                available: true,
                affiliation: entry.affiliation ?? 'N/A',
                constituency: entry.constituency ?? 'N/A',
                province_territory: entry.province_territory ?? 'N/A',
            };
        });
        return converted_data;
    }
);

const senateListSlice = createSlice({
    name: 'senateListReducer',
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(fetchSenatorList.pending, (state) => {
                state.senatorListLoading = true;
            })
            .addCase(fetchSenatorList.fulfilled, (state, action) => {
                state.senatorListLoading = false;
                state.senatorList = action.payload;
            })
            .addCase(fetchSenatorList.rejected, (state) => {
                state.senatorListLoading = false;
                state.senatorList = null;
            })
            .addCase(fetchSenateCommitteeList.pending, (state) => {
                state.senatecommitteeListLoading = true;
            })
            .addCase(fetchSenateCommitteeList.fulfilled, (state, action) => {
                state.senatecommitteeListLoading = false;
                state.senatecommitteeList = action.payload;
            })
            .addCase(fetchSenateCommitteeList.rejected, (state) => {
                state.senatecommitteeListLoading = false;
                state.senatecommitteeList = null;
            });
    },
});

export const selectSenatorList = (state: RootState) => state.senateList.senatorList;
export const selectSenatorListLoading = (state: RootState) => state.senateList.senatorListLoading;

export const selectSenateCommitteeList = (state: RootState) => state.senateList.senatecommitteeList;
export const selectSenateCommitteeListLoading = (state: RootState) =>
    state.senateList.senatecommitteeListLoading;

export default senateListSlice.reducer;
