import React, { useCallback, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';

import {
    entityLobbyingActivity,
    entityOrg,
    entityOverviews,
    entityRegistration,
} from '../../interfaces/generic-entity.interface';

import { useAppDispatch } from '../../store/store';
import { useSelector } from 'react-redux';
import moment from 'moment';
import {
    fetchInternalExternalSplit,
    fetchOrganizationDPOHActivity,
    fetchOrganizationLatestDeregistrations,
    fetchOrganizationLatestRegistrations,
    fetchOrganizationOverview,
    fetchOrganizationRecentLobbying,
    fetchTopInstitutions,
    fetchTopSubjects,
    selectOrganizationDPOHActivity,
    selectOrganizationDPOHActivityLoading,
    selectOrganizationInteralExternal,
    selectOrganizationInteralExternalLoading,
    selectOrganizationLatestDeregistrations,
    selectOrganizationLatestDeregistrationsLoading,
    selectOrganizationLatestRegistrations,
    selectOrganizationLatestRegistrationsLoading,
    selectOrganizationOverview,
    selectOrganizationOverviewLoading,
    selectOrganizationRecentLobbying,
    selectOrganizationRecentLobbyingLoading,
    selectOrganizationTopInstitutions,
    selectOrganizationTopInstitutionsLoading,
    selectOrganizationTopSubjects,
    selectOrganizationTopSubjectsLoading,
} from '../../store/reducers/organizationSlice';
import OrganizationDashboard from './OrganizationDashboard';
import { entityActivity } from '../../interfaces/organization-entity.interface';
import {
    ProductType,
    selectInHouseName,
    selectIsFirm,
    selectUserPermissions,
} from '../../store/reducers/userSlice';
import {
    selectChosenSectors,
    selectSectorsLoading,
} from '../../store/reducers/customSelectionSlice';
import { DashboardNotLicenced } from '../misc/DashboardNotLicenced';
import { selectOrganizationExportFail, selectOrganizationExportLoading } from '../../store/reducers/organizationSlice';

const OrganizationDataLayer = () => {
    const dispatch = useAppDispatch();

    // Get the name of the current org
    const url_params = useParams();

    const org_name = url_params.orgName !== undefined ? url_params.orgName : '';
    const sector_code =
        url_params.entityId !== undefined ? url_params.entityId : '';

    const [date, setDate] = useState<string>(
        moment(new Date()).format('YYYY-MM-DD')
    );

    const org_overview: entityOverviews | null = useSelector(
        selectOrganizationOverview
    );
    const org_overview_loading: boolean = useSelector(
        selectOrganizationOverviewLoading
    );

    const org_activity: entityLobbyingActivity[] | null = useSelector(
        selectOrganizationRecentLobbying
    );
    const org_activity_loading: boolean = useSelector(
        selectOrganizationRecentLobbyingLoading
    );

    const org_registrations: entityRegistration[] | null = useSelector(
        selectOrganizationLatestRegistrations
    );
    const org_registrations_loading: boolean = useSelector(
        selectOrganizationLatestRegistrationsLoading
    );

    const org_deregistrations: entityRegistration[] | null = useSelector(
        selectOrganizationLatestDeregistrations
    );
    const org_deregistrations_loading: boolean = useSelector(
        selectOrganizationLatestDeregistrationsLoading
    );

    const dpoh_activity: entityActivity[] | null = useSelector(
        selectOrganizationDPOHActivity
    );
    const dpoh_activity_loading: boolean = useSelector(
        selectOrganizationDPOHActivityLoading
    );

    const top_institutions: entityOrg[] | null = useSelector(
        selectOrganizationTopInstitutions
    );
    const top_institutions_loading: boolean = useSelector(
        selectOrganizationTopInstitutionsLoading
    );

    const top_subjects: entityOrg[] | null = useSelector(
        selectOrganizationTopSubjects
    );
    const top_subjects_loading: boolean = useSelector(
        selectOrganizationTopSubjectsLoading
    );

    const internal_external_split: entityOrg[] | null = useSelector(
        selectOrganizationInteralExternal
    );
    const internal_external_split_loading: boolean = useSelector(
        selectOrganizationInteralExternalLoading
    );

    const permissions = useSelector(selectUserPermissions);
    const sectorSelections = useSelector(selectChosenSectors);
    const sectorSelectionsLoading = useSelector(selectSectorsLoading);
    const inHouseName = useSelector(selectInHouseName);
    const isFirm = useSelector(selectIsFirm);
    const export_loading = useSelector(selectOrganizationExportLoading);
    const export_fail = useSelector(selectOrganizationExportFail);

    const canAccessSelection = useCallback(() => {
        if (
            permissions?.productType === ProductType.ENTERPRISE ||
            permissions?.productType === ProductType.TRIAL
        ) {
            return true;
        } else if (
            inHouseName?.toLowerCase() === org_name.toLowerCase() &&
            !isFirm
        ) {
            // allow acces if org is the user's inhouse even if it's not a selected sector
            return true;
        } else
            return !!sectorSelections.find(
                (selection) => selection.code === sector_code
            );
    }, [
        permissions,
        sector_code,
        sectorSelections,
        inHouseName,
        org_name,
        isFirm,
    ]);

    useEffect(() => {
        // Sector selections fetched in Navbar
        if (!sectorSelectionsLoading && canAccessSelection()) {
            dispatch(fetchOrganizationOverview({ org: org_name, date: date }));
            dispatch(
                fetchOrganizationRecentLobbying({ org: org_name, date: date })
            );
            dispatch(
                fetchOrganizationLatestRegistrations({
                    org: org_name,
                    date: date,
                })
            );
            dispatch(
                fetchOrganizationLatestDeregistrations({
                    org: org_name,
                    date: date,
                })
            );
            dispatch(
                fetchOrganizationDPOHActivity({ org: org_name, date: date })
            );
            dispatch(fetchTopInstitutions({ org: org_name, date: date }));
            dispatch(fetchTopSubjects({ org: org_name, date: date }));
            dispatch(fetchInternalExternalSplit({ org: org_name, date: date }));
        }
    }, [dispatch, org_name, date, canAccessSelection, sectorSelectionsLoading]);

    const overview_headers = ['count'];

    // Get the current year
    const currentYear = moment(date, 'YYYY-MM-DD').year();

    // Populate the list with the last 8 years
    for (let i = currentYear; i >= currentYear - 6; i--) {
        overview_headers.push(String(i));
    }

    return canAccessSelection() ? (
        <OrganizationDashboard
            sectorCode={sector_code}
            orgName={org_name}
            date={date}
            handleDateChange={setDate}
            overview={org_overview}
            overviewHeaders={overview_headers}
            overviewLoading={org_overview_loading}
            overviewIndent={['of Ministers or DMs', 'by Consultants']}
            recentLobbying={org_activity}
            recentLobbyingLoading={org_activity_loading}
            registrations={org_registrations}
            registrationsLoading={org_registrations_loading}
            deregistrations={org_deregistrations}
            deregistrationsLoading={org_deregistrations_loading}
            dpohActivity={dpoh_activity}
            dpohActivityLoading={dpoh_activity_loading}
            topInstitutions={top_institutions}
            topInstitutionsLoading={top_institutions_loading}
            topSubjects={top_subjects}
            topSubjectsLoading={top_subjects_loading}
            internalExternal={internal_external_split}
            internalExternalLoading={internal_external_split_loading}
            exportLoading={export_loading}
            exportFail={export_fail}
        />
    ) : (
        <DashboardNotLicenced type="sectors" />
    );
};

export default OrganizationDataLayer;
