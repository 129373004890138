import React, { useState } from 'react';
import Movers from './Movers';
import Breakdown from './Breakdown';
import { entityCluster } from '../../interfaces/generic-entity.interface';
import BarChart from './BarChart';
import { COLORS } from '../../constants/colors';
import LoadingCluster from './loading/LoadingCluster';
import { Fade, Grow } from '@mui/material';
import { MissingData } from '../misc/MissingData';
import Modal from '../misc/Modal';
import HelpIcon from '@mui/icons-material/Help';
import { useSelector } from 'react-redux';
import { GATrackDashboardEvent, EVENT_TYPES } from '../../google_analytics/TrackEvent';
import { selectEmail, selectUserProductType } from '../../store/reducers/userSlice';

// Title, subtitle are optional parameters with defaults specified in function signature below.
interface clusterProps {
    dashboard?: string;
    topic?: string;
    title?: string;
    subtitle?: string;
    date: string;
    clusterData: entityCluster | null;
    clusterLoading: boolean;
    moversTitle?: string;
    monthly?: boolean;
    tooltipContent?: JSX.Element;
}

/*
    Component: Cluster.tsx
    Params: 
        title - title of the row
        subtitle - secondary title of the row
        clusterData - data (type entityCluster) to be used for data in cluster graphs and tables
    Author: Will Brewer
    Desc: Wrapper component for the large analytics dashboard rows. Features movers trends, breakdown of top
        lobbying orgs for the year, and a month-over-month histogram for each top mover.
*/
const Cluster = ({
    dashboard = '',
    topic = '',
    title = '',
    subtitle = '',
    date,
    clusterData,
    clusterLoading,
    moversTitle = 'Movers',
    tooltipContent,
}: clusterProps) => {
    const [modalOpen, setModalOpen] = useState<boolean>(false);
    const userEmail = useSelector(selectEmail);
    const userProductType = useSelector(selectUserProductType);
    const TrackEvent = (name: string) => {
        GATrackDashboardEvent(
            name,
            EVENT_TYPES.CLICK,
            dashboard,
            title,
            topic,
            userEmail ? userEmail : undefined,
            userProductType ? userProductType : undefined
        );
    };
    return (
        <Fade in={true}>
            <div>
                <div className="flex lg:flex-row flex-col justify-between">
                    <div className="text-xl">{title}</div>
                    <div className="flex flex-row items-center">
                        <div className="lg:text-xl text-lg font-light">{subtitle}</div>
                        <div
                            className="ml-2 hover:cursor-pointer"
                            onClick={() => setModalOpen(true)}
                        >
                            <HelpIcon color="info" />
                        </div>
                    </div>
                </div>
                <Modal
                    title={title}
                    open={modalOpen}
                    width={50}
                    onClose={() => setModalOpen(false)}
                >
                    {tooltipContent}
                </Modal>
                <hr className="h-0.5 my-3 bg-slate-100 rounded" />
                {clusterLoading ? (
                    <LoadingCluster />
                ) : !clusterLoading && !clusterData ? (
                    <MissingData />
                ) : clusterData ? (
                    <Grow in={true}>
                        <div className="flex flex-col lg:grid lg:grid-cols-12 gap-4 mt-2">
                            <div className="col-start-1 col-end-13 flex flex-col lg:grid lg:grid-cols-4 gap-4">
                                {clusterData.top_four.map((entry, idx) => (
                                    <BarChart
                                        key={idx}
                                        title={entry.name}
                                        subtitle={entry.institution && entry.institution}
                                        primaryColor={COLORS[idx].primary}
                                        secondaryColor={COLORS[idx].secondary}
                                        data={[...entry.ttm].reverse()}
                                        dataKey="count"
                                        xKey="mn"
                                        yKey="count"
                                        mini
                                        average
                                    />
                                ))}
                            </div>
                            <div className="col-start-1 col-end-4">
                                <Breakdown
                                    title="Most Active (12M)"
                                    breakdownData={clusterData.top_orgs}
                                    dataKey="count"
                                />
                            </div>
                            <div className="col-start-4 col-end-13">
                                <Movers
                                    title={moversTitle}
                                    date={date}
                                    moverData={clusterData.top_movers}
                                    moverMeta={clusterData.movers_meta}
                                    isInstitutionType={clusterData.isInstitutionType}
                                    trackClickEvent={TrackEvent}
                                />
                            </div>
                        </div>
                    </Grow>
                ) : (
                    <></>
                )}
            </div>
        </Fade>
    );
};

export default Cluster;
