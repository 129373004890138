import { useEffect, useState } from 'react';
import {
    createUser,
    selectActionLoading,
    selectCompany,
    selectEmail,
    selectFirstName,
    selectIndustry,
    selectJobTitle,
    selectLastName,
    selectLicenceAccepted,
    selectPhone,
    signUp,
} from '../store/reducers/userSlice';
import { useAppDispatch } from '../store/store';
import { useSelector } from 'react-redux';
import { TextField, FormControl, Button } from '@mui/material';
import LicenseAgreementModal from '../components/misc/LicenseAgreementModal';
import LoadSpinner from '../components/misc/LoadSpinner';
import logo from '../assets/vectors/LIQ_badge.png';

const PricingPage = () => {
    const dispatch = useAppDispatch();

    const email = useSelector(selectEmail);
    const acceptedLicense = useSelector(selectLicenceAccepted);
    const existingFirstName = useSelector(selectFirstName);
    const existingLastName = useSelector(selectLastName);
    const existingCompany = useSelector(selectCompany);
    const existingJobTitle = useSelector(selectJobTitle);
    const existingIndustry = useSelector(selectIndustry);
    const existingPhone = useSelector(selectPhone);
    const loading = useSelector(selectActionLoading);

    const [firstName, setFirstName] = useState(existingFirstName || '');
    const [lastName, setLastName] = useState(existingLastName || '');
    const [company, setCompany] = useState(existingCompany || '');
    const [jobTitle, setJobTitle] = useState(existingJobTitle || '');
    const [industry, setIndustry] = useState(existingIndustry || '');
    const [phone, setPhone] = useState(existingPhone || '');
    const [didSubmit, setDidSubmit] = useState(false);

    const [licenseModalOpen, setLicenseModalOpen] = useState<boolean>(false);

    useEffect(() => {
        existingFirstName && setFirstName(existingFirstName);
        existingLastName && setLastName(existingLastName);
        existingCompany && setCompany(existingCompany);
        existingJobTitle && setJobTitle(existingJobTitle);
        existingIndustry && setIndustry(existingIndustry);
        existingPhone && setPhone(existingPhone);
    }, [
        existingFirstName,
        existingLastName,
        existingCompany,
        existingJobTitle,
        existingIndustry,
        existingPhone,
    ]);

    const handleSignUpRedirect = async () => {
        dispatch(signUp());
    };

    const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault();

        setDidSubmit(true);

        if (
            firstName !== '' &&
            lastName !== '' &&
            company !== '' &&
            jobTitle !== '' &&
            industry !== '' &&
            phone !== ''
        ) {
            dispatch(
                createUser({
                    firstName,
                    lastName,
                    company,
                    job_title: jobTitle,
                    industry,
                    phone,
                })
            )
                .then(() => {
                    setLicenseModalOpen(true);
                })
                .catch((error: any) => {
                    console.log(error);
                });
        }
    };

    return email && !acceptedLicense ? (
        <div className="lg:p-16 lg:pt-32 pt-24 p-10">
            <div className="flex flex-col justify-center items-center text-center mb-10">
                <div className="font-bold mb-3 lg:text-2xl">User Profile</div>
                <div className="mb-5 lg:text-xl">
                    Please provide the information below to complete your User
                    Profile.
                </div>
            </div>
            <form autoComplete="off" onSubmit={handleSubmit}>
                <div className="flex lg:flex-row md:flex-row flex-col justify-center items-center lg:pb-8 pb-6">
                    <div className="mb-5 lg:mb-0 md:mb-0">
                        <div className="p-3">First Name</div>
                        <div className="lg:w-96 w-72 lg:pr-4 md:pr-4">
                            <TextField
                                fullWidth
                                id="first-name"
                                placeholder="First Name"
                                variant="outlined"
                                required
                                onChange={(e) => setFirstName(e.target.value)}
                                value={firstName}
                                error={didSubmit && firstName === ''}
                            />
                        </div>
                    </div>
                    <div>
                        <div className="lg:pl-6 md:pl-6 p-3">Last Name</div>
                        <div className="lg:w-96 w-72 lg:pl-4 md:pl-4">
                            <TextField
                                fullWidth
                                id="last-name"
                                placeholder="Last Name"
                                variant="outlined"
                                required
                                onChange={(e) => setLastName(e.target.value)}
                                value={lastName}
                                error={didSubmit && lastName === ''}
                            />
                        </div>
                    </div>
                </div>
                <div className="flex lg:flex-row md:flex-row flex-col justify-center items-center lg:pb-8 pb-6">
                    <div className="mb-5 lg:mb-0 md:mb-0">
                        <div className="p-3">Industry</div>
                        <div className="lg:w-96 w-72 lg:pr-4 md:pr-4">
                            <TextField
                                fullWidth
                                id="industry"
                                placeholder="Oil, Telecoms, etc."
                                variant="outlined"
                                required
                                onChange={(e) => setIndustry(e.target.value)}
                                value={industry}
                                error={didSubmit && industry === ''}
                            />
                        </div>
                    </div>
                    <div>
                        <div className="lg:pl-6 md:pl-6 p-3">Phone Number</div>
                        <div className="lg:w-96 w-72 lg:pl-4 md:pl-4">
                            <TextField
                                fullWidth
                                id="phone-num"
                                placeholder="+1 123-456-7890"
                                variant="outlined"
                                required
                                onChange={(e) =>
                                    setPhone(e.target.value.replace(/\D/g, ''))
                                }
                                value={phone}
                                error={didSubmit && phone === ''}
                            />
                        </div>
                    </div>
                </div>
                <div className="flex lg:flex-row md:flex-row flex-col justify-center items-center lg:pb-12 md:pb-10 pb-6">
                    <div className="mb-5 lg:mb-0 md:mb-0">
                        <div className="p-3">Company/Organization</div>
                        <div className="lg:w-96 w-72 lg:pr-4 md:pr-4">
                            <TextField
                                fullWidth
                                id="comp-org"
                                placeholder="Company/Organization"
                                variant="outlined"
                                required
                                onChange={(e) => setCompany(e.target.value)}
                                value={company}
                                error={didSubmit && company === ''}
                            />
                        </div>
                    </div>
                    <div>
                        <div className="lg:pl-6 md:pl-6 p-3">Job Title</div>
                        <div className="lg:w-96 w-72 lg:pl-4 md:pl-4">
                            <TextField
                                fullWidth
                                id="occupation"
                                placeholder="Head of Marketing"
                                variant="outlined"
                                required
                                onChange={(e) => setJobTitle(e.target.value)}
                                value={jobTitle}
                                error={didSubmit && jobTitle === ''}
                            />
                        </div>
                    </div>
                </div>

                <div className="flex flex-col items-center">
                    <FormControl variant="standard">
                        {loading ? (
                            <LoadSpinner />
                        ) : (
                            <div className="flex flex-row justify-center items-center mt-4">
                                <Button
                                    className="bg-slate-600"
                                    variant="contained"
                                    size="large"
                                    type="submit"
                                    disabled={loading}
                                >
                                    <div className="font-sans not-italic font-bold text-normal">
                                        Proceed
                                    </div>
                                </Button>
                            </div>
                        )}
                    </FormControl>
                </div>
            </form>
            <LicenseAgreementModal
                open={licenseModalOpen}
                handleClose={() => setLicenseModalOpen(false)}
                selectedProductId={''}
                disableProductRedirect
            />
        </div>
    ) : email && acceptedLicense ? (
        <div>
            <div className="flex flex-col items-center justify-center text-xl py-[40vh]">
                <img src={logo} className="h-20" alt="logo" />
                <div style={{ marginLeft: '20px', marginRight: '20px' }} />
                <div className="text-2xl font-bold my-2">
                    Welcome to LobbyIQ!
                </div>
                <p>
                    Your LobbyIQ account will be activated once payment is
                    processed.
                </p>
            </div>
        </div>
    ) : (
        <div>
            <div className="flex flex-col sm:flex-row items-center justify-center text-xl py-[50vh]">
                <img src={logo} className="h-20" alt="logo" />
                <div style={{ marginLeft: '20px', marginRight: '20px' }} />
                <p>Please</p>
                <div
                    className="mx-1 text-cyan-500 font-bold hover:underline hover:cursor-pointer"
                    onClick={handleSignUpRedirect}
                >
                    create a LobbyIQ account
                </div>
                <p>to begin the subscription process.</p>
            </div>
        </div>
    );
};

export default PricingPage;
