import React, { useEffect } from 'react';
import {
    fetchCommitteeList,
    selectCommitteeList,
} from '../../features/committee-dashboards/data/committeeListSlice';
import { useSelector } from 'react-redux';
import { entityListExtra } from '../../interfaces/generic-entity.interface';
import { useAppDispatch } from '../../store/store';
import EntityListPage from './EntityListPage';
import {
    fetchCommitteesSelections,
    selectChosenCommittees,
} from '../../store/reducers/customSelectionSlice';

const CommitteeListDataLayer = () => {
    const dispatch = useAppDispatch();

    const committeeSelections = useSelector(selectChosenCommittees);
    const entity_list: entityListExtra[] | null = useSelector(selectCommitteeList);

    useEffect(() => {
        dispatch(fetchCommitteeList());
        dispatch(fetchCommitteesSelections());
    }, [dispatch]);

    return (
        <EntityListPage
            type="committees"
            list={entity_list || []}
            selections={committeeSelections}
        />
    );
};

export default CommitteeListDataLayer;
