import axios from 'axios';
import {
    entityLobbyingActivity,
    entityOrg,
    entityOverview,
    entityOverviewRefined,
    entityRegistration,
} from '../interfaces/generic-entity.interface';
import moment from 'moment';
import { entityActivity, entityActivityRaw } from '../interfaces/organization-entity.interface';
import {
    DPOHLinkFormatter,
    consultantLinkFormatter,
    institutionLinkFormatter,
    lobbyFirmLinkFormatter,
    organizationLinkFormatter,
} from '../helpers/internal_routing';

// Base URL for accessing the sector API
const baseURL = process.env.REACT_APP_API_BASE_URL;

const invertYearlyOverview = (overview: entityOverviewRefined[]) => {
    const params: (keyof entityOverviewRefined)[] = [
        'Lobbying Reports',
        'by Consultants',
        'of Ministers or DMs',
        'Institutions Lobbied',
        'New Registrations',
    ];

    const output: any = [];

    params.forEach((param: keyof entityOverviewRefined, idx: number) => {
        const param_data: any = { order: idx, count: param };
        overview.forEach((entry: entityOverviewRefined) => {
            param_data[String(entry.year)] = entry[param];
        });
        output.push(param_data);
    });

    return output;
};

// Fetch an overview of a given organization
const fetchOverview = async (
    code: string | undefined,
    date: string,
    inactive: boolean | undefined
) => {
    const year = date?.split('-')[0];
    const month = date?.split('-')[1];

    const yearly_response = await axios.get(
        `${baseURL}/org/${code}/overview?monthly=false${inactive ? '&inactive=true' : ''}`
    );
    const yearly_data: entityOverviewRefined[] = yearly_response.data.orgOverview
        .filter((entry: any) => +entry.year <= +year)
        .map((entry: entityOverview) => {
            return {
                ...entry,
                'Lobbying Reports': entry.count || 0,
                'by Consultants': entry.external || 0,
                'of Ministers or DMs': entry.ministers || 0,
                'Institutions Lobbied': entry.institutions || 0,
                'New Registrations': entry.registrations || 0,
            };
        });

    const inverted_yearly = invertYearlyOverview(yearly_data);

    const monthly_response = await axios.get(`${baseURL}/org/${code}/overview?monthly=true`);

    const monthly_data: entityOverview[] = monthly_response.data.orgOverview
        .filter((entry: any) => {
            return (
                +[entry.year, (entry.month + '').padStart(2, '0')].join('') <=
                +[year, month].join('')
            );
        })
        .slice(0, inactive ? 36 : 18);

    return { yearly: inverted_yearly, monthly: monthly_data };
};

// Fetch recent lobbying communications in a given organization
const fetchRecentLobbyingActivity = async (
    org: string,
    date: string,
    inactive: boolean | undefined
) => {
    const response = await axios.get(
        `${baseURL}/org/${encodeURIComponent(org)}/activity?limit=25&date=${date}${
            inactive ? '&inactive=true' : ''
        }`
    );
    const data: entityLobbyingActivity[] = response.data.organizationActivity;
    const converted_data: entityLobbyingActivity[] = data.map((entry) => {
        return {
            ...entry,
            'Prior Comms (36M)': entry.three_years_prior,
            date: moment.utc(entry.date).format('YYYY-MM-DD'),
            organization:
                entry.sector_id && entry.sector
                    ? organizationLinkFormatter(entry.sector_id, entry.sector, entry.client)
                    : entry.client,
            dpoh:
                entry.institution_id && entry.institution
                    ? DPOHLinkFormatter(entry.institution_id, entry.institution, entry.dpoh)
                    : entry.dpoh,
            institution:
                entry.institution_id && entry.institution
                    ? institutionLinkFormatter(entry.institution_id, entry.institution)
                    : entry.institution,
            lobby_firm:
                entry.lobby_firm === null || entry.consultant === null
                    ? 'In-House'
                    : lobbyFirmLinkFormatter(entry.lobby_firm),
            consultant:
                entry.lobby_firm === null || entry.consultant === null
                    ? 'In-House'
                    : consultantLinkFormatter(entry.lobby_firm, entry.consultant),
            link: `https://lobbycanada.gc.ca/app/secure/ocl/lrs/do/cmmLgPblcVw?comlogId=${entry.comm}`,
        };
    });
    return converted_data;
};

// Fetch recent lobbying communications in a given organization
const fetchInternalExternalSplit = async (
    org: string,
    date: string,
    inactive: boolean | undefined
) => {
    const response = await axios.get(
        `${baseURL}/org/${encodeURIComponent(org)}/yearactivity?date=${date}${
            inactive ? '&inactive=true' : ''
        }`
    );
    const data: entityOrg[] = response.data.organizationActivity;
    // Count the total number of comms for institutions outside of the top 6
    var other_count = 0;
    if (data.length > 6)
        other_count = data
            .slice(6)
            .map((item) => item.count)
            .reduce((sum, next) => sum + next);

    const top_six = data.slice(0, 6);
    top_six.push({ name: 'Other', code: 'Other', count: other_count });

    return top_six;
};

// Fetch recent registrations in a given organization
const fetchOrganizationLatestRegistrations = async (
    org: string,
    date: string,
    inactive: boolean | undefined
): Promise<entityRegistration[]> => {
    const response = await axios.get(
        `${baseURL}/org/${encodeURIComponent(org)}/latestregistrations?date=${date}${
            inactive ? '&inactive=true' : ''
        }`
    );

    const lobbying_field: string = `Lobbying Reports (${inactive ? '36M' : '12M'})`;
    const data: entityRegistration[] = response.data.latestRegistrations.map(
        (entry: entityRegistration) => {
            return {
                ...entry,
                [lobbying_field]: entry.count || 0,
                date: moment.utc(entry.date).format('YYYY-MM-DD'),
                organization: entry.client,
                lobby_firm:
                    entry.lobby_firm === null || entry.consultant === null
                        ? 'In-House'
                        : lobbyFirmLinkFormatter(entry.lobby_firm),
                consultant:
                    entry.lobby_firm === null || entry.consultant === null
                        ? 'In-House'
                        : consultantLinkFormatter(entry.lobby_firm, entry.consultant),
                history: entry.history ? 'Renewed' : 'New',
                dashboard: `link:/registrations/${entry.registration}`,
            };
        }
    );
    return data;
};

// Fetch recent deregistrations in a given organization
const fetchOrganizationLatestDeregistrations = async (
    org: string,
    date: string,
    inactive: boolean | undefined
): Promise<entityRegistration[]> => {
    const response = await axios.get(
        `${baseURL}/org/${encodeURIComponent(org)}/latestderegistrations?date=${date}${
            inactive ? '&inactive=true' : ''
        }`
    );

    const lobbying_field: string = `Lobbying Reports (${inactive ? '36M' : '12M'})`;
    const data: entityRegistration[] = response.data.latestDeRegistrations.map(
        (entry: entityRegistration) => {
            return {
                ...entry,
                [lobbying_field]: entry.count || 0,
                date: moment.utc(entry.date).format('YYYY-MM-DD'),
                organization: entry.client,
                lobby_firm:
                    entry.lobby_firm === null || entry.consultant === null
                        ? 'In-House'
                        : lobbyFirmLinkFormatter(entry.lobby_firm),
                consultant:
                    entry.lobby_firm === null || entry.consultant === null
                        ? 'In-House'
                        : consultantLinkFormatter(entry.lobby_firm, entry.consultant),
                history: entry.history ? 'Renewed' : 'New',
                dashboard: `link:/registrations/${entry.registration}`,
            };
        }
    );
    return data;
};

// Fetch recent DPOH activity in a given organization
const fetchRecentDPOHActivity = async (
    org: string,
    date: string,
    inactive: boolean | undefined
) => {
    const response = await axios.get(
        `${baseURL}/org/${encodeURIComponent(org)}/topportionsdpoh?date=${date}${
            inactive ? '&inactive=true' : ''
        }`
    );
    const data: entityActivityRaw[] = response.data.organizationTopPortionsDPOH;
    const converted_data: entityActivity[] = data.map((entry) => {
        return {
            ...entry,
            name:
                entry.institution_id && entry.institution
                    ? DPOHLinkFormatter(entry.institution_id, entry.institution, entry.name)
                    : entry.name,
            institution:
                entry.institution_id && entry.institution
                    ? institutionLinkFormatter(entry.institution_id, entry.institution)
                    : entry.institution,
            'prior_comms_(12M)': entry.one_year_count,
            'prior_comms_(36M)': entry.three_year_count,
            'percent_comms_(12M)':
                Math.round((entry.one_year_count / entry.one_year_total) * 100 * 100) / 100,
        };
    });
    return converted_data;
};

// Fetch top institutions lobbied in a given organization
const fetchTopInstitutions = async (org: string, date: string, inactive: boolean | undefined) => {
    const response = await axios.get(
        `${baseURL}/org/${encodeURIComponent(org)}/topinstitutions?date=${date}${
            inactive ? '&inactive=true' : ''
        }`
    );
    const data: entityOrg[] = response.data.organizationTopInstitutions;

    // Count the total number of comms for institutions outside of the top 6
    var other_count = 0;
    if (data.length > 6)
        other_count = data
            .slice(6)
            .map((item) => item.count)
            .reduce((sum, next) => sum + next);

    const top_six = data.slice(0, 6);
    top_six.push({ name: 'Other', code: 'Other', count: other_count });

    return top_six;
};

// Fetch top subjects lobbied in a given organization
const fetchTopSubjects = async (org: string, date: string, inactive: boolean | undefined) => {
    const response = await axios.get(
        `${baseURL}/org/${encodeURIComponent(org)}/topsubjects?date=${date}${
            inactive ? '&inactive=true' : ''
        }`
    );
    const data: entityOrg[] = response.data.organizationTopSubjects;

    // Count the total number of comms for institutions outside of the top 6
    var other_count = 0;
    if (data.length > 6)
        other_count = data
            .slice(6)
            .map((item) => item.count)
            .reduce((sum, next) => sum + next);

    const top_six = data.slice(0, 6);
    top_six.push({ name: 'Other', code: 'Other', count: other_count });

    return top_six;
};

export const orgAPI = {
    fetchOverview,
    fetchRecentLobbyingActivity,
    fetchInternalExternalSplit,
    fetchOrganizationLatestRegistrations,
    fetchOrganizationLatestDeregistrations,
    fetchRecentDPOHActivity,
    fetchTopInstitutions,
    fetchTopSubjects,
};
