import React from 'react';

const LoadingMovers = () => {
    return (
        <div className="h-96 bg-white shadow-md rounded-md p-4 flex flex-col justify-between animate-pulse">
            <div className="bg-slate-100 w-1/3 h-4 mt-2 rounded-md">&nbsp;</div>
            <div className="grid grid-cols-12">
                <div className="col-span-8 grid grid-cols-12 items-center">
                    <div className="bg-slate-100 rounded-md col-span-3 h-6">
                        &nbsp;
                    </div>
                </div>
            </div>
            <div className="grid grid-cols-12 mt-6">
                <div className="col-span-8 grid grid-cols-12 items-center">
                    <div className="bg-slate-100 rounded-md col-span-4 h-3">
                        &nbsp;
                    </div>
                </div>
            </div>
            <div className="grid grid-cols-12">
                <div className="col-span-6 bg-slate-100 rounded-md h-3">
                    &nbsp;
                </div>
            </div>
            <div className="grid grid-cols-12">
                <div className="col-span-3 bg-slate-100 rounded-md h-3">
                    &nbsp;
                </div>
            </div>
            <div className="grid grid-cols-12">
                <div className="col-span-5 bg-slate-100 rounded-md h-3">
                    &nbsp;
                </div>
            </div>
            <div className="grid grid-cols-12">
                <div className="col-span-6 bg-slate-100 rounded-md h-3">
                    &nbsp;
                </div>
            </div>
            <div className="grid grid-cols-12">
                <div className="col-span-10 bg-slate-100 rounded-md h-3">
                    &nbsp;
                </div>
            </div>
            <div className="grid grid-cols-12">
                <div className="col-span-8 bg-slate-100 rounded-md h-3">
                    &nbsp;
                </div>
            </div>
            <div className="grid grid-cols-12">
                <div className="col-span-3 bg-slate-100 rounded-md h-3">
                    &nbsp;
                </div>
            </div>
            <div className="grid grid-cols-12 mb-6">
                <div className="col-span-7 bg-slate-100 rounded-md h-3">
                    &nbsp;
                </div>
            </div>
        </div>
    );
};

export default LoadingMovers;
