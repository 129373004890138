import { useEffect } from "react";
import { useLocation } from "react-router-dom";

/*
    Function: ScrollToTop.tsx
    Params: N/A
    Author: Will Brewer
    Desc: Scrolls the viewport to the top of the parent component on path change
*/
const ScrollToTop = () => {

    // use location returns the route name
    const { pathname } = useLocation();

    // useEffect listens to changes in pathname
    useEffect(() => {
        // On change of pathname/route, scroll to top
        window.scrollTo(0, 0);
    }, [pathname]);

    return null;
}

export default ScrollToTop;