export const months = [
    'January',
    'February',
    'March',
    'April',
    'May',
    'June',
    'July',
    'August',
    'September',
    'October',
    'November',
    'December',
];

const date = new Date();
export const current_month = months[date.getMonth()];

date.setDate(0);
export const last_month = months[date.getMonth()];

date.setDate(0);
export const two_months_ago = months[date.getMonth()];

date.setDate(0);
export const three_months_ago = months[date.getMonth()];

date.setDate(0);
export const four_months_ago = months[date.getMonth()];

date.setDate(0);
export const five_months_ago = months[date.getMonth()];

date.setDate(0);
export const six_months_ago = months[date.getMonth()];

const date_idx = new Date();
export const current_month_idx = date_idx.getMonth();

date_idx.setDate(0);
export const last_month_idx = date_idx.getMonth();

date_idx.setDate(0);
export const two_months_ago_idx = date_idx.getMonth();

date_idx.setDate(0);
export const three_months_ago_idx = date_idx.getMonth();

date_idx.setDate(0);
export const four_months_ago_idx = date_idx.getMonth();

date_idx.setDate(0);
export const five_months_ago_idx = date_idx.getMonth();

date_idx.setDate(0);
export const six_months_ago_idx = date_idx.getMonth();
