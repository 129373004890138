export const DPOHLobbyingCommunications = (
    <div className="flex flex-col gap-2">
        <div>
            This exhibit breaks down DPOH communication filings along three
            dimensions.
        </div>
        <div>
            Type of filing outlines the breakdown of communications by lobby
            firm, or in-house communications made by organizations.
        </div>
    </div>
);
