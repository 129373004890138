export const InstitutionLobbyingCommunications = (
    <div className="flex flex-col gap-2">
        <div>
            A list of the 25 most recent communications in a sector, with a link
            to the underlying primary record on LobbyCanada.gc.ca
        </div>
        <div>
            Each row corresponds to one DPOH at the meeting, so that meetings
            with multiple DPOHs span multiple rows.
        </div>
        <div>
            The column “Prior Comms (36M)” counts the number of meetings between
            the client and DPOH over the last 36 months
        </div>
    </div>
);

//TODO line 1 make real link?
