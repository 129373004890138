import React from 'react';
import { Logout, Settings } from '@mui/icons-material';
import { Divider, MenuItem } from '@mui/material';
import { Link } from 'react-router-dom';

// userEmail and userloading are required parameters to determine account info to display
interface accountPanelProps {
    userEmail: string | null;
    userLoading: boolean;
    handleLogin: () => Promise<void>;
    handleLogout: () => void;
    handleClose: () => void;
}

const AccountPanel = ({
    userEmail,
    userLoading,
    handleLogout,
    handleClose,
}: accountPanelProps) => {
    const link_tran = 'text-slate-600 transition ease-in-out hover:text-black';
    const spacing = 'w-full flex flex-row items-center justify-between';

    return (
        <div>
            {userEmail && !userLoading && (
                <div>
                    <div className="text-slate-600 flex flex-row justify-center px-8 p-4">{userEmail}</div>
                    <Divider sx={{ marginY: 1 }} />
                    <div className={link_tran}>
                        <MenuItem
                            onClick={handleClose}
                            component={Link}
                            to="/account"
                        >
                            <div className={spacing}>
                                Settings
                                <Settings style={{ fontSize: 20 }} />
                            </div>
                        </MenuItem>
                    </div>

                    <div className={link_tran}>
                        <MenuItem onClick={handleLogout}>
                            <div className={spacing}>
                                Logout
                                <Logout style={{ fontSize: 18 }} />
                            </div>
                        </MenuItem>
                    </div>
                </div>
            )}
        </div>
    );
};

export default AccountPanel;
