import { Grow } from '@mui/material';
import React from 'react';

const container = 'bg-white shadow-sm rounded-md px-2 py-1 my-3 animate-pulse';

const LoadingList = () => {
    return (
        <>
            {[...Array(10)].map((_, idx: number) => {
                return (
                    <Grow key={idx} in={true}>
                        <div className={container}>
                            <div className="lg:text-slate-700 text-md grid grid-cols-12 gap-2 py-2 pl-2 items-center">
                                <div className="lg:col-span-6 col-span-12">
                                    <div className="w-3/4 bg-slate-100 rounded-md h-6">
                                        &nbsp;
                                    </div>
                                </div>
                                <div className="lg:col-span-6 col-span-12 flex flex-row justify-end gap-2">
                                    <div className="w-20 bg-slate-100 rounded-md h-6">
                                        &nbsp;
                                    </div>
                                    <div className="w-20 bg-slate-100 rounded-md h-6">
                                        &nbsp;
                                    </div>
                                    <div className="w-20 bg-slate-100 rounded-md h-6">
                                        &nbsp;
                                    </div>
                                    <div className="w-20 bg-slate-100 rounded-md h-6">
                                        &nbsp;
                                    </div>
                                    <div className="w-20 bg-slate-100 rounded-md h-6">
                                        &nbsp;
                                    </div>
                                </div>
                            </div>
                        </div>
                    </Grow>
                );
            })}
        </>
    );
};

export default LoadingList;
