import React from 'react';
import logo from '../../assets/vectors/LIQ_badge.png';

export const PaymentSuccess = () => {
    return (
        <div>
            <div className="flex flex-row items-center justify-center py-[50vh] text-xl">
                <img src={logo} className="h-32" alt="logo" />
                <div style={{ marginLeft: '20px', marginRight: '20px' }} />
                <div className="flex flex-col items-center gap-5">
                    <p>Thank you for subscribing to LobbyIQ’s Committee Monitoring service.</p>
                    <p>
                        {' '}
                        If you have any questions about your subscription, please email Customer
                        Service at contact@lobbyiq.org.
                    </p>
                </div>
            </div>
        </div>
    );
};
