export const replace_underscores_capitalize = (str: string) => {
    const known_abbreviations: Array<string> = ['DPOH', 'YTD', 'ID', 'MTD', 'MP'];
    const ignore: Array<string> = ['and'];

    if (known_abbreviations.includes(str.toUpperCase())) return str.toUpperCase();

    // Replace underscores with spaces
    var replaced = str.replace(/_/g, ' ');

    // Capitalize the words
    var capitalized = replaced.replace(/(?:^|\s)(?!and\b)\w/g, function (match) {
        if (!ignore.includes(match)) return match.toUpperCase();
        else return match;
    });

    return capitalized;
};
