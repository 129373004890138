import React, { useEffect } from 'react';
import { useAppDispatch } from '../../../store/store';
import EntityListPage from '../../../pages/lists/EntityListPage';
import { useSelector } from 'react-redux';
import {
    fetchSenateCommitteeList,
    fetchSenatorList,
    selectSenateCommitteeList,
    selectSenatorList,
} from '../store/senateListSlice';
import { entityListExtra } from '../../../interfaces/generic-entity.interface';

export enum senateType {
    Members,
    Cmtes,
}

const SenateListDataLayer = ({ listType }: { listType: senateType }) => {
    const dispatch = useAppDispatch();

    var type: string;
    switch (listType) {
        case senateType.Members:
            type = 'senators';
            break;
        case senateType.Cmtes:
            type = 'senate committees';
            break;
    }

    var entity_list: entityListExtra[] | null = useSelector(
        listType === senateType.Members ? selectSenatorList : selectSenateCommitteeList
    );

    useEffect(() => {
        dispatch(listType === senateType.Members ? fetchSenatorList() : fetchSenateCommitteeList());
    }, [dispatch, listType]);

    return <EntityListPage type={type as any} list={entity_list || []} />;
};

export default SenateListDataLayer;
