import React from 'react';
import { Checkbox, FormControlLabel, MenuItem, Select } from '@mui/material';

interface ExportExhibitSelectorProps {
    includeSelection: boolean;
    hasRowCount: boolean;
    labelText: string;
    selectedRowCount?: number;
    dataLength?: number;
    handleChangeSelection: (b: boolean) => void;
    handleChangeRowCount?: (n: number) => void;
}

export const ExportExhibitSelector = ({
    includeSelection,
    hasRowCount,
    labelText,
    selectedRowCount,
    dataLength = 0,
    handleChangeSelection,
    handleChangeRowCount,
}: ExportExhibitSelectorProps) => {
    return (
        <div className="flex flex-col gap-1 lg:text-lg text-sm">
            <div className="flex flex-row justify-between">
                <FormControlLabel
                    control={
                        <Checkbox
                            checked={includeSelection}
                            onChange={() => handleChangeSelection(!includeSelection)}
                        />
                    }
                    label={<div className="lg:text-lg text-sm">{labelText}</div>}
                />

                {hasRowCount && (
                    <div className="flex flex-row">
                        <div className="my-auto mr-2">Rows:</div>
                        <Select
                            sx={{ height: 30, width: 70, marginTop: 'auto', marginBottom: 'auto' }}
                            value={selectedRowCount}
                            onChange={(event) => handleChangeRowCount!(+event.target.value)}
                        >
                            <MenuItem value={Math.min(10, dataLength)}>
                                {Math.min(10, dataLength)}
                            </MenuItem>
                            {dataLength > 10 && (
                                <MenuItem value={Math.min(15, dataLength)}>
                                    {Math.min(15, dataLength)}
                                </MenuItem>
                            )}
                            {dataLength > 15 && (
                                <MenuItem value={Math.min(20, dataLength)}>
                                    {Math.min(20, dataLength)}
                                </MenuItem>
                            )}
                            {dataLength > 20 && (
                                <MenuItem value={Math.min(25, dataLength)}>
                                    {Math.min(25, dataLength)}
                                </MenuItem>
                            )}
                            {dataLength > 25 && (
                                <MenuItem value={Math.min(50, dataLength)}>
                                    {Math.min(50, dataLength)}
                                </MenuItem>
                            )}
                            {dataLength > 50 && (
                                <MenuItem value={dataLength}>{dataLength}</MenuItem>
                            )}
                        </Select>
                    </div>
                )}
            </div>
            <hr className="h-0.5 bg-slate-100 rounded" />
        </div>
    );
};
