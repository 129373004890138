export const SubjectLobbyRegistrations = (
    <div className="flex flex-col gap-2">
        <div>
            This exhibit lists the registrations that were new or renewed for a subject in the last
            3 months.
        </div>
        <div>
            Province denotes the province of a client firm’s self-reported address (which can
            verified in the reported link to the primary record).
        </div>
    </div>
);
