import { useNavigate } from 'react-router-dom';
import logo from '../../assets/vectors/LIQ_badge.png';

interface DashboardNotLicencedProps {
    type: 'sectors' | 'institutions' | 'committees';
    name?: string | null;
}

export const DashboardNotLicenced = ({
    type,
    name = '',
}: DashboardNotLicencedProps) => {
    const navigate = useNavigate();

    const handleListRedirect = async () => {
        navigate(`/${type}`);
    };

    return (
        <div className="flex flex-col items-center justify-center text-xl py-[40vh]">
            <img src={logo} className="h-20" alt="logo" />
            <div className="mt-8" />
            <div className="flex flex-col sm:flex-row items-center justify-center">
                <p>{name ? 'The' : 'This'}</p>
                <p className="font-bold sm:mx-1">{name}</p>
                <p>{type.slice(0, -1)} is not part of your subscription.</p>
            </div>
            <div className="flex flex-row items-center justify-center align-middle">
                <p>Click</p>
                <div
                    className="mx-1 text-cyan-500 font-bold hover:underline hover:cursor-pointer"
                    onClick={handleListRedirect}
                >
                    here
                </div>
                <p>to go back.</p>
            </div>
        </div>
    );
};
