import { useNavigate } from 'react-router-dom';
import logo from '../../assets/vectors/LIQ_badge.png';
import { useAppDispatch } from '../../store/store';
import { logIn } from '../../store/reducers/userSlice';

export const SubscriptionRequired = () => {
    const navigate = useNavigate();
    const dispatch = useAppDispatch();

    const handleRedirectPricing = async () => {
        navigate('/pricing');
    };

    const handleLogin = async () => {
        dispatch(logIn());
    };

    return (
        <div className="flex flex-row items-center justify-center text-xl py-[50vh]">
            <img src={logo} className="h-20" alt="logo" />
            <div style={{ marginLeft: '20px', marginRight: '20px' }} />
            <div className="flex flex-col sm:flex-row">
                <p>Please</p>
                <div
                    className="sm:mx-1 font-bold text-cyan-500 hover:underline hover:cursor-pointer"
                    onClick={handleLogin}
                >
                    log in
                </div>
                 <p>or</p>
                <div
                    className="sm:mx-1 font-bold text-cyan-500 hover:underline hover:cursor-pointer"
                    onClick={handleRedirectPricing}
                >
                    subscribe
                </div>
                <p>to access LobbyIQ dashboards.</p>
            </div>
        </div>
    );
};
