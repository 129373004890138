import React from 'react';
import LoadingBarChart from './LoadingBarChart';
import LoadingMovers from './LoadingMovers';
import LoadingBreakdown from './LoadingBreakdown';

const LoadingCluster = () => {
    return (
        <div className="flex flex-col lg:grid lg:grid-cols-12 gap-4">
            <div className="col-start-1 col-end-13 flex flex-col lg:grid lg:grid-cols-4 gap-4">
                <LoadingBarChart mini />
                <LoadingBarChart mini />
                <LoadingBarChart mini />
                <LoadingBarChart mini />
            </div>
            <div className="col-start-1 col-end-4">
                <LoadingBreakdown />
            </div>
            <div className="col-start-4 col-end-13">
                <LoadingMovers />
            </div>
        </div>
    );
};

export default LoadingCluster;
