export const InstitutionOrganizations = (
    <div className="flex flex-col gap-2">
        <div>This cluster has six elements:</div>
        <div>
            A pie-chart breaking down the last 12
            months of communications by organizations, twelve-month histograms
            for the four most lobbying-active organizations, and a Movers table.
            The Movers table includes the two most recent months. The earlier of
            these two months has approximately complete data, whereas the later
            is tentative and data is still incoming. For example, when viewing
            the data in October, the August column should be considered
            approximately complete, but September is not yet. The +/- column
            considers the difference between the earlier of the two most recent
            months and the average of the twelve months that preceded it. For
            example: in October, the +/- compares August data with an average of
            data from July back to June of the previous year.
        </div>
    </div>
);
