import React from 'react';

interface loadingBarChartProps {
    mini?: boolean;
}

const LoadingBarChart = ({ mini }: loadingBarChartProps) => {
    return (
        <div
            className={`${
                mini ? 'h-40' : 'h-64'
            } bg-white shadow-md rounded-md p-4 flex flex-col justify-between animate-pulse`}
        >
            <div className="bg-slate-100 w-1/2 h-4 rounded-md">&nbsp;</div>
            <div className="flex items-baseline gap-1">
                <div className="w-full bg-slate-100 rounded-md h-8"></div>
                <div className="w-full bg-slate-100 rounded-md h-4"></div>
                <div className="w-full bg-slate-100 rounded-md h-2"></div>
                <div className="w-full bg-slate-100 rounded-md h-6"></div>
                <div className="w-full bg-slate-100 rounded-md h-12"></div>
                <div className="w-full bg-slate-100 rounded-md h-8"></div>
                <div className="w-full bg-slate-100 rounded-md h-12"></div>
                <div className="w-full bg-slate-100 rounded-md h-4"></div>
                <div className="w-full bg-slate-100 rounded-md h-8"></div>
                <div className="w-full bg-slate-100 rounded-md h-12"></div>
                <div className="w-full bg-slate-100 rounded-md h-16"></div>
                <div className="w-full bg-slate-100 rounded-md h-20"></div>
                {!mini ? (
                    <>
                        <div className="w-full bg-slate-100 rounded-md h-24"></div>
                        <div className="w-full bg-slate-100 rounded-md h-36"></div>
                        <div className="w-full bg-slate-100 rounded-md h-32"></div>
                        <div className="w-full bg-slate-100 rounded-md h-48"></div>
                        <div className="w-full bg-slate-100 rounded-md h-40"></div>
                    </>
                ) : (
                    <></>
                )}
            </div>
        </div>
    );
};

export default LoadingBarChart;
