import React from 'react';
import { Breadcrumbs } from '@mui/material';
import { Link } from 'react-router-dom';

import logo from '../assets/vectors/LIQ_badge.png';
import MetaTag from '../components/metatag-component/MetaTag';

// TODO: add routing for "See Pricing" button and "Contact Us" button

export const AboutPage = () => {
    return (
        <div className="lg:py-32 py-20">
            <MetaTag
                title="About"
                keywords="LobbyIQ, Data Analytics, Government Relations, Lobbying Patterns, Machine Learning, Industry Sectors, Government Institutions, Dashboards, Competitors, Stakeholders, Advocacy Issues, Christian Dippel, Guy Holburn, Business Strategy, Public Policy, Federal Government, Data-Driven Insights"
                description="LobbyIQ helps clients navigate the federal government with data analytics, enhancing government relations strategies. Our dashboards offer insights on lobbying patterns, competitors, and emerging advocacy issues using proprietary machine learning algorithms. Founded by experts Christian Dippel and Guy Holburn, LobbyIQ provides data-driven solutions for effective lobbying and policy influence."
            />
            <div className="grid grid-cols-12">
                <Breadcrumbs className="col-start-2 col-end-12" separator=">">
                    <Link to="/" className="hover:underline">
                        Lobby<b>IQ</b>
                    </Link>
                    <div>About</div>
                </Breadcrumbs>
            </div>

            <div className="grid grid-cols-12 lg:mt-12 mt-4 py-8">
                <div className="col-start-2 lg:col-end-8 col-end-12 flex flex-col">
                    <h1 className="text-slate-600 lg:text-4xl text-3xl mb-4">
                        <span>About</span> Lobby
                        <b className="font-black">IQ</b>
                    </h1>
                    <p className="text-lg lg:mt-8 font-light">
                        LobbyIQ's mission is to help our clients navigate the federal government
                        using the power of data analytics, strengthening the impact of government
                        relations strategies.
                        <br /> <br />
                        LobbyIQ provides data-driven insights on lobbying patterns and anomalies
                        within industry sectors and government institutions, using proprietary
                        machine learning algorithms and data sources. LobbyIQ's unique dashboards
                        enable executives to monitor lobbying activities of competitors and
                        stakeholders, to track targeted DPOHs, to discern emerging advocacy issues,
                        and much more.
                    </p>
                </div>
                <div className="col-start-8 col-end-12 pr-12 lg:flex hidden flex-col items-end justify-center text-center text-slate-600">
                    <img src={logo} className="w-72" alt="logo" />
                </div>
            </div>

            <div className="grid grid-cols-12 lg:mt-12 mt-4 py-8">
                <div className="col-start-2 col-end-12 flex flex-col">
                    <div className="text-slate-600 lg:text-4xl text-3xl mb-4">
                        About the Founders
                    </div>
                    <p className="text-lg lg:mt-8 font-light">
                        LobbyIQ was founded in 2023 by Christian Dippel and Guy Holburn. Dippel and
                        Holburn are experts in business strategy and public policy, and have
                        consulted for a wide range of corporations and governments in Canada and the
                        United States.
                        <br />
                        <br />
                        Guy Holburn is Professor of Business, Economics and Public Policy at the
                        Ivey Business School, Western University, where he researches and teaches
                        business strategy, regulation and governance. He is a Director of London
                        Hydro, a board member of the Alliance for Research on Corporate
                        Sustainability, and the founding Director of the Ivey EnergyPolicy and
                        Management Centre. He has served as a consultant and advisor to corporations
                        and governments in Canada and the U.S., and testified as an expert witness
                        in government inquiries and commercial litigation. He holds a PhD from the
                        University of California, Berkeley, and a BA Hons. from Cambridge
                        University. Previously, Holburn worked for several years as a management
                        consultant for Bain and Company in the U.K. and South Africa.
                        <br />
                        <br />
                        Christian Dippel is Professor of Business, Economics and Public Policy at
                        the Ivey Business School. He joined Ivey in 2021 after a decade at UCLA's
                        Anderson School of Management in California. He is a Research Associate with
                        the National Bureau of Economic Research's Political Economy Group, and a
                        Campbell Fellow at Stanford's Hoover Institution. His research has been
                        funded by the National Science Foundation in the U.S., and by Canada's
                        SSHRC. Dippel has consulted for corporations in Canada and the U.S., as well
                        as for the World Bank, the Inter-American Development Bank, and the Chicago
                        FED. Prior to his PhD studies, he worked in investment banking in his native
                        Germany.
                    </p>
                </div>
            </div>
        </div>
    );
};
