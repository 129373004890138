import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { entityListExtra } from '../../interfaces/generic-entity.interface';
import { useAppDispatch } from '../../store/store';
import EntityListPage from './EntityListPage';
import {
    fetchConsultantList,
    selectConsultantList,
} from '../../store/reducers/lobbyFirmListSlice';
import { useParams } from 'react-router-dom';

const ConsultantListDataLayer = () => {
    const dispatch = useAppDispatch();

    const url_params = useParams();

    const entity_code =
        url_params.entityId !== undefined ? url_params.entityId : '';

    const entity_list: entityListExtra[] | null =
        useSelector(selectConsultantList);

    useEffect(() => {
        dispatch(fetchConsultantList({ code: entity_code }));
    }, [dispatch, entity_code]);

    return <EntityListPage type="consultants" list={entity_list || []} />;
};

export default ConsultantListDataLayer;
