import { entityOverviewRefined } from '../interfaces/generic-entity.interface';

export const overviewFormatter = (
    overview: entityOverviewRefined[],
    params: (keyof entityOverviewRefined)[]
) => {
    const output: any = [];

    params.forEach((param: keyof entityOverviewRefined, idx: number) => {
        const param_data: any = { order: idx, count: param };
        overview.forEach((entry: entityOverviewRefined) => {
            param_data[String(entry.year)] = entry[param];
        });
        output.push(param_data);
    });

    return output;
};
