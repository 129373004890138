import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { RootState } from '../../../store/store';
import { sectorAPI } from '../API/sectorAPI';
import { bumperSuperList } from '../../../interfaces/bumper-types.interface';
import {
    entityOverviews,
    entityLobbyingActivity,
    entityRegistration,
    entityCluster,
} from '../../../interfaces/generic-entity.interface';
import axios from 'axios';
import saveAs from 'file-saver';
import { recentUpdates } from '../../shared/interfaces/whats-new.interface';

interface SectorState {
    updates: recentUpdates | null;
    updates_loading: boolean;
    updates_fail: boolean;

    overview: entityOverviews | null;
    overview_loading: boolean;
    overview_fail: boolean;

    lobbying_activity: entityLobbyingActivity[] | null;
    lobbying_activity_loading: boolean;
    lobbying_activity_fail: boolean;

    recent_reg: entityRegistration[] | null;
    recent_reg_loading: boolean;
    recent_reg_fail: boolean;

    recent_dereg: entityRegistration[] | null;
    recent_dereg_loading: boolean;
    recent_dereg_fail: boolean;

    org_cluster: entityCluster | null;
    org_cluster_loading: boolean;
    org_cluster_fail: boolean;

    inst_cluster: entityCluster | null;
    inst_cluster_loading: boolean;
    inst_cluster_fail: boolean;

    firm_cluster: entityCluster | null;
    firm_cluster_loading: boolean;
    firm_cluster_fail: boolean;

    dpoh_cluster: entityCluster | null;
    dpoh_cluster_loading: boolean;
    dpoh_cluster_fail: boolean;

    subject_cluster: entityCluster | null;
    subject_cluster_loading: boolean;
    subject_cluster_fail: boolean;

    terms: bumperSuperList[] | null;
    terms_loading: boolean;
    terms_fail: boolean;

    export_loading: boolean;
    export_fail: boolean;
}

const initialState: SectorState = {
    updates: null,
    updates_loading: false,
    updates_fail: false,

    overview: null,
    overview_loading: false,
    overview_fail: false,

    lobbying_activity: null,
    lobbying_activity_loading: false,
    lobbying_activity_fail: false,

    recent_reg: null,
    recent_reg_loading: false,
    recent_reg_fail: false,

    recent_dereg: null,
    recent_dereg_loading: false,
    recent_dereg_fail: false,

    org_cluster: null,
    org_cluster_loading: false,
    org_cluster_fail: false,

    inst_cluster: null,
    inst_cluster_loading: false,
    inst_cluster_fail: false,

    firm_cluster: null,
    firm_cluster_loading: false,
    firm_cluster_fail: false,

    dpoh_cluster: null,
    dpoh_cluster_loading: false,
    dpoh_cluster_fail: false,

    subject_cluster: null,
    subject_cluster_loading: false,
    subject_cluster_fail: false,

    terms: null,
    terms_loading: false,
    terms_fail: false,

    export_loading: false,
    export_fail: false,
};

// Fetch the recent updates for the current sector
export const fetchSectorUpdates = createAsyncThunk(
    'sector/fetchSectorUpdates',
    async ({ code, date }: { code: string | undefined; date: string }): Promise<recentUpdates> =>
        sectorAPI.fetchUpdates(code, date)
);

// Fetch the yearly overview data for the current sector
export const fetchSectorOverview = createAsyncThunk(
    'sector/fetchSectorOverviewYearly',
    async ({ code, date }: { code: string | undefined; date: string }): Promise<entityOverviews> =>
        sectorAPI.fetchOverview(code, date)
);

// Fetch the recent lobbying activity data for the current sector
export const fetchSectorRecentLobbying = createAsyncThunk(
    'sector/fetchSectorRecentLobbying',
    async ({
        sector_code,
        sector_name,
        date,
    }: {
        sector_code: string;
        sector_name: string;
        date: string | undefined;
    }): Promise<entityLobbyingActivity[]> =>
        sectorAPI.fetchRecentLobbyingActivity(sector_code, sector_name, date)
);

// Fetch the recent lobbying registrations in the current sector
export const fetchSectorRegistrations = createAsyncThunk(
    'sector/fetchSectorRegistrations',
    async ({
        sector_code,
        sector_name,
        date,
    }: {
        sector_code: string;
        sector_name: string;
        date: string | undefined;
    }): Promise<entityRegistration[]> =>
        sectorAPI.fetchRecentRegistrations(sector_code, sector_name, date)
);

// Fetch the recent lobbying registrations in the current sector
export const fetchSectorDeregistrations = createAsyncThunk(
    'sector/fetchSectorDeregistrations',
    async ({
        sector_code,
        sector_name,
        date,
    }: {
        sector_code: string;
        sector_name: string;
        date: string | undefined;
    }): Promise<entityRegistration[]> =>
        sectorAPI.fetchRecentDeregistrations(sector_code, sector_name, date)
);

// Fetch the organization cluster data
export const fetchOrganizationCluster = createAsyncThunk(
    'sector/fetchOrganizationCluster',
    async ({
        code,
        date,
    }: {
        code: string | undefined;
        date: string | undefined;
    }): Promise<entityCluster> => sectorAPI.fetchCluster(code, 'organizations', date)
);

// Fetch the institution cluster data
export const fetchInstitutionCluster = createAsyncThunk(
    'sector/fetchInstitutionCluster',
    async ({
        code,
        date,
    }: {
        code: string | undefined;
        date: string | undefined;
    }): Promise<entityCluster> => sectorAPI.fetchCluster(code, 'institutions', date)
);

// Fetch the lobby firm cluster data
export const fetchFirmCluster = createAsyncThunk(
    'sector/fetchFirmCluster',
    async ({
        code,
        date,
    }: {
        code: string | undefined;
        date: string | undefined;
    }): Promise<entityCluster> => sectorAPI.fetchCluster(code, 'lobbyfirms', date)
);

// Fetch the lobby firm cluster data
export const fetchDPOHCluster = createAsyncThunk(
    'sector/fetchDPOHCluster',
    async ({
        code,
        date,
    }: {
        code: string | undefined;
        date: string | undefined;
    }): Promise<entityCluster> => sectorAPI.fetchCluster(code, 'dpoh', date)
);

// Fetch the subject cluster data
export const fetchSubjectCluster = createAsyncThunk(
    'sector/fetchSubjectCluster',
    async ({
        code,
        date,
    }: {
        code: string | undefined;
        date: string | undefined;
    }): Promise<entityCluster> => sectorAPI.fetchCluster(code, 'subject', date)
);

// Fetch the sector Terms data
export const fetchSectorTerms = createAsyncThunk(
    'sector/fetchSectorTerms',
    async ({
        code,
        date,
    }: {
        code: string | undefined;
        date: string | undefined;
    }): Promise<bumperSuperList[]> => sectorAPI.fetchTerms(code, date)
);

// Export Sector PDF
export const exportSectorDashboard = createAsyncThunk(
    'sector/exportDashboard',
    async (data: any): Promise<void> => {
        const response = await axios.post(
            `${process.env.REACT_APP_PDF_SERVICE_URL}/sector/report`,
            data,
            {
                responseType: 'blob',
            }
        );

        const pdfEncoding = response.data;

        const blob = new Blob([pdfEncoding], { type: 'application/pdf' });

        saveAs(blob, 'LobbyIQ-Sector');
    }
);

const sectorSlice = createSlice({
    name: 'sectorReducer',
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(fetchSectorUpdates.pending, (state) => {
                state.updates = null;
                state.updates_loading = true;
            })
            .addCase(fetchSectorUpdates.fulfilled, (state, action) => {
                state.updates_loading = false;
                state.updates_fail = false;
                state.updates = action.payload;
            })
            .addCase(fetchSectorUpdates.rejected, (state) => {
                state.updates_loading = false;
                state.updates_fail = true;
                state.updates = null;
            })
            .addCase(fetchSectorOverview.pending, (state) => {
                state.overview = null;
                state.overview_loading = true;
            })
            .addCase(fetchSectorOverview.fulfilled, (state, action) => {
                state.overview_loading = false;
                state.overview_fail = false;
                state.overview = action.payload;
            })
            .addCase(fetchSectorOverview.rejected, (state) => {
                state.overview_loading = false;
                state.overview_fail = true;
                state.overview = null;
            })
            .addCase(fetchSectorRecentLobbying.pending, (state) => {
                state.lobbying_activity_loading = true;
                state.lobbying_activity = null;
            })
            .addCase(fetchSectorRecentLobbying.fulfilled, (state, action) => {
                state.lobbying_activity_loading = false;
                state.lobbying_activity_fail = false;
                state.lobbying_activity = action.payload;
            })
            .addCase(fetchSectorRecentLobbying.rejected, (state) => {
                state.lobbying_activity_loading = false;
                state.lobbying_activity_fail = true;
                state.lobbying_activity = null;
            })
            .addCase(fetchSectorRegistrations.pending, (state) => {
                state.recent_reg_loading = true;
                state.recent_reg = null;
            })
            .addCase(fetchSectorRegistrations.fulfilled, (state, action) => {
                state.recent_reg_loading = false;
                state.recent_reg_fail = false;
                state.recent_reg = action.payload;
            })
            .addCase(fetchSectorRegistrations.rejected, (state) => {
                state.recent_reg_loading = false;
                state.recent_reg_fail = true;
                state.recent_reg = null;
            })
            .addCase(fetchSectorDeregistrations.pending, (state) => {
                state.recent_dereg_loading = true;
                state.recent_dereg = null;
            })
            .addCase(fetchSectorDeregistrations.fulfilled, (state, action) => {
                state.recent_dereg_loading = false;
                state.recent_dereg_fail = false;
                state.recent_dereg = action.payload;
            })
            .addCase(fetchSectorDeregistrations.rejected, (state) => {
                state.recent_dereg_loading = false;
                state.recent_dereg_fail = true;
                state.recent_dereg = null;
            })
            .addCase(fetchOrganizationCluster.pending, (state) => {
                state.org_cluster_loading = true;
                state.org_cluster = null;
            })
            .addCase(fetchOrganizationCluster.fulfilled, (state, action) => {
                state.org_cluster_loading = false;
                state.org_cluster_fail = false;
                state.org_cluster = action.payload;
            })
            .addCase(fetchOrganizationCluster.rejected, (state) => {
                state.org_cluster_loading = false;
                state.org_cluster_fail = true;
                state.org_cluster = null;
            })
            .addCase(fetchInstitutionCluster.pending, (state) => {
                state.inst_cluster_loading = true;
                state.inst_cluster = null;
            })
            .addCase(fetchInstitutionCluster.fulfilled, (state, action) => {
                state.inst_cluster_loading = false;
                state.inst_cluster_fail = false;
                state.inst_cluster = action.payload;
            })
            .addCase(fetchInstitutionCluster.rejected, (state) => {
                state.inst_cluster_loading = false;
                state.inst_cluster_fail = true;
                state.inst_cluster = null;
            })
            .addCase(fetchFirmCluster.pending, (state) => {
                state.firm_cluster_loading = true;
                state.firm_cluster = null;
            })
            .addCase(fetchFirmCluster.fulfilled, (state, action) => {
                state.firm_cluster_loading = false;
                state.firm_cluster_fail = false;
                state.firm_cluster = action.payload;
            })
            .addCase(fetchFirmCluster.rejected, (state) => {
                state.firm_cluster_loading = false;
                state.firm_cluster_fail = true;
                state.firm_cluster = null;
            })
            .addCase(fetchDPOHCluster.pending, (state) => {
                state.dpoh_cluster_loading = true;
                state.dpoh_cluster = null;
            })
            .addCase(fetchDPOHCluster.fulfilled, (state, action) => {
                state.dpoh_cluster_loading = false;
                state.dpoh_cluster_fail = false;
                state.dpoh_cluster = action.payload;
            })
            .addCase(fetchDPOHCluster.rejected, (state) => {
                state.dpoh_cluster_loading = false;
                state.dpoh_cluster_fail = true;
                state.dpoh_cluster = null;
            })
            .addCase(fetchSubjectCluster.pending, (state) => {
                state.subject_cluster_loading = true;
                state.subject_cluster = null;
            })
            .addCase(fetchSubjectCluster.fulfilled, (state, action) => {
                state.subject_cluster_loading = false;
                state.subject_cluster_fail = false;
                state.subject_cluster = action.payload;
            })
            .addCase(fetchSubjectCluster.rejected, (state) => {
                state.subject_cluster_loading = false;
                state.subject_cluster_fail = true;
                state.subject_cluster = null;
            })
            .addCase(fetchSectorTerms.pending, (state) => {
                state.terms_loading = true;
                state.terms = null;
            })
            .addCase(fetchSectorTerms.fulfilled, (state, action) => {
                state.terms_loading = false;
                state.terms_fail = false;
                state.terms = action.payload;
            })
            .addCase(fetchSectorTerms.rejected, (state) => {
                state.terms_loading = false;
                state.terms_fail = true;
                state.terms = null;
            })
            .addCase(exportSectorDashboard.pending, (state) => {
                state.export_loading = true;
            })
            .addCase(exportSectorDashboard.fulfilled, (state, action) => {
                state.export_loading = false;
                state.export_fail = false;
            })
            .addCase(exportSectorDashboard.rejected, (state) => {
                state.export_loading = false;
                state.export_fail = true;
            });
    },
});

export const selectSectorUpdates = (state: RootState) => state.sectorData.updates;
export const selectSectorUpdatesLoading = (state: RootState) => state.sectorData.updates_loading;

export const selectSectorOverview = (state: RootState) => state.sectorData.overview;
export const selectSectorOverviewLoading = (state: RootState) => state.sectorData.overview_loading;

export const selectSectorRecentLobbying = (state: RootState) => state.sectorData.lobbying_activity;
export const selectSectorRecentLobbyingLoading = (state: RootState) =>
    state.sectorData.lobbying_activity_loading;

export const selectSectorRegistrations = (state: RootState) => state.sectorData.recent_reg;
export const selectSectorRegistrationsLoading = (state: RootState) =>
    state.sectorData.recent_reg_loading;

export const selectSectorDeregistrations = (state: RootState) => state.sectorData.recent_dereg;
export const selectSectorDeregistrationsLoading = (state: RootState) =>
    state.sectorData.recent_dereg_loading;

export const selectOrganizationCluster = (state: RootState) => state.sectorData.org_cluster;
export const selectOrganizationClusterLoading = (state: RootState) =>
    state.sectorData.org_cluster_loading;

export const selectInstitutionCluster = (state: RootState) => state.sectorData.inst_cluster;
export const selectInstitutionClusterLoading = (state: RootState) =>
    state.sectorData.inst_cluster_loading;

export const selectFirmCluster = (state: RootState) => state.sectorData.firm_cluster;
export const selectFirmClusterLoading = (state: RootState) => state.sectorData.firm_cluster_loading;

export const selectDPOHCluster = (state: RootState) => state.sectorData.dpoh_cluster;
export const selectDPOHClusterLoading = (state: RootState) => state.sectorData.dpoh_cluster_loading;

export const selectSubjectCluster = (state: RootState) => state.sectorData.subject_cluster;
export const selectSubjectClusterLoading = (state: RootState) =>
    state.sectorData.subject_cluster_loading;

export const selectSectorTerms = (state: RootState) => state.sectorData.terms;
export const selectSectorTermsLoading = (state: RootState) => state.sectorData.terms_loading;

export const selectSectorExportLoading = (state: RootState) => state.sectorData.export_loading;
export const selectSectorExportFail = (state: RootState) => state.sectorData.export_fail;

export const selectSectorData = (state: RootState) => state.sectorData;

export default sectorSlice.reducer;
