export const DPOHCommunications = (
    <div className="flex flex-col gap-2">
        <div>
            A list of the 25 most recent communications targeting a DPOH, with a link to the
            underlying primary record on LobbyCanada.gc.ca
        </div>
        <div>
            The column “Prior Comms (36M)” counts the number of meetings between the client and DPOH
            over the last 36 months
        </div>
    </div>
);
