import axios from 'axios';
import moment from 'moment';
import { bumperSuperList } from '../../../interfaces/bumper-types.interface';
import {
    entityLobbyingActivity,
    entityOverviews,
    entityOverview,
    entityRegistration,
    entityOrg,
    entityCluster,
    entityOrgExtra,
    entityOrgTrail,
    entityOverviewRefined,
    keywordMonthRaw,
} from '../../../interfaces/generic-entity.interface';
import { percentage_change_num } from '../../../helpers/percentage_change';
import {
    DPOHLinkFormatter,
    consultantLinkFormatter,
    institutionLinkFormatter,
    lobbyFirmLinkFormatter,
    moverLinkFormatter,
    organizationLinkFormatter,
} from '../../../helpers/internal_routing';
import { recentUpdates } from '../../shared/interfaces/whats-new.interface';

// Cluster relevant API path names (item 1) and corresponding data object names (item 2)
const topCountAPIPath = {
    organizations: { path: 'toporganizations', data: 'subjectTopOrganizations' },
    sectors: {
        path: 'topsectors',
        data: 'subjectTopSectors',
    },
    institutions: { path: 'topinstitutions', data: 'subjectTopInstitutions' },
    lobbyfirms: { path: 'toplobbyfirms', data: 'subjectTopLobbyFirms' },
    dpoh: { path: 'topdpoh', data: 'subjectTopDPOH' },
};

const topMoversAPIPath = {
    organizations: { path: 'topmovers', data: 'subjectTopMovers' },
    sectors: {
        path: 'topmoverssectors',
        data: 'subjectTopMoversSectors',
    },
    institutions: {
        path: 'topmoversinstitutions',
        data: 'subjectTopMoversInstitutions',
    },
    lobbyfirms: {
        path: 'topmoverslobbyfirms',
        data: 'subjectTopMoversLobbyFirms',
    },
    dpoh: { path: 'topmoversdpoh', data: 'subjectTopMoversDPOH' },
};

const TTMAPIPath = {
    organizations: { path: 'org' },
    sectors: { path: 'sector' },
    institutions: { path: 'inst' },
    lobbyfirms: { path: 'firm' },
    dpoh: { path: 'dpoh' },
};

// Base URL for accessing the subject API
const baseURL = process.env.REACT_APP_API_BASE_URL;

const invertYearlyOverview = (overview: entityOverviewRefined[]) => {
    const params: (keyof entityOverviewRefined)[] = [
        'Lobbying Reports',
        'by Consultants',
        'of Ministers or DMs',
        'Organizations',
    ];

    const output: any = [];

    params.forEach((param: keyof entityOverviewRefined, idx: number) => {
        const param_data: any = { order: idx, count: param };
        overview.forEach((entry: entityOverviewRefined) => {
            param_data[String(entry.year)] = entry[param];
        });
        output.push(param_data);
    });

    return output;
};

// Fetch an overview of a given subject
const fetchUpdates = async (code: string | undefined, date: string) => {
    const response = await axios.get(`${baseURL}/subject/${code}/updates?date=${date}`);
    const data: recentUpdates = response.data;
    return data;
};

// Fetch an overview of a given subject
const fetchOverview = async (code: string | undefined, date: string) => {
    const year = date?.split('-')[0];
    const month = date?.split('-')[1];

    const yearly_response = await axios.get(`${baseURL}/subject/${code}/overview?monthly=false`);
    const yearly_data: entityOverviewRefined[] = yearly_response.data.overview
        .filter((entry: any) => +entry.year <= +year)
        .map((entry: entityOverview) => {
            return {
                ...entry,
                'Lobbying Reports': entry.count,
                'by Consultants': entry.external,
                'of Ministers or DMs': entry.ministers,
                Organizations: entry.organizations,
            };
        });

    const inverted_yearly = invertYearlyOverview(yearly_data);

    const monthly_response = await axios.get(`${baseURL}/subject/${code}/overview?monthly=true`);

    const monthly_data: entityOverview[] = monthly_response.data.overview
        .filter((entry: any) => {
            return (
                +[entry.year, (entry.month + '').padStart(2, '0')].join('') <=
                +[year, month].join('')
            );
        })
        .slice(0, 18);

    const overview: entityOverviews = {
        yearly: inverted_yearly,
        monthly: monthly_data,
    };

    return overview;
};

// Fetch recent lobbying communications in a given subject
const fetchRecentLobbyingActivity = async (code: string, date: string | undefined) => {
    const response = await axios.get(`${baseURL}/subject/${code}/activity?limit=25&date=${date}`);
    const data: entityLobbyingActivity[] = response.data.subjectActivity;
    const converted_data: entityLobbyingActivity[] = data.map((entry) => {
        return {
            ...entry,
            'Prior Comms (36M)': entry.three_years_prior,
            date: moment.utc(entry.date).format('YYYY-MM-DD'),
            organization:
                entry.sector_id && entry.sector
                    ? organizationLinkFormatter(entry.sector_id, entry.sector, entry.client)
                    : entry.client,
            dpoh:
                entry.institution_id && entry.institution
                    ? DPOHLinkFormatter(entry.institution_id, entry.institution, entry.dpoh)
                    : entry.dpoh,
            institution:
                entry.institution_id && entry.institution
                    ? institutionLinkFormatter(entry.institution_id, entry.institution)
                    : entry.institution,
            lobby_firm:
                entry.lobby_firm === null || entry.consultant === null
                    ? 'In-House'
                    : lobbyFirmLinkFormatter(entry.lobby_firm),
            consultant:
                entry.lobby_firm === null || entry.consultant === null
                    ? 'In-House'
                    : consultantLinkFormatter(entry.lobby_firm, entry.consultant),
            link: `https://lobbycanada.gc.ca/app/secure/ocl/lrs/do/cmmLgPblcVw?comlogId=${entry.comm}`,
        };
    });
    return converted_data;
};

// Fetch recent registrations in a given subject
const fetchRecentRegistrations = async (
    code: string,
    date: string | undefined
): Promise<entityRegistration[]> => {
    const response = await axios.get(
        `${baseURL}/subject/${code}/recentregistrations?limit=25&updated=true&new=true&date=${date}`
    );
    const data: entityRegistration[] = response.data.recentRegistrations;
    const converted_data: entityRegistration[] = data.map((entry) => {
        return {
            ...entry,
            date: moment.utc(entry.date).format('YYYY-MM-DD'),
            organization:
                entry.sector_id && entry.sector
                    ? organizationLinkFormatter(entry.sector_id, entry.sector, entry.client)
                    : entry.client,
            lobby_firm:
                entry.lobby_firm === null || entry.consultant === null
                    ? 'In-House'
                    : lobbyFirmLinkFormatter(entry.lobby_firm),
            consultant:
                entry.lobby_firm === null || entry.consultant === null
                    ? 'In-House'
                    : consultantLinkFormatter(entry.lobby_firm, entry.consultant),
            history: entry.history ? 'Renewed' : 'New',
            link: `https://lobbycanada.gc.ca/app/secure/ocl/lrs/do/vwRg?cno=${entry.corp}&regId=${entry.registration}#regStart`,
            dashboard: `link:/registrations/${entry.registration}`,
        };
    });
    return converted_data;
};

// Fetch recent deregistrations in a given subject
const fetchRecentDeregistrations = async (
    code: string,
    date: string | undefined
): Promise<entityRegistration[]> => {
    const response = await axios.get(
        `${baseURL}/subject/${code}/recentderegistrations?limit=25&date=${date}`
    );
    const data: entityRegistration[] = response.data.recentDeregistrations;
    const converted_data: entityRegistration[] = data.map((entry) => {
        return {
            ...entry,
            date: moment.utc(entry.date).format('YYYY-MM-DD'),
            organization:
                entry.sector_id && entry.sector
                    ? organizationLinkFormatter(entry.sector_id, entry.sector, entry.client)
                    : entry.client,
            lobby_firm:
                entry.lobby_firm === null || entry.consultant === null
                    ? 'In-House'
                    : lobbyFirmLinkFormatter(entry.lobby_firm),
            consultant:
                entry.lobby_firm === null || entry.consultant === null
                    ? 'In-House'
                    : consultantLinkFormatter(entry.lobby_firm, entry.consultant),
            link: `https://lobbycanada.gc.ca/app/secure/ocl/lrs/do/vwRg?regId=${entry.registration}&cno=${entry.corp}#regStart`,
            dashboard: `link:/registrations/${entry.registration}`,
        };
    });
    return converted_data;
};

// Fetch a sector data cluster of the given subject, under a specific "type"
const fetchCluster = async (
    code: string | undefined,
    type: 'organizations' | 'institutions' | 'lobbyfirms' | 'dpoh' | 'sectors',
    date: string | undefined
): Promise<entityCluster> => {
    // Fetch top 6 *type* within the given subject (determined by YTD communication count)
    const top_count_response = await axios.get(
        `${baseURL}/subject/${code}/${topCountAPIPath[type].path}?date=${date}`
    );

    const top_count_data: entityOrg[] =
        // name of data object varies by endpoint, we don't need to know its precise name this way
        top_count_response.data[topCountAPIPath[type].data];

    // Count the total number of comms for orgs outside of the top 6 ytd
    var other_count = 0;
    if (top_count_data.length > 6)
        other_count = top_count_data
            .slice(6)
            .map((item) => item.count)
            .reduce((sum, next) => sum + next);

    const top_six_ytd = top_count_data.slice(0, 6);
    top_six_ytd.push({ name: 'Other', code: 'Other', count: other_count });

    // Get the top 1-4 orgs from the top YTD orgs
    // These will be used for mini bar charts with trailing 12 months of lobbying displayed
    const top_four: entityOrgTrail[] = top_count_data.slice(0, 4).map((entry) => {
        return {
            ...entry,
            ttm: [],
        };
    });

    // Add a trailing twelve months array to the top 1-4 orgs
    await Promise.all(
        top_four.map(async (entry): Promise<void> => {
            const response = await axios.get(
                `${baseURL}/${TTMAPIPath[type].path}/${encodeURIComponent(
                    entry.code
                )}/ttmcount/subject/${code}?date=${date}`
            );
            // Modify the object with the API response
            entry.ttm = response.data.data;
        })
    );

    // Fetch top 10 mover *type* within the given subject
    const top_movers_response = await axios.get(
        `${baseURL}/subject/${code}/${topMoversAPIPath[type].path}?limit=25&date=${date}`
    );
    const top_movers_data: entityOrg[] = top_movers_response.data.topMovers;

    // Take top movers and add a percentage change + empty trialing twelve month array
    // Sort in descending order
    const converted_movers_data: entityOrgExtra[] = top_movers_data.map((entry) => {
        return {
            ...entry,
            name: moverLinkFormatter(entry, type),
            ...(type === 'dpoh' &&
                entry.institution_id &&
                entry.institution && {
                    institution: institutionLinkFormatter(+entry.institution_id, entry.institution),
                }),
            // Calculate with potential nulls replaced by 0
            change_rel: percentage_change_num(entry.tma || 0, entry.oma || 0),
            change_abs: (entry.oma || 0) - (entry.tma || 0),
            // long decimals returned as string by DB, cast to number
            avg: +(entry.avg || 0),
            deviation: +(entry.deviation || 0),
        };
    });

    const cluster: entityCluster = {
        top_movers: converted_movers_data,
        top_orgs: top_six_ytd,
        top_four: top_four,
        movers_meta: top_movers_response.data.movers_meta,
        isInstitutionType: false,
    };

    return cluster;
};

// Fetch keywords from registrations in the given subject
const fetchTerms = async (code: string | undefined, date: string | undefined) => {
    const keyword_response = await axios.get(
        `${baseURL}/sector/${code}/terms?limit=6&date=${date}`
    );
    const keyword_data: keywordMonthRaw[] = keyword_response.data.data;
    const keyword_list: bumperSuperList[] = keyword_data.map((entry: keywordMonthRaw) => {
        const year: string = String(moment(entry.date).year());
        const month_str: string = moment(entry.date).format('MMMM');
        return {
            title: `${month_str}, ${year}`,
            lists: entry.datasets,
        };
    });

    return keyword_list;
};

export const subjectAPI = {
    fetchUpdates,
    fetchOverview,
    fetchRecentLobbyingActivity,
    fetchRecentRegistrations,
    fetchRecentDeregistrations,
    fetchCluster,
    fetchTerms,
};
