import { useEffect, useState } from 'react';
import {
    entityOrg,
    entityOverviews,
    entityRegistration,
} from '../../../interfaces/generic-entity.interface';
import { Alert, Breadcrumbs, Button, Grow, Slide, Snackbar } from '@mui/material';
import CalendarMonthRoundedIcon from '@mui/icons-material/CalendarMonthRounded';
import DashTable from '../../../components/generic-dashboards/DashTable';
import TwoBreakdowns from '../../shared/UI/TwoBreakdowns';
import DateSelection from '../../../components/misc/DateSelection';
import { Link, useParams } from 'react-router-dom';
import LoadingTable from '../../../components/generic-dashboards/loading/LoadingTable';
import { MissingData } from '../../../components/misc/MissingData';
import { RegistrationActivityCombo } from '../../../interfaces/organization-entity.interface';
import Overview from '../../../components/generic-dashboards/Overview';
import { RegistrationOverview } from '../../../tooltips/regestration/RegistrationOverview';
import { useSelector } from 'react-redux';
import { GATrackDashboardLoad, EVENT_TYPES } from '../../../google_analytics/TrackEvent';
import { selectEmail, selectUserProductType } from '../../../store/reducers/userSlice';
import { DashboardDownloadModal } from '../../../components/misc/DashboardDownloadModal';
import SimCardDownloadRoundedIcon from '@mui/icons-material/SimCardDownloadRounded';

const RegistrationDashboard = ({
    date,
    handleDateChange,
    registrationOverview,
    registrationOverviewLoading = false,
    registrationOverviewHeaders = [],
    registrationOverviewIndent = [],
    registrationInfo,
    registrationInfoLoading = false,
    activity,
    activityLoading = false,
    topInstitutions,
    topInstitutionsLoading = false,
    topSubjects,
    topSubjectsLoading = false,
    exportLoading = false,
    exportFail = false,
}: {
    date: string;
    handleDateChange: (x: string) => void;
    registrationOverview: entityOverviews | null;
    registrationOverviewLoading: boolean;
    registrationOverviewHeaders: string[];
    registrationOverviewIndent: Array<string>;
    registrationInfo: entityRegistration | null;
    registrationInfoLoading: boolean;
    activity: RegistrationActivityCombo | null;
    activityLoading: boolean;
    topInstitutions: entityOrg[] | null;
    topInstitutionsLoading: boolean;
    topSubjects: entityOrg[] | null;
    topSubjectsLoading: boolean;
    exportLoading: boolean;
    exportFail: boolean;
}) => {
    const [scrollPosition, setScrollPosition] = useState(0);

    // Get the id of the current registration
    const url_params = useParams();
    const reg_id: number = url_params.regId !== undefined ? Number(url_params.regId) : 0;

    const userEmail = useSelector(selectEmail);
    const userProductType = useSelector(selectUserProductType);

    useEffect(() => {
        GATrackDashboardLoad(
            'Registration',
            `${'Registration: ' + reg_id}`,
            EVENT_TYPES.PAGE_LOAD,
            userEmail ? userEmail : undefined,
            userProductType ? userProductType : undefined
        );
        const handleScroll = () => {
            const scrollPosition = window.scrollY;
            setScrollPosition(scrollPosition);
        };
        window.addEventListener('scroll', handleScroll);
        // Cleanup the event listener when the component is unmounted
        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, [reg_id, userEmail, userProductType]);

    const [dateOpen, setDateOpen] = useState<boolean>(false);
    const [dateSet, setDateSet] = useState<boolean>(false);

    const [exportModalOpen, setExportModalOpen] = useState<boolean>(false);

    const handleDateOpen = () => setDateOpen(true);
    const handleDateClose = () => {
        setDateOpen(false);
        setDateSet(true);
    };

    return (
        <div className="py-32 lg:px-20 px-4 grid grid-cols-12">
            <Snackbar
                anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
                open={dateSet}
                onClose={() => setDateSet(false)}
                autoHideDuration={3000}
                message="Date Set"
            >
                <Alert onClose={() => setDateSet(false)} severity="success">
                    Date set to {date}
                </Alert>
            </Snackbar>

            <Slide direction="left" in={scrollPosition >= 50} mountOnEnter unmountOnExit>
                <div className="fixed z-20 bottom-36 right-5 gap-2 lg:top-20 lg:right-4 flex flex-col h-0">
                    <Button
                        className="group flex"
                        onClick={handleDateOpen}
                        variant="contained"
                        startIcon={<CalendarMonthRoundedIcon />}
                    >
                        {date}
                    </Button>
                    <Button
                        variant="contained"
                        startIcon={<SimCardDownloadRoundedIcon />}
                        onClick={() => setExportModalOpen(true)}
                    >
                        Save PDF
                    </Button>
                </div>
            </Slide>

            <DateSelection
                open={dateOpen}
                handleClose={handleDateClose}
                handleDateChange={handleDateChange}
                views={['year', 'month', 'day']}
            />

            <div className=" col-start-1 col-end-13 flex flex-row justify-between">
                <div>
                    <Breadcrumbs className="col-start-2 lg:col-end-12 col-end-12" separator=">">
                        <Link to="/" className="hover:underline">
                            Lobby<b>IQ</b>
                        </Link>
                        <div>Registrations</div>
                        <div>{reg_id}</div>
                    </Breadcrumbs>
                </div>
                <Slide direction="left" in={true}>
                    <div className="lg:flex hidden flex-row gap-4">
                        <Button
                            onClick={handleDateOpen}
                            variant="outlined"
                            startIcon={<CalendarMonthRoundedIcon />}
                        >
                            Data From: {date}
                        </Button>
                        <Button
                            variant="outlined"
                            startIcon={<SimCardDownloadRoundedIcon />}
                            onClick={() => setExportModalOpen(true)}
                        >
                            Save PDF
                        </Button>
                    </div>
                </Slide>
            </div>

            <DashboardDownloadModal
                title={`Registration ${registrationInfo?.registration}` || 'Dashboard'}
                type={'registration'}
                modalOpen={exportModalOpen}
                handleClose={() => setExportModalOpen(false)}
                registrationInfo={registrationInfo}
                overview={registrationOverview}
                recentLobbying={activity?.activity}
                registrationDpohActivity={activity?.dpoh_activity}
                twoBreakdowns={
                    topInstitutions && topSubjects
                        ? { cluster_1: topInstitutions, cluster_2: topSubjects }
                        : null
                }
                exportLoading={exportLoading}
                exportFail={exportFail}
            />

            <div className="mt-4 col-start-1 col-end-13">
                {registrationInfoLoading ? (
                    <LoadingTable />
                ) : !registrationInfoLoading && !registrationInfo ? (
                    <MissingData />
                ) : registrationInfo ? (
                    <Grow in={true}>
                        <div
                            className={`bg-white p-3 flex lg:flex-row flex-col justify-between lg:items-center w-full pr-2 shadow-md rounded-md border-r-4 ${
                                registrationInfo.history === 'Renewed'
                                    ? 'border-liq-gray-blue'
                                    : 'border-liq-green'
                            }`}
                        >
                            <div className="flex flex-col">
                                <div className="text-lg font-medium">{registrationInfo.client}</div>

                                <div className="text-md font-normal">
                                    {registrationInfo.lobby_firm}
                                </div>
                                {registrationInfo.consultant !== 'In-House' &&
                                    registrationInfo.consultant}
                                <a
                                    href={registrationInfo.link}
                                    target="_blank"
                                    rel="noreferrer"
                                    className="text-blue-500 hover:underline"
                                >
                                    Registration - {registrationInfo.registration}
                                </a>
                            </div>
                            <div className="flex flex-col items-end">
                                {registrationInfo.history === 'Renewed' ? (
                                    <span className="text-liq-gray-blue font-bold">
                                        {registrationInfo.history}
                                    </span>
                                ) : (
                                    <span className="text-liq-green font-bold">
                                        {registrationInfo.history}
                                    </span>
                                )}
                                <div className="text-slate-600">{registrationInfo.date}</div>
                            </div>
                        </div>
                    </Grow>
                ) : (
                    <></>
                )}
            </div>

            <div className="mt-12 col-start-1 col-end-13">
                <Overview
                    dashboard={'Registration'}
                    topic={'Registration: ' + reg_id}
                    title={'Registration Overview'}
                    subtitle={'Registration: ' + reg_id}
                    tooltipContent={RegistrationOverview}
                    barChartTitle="Lobbying Communications (18 months)"
                    primaryColor="rgb(14 116 144)"
                    secondaryColor="rgb(6 182 212)"
                    overviews={registrationOverview}
                    loading={registrationOverviewLoading}
                    tableHeader={registrationOverviewHeaders}
                    overviewIndent={registrationOverviewIndent}
                />
            </div>

            <div className="mt-12 col-start-1 col-end-13">
                <DashTable
                    dashboard="Registration"
                    topic={'Registration: ' + reg_id}
                    title="Recent Lobbying Communications"
                    subtitle="Past 12 Months"
                    tableData={activity?.activity}
                    loading={activityLoading}
                    tableHeader={[
                        'date',
                        'dpoh',
                        'title',
                        'institution',
                        'lobby_firm',
                        'consultant',
                        'Prior Comms (36M)',
                        'link',
                    ]}
                />
            </div>

            <div className="mt-16 col-start-1 col-end-13">
                <DashTable
                    dashboard="Registration"
                    topic={'Registration: ' + reg_id}
                    title="DPOH's Lobbied"
                    subtitle="Past 12 Months"
                    tableData={activity?.dpoh_activity}
                    loading={activityLoading}
                    tableHeader={[
                        'name',
                        'title',
                        'institution',
                        'prior_comms_(12M)',
                        'percent_comms_(12M)',
                    ]}
                    suffixes={{ 'percent_comms_(12M)': '%' }}
                    defaultSort="percent_comms_(12M)"
                />
            </div>

            <div className="mt-16 col-start-1 col-end-13">
                <TwoBreakdowns
                    title="Lobbying Communications"
                    subtitle="Past 12 Months"
                    breakdownData1={topInstitutions}
                    loading1={topInstitutionsLoading}
                    breakdownData2={topSubjects}
                    loading2={topSubjectsLoading}
                />
            </div>
        </div>
    );
};

export default RegistrationDashboard;
