import React, { useEffect } from 'react';
import {
    fetchInstitutionList,
    selectInstitutionList,
} from '../../store/reducers/institutionListSlice';
import { useSelector } from 'react-redux';
import { entityListExtra } from '../../interfaces/generic-entity.interface';
import { useAppDispatch } from '../../store/store';
import EntityListPage from './EntityListPage';
import { fetchInstitutionsSelections, selectChosenInstitutions } from '../../store/reducers/customSelectionSlice';

const InstitutionListDataLayer = () => {
    const dispatch = useAppDispatch();

    const institutionSelections = useSelector(selectChosenInstitutions);
    const entity_list: entityListExtra[] | null = useSelector(
        selectInstitutionList
    );

    useEffect(() => {
        dispatch(fetchInstitutionList());
        dispatch(fetchInstitutionsSelections());
    }, [dispatch]);

    return <EntityListPage type="institutions" list={entity_list || []} selections={institutionSelections} />;
};

export default InstitutionListDataLayer;
