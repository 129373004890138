import React from 'react';
import { Breadcrumbs } from '@mui/material';
import { Link } from 'react-router-dom';
import { replace_underscores_capitalize } from '../../helpers/string_formatters';

interface CatalogueBreadcrumbProps {
    catalogueType: string;
    catalogueParentCode: string;
    catalogueParentName: string;
}

const EntityCatalogueBreadcrumbs = ({
    catalogueType,
    catalogueParentCode,
    catalogueParentName,
}: CatalogueBreadcrumbProps) => {
    return (
        <Breadcrumbs className="col-start-2 col-end-12" separator=">">
            <Link to="/" className="hover:underline">
                Lobby<b>IQ</b>
            </Link>
            {catalogueType === 'organizations' && (
                <Link to={`/sectors`} className="hover:underline">
                    Sectors
                </Link>
            )}
            {catalogueType === 'organizations' && (
                <Link
                    to={`/sectors/${catalogueParentCode}?name=${catalogueParentName}`}
                    className="hover:underline"
                >
                    {catalogueParentName}
                </Link>
            )}
            {catalogueType === 'inactive organizations' && (
                <Link to={`/sectors`} className="hover:underline">
                    Sectors
                </Link>
            )}
            {catalogueType === 'inactive organizations' && (
                <Link
                    to={`/sectors/${catalogueParentCode}?name=${catalogueParentName}`}
                    className="hover:underline"
                >
                    {catalogueParentName}
                </Link>
            )}
            {catalogueType === 'consultants' && (
                <Link to={`/firms`} className="hover:underline">
                    Firms
                </Link>
            )}
            {catalogueType === 'consultants' && (
                <Link
                    to={`/firms/${catalogueParentCode}?name=${catalogueParentName}`}
                    className="hover:underline"
                >
                    {catalogueParentName}
                </Link>
            )}
            {catalogueType === 'issues' && catalogueParentName !== '' && (
                <Link to={`/subjects`} className="hover:underline">
                    Subjects
                </Link>
            )}
            {catalogueType === 'issues' && catalogueParentName !== '' && (
                <Link
                    to={`/subjects/${catalogueParentCode}?name=${catalogueParentName}`}
                    className="hover:underline"
                >
                    {catalogueParentName}
                </Link>
            )}
            {catalogueType === 'DPOHs' && (
                <Link to={`/institutions`} className="hover:underline">
                    Institutions
                </Link>
            )}
            {catalogueType === 'DPOHs' && (
                <Link
                    to={`/institutions/${catalogueParentCode}?name=${catalogueParentName}`}
                    className="hover:underline"
                >
                    {catalogueParentName}
                </Link>
            )}
            {catalogueType === 'MPs' && (
                <Link to={`/committees`} className="hover:underline">
                    Parliamentary Committees
                </Link>
            )}
            {catalogueType === 'MPs' && (
                <Link
                    to={`/committees/${catalogueParentCode}?name=${catalogueParentName}`}
                    className="hover:underline"
                >
                    {catalogueParentName}
                </Link>
            )}
            <div>{replace_underscores_capitalize(catalogueType)}</div>
        </Breadcrumbs>
    );
};

export default EntityCatalogueBreadcrumbs;
