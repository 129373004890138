import React from 'react';
import { Link } from 'react-router-dom';

// TODO add checks for proper string format
const LinkFormatter = ({ urlFormatStr }: { urlFormatStr: string }) => {
    return (
        <Link
            className="text-sky-600 hover:underline underline-offset-2"
            to={urlFormatStr.split('#')[2]}
            target="_blank"
            rel="noreferrer"
        >
            {urlFormatStr.split('#')[1]}
        </Link>
    );
};

export default LinkFormatter;
