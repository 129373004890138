import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';
import { RootState } from '../store';
import {
    entityList,
    entityListExtra,
} from '../../interfaces/generic-entity.interface';
import { percentage_change_num } from '../../helpers/percentage_change';

interface firmListState {
    firm_list: entityListExtra[] | null;
    firm_list_loading: boolean;

    consultant_list: entityListExtra[] | null;
    consultant_list_loading: boolean;
}

const initialState: firmListState = {
    firm_list: null,
    firm_list_loading: false,

    consultant_list: null,
    consultant_list_loading: false,
};

const baseURL = process.env.REACT_APP_API_BASE_URL;

export const fetchFirmList = createAsyncThunk(
    'firm/fetchList',
    async (): Promise<entityListExtra[]> => {
        const response = await axios.get(`${baseURL}/firm/list`);
        const data: entityList[] = response.data.firmList;
        const converted_data: entityListExtra[] = data.map((entry) => {
            return {
                ...entry,
                // Calculate with potential nulls replaced by 0
                change_abs:
                    (entry.last_month || 0) - (entry.two_months_ago || 0),
                change_rel: percentage_change_num(
                    entry.two_months_ago || 0,
                    entry.last_month || 0
                ),
                num_active_registrations: +entry.num_active_registrations,
                available: true,
            };
        });
        return converted_data;
    }
);

export const fetchConsultantList = createAsyncThunk(
    'firm/fetchConsultantList',
    async ({
        code,
    }: {
        code: string | undefined;
    }): Promise<entityListExtra[]> => {
        const response = await axios.get(`${baseURL}/consultant/${code}/list`);
        const data: entityList[] = response.data.consultants;
        const converted_data: entityListExtra[] = data.map((entry) => {
            return {
                ...entry,
                // Calculate with potential nulls replaced by 0
                change_abs:
                    (entry.last_month || 0) - (entry.two_months_ago || 0),
                change_rel: percentage_change_num(
                    entry.two_months_ago || 0,
                    entry.last_month || 0
                ),
                available: true,
            };
        });
        return converted_data;
    }
);

const lobbyFirmListSlice = createSlice({
    name: 'firmListReducer',
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(fetchFirmList.pending, (state) => {
                state.firm_list_loading = true;
            })
            .addCase(fetchFirmList.fulfilled, (state, action) => {
                state.firm_list_loading = false;
                state.firm_list = action.payload;
            })
            .addCase(fetchFirmList.rejected, (state) => {
                state.firm_list_loading = false;
                state.firm_list = null;
            })
            .addCase(fetchConsultantList.pending, (state) => {
                state.consultant_list_loading = true;
            })
            .addCase(fetchConsultantList.fulfilled, (state, action) => {
                state.consultant_list_loading = false;
                state.consultant_list = action.payload;
            })
            .addCase(fetchConsultantList.rejected, (state) => {
                state.consultant_list_loading = false;
                state.consultant_list = null;
            });
    },
});

export const selectFirmList = (state: RootState) => state.firmList.firm_list;
export const selectFirmListLoading = (state: RootState) =>
    state.firmList.firm_list_loading;

export const selectConsultantList = (state: RootState) =>
    state.firmList.consultant_list;
export const selectConsultantLoading = (state: RootState) =>
    state.firmList.consultant_list_loading;

export default lobbyFirmListSlice.reducer;
