import React, { useCallback, useState } from 'react';
import { useSelector } from 'react-redux';
import {
    selectQueryCommResults,
    selectQueryCommResultsFail,
    selectQueryCommResultsLoading,
    selectQueryRegResults,
    selectQueryRegResultsFail,
    selectQueryRegResultsLoading,
    updateRowsDownloaded,
} from '../../store/reducers/querySlice';
import Table from '../generic-dashboards/Table';
import { Button, Fade, Grow } from '@mui/material';
import SimCardDownloadIcon from '@mui/icons-material/SimCardDownload';
import LoadingTable from '../generic-dashboards/loading/LoadingTable';
import { CSVLink } from 'react-csv';
import { replace_underscores_capitalize } from '../../helpers/string_formatters';
import { selectRowCount, selectUserPermissions } from '../../store/reducers/userSlice';
import { useAppDispatch } from '../../store/store';

const QueryResults = ({ type }: { type: 'communications' | 'registrations' }) => {
    const dispatch = useAppDispatch();

    const comm_results: any[] | null = useSelector(selectQueryCommResults);
    const comm_results_loading: boolean = useSelector(selectQueryCommResultsLoading);
    const comm_results_fail: boolean = useSelector(selectQueryCommResultsFail);

    const reg_results: any[] | null = useSelector(selectQueryRegResults);
    const reg_results_loading: boolean = useSelector(selectQueryRegResultsLoading);
    const reg_results_fail: boolean = useSelector(selectQueryRegResultsFail);

    const remainingRows = useSelector(selectRowCount);
    const permissions = useSelector(selectUserPermissions);

    const [displayedRows, setDisplayedRows] = useState<number>(5);
    const [resultsPage, setResultsPage] = useState<number>(0);
    const [sortedData, setSortedData] = useState<any[] | null>(comm_results || reg_results);

    const getCSVHeaders = () => {
        if (type === 'communications') {
            return [
                {
                    label: 'Date',
                    key: 'date',
                },
                {
                    label: 'Organization',
                    key: 'organization',
                },
                {
                    label: 'DPOH',
                    key: 'dpoh',
                },
                {
                    label: 'Institution',
                    key: 'institution',
                },
                {
                    label: 'Lobby Firm',
                    key: 'lobby_firm',
                },
                {
                    label: 'Consultant',
                    key: 'consultant',
                },
            ];
        } else {
            return [
                {
                    label: 'Date',
                    key: 'date',
                },
                {
                    label: 'Organization',
                    key: 'organization',
                },
                {
                    label: 'Lobby Firm',
                    key: 'lobby_firm',
                },
                {
                    label: 'Consultant',
                    key: 'consultant',
                },
                {
                    label: 'History',
                    key: 'history',
                },
            ];
        }
    };

    const updateRemainingDownloads = () => {
        const startIndex = resultsPage * displayedRows;
        const endIndex = startIndex + displayedRows;
        const rowsToDownload = Math.min(endIndex, sortedData!.length) - startIndex;

        dispatch(updateRowsDownloaded(rowsToDownload));
    };

    const getDownloadableData = useCallback(() => {
        const startIndex = resultsPage * displayedRows;
        const endIndex = startIndex + displayedRows;
        const desiredNumRows = endIndex - startIndex;

        if (remainingRows && sortedData) {
            if (remainingRows >= desiredNumRows) {
                return sortedData.slice(startIndex, Math.min(endIndex, sortedData.length));
            } else {
                return sortedData.slice(startIndex, startIndex + remainingRows);
            }
        } else {
            return [];
        }
    }, [resultsPage, displayedRows, remainingRows, sortedData]);

    const exportButton = (data: any[]) => (
        <div className="mt-5">
            <span>
                {`Rows downloaded: ${
                    remainingRows
                        ? (permissions?.maxRowsDownload ?? 0) - remainingRows
                        : permissions?.maxRowsDownload
                } of ${permissions?.maxRowsDownload ?? 0}`}
            </span>
            <CSVLink
                filename={'CommunicationResults.csv'}
                headers={getCSVHeaders()}
                data={getDownloadableData()}
                onClick={() => {
                    if (data.length === 0 || !remainingRows || !sortedData) {
                        return false;
                    }
                }}
                style={{ cursor: 'auto' }}
            >
                <Button
                    variant="contained"
                    disableElevation
                    fullWidth
                    disabled={data.length === 0 || !remainingRows || !sortedData}
                    endIcon={<SimCardDownloadIcon />}
                    onClick={updateRemainingDownloads}
                    sx={{
                        marginTop: '15px',
                        backgroundColor: '#15803d',
                        '&:hover': { backgroundColor: '#166534' },
                    }}
                >
                    Download as CSV
                </Button>
            </CSVLink>
        </div>
    );

    return (
        <Fade in={true}>
            <div>
                <div className="flex lg:flex-row flex-col justify-between">
                    <div className="text-xl">{`${replace_underscores_capitalize(
                        type.slice(0, -1)
                    )} Query Results`}</div>
                </div>
                <hr className="h-0.5 my-3 bg-slate-100 rounded" />
                {type === 'communications' ? (
                    comm_results_loading ? (
                        <LoadingTable />
                    ) : !comm_results_loading && !comm_results ? (
                        comm_results_fail ? (
                            <div>Daily query limit reached</div>
                        ) : (
                            <div>No data to display</div>
                        )
                    ) : (
                        comm_results && (
                            <Grow in={true}>
                                <div>
                                    <Table
                                        alignment={'left'}
                                        paginated={true}
                                        defaultSort={'date'}
                                        rowData={comm_results || []}
                                        defaultNumRows={5}
                                        externalSpyRowsPerPage={setDisplayedRows}
                                        externalSpyPage={setResultsPage}
                                        externalSpySortedData={setSortedData}
                                        rowHeaders={[
                                            'date',
                                            'organization',
                                            'dpoh',
                                            'institution',
                                            'lobby_firm',
                                            'consultant',
                                            'link',
                                        ]}
                                    />

                                    {exportButton(comm_results!)}
                                </div>
                            </Grow>
                        )
                    )
                ) : reg_results_loading ? (
                    <LoadingTable />
                ) : !reg_results_loading && !reg_results ? (
                    reg_results_fail ? (
                        <div>Daily query limit reached</div>
                    ) : (
                        <div>No data to display</div>
                    )
                ) : (
                    reg_results && (
                        <Grow in={true}>
                            <div>
                                <Table
                                    alignment={'left'}
                                    paginated={true}
                                    defaultSort={'date'}
                                    rowData={reg_results || []}
                                    defaultNumRows={5}
                                    externalSpyRowsPerPage={setDisplayedRows}
                                    externalSpyPage={setResultsPage}
                                    externalSpySortedData={setSortedData}
                                    rowHeaders={[
                                        'date',
                                        'organization',
                                        'lobby_firm',
                                        'consultant',
                                        'history',
                                        'link',
                                    ]}
                                />

                                {exportButton(reg_results!)}
                            </div>
                        </Grow>
                    )
                )}
            </div>
        </Fade>
    );
};
export default QueryResults;
