import axios from 'axios';
import moment from 'moment';
import { bumperSuperList } from '../../../interfaces/bumper-types.interface';
import {
    entityLobbyingActivity,
    entityOverviews,
    entityOverview,
    entityOrg,
    entityCluster,
    entityOrgExtra,
    entityOrgTrail,
    entityOverviewRefined,
    keywordMonthRaw,
} from '../../../interfaces/generic-entity.interface';
import { percentage_change_num } from '../../../helpers/percentage_change';
import { committeeMember } from '../interfaces/committee-entity.interface';
import {
    DPOHLinkFormatter,
    consultantLinkFormatter,
    institutionLinkFormatter,
    lobbyFirmLinkFormatter,
    moverLinkFormatter,
    organizationLinkFormatter,
    sectorLinkFormatter,
} from '../../../helpers/internal_routing';
import { committeeMeetingLinkFormatter } from '../../../helpers/external_routing';

// Cluster relevant API path names (item 1) and corresponding data object names (item 2)
const topCountAPIPath = {
    organizations: {
        path: 'toporganizations',
        data: 'committeeTopOrganizations',
    },
    sectors: {
        path: 'topsectors',
        data: 'committeeTopSectors',
    },
    lobbyfirms: { path: 'toplobbyfirms', data: 'committeeTopLobbyFirms' },
    dpoh: { path: 'topdpoh', data: 'committeeTopDPOH' },
    subject: { path: 'topsubject', data: 'committeeTopSubject' },
};

const topMoversAPIPath = {
    organizations: { path: 'topmovers', data: 'committeeTopMovers' },
    sectors: {
        path: 'topmoverssectors',
        data: 'committeeTopMoversSectors',
    },
    lobbyfirms: {
        path: 'topmoverslobbyfirms',
        data: 'committeeTopMoversLobbyFirms',
    },
    dpoh: { path: 'topmoversdpoh', data: 'committeeTopMoversDPOH' },
    subject: { path: 'topmoverssubject', data: 'committeeTopMoversSubject' },
};

const TTMAPIPath = {
    organizations: { path: 'org' },
    sectors: { path: 'sector' },
    lobbyfirms: { path: 'firm' },
    dpoh: { path: 'dpoh' },
    subject: { path: 'subject' },
};

// Base URL for accessing the committee API
const baseURL = process.env.REACT_APP_API_BASE_URL;

const invertYearlyOverview = (overview: entityOverviewRefined[]) => {
    const params: (keyof entityOverviewRefined)[] = [
        'Lobbying Reports',
        'by Consultants',
        'Organizations',
        'Sectors',
    ];

    const output: any = [];

    params.forEach((param: keyof entityOverviewRefined, idx: number) => {
        const param_data: any = { order: idx, count: param };
        overview.forEach((entry: entityOverviewRefined) => {
            param_data[String(entry.year)] = entry[param];
        });
        output.push(param_data);
    });

    return output;
};

// Fetch an overview of a given committee
const fetchOverview = async (code: string | undefined, date: string) => {
    const year = date?.split('-')[0];
    const month = date?.split('-')[1];

    const yearly_response = await axios.get(`${baseURL}/hoc/${code}/overview?monthly=false`);
    const yearly_data: entityOverviewRefined[] = yearly_response.data.committeeOverview
        .filter((entry: any) => +entry.year <= +year)
        .map((entry: entityOverview) => {
            return {
                ...entry,
                'Lobbying Reports': entry.count,
                'by Consultants': entry.external,
                Organizations: entry.organizations,
                Sectors: entry.sectors,
            };
        });

    const inverted_yearly = invertYearlyOverview(yearly_data);

    const monthly_response = await axios.get(`${baseURL}/hoc/${code}/overview?monthly=true`);

    const monthly_data: entityOverview[] = monthly_response.data.committeeOverview
        .filter((entry: any) => {
            return (
                +[entry.year, (entry.month + '').padStart(2, '0')].join('') <=
                +[year, month].join('')
            );
        })
        .slice(0, 18);

    const overview: entityOverviews = {
        yearly: inverted_yearly,
        monthly: monthly_data,
    };

    return overview;
};

// Fetch a list of members of a given committee
const fetchMembers = async (code: string | undefined, date: string) => {
    const members_response = await axios.get(`${baseURL}/hoc/${code}/mplist?date=${date}`);
    const member_data: committeeMember[] = members_response.data.mpList;
    const converted_member_data = member_data.map((entry: committeeMember) => {
        return { ...entry, name: DPOHLinkFormatter(103, 'House%20of%20Commons', entry.name) };
    });

    return converted_member_data;
};

// Fetch recent lobbying communications in a given committee
const fetchRecentLobbyingActivity = async (code: string | undefined, date: string | undefined) => {
    const response = await axios.get(`${baseURL}/hoc/${code}/activity?limit=25&date=${date}`);
    const data: entityLobbyingActivity[] = response.data.committeeActivity;
    const converted_data: entityLobbyingActivity[] = data.map((entry) => {
        return {
            ...entry,
            'Prior Comms (36M)': entry.three_years_prior,
            date: moment.utc(entry.date).format('YYYY-MM-DD'),
            organization:
                entry.sector_id && entry.sector
                    ? organizationLinkFormatter(entry.sector_id, entry.sector, entry.client)
                    : entry.client,
            dpoh:
                entry.institution_id && entry.institution
                    ? DPOHLinkFormatter(entry.institution_id, entry.institution, entry.dpoh)
                    : entry.dpoh,
            institution:
                entry.institution_id && entry.institution
                    ? institutionLinkFormatter(entry.institution_id, entry.institution)
                    : entry.institution,
            sector:
                entry.sector_id && entry.sector
                    ? sectorLinkFormatter(entry.sector_id, entry.sector)
                    : entry.sector,
            lobby_firm:
                entry.lobby_firm === null || entry.consultant === null
                    ? 'In-House'
                    : lobbyFirmLinkFormatter(entry.lobby_firm),
            consultant:
                entry.lobby_firm === null || entry.consultant === null
                    ? 'In-House'
                    : consultantLinkFormatter(entry.lobby_firm, entry.consultant),
            link: `https://lobbycanada.gc.ca/app/secure/ocl/lrs/do/cmmLgPblcVw?comlogId=${entry.comm}`,
        };
    });
    return converted_data;
};

// Fetch a committee data cluster of the given committee code, under a specific "type"
const fetchCluster = async (
    code: string | undefined,
    type: 'organizations' | 'sectors' | 'lobbyfirms' | 'dpoh' | 'subject',
    date: string | undefined
) => {
    // Fetch top 6 *type* within the given committee (determined by YTD communication count)
    const top_count_response = await axios.get(
        `${baseURL}/hoc/${code}/${topCountAPIPath[type].path}?date=${date}`
    );

    const top_count_data: entityOrg[] =
        // name of data object varies by endpoint, we don't need to know its precise name this way
        top_count_response.data[topCountAPIPath[type].data];

    // Count the total number of comms for orgs outside of the top 6 ytd
    var other_count = 0;
    if (top_count_data.length > 6)
        other_count = top_count_data
            .slice(6)
            .map((item) => item.count)
            .reduce((sum, next) => sum + next);

    const top_six_ytd = top_count_data.slice(0, 6);
    top_six_ytd.push({ name: 'Other', code: 'Other', count: other_count });

    // Get the top 1-4 orgs from the top YTD orgs
    // These will be used for mini bar charts with trailing 12 months of lobbying displayed
    const top_four: entityOrgTrail[] = top_count_data.slice(0, 4).map((entry) => {
        return {
            ...entry,
            ttm: [],
        };
    });

    // Add a trailing twelve months array to the top 1-4 orgs
    await Promise.all(
        top_four.map(async (entry): Promise<void> => {
            const response = await axios.get(
                `${baseURL}/${TTMAPIPath[type].path}/${entry.code}/ttmcount/hoc/${code}?date=${date}`
            );
            // Modify the object with the API response
            entry.ttm = response.data.data;
        })
    );

    // Fetch top 10 mover *type* within the given committee
    const top_movers_response = await axios.get(
        `${baseURL}/hoc/${code}/${topMoversAPIPath[type].path}?limit=25&date=${date}`
    );
    const top_movers_data: entityOrg[] = top_movers_response.data[topMoversAPIPath[type].data];

    // Take top movers and add a percentage change + empty trialing twelve month array
    // Sort in descending order
    const converted_movers_data: entityOrgExtra[] = top_movers_data.map((entry) => {
        return {
            ...entry,
            name: moverLinkFormatter(entry, type),
            ...(type === 'dpoh' &&
                entry.institution_id &&
                entry.institution && {
                    institution: institutionLinkFormatter(+entry.institution_id, entry.institution),
                }),
            // Calculate with potential nulls replaced by 0
            change_rel: percentage_change_num(entry.tma || 0, entry.oma || 0),
            change_abs: (entry.oma || 0) - (entry.tma || 0),
            // long decimals returned as string by DB, cast to number
            avg: +(entry.avg || 0),
            deviation: +(entry.deviation || 0),
        };
    });

    const cluster: entityCluster = {
        top_movers: converted_movers_data,
        top_orgs: top_six_ytd,
        top_four: top_four,
        movers_meta: top_movers_response.data.movers_meta,
        isInstitutionType: true,
    };

    return cluster;
};

// Fetch meeting keywords and topics results in a given committee for most recent meetings
const fetchTerms = async (
    code: string | undefined,
    date: string | undefined
): Promise<bumperSuperList[]> => {
    const keyword_response = await axios.get(`${baseURL}/hoc/${code}/terms?limit=6&date=${date}`);
    const keyword_data: keywordMonthRaw[] = keyword_response.data.data;

    const keyword_list: bumperSuperList[] = keyword_data.map((entry: keywordMonthRaw) => {
        return {
            title:
                entry.meetingNo && entry.parliament && entry.session
                    ? committeeMeetingLinkFormatter(
                          code!,
                          entry.parliament,
                          entry.session,
                          entry.meetingNo,
                          entry.title
                              ? `Meeting ${entry.meetingNo} - ${entry.date}: ${entry.title}`
                              : `Meeting ${entry.meetingNo} - ${entry.date}`
                      )
                    : entry.date,
            parliament: entry.parliament,
            session: entry.session,
            meetingNo: entry.meetingNo,
            committee: entry.committee,
            date: entry.date,
            lists: entry.datasets,
        };
    });

    return keyword_list;
};

export const committeeAPI = {
    fetchOverview,
    fetchMembers,
    fetchRecentLobbyingActivity,
    fetchCluster,
    fetchTerms,
};
