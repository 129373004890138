import { logIn } from '../../store/reducers/userSlice';
import { useAppDispatch } from '../../store/store';
import logo from '../../assets/vectors/LIQ_badge.png';

export const LoginRequired = () => {
    const dispatch = useAppDispatch();

    const handleLoginRedirect = async () => {
        dispatch(logIn());
    };

    return (
        <div className="flex flex-row items-center justify-center text-xl py-[50vh]">
            <img src={logo} className="h-20" alt="logo" />
            <div style={{ marginLeft: '20px', marginRight: '20px' }} />
            <p>Please</p>
            <div
                className="mx-1 text-cyan-500 font-bold hover:underline hover:cursor-pointer"
                onClick={handleLoginRedirect}
            >
                log in
            </div>
            <p>to view this page.</p>
        </div>
    );
};
