export const MissingData = () => {
    const refreshPage = () => {
        window.location.reload();
    };

    return (
        <div className="flex row">
            <div>Missing Data...</div>
            <div
                className="mx-1 text-cyan-500 font-bold italic hover:underline hover:cursor-pointer"
                onClick={refreshPage}
            >
                refresh
            </div>
        </div>
    );
};
