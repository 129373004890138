import { Link } from 'react-router-dom';
import { useAppDispatch } from '../../store/store';
import { useSelector } from 'react-redux';
import {
    fetchNewsletterSelections,
    selectChosenNewsletters,
    selectSelectionLoading,
} from '../../store/reducers/customSelectionSlice';
import {
    fetchSectorList,
    selectSectorList,
} from '../../features/sector-dashboards/store/sectorListSlice';
import { selectUserLoading, selectUserPermissions } from '../../store/reducers/userSlice';
import LoadPage from '../misc/LoadPage';
import { SubscriptionRequired } from '../misc/SubscriptionRequired';
import { Fade, Breadcrumbs, Button, Grow } from '@mui/material';
import EditNoteRoundedIcon from '@mui/icons-material/EditNoteRounded';
import { useEffect, useState } from 'react';
import SelectionModal from './SelectionModal';

const NewsletterSelection = () => {
    const dispatch = useAppDispatch();

    const userLoading = useSelector(selectUserLoading);
    const permissions = useSelector(selectUserPermissions);
    const sectors = useSelector(selectSectorList);
    const selectedNewsletters = useSelector(selectChosenNewsletters);
    const dataLoading = useSelector(selectSelectionLoading);

    const [selectionOpen, setSelectionOpen] = useState<boolean>(false);

    useEffect(() => {
        dispatch(fetchSectorList());
        dispatch(fetchNewsletterSelections());
    }, [dispatch]);

    return userLoading || dataLoading ? (
        <LoadPage />
    ) : !permissions ? (
        <SubscriptionRequired />
    ) : (
        <Fade in={true}>
            <div className="lg:py-32 py-20 grid grid-cols-12">
                <Breadcrumbs className="col-start-2 col-end-12" separator=">">
                    <Link to="/" className="hover:underline">
                        Lobby<b>IQ</b>
                    </Link>
                    <Link to="/account" className="hover:underline">
                        My Account
                    </Link>
                    <div>Settings</div>
                </Breadcrumbs>

                {selectionOpen && (
                    <SelectionModal
                        open={selectionOpen}
                        availableList={sectors || []}
                        ownedList={selectedNewsletters || []}
                        maxEntitlements={permissions.newsLetters}
                        type={'newsletters'}
                        handleClose={() => setSelectionOpen(false)}
                    />
                )}

                <div className="col-start-3 col-end-11 mt-16">
                    <div className="text-2xl flex flex-row gap-2 items-center">
                        Newsletter Selections
                    </div>
                </div>

                <div className="col-start-3 col-end-11 mt-6">
                    <div className="flex flex-row justify-between items-center">
                        <div className="text-lg flex flex-row gap-2 items-center">
                            Your Newsletters
                        </div>
                    </div>
                    <hr className="h-0.5 my-3 bg-slate-100 rounded" />
                    <div className="flex flex-row justify-between items-center py-2 text-md text-slate-500">
                        <div>Click Edit to select up to {permissions.newsLetters} Newsletters</div>
                        <Button
                            onClick={() => setSelectionOpen(true)}
                            endIcon={<EditNoteRoundedIcon />}
                            size="medium"
                            variant="contained"
                            color={'success'}
                        >
                            Edit
                        </Button>
                    </div>
                    <div>
                        {selectedNewsletters?.map((entity) => (
                            <Grow in={true}>
                                <div className="bg-white shadow-sm rounded-md p-3 my-2 text-sm text-blue-400 font-medium">
                                    <Link
                                        to={`/sectors/${entity.code}?name=${encodeURIComponent(
                                            entity.title
                                        )}`}
                                        className="hover:underline underline-offset-2"
                                    >
                                        {entity.code} - {entity.title}
                                    </Link>
                                </div>
                            </Grow>
                        ))}
                        {[
                            ...Array(permissions.newsLetters - (selectedNewsletters || []).length),
                        ].map(() => (
                            <Grow in={true}>
                                <div className="border border-1 border-slate-300 rounded-md p-3 my-2 text-sm text-slate-300 font-medium">
                                    Add another newsletter
                                </div>
                            </Grow>
                        ))}
                    </div>
                </div>
            </div>
        </Fade>
    );
};

export default NewsletterSelection;
