export const SectorKeyPhrases = (
    <div className="flex flex-col gap-2">
        <div>
            We use machine-learning (ML) to extract the key-phrases that characterize the
            lobby-registrations that generated communications in a sector/institution in a month. We
            use a ML-technique called “text frequency, inverse document frequency” (TFIDF). Consult
            our Frequently-Asked-Questions for details.
        </div>
    </div>
);
