import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useAppDispatch } from '../../store/store';
import EntityListPage from './EntityListPage';
import { useLocation, useParams } from 'react-router-dom';
import { entityListExtra } from '../../interfaces/generic-entity.interface';
import {
    fetchDPOHList,
    selectDPOHList,
    selectDPOHListLoading,
} from '../../store/reducers/institutionListSlice';

const DPOHListDataLayer = () => {
    const url_params = useParams();
    const entity_code = url_params.entityId !== undefined ? url_params.entityId : '';

    const location = useLocation();
    const titleFilter: string | undefined =
        new URLSearchParams(location.search).get('title') ?? undefined;

    const dispatch = useAppDispatch();

    const entity_list: entityListExtra[] | null = useSelector(selectDPOHList);
    const dataLoading = useSelector(selectDPOHListLoading);

    useEffect(() => {
        dispatch(fetchDPOHList({ code: entity_code, titleFilter: titleFilter }));
    }, [dispatch, entity_code, titleFilter]);

    return <EntityListPage type="DPOHs" list={entity_list || []} loading={dataLoading} />;
};

export default DPOHListDataLayer;
