import React, { useEffect } from 'react';
import { fetchSectorList, selectSectorList } from '../store/sectorListSlice';
import { useSelector } from 'react-redux';
import { entityListExtra, entityListSimple } from '../../../interfaces/generic-entity.interface';
import { useAppDispatch } from '../../../store/store';
import EntityListPage from '../../../pages/lists/EntityListPage';
import {
    fetchSectorsSelections,
    selectChosenSectors,
} from '../../../store/reducers/customSelectionSlice';
import { fetchQueryOrgList, selectQueryOrgList } from '../../../store/reducers/querySlice';

const SectorListDataLayer = () => {
    const dispatch = useAppDispatch();

    const selectedSectors = useSelector(selectChosenSectors);
    const sector_list: entityListExtra[] | null = useSelector(selectSectorList);
    const org_list: entityListSimple[] | null = useSelector(selectQueryOrgList);

    useEffect(() => {
        dispatch(fetchSectorList());
        dispatch(fetchSectorsSelections());
        dispatch(fetchQueryOrgList(null));
    }, [dispatch]);

    return (
        <EntityListPage
            type="sectors"
            list={sector_list || []}
            subList={org_list || []}
            selections={selectedSectors}
        />
    );
};

export default SectorListDataLayer;
