import React from 'react';
import { entityListExtra } from '../../interfaces/generic-entity.interface';
import { EntityType } from '../config/Entities';
import { Link } from 'react-router-dom';
import { Button, Grow } from '@mui/material';
import { ENTITY_CATALOGUE_DATA_KEY_NAME_MAP } from './EntityCatalogueConstants';
import { Selection } from '../../store/reducers/customSelectionSlice';

interface CatalogueItemCreatorProps {
    catalogueType: EntityType;
    catalogueParentCode: string;
    catalogueParentName: string;
    catalogueEntries: entityListExtra[];
    catalogueSelections: Selection[];
    catalogueFields: string[];
}

const EntityCatalogueItemCreator = ({
    catalogueType,
    catalogueParentCode,
    catalogueParentName,
    catalogueEntries,
    catalogueSelections,
    catalogueFields,
}: CatalogueItemCreatorProps) => {
    const pill = `${
        catalogueType === EntityType.Issues
            ? 'lg:col-span-3 col-span-3'
            : catalogueType === EntityType.Firm || catalogueType === EntityType.Consultant
            ? 'lg:col-span-2 col-span-3'
            : catalogueType === EntityType.InstitutionMP
            ? 'lg:col-span-3 col-span-3'
            : 'lg:col-span-2 col-span-3'
    } text-sm lg:bg-opacity-0 bg-slate-50 rounded-xl p-1 m-1 flex flex-row lg:justify-end justify-between`;

    return (
        <ul>
            {catalogueEntries.map((entity: entityListExtra, idx: number) => {
                return (
                    <Grow key={idx} in={true}>
                        <li
                            className={`${
                                idx < catalogueSelections.length && ' border-l-4 border-sky-400'
                            } bg-white shadow-sm rounded-md px-1 my-3`}
                        >
                            <div className="lg:text-slate-700 text-md grid grid-cols-12 gap-2">
                                <div
                                    className={
                                        'flex flex-col items-start justify-center py-2 ' +
                                        (catalogueType === EntityType.Firm ||
                                        catalogueType === EntityType.Consultant
                                            ? 'lg:col-span-4 col-span-12'
                                            : 'lg:col-span-6 col-span-12')
                                    }
                                >
                                    <Link
                                        className="pl-2 text-blue-400 font-medium hover:underline"
                                        to={
                                            catalogueType === EntityType.Organization
                                                ? `/sectors/${catalogueParentCode}/organizations/${
                                                      entity.title
                                                  }?sector=${encodeURIComponent(
                                                      catalogueParentName
                                                  )}`
                                                : catalogueType === EntityType.InactiveOrganization
                                                ? `/sectors/${catalogueParentCode}/organizations/inactive/${
                                                      entity.title
                                                  }?sector=${encodeURIComponent(
                                                      catalogueParentName
                                                  )}`
                                                : catalogueType === EntityType.Consultant
                                                ? `/firms/${encodeURIComponent(
                                                      catalogueParentCode
                                                  )}/consultants/${
                                                      entity.title
                                                  }?firm=${encodeURIComponent(catalogueParentName)}`
                                                : catalogueType === EntityType.DPOH
                                                ? `/institutions/${encodeURIComponent(
                                                      catalogueParentCode
                                                  )}/dpoh/${
                                                      entity.title
                                                  }?institution=${encodeURIComponent(
                                                      catalogueParentName
                                                  )}${
                                                      entity.title2 && entity.title2 === 'Minister'
                                                          ? '&includeHOC=true'
                                                          : ''
                                                  }${
                                                      catalogueParentCode === '103'
                                                          ? '&includeMinisterPosition=true&isMP=true'
                                                          : ''
                                                  }`
                                                : catalogueType === EntityType.MP ||
                                                  catalogueType === EntityType.InstitutionMP
                                                ? `/institutions/103/dpoh/${entity.title}?institution=House of Commons&includeMinisterPosition=true&isMP=true`
                                                : catalogueType === EntityType.SenateMember
                                                ? `/senate/members/${encodeURIComponent(
                                                      entity.code
                                                  )}?name=${encodeURIComponent(entity.title)}`
                                                : catalogueType === EntityType.SenateCommittee
                                                ? `/senate/committees/${encodeURIComponent(
                                                      entity.code
                                                  )}?name=${encodeURIComponent(entity.title)}`
                                                : `/${catalogueType}/${encodeURIComponent(
                                                      entity.code
                                                  )}?name=${encodeURIComponent(entity.title)}`
                                        }
                                    >
                                        {entity.title}
                                        {entity.title2 && ' - ' + entity.title2}{' '}
                                        {catalogueType === EntityType.Committee &&
                                            '(' + entity.code + ')'}
                                        {catalogueType === EntityType.SenateCommittee &&
                                            '(' + entity.code + ')'}
                                    </Link>
                                    {catalogueType === EntityType.Sector ? (
                                        <div className="flex lg:flex-row flex-col items-start lg:gap-2">
                                            <Button
                                                component={Link}
                                                to={`/sectors/${
                                                    entity.code
                                                }/organizations?sector=${encodeURIComponent(
                                                    entity.title
                                                )}`}
                                                sx={{ px: 1, py: 0 }}
                                                size="small"
                                                color="success"
                                            >
                                                Organizations
                                            </Button>
                                            <Button
                                                component={Link}
                                                to={`/sectors/${
                                                    entity.code
                                                }/organizations/inactive?sector=${encodeURIComponent(
                                                    entity.title
                                                )}`}
                                                sx={{ px: 1, py: 0 }}
                                                size="small"
                                                color="success"
                                            >
                                                Inactive Organizations
                                            </Button>
                                        </div>
                                    ) : catalogueType === EntityType.Firm ? (
                                        <Button
                                            component={Link}
                                            to={`/firms/${
                                                entity.code
                                            }/consultants?firm=${encodeURIComponent(entity.title)}`}
                                            sx={{ px: 1, py: 0 }}
                                            size="small"
                                            color="info"
                                        >
                                            Consultants
                                        </Button>
                                    ) : catalogueType === EntityType.Institution ? (
                                        <Button
                                            component={Link}
                                            to={`/institutions/${
                                                entity.code
                                            }/dpoh?institution=${encodeURIComponent(entity.title)}`}
                                            sx={{ px: 1, py: 0 }}
                                            size="small"
                                            color="info"
                                        >
                                            DPOH List
                                        </Button>
                                    ) : catalogueType === EntityType.Committee ? (
                                        <Button
                                            component={Link}
                                            to={`/committees/${
                                                entity.code
                                            }/mps?committee=${encodeURIComponent(entity.title)}`}
                                            sx={{ px: 1, py: 0 }}
                                            size="small"
                                            color="info"
                                        >
                                            MP List
                                        </Button>
                                    ) : (
                                        <></>
                                    )}
                                </div>
                                <div
                                    className={
                                        'grid grid-cols-12 ' +
                                        (catalogueType === EntityType.Firm ||
                                        catalogueType === EntityType.Consultant
                                            ? 'lg:col-span-8 col-span-12 lg:grid-cols-14'
                                            : catalogueType === EntityType.InactiveOrganization
                                            ? 'lg:col-span-6 col-span-12 lg:grid-cols-4'
                                            : catalogueType === EntityType.SenateMember
                                            ? 'lg:col-span-6 col-span-12 lg:grid-cols-6 lg:grid hidden'
                                            : catalogueType === EntityType.InstitutionMP
                                            ? 'lg:col-span-6 col-span-12 lg:grid hidden'
                                            : 'lg:col-span-6 col-span-12')
                                    }
                                >
                                    {catalogueFields.map((field: string, idx: number) => {
                                        return (
                                            <div className={pill} key={idx}>
                                                <p className="lg:hidden flex">
                                                    {ENTITY_CATALOGUE_DATA_KEY_NAME_MAP.get(
                                                        field
                                                    ) ?? field}
                                                    :
                                                </p>
                                                {field === 'change_rel' ? (
                                                    <p
                                                        className={
                                                            entity.change_rel > 0
                                                                ? 'text-liq-lime'
                                                                : 'text-liq-red'
                                                        }
                                                    >
                                                        {(+entity.change_rel > 0 ? '+' : '') +
                                                            entity.change_rel +
                                                            '%'}
                                                    </p>
                                                ) : field === 'change_abs' ? (
                                                    <p
                                                        className={
                                                            entity.change_rel > 0
                                                                ? 'text-liq-lime'
                                                                : 'text-liq-red'
                                                        }
                                                    >
                                                        {(+entity.change_abs > 0 ? '+' : '') +
                                                            entity.change_abs}
                                                    </p>
                                                ) : (
                                                    entity[field as keyof entityListExtra] || 0
                                                )}
                                            </div>
                                        );
                                    })}
                                </div>
                            </div>
                        </li>
                    </Grow>
                );
            })}
        </ul>
    );
};

export default EntityCatalogueItemCreator;
