import { Link } from 'react-router-dom';
import MetaTag from '../../components/metatag-component/MetaTag';

const GRInsiderFeatures = () => {
    return (
        <div className="xl:mx-24 mx-8 ">
            <MetaTag
                title="GR Insider"
                keywords="Monitor Lobby Firms,Monitor Consultants,Business Development Dashboards,Weekly Business Development Email,Monitor Lobby Firms, Lobby Firm Dashboards, Real-Time Metrics, Lobbying Activity, Ottawa, Clients, Sectors, Institutions, DPOHs, Registration Dashboards, Monitor Consultants, Business Development Dashboards, Benchmark Lobbying, Comparable Organizations, Client Communications, In-House GR Teams, De-Registered Client-Firm Relationships, Weekly Business Development Email, Lobbying Updates"
                description="Monitor lobby firms and consultants with real-time metrics on their activities in Ottawa. Access comprehensive dashboards for each registration, benchmark lobbying activity, and stay updated with weekly emails on business development, in-house GR team changes, and de-registered client relationships."
            />
            <div className="col-12 md:col-7 lg:col-6 py-14 lg:gap-12 gap-y-8 lg:gap-y-0"></div>
            <div className="lg:grid grid-cols-12 grid-rows-auto gap-8 py-8 flex flex-col ">
                <div className="col-start-1 row-start-1 col-span-12 col-end-12 text-left">
                    <h1 className="lg:text-4xl text-3xl ">GR Insider</h1>
                </div>

                <div className="lg:col-start-1 row-start-2 col-start-2 col-end-7 grid grid-cols-12 gap-6">
                    <div className="lg:col-span-6 col-span-12 bg-white shadow-md p-6 rounded-xl flex flex-col justify-between min-h-[20rem]">
                        <div>
                            <div className="text-2xl">
                                Monitor{' '}
                                <span className="text-liq-ultramarine hover:underline">
                                    <Link to="/firms">Lobby Firms</Link>
                                </span>
                            </div>
                            <div className="text-md mt-8 font-light">
                                Lobby firm dashboards provide real-time metrics on the lobbying of
                                hundreds of lobby-firms in Ottawa, their clients, the sectors they
                                represent, and the institutions and DPOHs they lobby. Standalone
                                dashboards for each registration.
                            </div>
                        </div>
                    </div>
                    <div className="lg:col-span-6 col-span-12 bg-white shadow-md p-6 rounded-xl flex flex-col justify-between min-h-[20rem]">
                        <div>
                            <div className="text-2xl">
                                Monitor{' '}
                                <span className="text-liq-lavender hover:underline">
                                    <a href="https://ca.lobbyiq.org/firms/PAA%20Advisory%20%7C%20Conseils/consultants?firm=PAA%20Advisory%20%7C%20Conseils">
                                        Consultants
                                    </a>
                                </span>
                            </div>
                            <div className="text-md mt-8 font-light">
                                Lobby firm dashboards provide real-time metrics on the lobbying of
                                hundreds of lobby-firms in Ottawa, their clients, the sectors they
                                represent, and the institutions and DPOHs they lobby. Standalone
                                dashboards for each registration.
                            </div>
                        </div>
                    </div>
                    <div className="lg:col-span-6 col-span-12 bg-white shadow-md p-6 rounded-xl flex flex-col justify-between min-h-[20rem]">
                        <div>
                            <div className="text-2xl">Business Development Dashboards</div>
                            <div className="text-md mt-8 font-light">
                                Benchmark lobbying activity across comparable organizations. Is a
                                client ahead of behind competitors in their communications? Recent
                                changes in-House GR teams, and de-registered client-firm
                                relationships,
                            </div>
                        </div>
                    </div>
                    <div className="lg:col-span-6 col-span-12 bg-white shadow-md p-6 rounded-xl flex flex-col justify-between min-h-[20rem]">
                        <div>
                            <div className="text-2xl">Weekly Business Development Email</div>
                            <div className="text-md mt-8 font-light">
                                Weekly email updates on changing in-House GR teams, de-registered
                                client-firm relationships, and organizations whose communications
                                activity has fallen off.
                            </div>
                        </div>
                    </div>
                </div>
                <div className="col-start-7 col-span-6  flex justify-center items-center">
                    <img
                        src={process.env.PUBLIC_URL + '/Firms_Dashboard.png'}
                        className="w-full bg-slate-50 border border-slate-100 p-6 rounded-xl shadow-md	"
                        height="auto"
                        alt="logo"
                    />
                </div>
            </div>

            <div className="col-12 md:col-7 lg:col-6 py-14 lg:gap-12 gap-y-8 lg:gap-y-0"></div>
        </div>
    );
};

export default GRInsiderFeatures;
