import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { RootState } from '../../../store/store';
import { subjectAPI } from '../API/subjectAPI';
import {
    entityOverviews,
    entityLobbyingActivity,
    entityRegistration,
    entityCluster,
} from '../../../interfaces/generic-entity.interface';
import axios from 'axios';
import saveAs from 'file-saver';
import { recentUpdates } from '../../shared/interfaces/whats-new.interface';

interface SubjectState {
    updates: recentUpdates | null;
    updates_loading: boolean;
    updates_fail: boolean;

    overview: entityOverviews | null;
    overview_loading: boolean;
    overview_fail: boolean;

    lobbying_activity: entityLobbyingActivity[] | null;
    lobbying_activity_loading: boolean;
    lobbying_activity_fail: boolean;

    recent_reg: entityRegistration[] | null;
    recent_reg_loading: boolean;
    recent_reg_fail: boolean;

    recent_dereg: entityRegistration[] | null;
    recent_dereg_loading: boolean;
    recent_dereg_fail: boolean;

    org_cluster: entityCluster | null;
    org_cluster_loading: boolean;
    org_cluster_fail: boolean;

    inst_cluster: entityCluster | null;
    inst_cluster_loading: boolean;
    inst_cluster_fail: boolean;

    firm_cluster: entityCluster | null;
    firm_cluster_loading: boolean;
    firm_cluster_fail: boolean;

    dpoh_cluster: entityCluster | null;
    dpoh_cluster_loading: boolean;
    dpoh_cluster_fail: boolean;

    sector_cluster: entityCluster | null;
    sector_cluster_loading: boolean;
    sector_cluster_fail: boolean;

    export_loading: boolean;
    export_fail: boolean;
}

const initialState: SubjectState = {
    updates: null,
    updates_loading: false,
    updates_fail: false,

    overview: null,
    overview_loading: false,
    overview_fail: false,

    lobbying_activity: null,
    lobbying_activity_loading: false,
    lobbying_activity_fail: false,

    recent_reg: null,
    recent_reg_loading: false,
    recent_reg_fail: false,

    recent_dereg: null,
    recent_dereg_loading: false,
    recent_dereg_fail: false,

    org_cluster: null,
    org_cluster_loading: false,
    org_cluster_fail: false,

    inst_cluster: null,
    inst_cluster_loading: false,
    inst_cluster_fail: false,

    firm_cluster: null,
    firm_cluster_loading: false,
    firm_cluster_fail: false,

    dpoh_cluster: null,
    dpoh_cluster_loading: false,
    dpoh_cluster_fail: false,

    sector_cluster: null,
    sector_cluster_loading: false,
    sector_cluster_fail: false,

    export_loading: false,
    export_fail: false,
};

// Fetch the recent updates for the current subject
export const fetchSubjectUpdates = createAsyncThunk(
    'subject/fetchSubjectUpdates',
    async ({ code, date }: { code: string | undefined; date: string }): Promise<recentUpdates> =>
        subjectAPI.fetchUpdates(code, date)
);

// Fetch the yearly overview data for the current subject
export const fetchSubjectOverview = createAsyncThunk(
    'subject/fetchSubjectOverview',
    async ({ code, date }: { code: string | undefined; date: string }): Promise<entityOverviews> =>
        subjectAPI.fetchOverview(code, date)
);

// Fetch the recent lobbying activity data for the current subject
export const fetchSubjectRecentLobbying = createAsyncThunk(
    'subject/fetchSubjectRecentLobbying',
    async ({
        code,
        date,
    }: {
        code: string;
        date: string | undefined;
    }): Promise<entityLobbyingActivity[]> => subjectAPI.fetchRecentLobbyingActivity(code, date)
);

// Fetch the recent lobbying registrations in the current subject
export const fetchSubjectRegistrations = createAsyncThunk(
    'subject/fetchSubjectRegistrations',
    async ({
        code,
        date,
    }: {
        code: string;
        date: string | undefined;
    }): Promise<entityRegistration[]> => subjectAPI.fetchRecentRegistrations(code, date)
);

// Fetch the recent lobbying registrations in the current subject
export const fetchSubjectDeregistrations = createAsyncThunk(
    'subject/fetchSubjectDeregistrations',
    async ({
        code,
        date,
    }: {
        code: string;
        date: string | undefined;
    }): Promise<entityRegistration[]> => subjectAPI.fetchRecentDeregistrations(code, date)
);

// Fetch the organization cluster data
export const fetchOrganizationCluster = createAsyncThunk(
    'subject/fetchOrganizationCluster',
    async ({
        code,
        date,
    }: {
        code: string | undefined;
        date: string | undefined;
    }): Promise<entityCluster> => subjectAPI.fetchCluster(code, 'organizations', date)
);

// Fetch the institution cluster data
export const fetchInstitutionCluster = createAsyncThunk(
    'subject/fetchInstitutionCluster',
    async ({
        code,
        date,
    }: {
        code: string | undefined;
        date: string | undefined;
    }): Promise<entityCluster> => subjectAPI.fetchCluster(code, 'institutions', date)
);

// Fetch the lobby firm cluster data
export const fetchFirmCluster = createAsyncThunk(
    'subject/fetchFirmCluster',
    async ({
        code,
        date,
    }: {
        code: string | undefined;
        date: string | undefined;
    }): Promise<entityCluster> => subjectAPI.fetchCluster(code, 'lobbyfirms', date)
);

// Fetch the lobby firm cluster data
export const fetchDPOHCluster = createAsyncThunk(
    'subject/fetchDPOHCluster',
    async ({
        code,
        date,
    }: {
        code: string | undefined;
        date: string | undefined;
    }): Promise<entityCluster> => subjectAPI.fetchCluster(code, 'dpoh', date)
);

// Fetch the sector cluster data
export const fetchSectorCluster = createAsyncThunk(
    'subject/fetchSectorCluster',
    async ({
        code,
        date,
    }: {
        code: string | undefined;
        date: string | undefined;
    }): Promise<entityCluster> => subjectAPI.fetchCluster(code, 'sectors', date)
);

export const exportSubjectDashboard = createAsyncThunk(
    'subject/exportDashboard',
    async (data: any): Promise<void> => {
        const response = await axios.post(
            `${process.env.REACT_APP_PDF_SERVICE_URL}/subject/report`,
            data,
            {
                responseType: 'blob',
            }
        );

        const pdfEncoding = response.data;

        const blob = new Blob([pdfEncoding], { type: 'application/pdf' });

        saveAs(blob, 'LobbyIQ-Subject');
    }
);

const subjectSlice = createSlice({
    name: 'subjectReducer',
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(fetchSubjectUpdates.pending, (state) => {
                state.updates_loading = true;
                state.updates = null;
            })
            .addCase(fetchSubjectUpdates.fulfilled, (state, action) => {
                state.updates_loading = false;
                state.updates_fail = false;
                state.updates = action.payload;
            })
            .addCase(fetchSubjectUpdates.rejected, (state) => {
                state.updates_loading = false;
                state.updates_fail = true;
                state.updates = null;
            })
            .addCase(fetchSubjectOverview.pending, (state) => {
                state.overview_loading = true;
            })
            .addCase(fetchSubjectOverview.fulfilled, (state, action) => {
                state.overview_loading = false;
                state.overview_fail = false;
                state.overview = action.payload;
            })
            .addCase(fetchSubjectOverview.rejected, (state) => {
                state.overview_loading = false;
                state.overview_fail = true;
                state.overview = null;
            })
            .addCase(fetchSubjectRecentLobbying.pending, (state) => {
                state.lobbying_activity_loading = true;
            })
            .addCase(fetchSubjectRecentLobbying.fulfilled, (state, action) => {
                state.lobbying_activity_loading = false;
                state.lobbying_activity_fail = false;
                state.lobbying_activity = action.payload;
            })
            .addCase(fetchSubjectRecentLobbying.rejected, (state) => {
                state.lobbying_activity_loading = false;
                state.lobbying_activity_fail = true;
                state.lobbying_activity = null;
            })
            .addCase(fetchSubjectRegistrations.pending, (state) => {
                state.recent_reg_loading = true;
            })
            .addCase(fetchSubjectRegistrations.fulfilled, (state, action) => {
                state.recent_reg_loading = false;
                state.recent_reg_fail = false;
                state.recent_reg = action.payload;
            })
            .addCase(fetchSubjectRegistrations.rejected, (state) => {
                state.recent_reg_loading = false;
                state.recent_reg_fail = true;
                state.recent_reg = null;
            })
            .addCase(fetchSubjectDeregistrations.pending, (state) => {
                state.recent_dereg_loading = true;
            })
            .addCase(fetchSubjectDeregistrations.fulfilled, (state, action) => {
                state.recent_dereg_loading = false;
                state.recent_dereg_fail = false;
                state.recent_dereg = action.payload;
            })
            .addCase(fetchSubjectDeregistrations.rejected, (state) => {
                state.recent_dereg_loading = false;
                state.recent_dereg_fail = true;
                state.recent_dereg = null;
            })
            .addCase(fetchOrganizationCluster.pending, (state) => {
                state.org_cluster_loading = true;
            })
            .addCase(fetchOrganizationCluster.fulfilled, (state, action) => {
                state.org_cluster_loading = false;
                state.org_cluster_fail = false;
                state.org_cluster = action.payload;
            })
            .addCase(fetchOrganizationCluster.rejected, (state) => {
                state.org_cluster_loading = false;
                state.org_cluster_fail = true;
                state.org_cluster = null;
            })
            .addCase(fetchInstitutionCluster.pending, (state) => {
                state.inst_cluster_loading = true;
            })
            .addCase(fetchInstitutionCluster.fulfilled, (state, action) => {
                state.inst_cluster_loading = false;
                state.inst_cluster_fail = false;
                state.inst_cluster = action.payload;
            })
            .addCase(fetchInstitutionCluster.rejected, (state) => {
                state.inst_cluster_loading = false;
                state.inst_cluster_fail = true;
                state.inst_cluster = null;
            })
            .addCase(fetchFirmCluster.pending, (state) => {
                state.firm_cluster_loading = true;
            })
            .addCase(fetchFirmCluster.fulfilled, (state, action) => {
                state.firm_cluster_loading = false;
                state.firm_cluster_fail = false;
                state.firm_cluster = action.payload;
            })
            .addCase(fetchFirmCluster.rejected, (state) => {
                state.firm_cluster_loading = false;
                state.firm_cluster_fail = true;
                state.firm_cluster = null;
            })
            .addCase(fetchDPOHCluster.pending, (state) => {
                state.dpoh_cluster_loading = true;
            })
            .addCase(fetchDPOHCluster.fulfilled, (state, action) => {
                state.dpoh_cluster_loading = false;
                state.dpoh_cluster_fail = false;
                state.dpoh_cluster = action.payload;
            })
            .addCase(fetchDPOHCluster.rejected, (state) => {
                state.dpoh_cluster_loading = false;
                state.dpoh_cluster_fail = true;
                state.dpoh_cluster = null;
            })
            .addCase(fetchSectorCluster.pending, (state) => {
                state.sector_cluster_loading = true;
            })
            .addCase(fetchSectorCluster.fulfilled, (state, action) => {
                state.sector_cluster_loading = false;
                state.sector_cluster_fail = false;
                state.sector_cluster = action.payload;
            })
            .addCase(fetchSectorCluster.rejected, (state) => {
                state.sector_cluster_loading = false;
                state.sector_cluster_fail = true;
                state.sector_cluster = null;
            })
            .addCase(exportSubjectDashboard.pending, (state) => {
                state.export_loading = true;
            })
            .addCase(exportSubjectDashboard.fulfilled, (state, action) => {
                state.export_loading = false;
                state.export_fail = false;
            })
            .addCase(exportSubjectDashboard.rejected, (state) => {
                state.export_loading = false;
                state.export_fail = true;
            });
    },
});

export const selectSubjectUpdates = (state: RootState) => state.subjectData.updates;
export const selectSubjectUpdatesLoading = (state: RootState) => state.subjectData.updates_loading;

export const selectSubjectOverview = (state: RootState) => state.subjectData.overview;
export const selectSubjectOverviewLoading = (state: RootState) =>
    state.subjectData.overview_loading;

export const selectSubjectRecentLobbying = (state: RootState) =>
    state.subjectData.lobbying_activity;
export const selectSubjectRecentLobbyingLoading = (state: RootState) =>
    state.subjectData.lobbying_activity_loading;

export const selectSubjectRegistrations = (state: RootState) => state.subjectData.recent_reg;
export const selectSubjectRegistrationsLoading = (state: RootState) =>
    state.subjectData.recent_reg_loading;

export const selectSubjectDeregistrations = (state: RootState) => state.subjectData.recent_dereg;
export const selectSubjectDeregistrationsLoading = (state: RootState) =>
    state.subjectData.recent_dereg_loading;

export const selectOrganizationCluster = (state: RootState) => state.subjectData.org_cluster;
export const selectOrganizationClusterLoading = (state: RootState) =>
    state.subjectData.org_cluster_loading;

export const selectInstitutionCluster = (state: RootState) => state.subjectData.inst_cluster;
export const selectInstitutionClusterLoading = (state: RootState) =>
    state.subjectData.inst_cluster_loading;

export const selectFirmCluster = (state: RootState) => state.subjectData.firm_cluster;
export const selectFirmClusterLoading = (state: RootState) =>
    state.subjectData.firm_cluster_loading;

export const selectDPOHCluster = (state: RootState) => state.subjectData.dpoh_cluster;
export const selectDPOHClusterLoading = (state: RootState) =>
    state.subjectData.dpoh_cluster_loading;

export const selectSectorCluster = (state: RootState) => state.subjectData.sector_cluster;
export const selectSectorClusterLoading = (state: RootState) =>
    state.subjectData.sector_cluster_loading;

export const selectSubjectData = (state: RootState) => state.subjectData;

export const selectSubjectExportLoading = (state: RootState) => state.subjectData.export_loading;
export const selectSubjectExportFail = (state: RootState) => state.subjectData.export_fail;

export default subjectSlice.reducer;
