import React from 'react';
import LinkFormatter from '../shared/routing/LinkFormatter';
import {
    DPOHLinkFormatter,
    institutionLinkFormatter,
    organizationLinkFormatter,
} from '../../helpers/internal_routing';
import { recentUpdates } from '../../features/shared/interfaces/whats-new.interface';

const overallActivity = (data: recentUpdates) => {
    const percentChange: number =
        data.monthReportsLastYear > 0
            ? ((data.monthReports - data.monthReportsLastYear) / data.monthReportsLastYear) * 100
            : 0;
    let percentChangeStr: string;
    if (percentChange !== 0) {
        percentChangeStr =
            Math.abs(percentChange).toFixed(0) + '%' + (percentChange > 0 ? ' higher' : ' lower');
    } else if (percentChange === 0 && data.monthReportsLastYear > 0) {
        percentChangeStr = 'similar';
    } else {
        percentChangeStr = '100% more';
    }
    let busier;
    if (data.monthReports > data.twelveMonthAverage + 0.5 * data.twelveMonthStdDev) {
        busier = 'busier than';
    } else if (data.monthReports < data.twelveMonthAverage - 0.5 * data.twelveMonthStdDev) {
        busier = 'quieter than';
    } else {
        busier = 'on par with';
    }
    return (
        <li>
            <span className="font-medium">Overall lobbying activity</span> on the issue (
            {data.monthReports} reports) was {percentChangeStr} in {data.month}{' '}
            {percentChange === 0 && data.monthReportsLastYear > 0 ? 'as' : 'than'} in the same month
            last year. {data.month} was {busier} the 12M average of {data.twelveMonthAverage}{' '}
            reports per month.
        </li>
    );
};

const recentRegistrants = (str_arr: any[], month: string) => {
    const endPhrase =
        str_arr.length > 1
            ? 'filed new lobby registrations that included keywords relating to the issue'
            : 'filed a new lobby registration that included keywords relating to the issue';
    if (str_arr.length > 0) {
        return (
            <li>
                {str_arr.map((entry: any, idx: number) => {
                    return (
                        <>
                            {entry.code && entry.parent ? (
                                <LinkFormatter
                                    urlFormatStr={organizationLinkFormatter(
                                        String(entry.code),
                                        entry.parent,
                                        entry.client
                                    )}
                                />
                            ) : (
                                entry.client
                            )}
                            {idx === str_arr.length - 1 ? '' : ', '}
                        </>
                    );
                })}
                <span className="">
                    {' '}
                    {endPhrase} in {month}.
                </span>
            </li>
        );
    } else {
        return <li>No new registrations were filed in {month}.</li>;
    }
};

const mostActiveOrg = (data: recentUpdates) => {
    const activeOrgInfo = data.mostActiveOrg;
    if (
        activeOrgInfo.activeCode &&
        activeOrgInfo.activeName &&
        activeOrgInfo.increaseName &&
        (activeOrgInfo.increaseCount > 0 || activeOrgInfo.increaseAvg > 0)
    ) {
        return (
            <li>
                <LinkFormatter
                    urlFormatStr={organizationLinkFormatter(
                        String(activeOrgInfo.activeCode),
                        activeOrgInfo.activeParentName,
                        activeOrgInfo.activeName
                    )}
                />{' '}
                was the <span className="font-medium">most active organization</span> lobbying on
                the issue, filing {activeOrgInfo.activeCount} reports in {data.month}.{' '}
                <LinkFormatter
                    urlFormatStr={organizationLinkFormatter(
                        String(activeOrgInfo.increaseCode),
                        activeOrgInfo.increaseParentName,
                        activeOrgInfo.increaseName
                    )}
                />{' '}
                increased its activity the most, filing {activeOrgInfo.increaseCount} reports,
                compared to its 12M average of {activeOrgInfo.increaseAvg} per month.
            </li>
        );
    } else {
        return <></>;
    }
};

const mostActiveInstitution = (data: recentUpdates) => {
    const activeInstitutionInfo = data.mostActiveInstitution!;
    if (
        activeInstitutionInfo.activeCode &&
        activeInstitutionInfo.activeName &&
        activeInstitutionInfo.increaseCode &&
        activeInstitutionInfo.increaseName &&
        (activeInstitutionInfo.increaseCount > 0 || activeInstitutionInfo.increaseAvg > 0)
    ) {
        return (
            <li>
                <LinkFormatter
                    urlFormatStr={institutionLinkFormatter(
                        activeInstitutionInfo.activeCode,
                        activeInstitutionInfo.activeName
                    )}
                />{' '}
                was the <span className="font-medium">most lobbied institution</span> on the issue (
                {activeInstitutionInfo.activeCount} reports in {data.month}).{' '}
                <LinkFormatter
                    urlFormatStr={institutionLinkFormatter(
                        activeInstitutionInfo.increaseCode,
                        activeInstitutionInfo.increaseName
                    )}
                />{' '}
                was lobbied more heavily than usual on the issue, with{' '}
                {activeInstitutionInfo.increaseCount} reports compared to a 12M average of{' '}
                {activeInstitutionInfo.increaseAvg} per month.
            </li>
        );
    } else {
        return <></>;
    }
};

const mostActiveDpoh = (data: recentUpdates) => {
    const activeDpohInfo = data.mostActiveDpoh;
    if (
        activeDpohInfo.activeCode &&
        activeDpohInfo.activeParentName &&
        activeDpohInfo.activeName &&
        activeDpohInfo.increaseCode &&
        activeDpohInfo.increaseParentName &&
        activeDpohInfo.increaseName &&
        (activeDpohInfo.increaseCount > 0 || activeDpohInfo.increaseAvg > 0)
    ) {
        return (
            <li>
                <LinkFormatter
                    urlFormatStr={DPOHLinkFormatter(
                        activeDpohInfo.activeCode,
                        activeDpohInfo.activeParentName,
                        activeDpohInfo.activeName
                    )}
                />{' '}
                was the <span className="font-medium">top DPOH target</span> for organizations
                lobbying on the issue in {data.month} ({activeDpohInfo.activeCount} reports).{' '}
                <LinkFormatter
                    urlFormatStr={DPOHLinkFormatter(
                        activeDpohInfo.increaseCode,
                        activeDpohInfo.increaseParentName,
                        activeDpohInfo.increaseName
                    )}
                />{' '}
                was lobbied more heavily than usual, with {activeDpohInfo.increaseCount} reports
                compared to a 12M average of {activeDpohInfo.increaseAvg} per month.
            </li>
        );
    } else {
        return <></>;
    }
};

const monthlyKeywords = (data: recentUpdates) => {
    const keywords = data.keywordMentions!;
    if (keywords > 0) {
        return (
            <li>
                Issue keywords were mentioned {keywords} time(s) in Hansard and Committee meetings
                in {data.month}
            </li>
        );
    } else {
        return <></>;
    }
};

const monthlyMpList = (data: recentUpdates) => {
    const mps = data.mpList!;
    if (mps.length > 0) {
        return (
            <li>
                <span className="font-medium">MPs who mentioned</span> issue keywords in Hansard and
                Committee meetings in {data.month}:{' '}
                {mps.map((word: string, idx: number) => {
                    return (
                        <span key={idx}>
                            {word}
                            {idx === mps.length - 1 ? '' : ', '}
                        </span>
                    );
                })}
            </li>
        );
    } else {
        return <></>;
    }
};

const WhatsNew = ({ data, name }: { data: recentUpdates; name: string }) => {
    return (
        <div className="lg:h-auto h-72 overflow-auto flex flex-col gap-3 text-slate-700 bg-white p-4 rounded-md shadow-md">
            {data.keywordMentions ? monthlyKeywords(data) : <></>}
            {data.mpList ? monthlyMpList(data) : <></>}
            {overallActivity(data)}
            {recentRegistrants(data.newRegistrants, data.month)}
            {mostActiveOrg(data)}
            {data.mostActiveInstitution ? mostActiveInstitution(data) : <></>}
            {mostActiveDpoh(data)}
        </div>
    );
};

export default WhatsNew;
