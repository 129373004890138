import { Divider, Menu } from '@mui/material';

import ExpandLessRoundedIcon from '@mui/icons-material/ExpandLessRounded';
import ExpandMoreRoundedIcon from '@mui/icons-material/ExpandMoreRounded';
import { Entity, EntityCategory, EntityRepo } from '../../features/config/EntityRepo';
import { EntityLink } from '../../features/navigation/UI/EntityDropDown';

interface Props {
    open: boolean;
    handleClick: (event: React.MouseEvent<HTMLButtonElement>) => void;
    handleClose: () => void;
    anchorEl: HTMLElement | null;
}

const DashboardsButton = ({ open, handleClick, handleClose, anchorEl }: Props) => {
    const coreEntities = EntityRepo.getEntityListByCategory(EntityCategory.CORE);
    const personalEntities = EntityRepo.getEntityListByCategory(EntityCategory.PERSONAL);
    const globalEntities = EntityRepo.getEntityListByCategory(EntityCategory.GLOBAL);

    return (
        <div
            className={`${
                open
                    ? 'bg-none outline outline-1 rounded-md text-liq-gray-blue'
                    : 'bg-liq-blue rounded-md text-white'
            }`}
        >
            <button
                className="py-1 px-3 flex flex-row justify-center"
                id="dashboards-button"
                aria-controls={open ? 'basic-menu' : undefined}
                aria-haspopup="true"
                aria-expanded={open ? 'true' : undefined}
                onClick={handleClick}
            >
                Dashboards
                {open ? <ExpandLessRoundedIcon /> : <ExpandMoreRoundedIcon />}
            </button>
            <Menu
                sx={{
                    marginTop: '14px',
                    '& .MuiMenu-paper': {
                        backgroundColor: 'white',
                        borderTopWidth: 6,
                        borderTopColor: '#67A8CC',
                        borderRadius: '0px 0px 10px 10px',
                        boxShadow: 'rgba(0, 0, 0, 0.2) 0px 4px 4px -2px',
                    },
                }}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'center',
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'center',
                }}
                anchorEl={anchorEl}
                open={open}
                onClose={handleClose}
                disableScrollLock={true}
            >
                {coreEntities.map((entity: Entity, i: number) => (
                    <EntityLink key={i} entity={entity} handleClose={handleClose} />
                ))}
                <Divider sx={{ marginY: 1 }} />
                {personalEntities.map((entity: Entity, i: number) => (
                    <EntityLink key={i} entity={entity} handleClose={handleClose} />
                ))}
                <Divider sx={{ marginY: 1 }} />
                {globalEntities.map((entity: Entity, i: number) => (
                    <EntityLink key={i} entity={entity} handleClose={handleClose} />
                ))}
            </Menu>
        </div>
    );
};

export default DashboardsButton;
