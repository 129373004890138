import React, { useEffect } from 'react';
import {
    fetchInactiveOrganizationList,
    selectInactiveOrganizationList,
    selectInactiveOrganizationListLoading,
} from '../../features/sector-dashboards/store/sectorListSlice';
import { useSelector } from 'react-redux';
import { useAppDispatch } from '../../store/store';
import EntityListPage from './EntityListPage';
import { useParams } from 'react-router-dom';
import { entityListExtra } from '../../interfaces/generic-entity.interface';

const InactiveOrganizationListDataLayer = () => {
    const url_params = useParams();

    const entity_code = url_params.entityId !== undefined ? url_params.entityId : '';

    const dispatch = useAppDispatch();

    const entity_list: entityListExtra[] | null = useSelector(selectInactiveOrganizationList);
    const loading = useSelector(selectInactiveOrganizationListLoading);

    useEffect(() => {
        dispatch(fetchInactiveOrganizationList({ code: entity_code }));
    }, [dispatch, entity_code]);

    return (
        <EntityListPage type="inactive organizations" list={entity_list || []} loading={loading} />
    );
};

export default InactiveOrganizationListDataLayer;
