import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';
import { RootState } from '../store';
import {
    entityListSimple,
    entityLobbyingActivity,
    entityRegistration,
} from '../../interfaces/generic-entity.interface';
import moment from 'moment';
import { iterateQueryCount, reduceRemainingRows } from './userSlice';

interface QueryState {
    comm_results: any[] | null;
    comm_results_loading: boolean;
    comm_results_fail: boolean;

    reg_results: any[] | null;
    reg_results_loading: boolean;
    reg_results_fail: boolean;

    download_loading: boolean;
    download_fail: boolean;

    sector_list: entityListSimple[] | null;
    sector_list_loading: boolean;
    sector_list_fail: boolean;

    inst_list: entityListSimple[] | null;
    inst_list_loading: boolean;
    inst_list_fail: boolean;

    org_list: entityListSimple[] | null;
    org_list_loading: boolean;
    org_list_fail: boolean;

    dpoh_list: entityListSimple[] | null;
    dpoh_list_loading: boolean;
    dpoh_list_fail: boolean;

    firm_list: entityListSimple[] | null;
    firm_list_loading: boolean;
    firm_list_fail: boolean;

    consultant_list: entityListSimple[] | null;
    consultant_list_loading: boolean;
    consultant_list_fail: boolean;

    subject_list: entityListSimple[] | null;
    subject_list_loading: boolean;
    subject_list_fail: boolean;
}

const initialState: QueryState = {
    comm_results: null,
    comm_results_loading: false,
    comm_results_fail: false,

    reg_results: null,
    reg_results_loading: false,
    reg_results_fail: false,

    download_loading: false,
    download_fail: false,

    sector_list: null,
    sector_list_loading: false,
    sector_list_fail: false,

    inst_list: null,
    inst_list_loading: false,
    inst_list_fail: false,

    org_list: null,
    org_list_loading: false,
    org_list_fail: false,

    dpoh_list: null,
    dpoh_list_loading: false,
    dpoh_list_fail: false,

    firm_list: null,
    firm_list_loading: false,
    firm_list_fail: false,

    consultant_list: null,
    consultant_list_loading: false,
    consultant_list_fail: false,

    subject_list: null,
    subject_list_loading: false,
    subject_list_fail: false,
};

const baseURL = process.env.REACT_APP_API_BASE_URL;

export const fetchQuerySectorList = createAsyncThunk(
    'sector/fetchQuerySectorList',
    async (): Promise<entityListSimple[]> => {
        const response = await axios.get(`${baseURL}/customquery/comms/sectorlist`);
        const data: entityListSimple[] = response.data.sectorList;
        return data;
    }
);

export const fetchQueryInstitutionList = createAsyncThunk(
    'sector/fetchQueryInstitutionList',
    async (): Promise<entityListSimple[]> => {
        const response = await axios.get(`${baseURL}/customquery/comms/institutionlist`);
        const data: entityListSimple[] = response.data.institutionList;
        return data;
    }
);

export const fetchQueryOrgList = createAsyncThunk(
    'sector/fetchQueryOrgList',
    async (ind_code: string | null): Promise<entityListSimple[]> => {
        const response = await axios.get(
            `${baseURL}/customquery/comms/orglist${ind_code ? `?ind=${ind_code}` : ''}`
        );
        const data: entityListSimple[] = response.data.orgList;
        return data;
    }
);

export const fetchQueryDPOHList = createAsyncThunk(
    'sector/fetchQueryDPOHList',
    async (inst_code: string | null): Promise<entityListSimple[]> => {
        const response = await axios.get(
            `${baseURL}/customquery/comms/dpohlist${inst_code ? `?inst=${inst_code}` : ''}`
        );
        const data: entityListSimple[] = response.data.dpohList;
        return data;
    }
);

export const fetchQueryFirmList = createAsyncThunk(
    'sector/fetchQueryFirmList',
    async (): Promise<entityListSimple[]> => {
        const response = await axios.get(`${baseURL}/customquery/comms/firmlist`);
        const data: entityListSimple[] = response.data.firmList;
        return data;
    }
);

export const fetchQueryConsultantList = createAsyncThunk(
    'sector/fetchQueryConsultantList',
    async ({
        firm_code,
        ind_code,
        inst_code,
    }: {
        firm_code: string;
        ind_code: number | null;
        inst_code: number | null;
    }): Promise<entityListSimple[]> => {
        const response = await axios.get(
            `${baseURL}/customquery/comms/consultantlist?firm=${firm_code}${
                ind_code ? `&ind=${inst_code}` : ''
            }${inst_code ? `&inst=${inst_code}` : ''}`
        );
        const data: entityListSimple[] = response.data.consultantList;
        return data;
    }
);

export const fetchQuerySubjectList = createAsyncThunk(
    'sector/fetchQuerySubjectList',
    async (): Promise<entityListSimple[]> => {
        const response = await axios.get(`${baseURL}/customquery/comms/subjectlist`);
        const data: entityListSimple[] = response.data.subjectList;
        return data;
    }
);

export const fetchCommsResults = createAsyncThunk(
    'sector/fetchCommsResults',
    async (
        {
            ind_code,
            inst_code,
            org,
            dpoh,
            firm,
            con,
            sub,
            startDate,
            endDate,
        }: {
            ind_code: string | null;
            inst_code: string | null;
            org: string | null;
            dpoh: string | null;
            firm: string | null;
            con: string | null;
            sub: string | null;
            startDate: string;
            endDate: string;
        },
        thunk
    ): Promise<entityLobbyingActivity[]> => {
        const response = await axios.get(
            `${baseURL}/customquery/comms/request?ind=${ind_code}&inst=${inst_code}${
                org ? `&org=${encodeURIComponent(org)}` : ''
            }${dpoh ? `&dpoh=${encodeURIComponent(dpoh)}` : ''}${
                firm ? `&firm=${encodeURIComponent(firm)}` : ''
            }${con ? `&con=${encodeURIComponent(con)}` : ''}${
                sub ? `&sub=${encodeURIComponent(sub)}` : ''
            }&start=${startDate}&end=${endDate}`
        );
        const data: entityLobbyingActivity[] = response.data.results;
        const converted_data: entityLobbyingActivity[] = data.map((entry) => {
            return {
                ...entry,
                date: moment.utc(entry.date).format('YYYY-MM-DD'),
                organization: entry.client,
                lobby_firm: entry.lobby_firm === null ? 'In-House' : entry.lobby_firm,
                consultant: entry.lobby_firm === null ? 'In-House' : entry.consultant,
                link: `https://lobbycanada.gc.ca/app/secure/ocl/lrs/do/cmmLgPblcVw?comlogId=${entry.comm}`,
            };
        });
        if (converted_data.length !== 0) {
            thunk.dispatch(iterateQueryCount());
        }
        return converted_data;
    }
);

export const fetchRegResults = createAsyncThunk(
    'sector/fetchRegResults',
    async (
        {
            ind_code,
            org,
            dpoh,
            firm,
            con,
            sub,
            startDate,
            endDate,
        }: {
            ind_code: number | null;
            org: string | null;
            dpoh: string | null;
            firm: string | null;
            con: string | null;
            sub: string | null;
            startDate: string;
            endDate: string;
        },
        thunk
    ): Promise<entityRegistration[]> => {
        const response = await axios.get(
            `${baseURL}/customquery/reg/request?ind=${ind_code}${
                org ? `&org=${encodeURIComponent(org)}` : ''
            }${dpoh ? `&dpoh=${encodeURIComponent(dpoh)}` : ''}${
                firm ? `&firm=${encodeURIComponent(firm)}` : ''
            }${con ? `&con=${encodeURIComponent(con)}` : ''}${
                sub ? `&sub=${encodeURIComponent(sub)}` : ''
            }&start=${startDate}&end=${endDate}`
        );
        const data: entityRegistration[] = response.data.results;
        const converted_data: entityRegistration[] = data.map((entry) => {
            return {
                ...entry,
                date: moment.utc(entry.date).format('YYYY-MM-DD'),
                organization: entry.client,
                lobby_firm: entry.lobby_firm === null ? 'In-House' : entry.lobby_firm,
                consultant: entry.lobby_firm === null ? 'In-House' : entry.consultant,
                history: entry.history ? 'Renewed' : 'New',
                link: `https://lobbycanada.gc.ca/app/secure/ocl/lrs/do/vwRg?cno=${entry.corp}&regId=${entry.registration}#regStart`,
            };
        });
        if (converted_data.length !== 0) {
            thunk.dispatch(iterateQueryCount());
        }
        return converted_data;
    }
);

export const updateRowsDownloaded = createAsyncThunk(
    'sector/updateRowsDownloaded',
    async (rowCount: number, thunk): Promise<number> => {
        const response = await axios.post(`${baseURL}/customquery/download`, {
            rowCount,
        });
        const newRemainingDownloads: number = response.data.rowsToDownload;

        if (newRemainingDownloads) {
            thunk.dispatch(reduceRemainingRows(newRemainingDownloads));
        }

        return newRemainingDownloads;
    }
);

const querySlice = createSlice({
    name: 'queryReducer',
    initialState,
    reducers: {
        clearCommResults(state) {
            state.comm_results = null;
        },
        clearRegResults(state) {
            state.reg_results = null;
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(fetchQuerySectorList.pending, (state) => {
                state.sector_list_loading = true;
            })
            .addCase(fetchQuerySectorList.fulfilled, (state, action) => {
                state.sector_list_loading = false;
                state.sector_list = action.payload;
            })
            .addCase(fetchQuerySectorList.rejected, (state) => {
                state.sector_list_loading = false;
                state.sector_list = null;
            })
            .addCase(fetchQueryInstitutionList.pending, (state) => {
                state.inst_list_loading = true;
            })
            .addCase(fetchQueryInstitutionList.fulfilled, (state, action) => {
                state.inst_list_loading = false;
                state.inst_list_fail = false;
                state.inst_list = action.payload;
            })
            .addCase(fetchQueryInstitutionList.rejected, (state) => {
                state.inst_list_loading = false;
                state.inst_list_fail = true;
                state.inst_list = null;
            })
            .addCase(fetchQueryOrgList.pending, (state) => {
                state.org_list_loading = true;
            })
            .addCase(fetchQueryOrgList.fulfilled, (state, action) => {
                state.org_list_loading = false;
                state.org_list_fail = false;
                state.org_list = action.payload;
            })
            .addCase(fetchQueryOrgList.rejected, (state) => {
                state.org_list_loading = false;
                state.org_list_fail = true;
                state.org_list = null;
            })
            .addCase(fetchQueryDPOHList.pending, (state) => {
                state.dpoh_list_loading = true;
            })
            .addCase(fetchQueryDPOHList.fulfilled, (state, action) => {
                state.dpoh_list_loading = false;
                state.dpoh_list_fail = false;
                state.dpoh_list = action.payload;
            })
            .addCase(fetchQueryDPOHList.rejected, (state) => {
                state.dpoh_list_loading = false;
                state.dpoh_list_fail = true;
                state.dpoh_list = null;
            })
            .addCase(fetchQueryFirmList.pending, (state) => {
                state.firm_list_loading = true;
            })
            .addCase(fetchQueryFirmList.fulfilled, (state, action) => {
                state.firm_list_loading = false;
                state.firm_list_fail = false;
                state.firm_list = action.payload;
            })
            .addCase(fetchQueryFirmList.rejected, (state) => {
                state.firm_list_loading = false;
                state.firm_list_fail = true;
                state.firm_list = null;
            })
            .addCase(fetchQueryConsultantList.pending, (state) => {
                state.consultant_list_loading = true;
            })
            .addCase(fetchQueryConsultantList.fulfilled, (state, action) => {
                state.consultant_list_loading = false;
                state.consultant_list_fail = false;
                state.consultant_list = action.payload;
            })
            .addCase(fetchQueryConsultantList.rejected, (state) => {
                state.consultant_list_loading = false;
                state.consultant_list_fail = true;
                state.consultant_list = null;
            })
            .addCase(fetchQuerySubjectList.pending, (state) => {
                state.subject_list_loading = true;
            })
            .addCase(fetchQuerySubjectList.fulfilled, (state, action) => {
                state.subject_list_loading = false;
                state.subject_list_fail = false;
                state.subject_list = action.payload;
            })
            .addCase(fetchQuerySubjectList.rejected, (state) => {
                state.subject_list_loading = false;
                state.subject_list_fail = true;
                state.subject_list = null;
            })
            .addCase(fetchCommsResults.pending, (state) => {
                state.comm_results_loading = true;
            })
            .addCase(fetchCommsResults.fulfilled, (state, action) => {
                state.comm_results_loading = false;
                state.comm_results_fail = false;
                state.comm_results = action.payload;
            })
            .addCase(fetchCommsResults.rejected, (state) => {
                state.comm_results_loading = false;
                state.comm_results_fail = true;
                state.comm_results = null;
            })
            .addCase(fetchRegResults.pending, (state) => {
                state.reg_results_loading = true;
            })
            .addCase(fetchRegResults.fulfilled, (state, action) => {
                state.reg_results_loading = false;
                state.reg_results_fail = false;
                state.reg_results = action.payload;
            })
            .addCase(fetchRegResults.rejected, (state) => {
                state.reg_results_loading = false;
                state.reg_results_fail = true;
                state.reg_results = null;
            })
            .addCase(updateRowsDownloaded.pending, (state) => {
                state.download_loading = true;
                state.download_fail = false;
            })
            .addCase(updateRowsDownloaded.fulfilled, (state) => {
                state.download_loading = false;
            })
            .addCase(updateRowsDownloaded.rejected, (state) => {
                state.download_loading = false;
                state.download_fail = true;
            });
    },
});

export const { clearCommResults, clearRegResults } = querySlice.actions;

export const selectQuerySectorList = (state: RootState) => state.query.sector_list;
export const selectSectorListLoading = (state: RootState) => state.query.sector_list_loading;

export const selectQueryInstitutionList = (state: RootState) => state.query.inst_list;
export const selectQueryInstitutionListLoading = (state: RootState) =>
    state.query.inst_list_loading;

export const selectQueryOrgList = (state: RootState) => state.query.org_list;
export const selectQueryOrgListLoading = (state: RootState) => state.query.org_list_loading;

export const selectQueryDPOHList = (state: RootState) => state.query.dpoh_list;
export const selectQueryDPOHListLoading = (state: RootState) => state.query.dpoh_list_loading;

export const selectQueryFirmList = (state: RootState) => state.query.firm_list;
export const selectQueryFirmListLoading = (state: RootState) => state.query.firm_list_loading;

export const selectQueryConsultantList = (state: RootState) => state.query.consultant_list;
export const selectQueryConsultantListLoading = (state: RootState) =>
    state.query.consultant_list_loading;

export const selectQuerySubjectList = (state: RootState) => state.query.subject_list;
export const selectQuerySubjectListLoading = (state: RootState) => state.query.subject_list_loading;

export const selectQueryCommResults = (state: RootState) => state.query.comm_results;
export const selectQueryCommResultsLoading = (state: RootState) => state.query.comm_results_loading;
export const selectQueryCommResultsFail = (state: RootState) => state.query.comm_results_fail;

export const selectQueryRegResults = (state: RootState) => state.query.reg_results;
export const selectQueryRegResultsLoading = (state: RootState) => state.query.reg_results_loading;
export const selectQueryRegResultsFail = (state: RootState) => state.query.reg_results_fail;

export const selectDownloadsLoading = (state: RootState) => state.query.download_loading;
export const selectDownloadsFail = (state: RootState) => state.query.download_fail;

export default querySlice.reducer;
