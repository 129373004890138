import React from 'react';
import { PieChart, Pie, Cell, Tooltip, ResponsiveContainer } from 'recharts';
import PieIcon from '@mui/icons-material/DonutLargeRounded';
import Circle from '@mui/icons-material/LensRounded';
import { COLORS } from '../../constants/colors';

// Title is an optional parameters with default specified in function signature below.
interface breakdownProps {
    title?: string;
    breakdownData: any;
    dataKey: string;
    colors?: { primary: string; secondary: string }[];
    embedded?: boolean;
}

const RADIAN = Math.PI / 180;
const renderCustomizedLabel = ({
    cx,
    cy,
    midAngle,
    innerRadius,
    outerRadius,
    value,
    payload,
}: any) => {
    const radius = innerRadius + (outerRadius - innerRadius) * 1.2;
    const x = cx + radius * Math.cos(-midAngle * RADIAN);
    const y = cy + radius * Math.sin(-midAngle * RADIAN);
    return (
        <text
            className="font-medium text-xs"
            x={x}
            y={y}
            fill={payload.color}
            textAnchor="middle"
            dominantBaseline="central"
        >
            {payload.portion > 0 ? Math.floor(payload.portion) + '%' : ''}
        </text>
    );
};

const CustomTooltip = ({ active, payload }: any) => {
    if (active && payload && payload.length) {
        return (
            <div className="bg-white p-3 rounded-xl shadow-lg border border-1 border-slate-100">
                <p>{`${payload[0].name}`}</p>
                <p>{`${payload[0].value} Comms`}</p>
            </div>
        );
    }
    return null;
};

/*
    Component: Breakdown.tsx
    Params: title - title of the tile
    Author: William Brewer
    Desc: Breakdown tile contains a list of top 6 lobbying organizations as well as a pie chart breakdown.
*/
const Breakdown = ({
    title = 'Title',
    breakdownData,
    dataKey,
    colors = COLORS,
    embedded = false,
}: breakdownProps) => {
    if (breakdownData === null || breakdownData.length === 0)
        return (
            <div
                className={`h-full flex flex-col justify-between ${
                    embedded ? '' : 'bg-white shadow-md rounded-md'
                } p-4`}
            >
                <div>
                    <div className="lg:text-md lg:font-medium flex flex-row items-center space-x-2 mb-2">
                        <PieIcon color="success" />
                        <div>{title}</div>
                    </div>
                    <hr className="bg-slate-50 col-span-12 my-4" />
                    Data Unavailable
                </div>
            </div>
        );
    else {
        const total = breakdownData
            .map((i: any) => i[dataKey])
            .reduce((a: any, b: any) => a + b);

        const orgs = breakdownData.map((org: any, idx: number) => {
            return {
                ...org,
                color: colors[idx].primary,
                portion: (org[dataKey] / total) * 100,
            };
        });

        return (
            <div
                className={`h-full flex flex-col justify-between ${
                    embedded ? '' : 'bg-white shadow-md rounded-md'
                } p-4`}
            >
                <div>
                    <div className="lg:text-md lg:font-medium flex flex-row items-center space-x-2 mb-2">
                        <PieIcon color="success" />
                        <div>{title}</div>
                    </div>
                    <hr className="bg-slate-50 col-span-12 my-4" />
                    <ResponsiveContainer height={225} width="100%">
                        <PieChart>
                            <Pie
                                isAnimationActive={false}
                                dataKey={dataKey}
                                data={orgs}
                                label={renderCustomizedLabel}
                                labelLine={false}
                            >
                                {orgs.map((entry: any, idx: number) => (
                                    <Cell
                                        key={`cell-${idx}`}
                                        fill={colors[idx].primary}
                                    />
                                ))}
                            </Pie>
                            <Tooltip content={CustomTooltip} />
                        </PieChart>
                    </ResponsiveContainer>
                </div>
                <div>
                    {orgs.map((org: any, idx: number) => {
                        return (
                            <div
                                key={idx}
                                className={`${
                                    org.count > 0 ? 'flex' : 'hidden'
                                } flex-row items-center space-x-2`}
                            >
                                <Circle
                                    style={{
                                        color: colors[idx].primary,
                                        fontSize: 10,
                                    }}
                                    fontSize="inherit"
                                />
                                <div
                                    style={{ color: colors[idx].primary }}
                                    className="font-medium text-sm"
                                >
                                    {org.name} ({org.count})
                                </div>
                            </div>
                        );
                    })}
                </div>
            </div>
        );
    }
};

export default Breakdown;
