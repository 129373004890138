export const SectorLobbyDeregistrations = (
    <div className="flex flex-col gap-2">
        <div>
            This exhibit lists the registrations that were de-registered and
            not renewed in a sector in the last 3 months.
        </div>
        <div>
            Province denotes the province of a client firm’s self-reported
            address (which can verified in the reported link to the primary
            record).
        </div>
    </div>
);
