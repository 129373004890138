import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';
import { RootState } from '../../../store/store';
import { entityList, entityListExtra } from '../../../interfaces/generic-entity.interface';
import { percentage_change_num } from '../../../helpers/percentage_change';
import { issueEditorItem } from '../interface/issue-entity.interface';

interface IssueListState {
    list: entityListExtra[] | null;
    customList: issueEditorItem[];
    loading: boolean;
}

const initialState: IssueListState = {
    list: null,
    customList: [],
    loading: false,
};

const baseURL = process.env.REACT_APP_API_BASE_URL;

export const fetchIssueList = createAsyncThunk(
    'issue/fetchList',
    async (): Promise<entityListExtra[]> => {
        const response = await axios.get(`${baseURL}/issue/list`);
        const publicIssues: entityList[] = response.data.issueList;
        // keyword on personal issues ignored here
        let personalIssues: entityList[] = [];

        try {
            const personalResponse = await axios.get(`${baseURL}/issue/list/personal`);
            personalIssues = personalResponse.data.issueList;
        } catch (error) {
            console.log('Only showing public issues');
        }
        const combinedIssues = publicIssues.concat(personalIssues);
        const converted_data: entityListExtra[] = combinedIssues.map((entry) => {
            return {
                ...entry,
                // Calculate with potential nulls replaced by 0
                change_abs: (entry.last_month || 0) - (entry.two_months_ago || 0),
                change_rel: percentage_change_num(entry.two_months_ago || 0, entry.last_month || 0),
                available: true,
            };
        });
        return converted_data;
    }
);

export const fetchCustomIssueList = createAsyncThunk(
    'issue/fetchCustomIssueList',
    async (): Promise<issueEditorItem[]> => {
        const personalResponse = await axios.get(`${baseURL}/issue/list/personal`);
        const personalIssues: issueEditorItem[] = personalResponse.data.issueList;

        return personalIssues;
    }
);

const issueListSlice = createSlice({
    name: 'issueListReducer',
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(fetchIssueList.pending, (state) => {
                state.loading = true;
            })
            .addCase(fetchIssueList.fulfilled, (state, action) => {
                state.loading = false;
                state.list = action.payload;
            })
            .addCase(fetchIssueList.rejected, (state) => {
                state.loading = false;
                state.list = null;
            })
            .addCase(fetchCustomIssueList.pending, (state) => {
                state.loading = true;
            })
            .addCase(fetchCustomIssueList.fulfilled, (state, action) => {
                state.loading = false;
                state.customList = action.payload;
            })
            .addCase(fetchCustomIssueList.rejected, (state) => {
                state.loading = false;
                state.customList = [];
            });
    },
});

export const selectIssueList = (state: RootState) => state.issueList.list;
export const selectCustomIssueList = (state: RootState) => state.issueList.customList;
export const selectIssueListLoading = (state: RootState) => state.issueList.loading;

export default issueListSlice.reducer;
