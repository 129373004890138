import { Breadcrumbs } from '@mui/material';
import React from 'react';
import { Link } from 'react-router-dom';

import liq_badge from '../assets/vectors/LIQ_badge.png';

const Features = () => {
    return (
        <div className="lg:py-32 py-20">
            <div className="grid grid-cols-12">
                <Breadcrumbs className="col-start-2 col-end-12" separator=">">
                    <Link to="/" className="hover:underline">
                        Lobby<b>IQ</b>
                    </Link>
                    <div>Features</div>
                </Breadcrumbs>
            </div>

            <div className="grid grid-cols-12 lg:mt-12 mt-4 py-8">
                <div className="col-start-2 lg:col-end-8 col-end-12 flex flex-col">
                    <h1 className="lg:text-4xl text-3xl mb-8">
                        Lobby<b className="font-black">IQ</b>{' '}
                        <span>Capabilities</span>
                    </h1>
                    <div className="text-lg lg:mt-8 font-light">
                        LobbyIQ's user-friendly dashboards and query
                        capabilities provide the most up-to-date, comprehensive
                        and accessible view of federal lobbying communications
                        and registrations within each industry-sector and
                        institution. Our algorithms combine registry data with
                        text-data from committee meetings, parliamentary
                        debates, and government announcements to help our
                        clients recognize trends and anomalies before others. In
                        other words, LobbyIQ provides <b>insights</b> and{' '}
                        <b>intelligence</b> on the lobbying landscape for
                        government relations executives.
                    </div>
                </div>
                <div className="col-start-9 col-end-12 lg:flex hidden flex-col items-center justify-center text-center text-slate-600">
                    <img src={liq_badge} className="w-72" alt="logo" />
                </div>
            </div>

            <div className="grid grid-cols-12 py-14 lg:gap-12 gap-y-8 lg:gap-y-0">
                <div className="col-start-2 lg:col-end-7 col-end-12 flex">
                    <div className="bg-slate-50 border border-slate-100 p-6 rounded-xl shadow-md">
                        <img alt="" src={process.env.PUBLIC_URL + '/sector_dashboard.png'} />
                    </div>
                </div>
                <div className="lg:col-start-7 col-start-2 col-end-12 flex">
                    <div className="bg-slate-50 border border-slate-100 p-6 rounded-xl shadow-md">
                        <img alt="" src={process.env.PUBLIC_URL + '/sector_dashboard2.png'} />
                    </div>
                </div>
            </div>

            <div className="grid grid-cols-12 py-14 lg:gap-12 gap-y-8 lg:gap-y-0">
                <div className="col-start-2 lg:col-end-7 col-end-12">
                    <div className="lg:h-16 text-slate-600 text-lg">
                        Our Sector Dashboards Explained, with a Case Study of
                        the Volkswagen Battery Plant Subsidy.
                    </div>
                    <hr className="h-0.5 my-3 bg-slate-100 rounded" />
                    <iframe
                        className="w-full h-96 rounded-md shadow-md"
                        src={`https://www.youtube.com/embed/CFAR1VSGoeo`}
                        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                        allowFullScreen
                        title="Embedded youtube"
                    />
                </div>
                <div className="lg:col-start-7 col-start-2 col-end-12">
                    <div className="lg:h-16 text-slate-600 text-lg">
                        Our Other Dashboards Explained, with a Case Study of the
                        Origins of Bill C-18
                    </div>
                    <hr className="h-0.5 my-3 bg-slate-100 rounded" />
                    <iframe
                        className="w-full h-96 rounded-md shadow-md"
                        src={`https://www.youtube.com/embed/4qQfwhtxPxs`}
                        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                        allowFullScreen
                        title="Embedded youtube"
                    />
                </div>
            </div>

            <div className="grid grid-cols-12 py-8">
                <div className="col-start-2 lg:col-end-5 col-end-12 pt-6 pr-6 mb-6">
                    <div className="lg:text-4xl text-xl">
                        Sector, Institution, and Issue Dashboards
                    </div>
                    <div className="text-md mt-8 font-light lg:text-xl">
                        Comprehensive classification of lobbying by sector,
                        government institution, and HoC committee
                    </div>
                </div>
                <div className="lg:col-start-5 col-start-2 col-end-12 grid grid-cols-12 gap-6">
                    <div className="lg:col-span-6 col-span-12 bg-white shadow-md p-6 rounded-xl flex flex-col justify-between min-h-[20rem]">
                        <div>
                            <div className="text-2xl">
                                Monitor Lobbying
                                <br />
                                by{' '}
                                <span className="text-liq-green">Sector</span>
                            </div>
                            <div className="text-md mt-8 font-light">
                                We help our clients put lobbying data into the
                                correct context, by collating the federal lobby
                                registry data into sector dashboards with key
                                real-time metrics on lobbying by all
                                corporations and organizations within a sector.
                                Our text-based data analytics also reveal the
                                policy issues driving new registrations and
                                lobbying communications in each sector.
                            </div>
                        </div>
                        <div className="flex flex-row justify-between items-center mt-8">
                            <Link
                                to="/sectors"
                                className="flex h-8 flex-row items-center bg-slate-100 text-slate-600 border border-transparent hover:border-slate-300 rounded-full px-6 py-1"
                            >
                                Select a Sector
                            </Link>
                        </div>
                    </div>
                    <div className="lg:col-span-6 col-span-12 bg-white shadow-md p-6 rounded-xl flex flex-col justify-between min-h-[20rem]">
                        <div>
                            <div className="text-2xl">
                                Track{' '}
                                <span className="text-liq-orange">
                                    Organization
                                </span>
                                <br />
                                Lobbying
                            </div>
                            <div className="text-md mt-8 font-light">
                                Need to coordinate messaging across multiple
                                organizations, or need up-to-date information on
                                competitors' lobbying? Our organization
                                dashboards, organized by sector, provide
                                real-time statistics on lobbying, new
                                registrations and deregistrations
                            </div>
                        </div>
                        <div className="flex flex-row items-center justify-between mt-8">
                            <Link
                                to="/sectors"
                                className="flex h-8 flex-row items-center bg-slate-100 text-slate-600 border border-transparent hover:border-slate-300 rounded-full px-6 py-1"
                            >
                                Select a Sector
                            </Link>
                        </div>
                    </div>
                    <div className="lg:col-span-6 col-span-12 bg-white shadow-md p-6 rounded-xl flex flex-col justify-between min-h-[20rem]">
                        <div>
                            <div className="text-2xl">
                                Monitor{' '}
                                <span className="text-liq-pink">
                                    {' '}
                                    Policymakers
                                    <br />& Institutions
                                </span>{' '}
                            </div>
                            <div className="text-md mt-8 font-light">
                                Institution dashboards provide real-time metrics
                                on the lobbying of each federal government
                                institution and individual DPOHs. In addition to
                                highlighting the recorded Subjects of lobbying
                                communications, text-based analytics of
                                registrations identify the legislative, policy,
                                and regulatory issues that drive lobbying
                                activity within an institution or sector.
                            </div>
                        </div>
                        <div className="flex flex-row items-center justify-between mt-8">
                            <Link
                                to="/institutions"
                                className="flex h-8 flex-row items-center bg-slate-100 text-slate-600 border border-transparent hover:border-slate-300 rounded-full px-6 py-1"
                            >
                                Select an Institution
                            </Link>
                        </div>
                    </div>
                    <div className="lg:col-span-6 col-span-12 bg-white shadow-md p-6 rounded-xl flex flex-col justify-between min-h-[20rem]">
                        <div>
                            <div className="text-2xl">
                                Monitor{' '}
                                <span className="text-liq-gray-blue">
                                    {' '}
                                    Committee
                                    <br />
                                    Activity
                                </span>{' '}
                            </div>
                            <div className="text-md mt-8 font-light">
                                House of Commons Committee dashboards track
                                lobbying of the MPs on each Committee and the
                                key policy issues that are discussed during
                                committee meetings. Dashboards include links to
                                each MP's Instagram, Facebook and Twitter/X
                                accounts, enabling clients to easily monitor
                                their public communications.
                            </div>
                        </div>
                        <div className="flex flex-row items-center justify-between mt-8">
                            <Link
                                to="/institutions"
                                className="flex h-8 flex-row items-center bg-slate-100 text-slate-600 border border-transparent hover:border-slate-300 rounded-full px-6 py-1"
                            >
                                Select an Institution
                            </Link>
                        </div>
                    </div>
                    <div className="lg:col-span-6 col-span-12 bg-white shadow-md p-6 rounded-xl flex flex-col justify-between min-h-[20rem]">
                        <div>
                            <div className="text-2xl">
                                Identify{' '}
                                <span className="text-liq-red">
                                    Emerging
                                    <br />
                                    Issues
                                </span>
                            </div>
                            <div className="text-md mt-8 font-light">
                                Issue dashboards allow clients to track the
                                salience of major policy topics discussed in
                                House of Commons Committee meetings and MPs'
                                parliamentary debate speeches. Dashboards
                                provide a calendar view of when major policy
                                topics are discussed, and the nuances of related
                                issues that are discussed within each topic
                                area.
                            </div>
                        </div>
                        <div className="flex flex-row items-center justify-between mt-8">
                            <Link
                                to="/issues"
                                className="flex h-8 flex-row items-center bg-slate-100 text-slate-600 border border-transparent hover:border-slate-300 rounded-full px-6 py-1"
                            >
                                Select an Issue
                            </Link>
                        </div>
                    </div>
                    <div className="lg:col-span-6 col-span-12 bg-white shadow-md p-6 rounded-xl flex flex-col justify-between min-h-[20rem]">
                        <div>
                            <div className="text-2xl">
                                Save Time and Effort in Gathering{' '}
                                <span className="text-yellow-600">
                                    Relevant Intel
                                </span>
                            </div>
                            <div className="text-md mt-8 font-light">
                                Tired of laboriously collating registration and
                                communication data from government sources? Our
                                dashboards generate instant snapshots of the
                                last twelve month's key trends and patterns for
                                any sector, institution, committee and issue.
                            </div>
                        </div>
                    </div>
                    <div className="lg:col-span-6 col-span-12 bg-white shadow-md p-6 rounded-xl flex flex-col justify-between min-h-[20rem]">
                        <div>
                            <div className="text-2xl">
                                Uncover{' '}
                                <span className="text-green-500">
                                    Trends and Trendbreaks
                                </span>{' '}
                                in Lobbying Activity
                            </div>
                            <div className="text-md mt-8 font-light">
                                LobbyIQ's powerful Wayback machine allows our
                                clients to explore the lobbying landscape of a
                                sector or institution at any point in time since
                                2010. Dashboards from the past or from prior
                                governments can instantly be compared to the
                                present day.
                            </div>
                        </div>
                    </div>
                    <div className="lg:col-span-6 col-span-12 bg-white shadow-md p-6 rounded-xl flex flex-col justify-between min-h-[20rem]">
                        <div>
                            <div className="text-2xl">Professional Visuals</div>
                            <div className="text-md mt-8 font-light">
                                Our dashboard components provide instantly
                                available visuals for professional-grade decks
                                in the GR space, whether preparing a
                                presentation for a new or existing client or for
                                in-house purposes.
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className="grid grid-cols-12 py-8">
                <div className="col-start-2 lg:col-end-5 col-end-12 pt-6 pr-6 mb-6">
                    <div className="lg:text-4xl text-xl">
                        Custom Data, Analytics, and In-House Tracking
                    </div>
                    <div className="text-md mt-8 font-light lg:text-xl">
                        Text analytics of registrations, communications,
                        committee meetings, parliamentary debates, and
                        government announcements
                    </div>
                </div>
                <div className="lg:col-start-5 col-start-2 col-end-12 grid grid-cols-12 gap-6">
                    <div className="lg:col-span-6 col-span-12 bg-white shadow-md p-6 rounded-xl flex flex-col justify-between min-h-[20rem]">
                        <div>
                            <div className="text-2xl">
                                Track{' '}
                                <span className="text-liq-ultramarine">
                                    In-House
                                </span>
                                <br /> Lobbying Activity
                            </div>
                            <div className="text-md mt-8 font-light">
                                In-House dashboards provide a detailed report of
                                an organization's lobbying (by registration)
                                over the last 12 months, enabling clients to
                                easily monitor and assess their own lobbying
                                activities, and to ensure compliance reports are
                                accurate.
                            </div>
                        </div>
                    </div>
                    <div className="lg:col-span-6 col-span-12 bg-white shadow-md p-6 rounded-xl flex flex-col justify-between min-h-[20rem]">
                        <div>
                            <div className="text-2xl">
                                Download Custom{' '}
                                <span className="text-liq-lavender">
                                    <br />
                                    Lobbying Data
                                </span>
                            </div>
                            <div className="text-md mt-8 font-light">
                                LobbyIQ's advanced Custom Query search interface
                                makes it easy to create custom lobbying reports
                                and to download detailed communication-level
                                data in user-friendly spreadsheet formats.
                                Search by Organization, Industry, Institution,
                                Policymaker name, Policymaker title, Subjects,
                                Date.
                            </div>
                        </div>
                    </div>
                    <div className="lg:col-span-6 col-span-12 bg-white shadow-md p-6 rounded-xl flex flex-col justify-between min-h-[20rem]">
                        <div>
                            <div className="text-2xl">
                                Receive{' '}
                                <span className="text-liq-lime">
                                    Monthly Newsletters
                                </span>
                            </div>
                            <div className="text-md mt-8 font-light">
                                LobbyIQ clients receive by email a unique
                                monthly newsletter with a summary of lobbying
                                activities in their selected sectors.
                            </div>
                        </div>
                    </div>
                    <div className="lg:col-span-6 col-span-12 bg-white shadow-md p-6 rounded-xl flex flex-col justify-between min-h-[20rem]">
                        <div>
                            <div className="text-2xl">
                                Customized{' '}
                                <span className="text-liq-teal">
                                    Analytics and Consulting
                                </span>
                            </div>
                            <div className="text-md mt-8 font-light">
                                LobbyIQ offers bespoke consulting and data
                                services for clients who require in-depth
                                analyses on specific topics. This includes
                                automatic tracking and real-time alerts of key
                                words that feature in lobby registrations,
                                government press releases, parliamentary
                                debates, and committee meetings, or monitoring
                                of specific organizations or DPOHs.
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Features;
