import React from 'react';
import { Outlet } from 'react-router-dom';
import {
    selectUserLoading,
    selectEmail,
    selectUserPermissions,
    selectUserErrored,
    selectLicenceAccepted,
    selectTooManySessions,
} from '../store/reducers/userSlice';
import { useSelector } from 'react-redux';
import LoadPage from '../pages/misc/LoadPage';
import { LoginRequired } from '../pages/misc/LoginRequired';
import { SubscriptionRequired } from '../pages/misc/SubscriptionRequired';
import { SessionLimit } from '../pages/misc/SessionLimit';
import LicenseAgreementModal from '../components/misc/LicenseAgreementModal';

const PrivateRoute = () => {
    const userLoading = useSelector(selectUserLoading);
    const userError = useSelector(selectUserErrored);
    const userEmail = useSelector(selectEmail);
    const permissions = useSelector(selectUserPermissions);
    const licenseAccepted = useSelector(selectLicenceAccepted);
    const tooManySessions = useSelector(selectTooManySessions);

    return userLoading ? (
        <LoadPage />
    ) : userError ? (
        tooManySessions ? (
            <SessionLimit />
        ) : (
            <SubscriptionRequired />
        )
    ) : userEmail ? (
        permissions ? (
            licenseAccepted ? (
                <Outlet />
            ) : (
                <LicenseAgreementModal
                    open={!licenseAccepted}
                    handleClose={() => {}}
                    selectedProductId={''}
                    disableProductRedirect
                />
            )
        ) : (
            <SubscriptionRequired />
        )
    ) : (
        <LoginRequired />
    );
};

export default PrivateRoute;
