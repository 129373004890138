import React, { useState } from 'react';
import Graph from '@mui/icons-material/AutoGraphRounded';
import { months } from '../../constants/date_time';

import { default as MUITable } from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import { TablePagination, TableSortLabel, styled } from '@mui/material';
import { replace_underscores_capitalize } from '../../helpers/string_formatters';
import moment from 'moment';
import { Link } from 'react-router-dom';
import { entityOrgMeta } from '../../interfaces/generic-entity.interface';

// Title is an optional parameters with default specified in function signature below.
interface moversProps {
    title?: string;
    date: string;
    moverData: any;
    moverMeta?: entityOrgMeta;
    isInstitutionType: boolean;
    trackClickEvent?: (name: string) => void;
}

const StyledTableCell = styled(TableCell)({
    paddingLeft: 5,
    paddingRight: 5,
});

/*
    Component: Movers.tsx
    Params: Title - the title to be displayed on the tile.
    Author: William Brewer
    Desc: Movers custom table component displays the top moving organizations for a specific sector/institution.
*/
const Movers = ({
    title = 'Title',
    date,
    moverData,
    moverMeta,
    isInstitutionType = false,
    trackClickEvent = (name: string) => {},
}: moversProps) => {
    const curr_date = moment(date, 'YYYY-MM-DD');
    var curr_month = months[curr_date.month()].slice(0, 3);
    curr_date.subtract(1, 'months');
    var last_month = months[curr_date.month()].slice(0, 3);
    curr_date.subtract(1, 'months');
    var two_months_ago = months[curr_date.month()].slice(0, 3);
    curr_date.subtract(1, 'months');
    var three_months_ago = months[curr_date.month()].slice(0, 3);

    if (moverMeta) {
        curr_month = moverMeta.count.slice(0, 3);
        last_month = moverMeta.oma.slice(0, 3);
        two_months_ago = moverMeta.tma.slice(0, 3);
        three_months_ago = moverMeta.thma.slice(0, 3);
    }

    const [orderBy, setOrderBy] = useState<string>('deviation');
    const [order, setOrder] = useState<'asc' | 'desc'>('desc');

    const [page, setPage] = React.useState(0);

    const handleChangePage = (event: unknown, newPage: number) => {
        trackClickEvent(`Table page change: (${page} to ${newPage})`);
        setPage(newPage);
    };

    const headers = ['name', '12M Avg', last_month, '+/-', curr_month];

    const key_header_map = new Map();
    key_header_map.set('name', 'name');
    key_header_map.set('institution', 'institution');
    key_header_map.set('12M Avg', 'avg');
    key_header_map.set('12M', 'twelve_month_sum');
    key_header_map.set(three_months_ago, 'thma');
    key_header_map.set(two_months_ago, 'tma');
    key_header_map.set('+/-', 'deviation');
    key_header_map.set(last_month, 'oma');
    key_header_map.set(curr_month, 'count');

    const handleSort = (property: any) => {
        const prop = key_header_map.get(property);
        const isAsc = orderBy === prop && order === 'asc';
        setOrderBy(prop);
        setOrder(isAsc ? 'desc' : 'asc');
    };

    const sorted_movers = [...moverData].sort((a: any, b: any) => {
        if (order === 'asc') {
            return a[orderBy] > b[orderBy] ? 1 : -1;
        } else {
            return a[orderBy] < b[orderBy] ? 1 : -1;
        }
    });

    // Avoid a layout jump when reaching the last page with empty rows.
    const emptyRows = page > 0 ? Math.max(0, (1 + page) * 10 - moverData.length) : 0;

    return (
        <div className="lg:h-full bg-white shadow-md rounded-md p-4 overflow-auto">
            <div className="lg:text-md lg:font-medium flex flex-row items-center space-x-2 mb-2">
                <Graph color="success" />
                <div>{title}</div>
            </div>
            <hr className="bg-slate-50 col-span-12 mt-4" />
            <TableContainer>
                <MUITable size="small">
                    <TableHead>
                        <TableRow>
                            <StyledTableCell align="left">
                                <TableSortLabel
                                    active={orderBy === 'name'}
                                    onClick={() => {
                                        trackClickEvent('Table sort by: name');
                                        handleSort('name');
                                    }}
                                    direction={orderBy === 'name' ? order : 'asc'}
                                >
                                    {replace_underscores_capitalize('name')}
                                </TableSortLabel>
                            </StyledTableCell>
                            {sorted_movers[0]?.institution && !isInstitutionType ? (
                                <StyledTableCell align="left">
                                    <TableSortLabel
                                        active={orderBy === 'institution'}
                                        onClick={() => {
                                            trackClickEvent('Table sort by: institution');
                                            handleSort('institution');
                                        }}
                                        direction={orderBy === 'institution' ? order : 'asc'}
                                    >
                                        {replace_underscores_capitalize('institution')}
                                    </TableSortLabel>
                                </StyledTableCell>
                            ) : (
                                <></>
                            )}
                            {sorted_movers[0]?.title && (
                                <StyledTableCell align="left">
                                    <TableSortLabel
                                        active={orderBy === 'title'}
                                        onClick={() => {
                                            trackClickEvent('Table sort by: title');
                                            handleSort('title');
                                        }}
                                        direction={orderBy === 'title' ? order : 'asc'}
                                    >
                                        {replace_underscores_capitalize('title')}
                                    </TableSortLabel>
                                </StyledTableCell>
                            )}
                            {headers.slice(1).map((header, idx) => (
                                <StyledTableCell key={idx} align="right">
                                    <TableSortLabel
                                        active={orderBy === key_header_map.get(header)}
                                        onClick={() => {
                                            trackClickEvent('Table sort by: ' + header);
                                            handleSort(header);
                                        }}
                                        direction={
                                            orderBy === key_header_map.get(header) ? order : 'asc'
                                        }
                                    >
                                        {replace_underscores_capitalize(header)}
                                    </TableSortLabel>
                                </StyledTableCell>
                            ))}
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {sorted_movers
                            .slice(page * 10, page * 10 + 10)
                            .map((mover: any, idx: number) => (
                                <TableRow
                                    key={idx}
                                    sx={{
                                        '&:last-child td, &:last-child th': {
                                            border: 0,
                                        },
                                    }}
                                >
                                    <StyledTableCell align="left">
                                        {mover.name.startsWith('URL') ? (
                                            <Link
                                                className="text-sky-600 hover:underline underline-offset-2"
                                                to={mover.name.split('#')[2]}
                                                target="_blank"
                                                rel="noreferrer"
                                                onClick={() =>
                                                    trackClickEvent(
                                                        `Table redirect by URL: ${
                                                            mover.name.split('#')[1]
                                                        }`
                                                    )
                                                }
                                            >
                                                {mover.name.split('#')[1]}
                                            </Link>
                                        ) : (
                                            mover.name
                                        )}
                                    </StyledTableCell>
                                    {mover.institution && !isInstitutionType && (
                                        <StyledTableCell align="left">
                                            {mover.institution.startsWith('URL') ? (
                                                <Link
                                                    className="text-sky-600 hover:underline underline-offset-2"
                                                    to={mover.institution.split('#')[2]}
                                                    target="_blank"
                                                    rel="noreferrer"
                                                    onClick={() =>
                                                        trackClickEvent(
                                                            `Table redirect by URL: ${
                                                                mover.institution.split('#')[1]
                                                            }`
                                                        )
                                                    }
                                                >
                                                    {mover.institution.split('#')[1]}
                                                </Link>
                                            ) : (
                                                mover.institution
                                            )}
                                        </StyledTableCell>
                                    )}
                                    {mover.title && (
                                        <StyledTableCell align="left">
                                            {mover.title}
                                        </StyledTableCell>
                                    )}
                                    <StyledTableCell align="right">
                                        {Math.round(mover.avg * 10) / 10}
                                    </StyledTableCell>
                                    <StyledTableCell align="right">
                                        {mover.oma || 0}
                                    </StyledTableCell>
                                    <StyledTableCell align="right">
                                        <div
                                            className={
                                                (mover.deviation > 0
                                                    ? 'text-liq-lime '
                                                    : 'text-liq-red ') + 'col-span-2'
                                            }
                                        >
                                            {Math.round(mover.deviation * 10) / 10 || 0}
                                        </div>
                                    </StyledTableCell>
                                    <StyledTableCell align="right">
                                        <div className="col-span-2 text-slate-400">
                                            {mover.count || 0}
                                        </div>
                                    </StyledTableCell>
                                </TableRow>
                            ))}
                        {emptyRows > 0 && (
                            <TableRow
                                style={{
                                    height: 33 * emptyRows,
                                }}
                            >
                                <TableCell colSpan={Object.keys(moverData[0]).length} />
                            </TableRow>
                        )}
                    </TableBody>
                </MUITable>
            </TableContainer>
            <TablePagination
                rowsPerPageOptions={[]}
                component="div"
                count={moverData.length}
                rowsPerPage={10}
                page={page}
                onPageChange={handleChangePage}
            />
        </div>
    );
};

export default Movers;
