import { useNavigate } from 'react-router-dom';
import logo from '../../assets/vectors/LIQ_badge.png';

interface MissingDashboardSelectionsProps {
    type: 'sectors' | 'institutions' | 'committees';
}

export const MissingDashboardSelections = ({
    type,
}: MissingDashboardSelectionsProps) => {
    const navigate = useNavigate();

    const handleSettingsRedirect = async () => {
        navigate(`/account/selections`);
    };

    return (
        <div className="flex flex-col items-center justify-center text-xl py-[40vh]">
            <img src={logo} className="h-20" alt="logo" />
            <div className="flex flex-col sm:flex-row items-center justify-center my-5 font-bold">
                <p>There are no {type} selected for this account.</p>
            </div>
            <div className="flex flex-row items-center justify-center align-middle">
                <p>Please select which dashboards this account should have access to on the</p>
                <div
                    className="mx-1 text-cyan-500 font-bold hover:underline hover:cursor-pointer"
                    onClick={handleSettingsRedirect}
                >
                    Dashboard Selections Page.
                </div>
            </div>
        </div>
    );
};
