import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { RootState } from '../../../store/store';
import {
    entityOrg,
    entityOverviews,
    entityRegistration,
} from '../../../interfaces/generic-entity.interface';
import { RegistrationActivityCombo } from '../../../interfaces/organization-entity.interface';
import { registrationAPI } from '../../../features/registration-dashboards/API/RegistrationAPI';
import axios from 'axios';
import saveAs from 'file-saver';

interface RegistrationState {
    registration_overview: entityOverviews | null;
    registration_overview_fail: boolean;
    registration_overview_loading: boolean;

    registration_info: entityRegistration | null;
    registration_info_fail: boolean;
    registration_info_loading: boolean;

    activity: RegistrationActivityCombo | null;
    activity_loading: boolean;
    activity_fail: boolean;

    top_institutions: entityOrg[] | null;
    top_institutions_loading: boolean;
    top_institutions_fail: boolean;

    top_subjects: entityOrg[] | null;
    top_subjects_loading: boolean;
    top_subjects_fail: boolean;

    export_fail: boolean;
    export_loading: boolean;
}

const initialState: RegistrationState = {
    registration_overview: null,
    registration_overview_fail: false,
    registration_overview_loading: false,

    registration_info: null,
    registration_info_fail: false,
    registration_info_loading: false,

    activity: null,
    activity_fail: false,
    activity_loading: false,

    top_institutions: null,
    top_institutions_loading: false,
    top_institutions_fail: false,

    top_subjects: null,
    top_subjects_loading: false,
    top_subjects_fail: false,

    export_fail: false,
    export_loading: false,
};

// Fetch an overview for the current registration
export const fetchRegistrationOverview = createAsyncThunk(
    'registration/fetchRegistrationOverview',
    async ({ reg_id }: { reg_id: number }): Promise<entityOverviews> =>
        registrationAPI.fetchRegistrationOverview(reg_id)
);

// Fetch the recent lobbying activity data for the current registration
export const fetchRegistrationInfo = createAsyncThunk(
    'registration/fetchRegistrationInfo',
    async ({ reg_id }: { reg_id: number }): Promise<entityRegistration> =>
        registrationAPI.fetchRegistrationInfo(reg_id)
);

// Fetch the recent lobbying activity data for the current registration
export const fetchRegistrationActivity = createAsyncThunk(
    'registration/fetchRegistrationActivity',
    async ({
        reg_id,
        date,
    }: {
        reg_id: number;
        date: string;
    }): Promise<RegistrationActivityCombo> =>
        registrationAPI.fetchRegistrationActivity(reg_id, date)
);

// Fetch the top institutions lobbied by the current registration
export const fetchRegistrationTopInstitutions = createAsyncThunk(
    'registration/fetchRegistrationTopInstitutions',
    async ({ reg_id, date }: { reg_id: number; date: string }): Promise<entityOrg[]> =>
        registrationAPI.fetchRegistrationTopInstitutions(reg_id, date)
);

// Fetch the top subjects lobbied by the current registration
export const fetchRegistrationTopSubjects = createAsyncThunk(
    'registration/fetchRegistrationTopSubjects',
    async ({ reg_id, date }: { reg_id: number; date: string }): Promise<entityOrg[]> =>
        registrationAPI.fetchRegistrationTopSubjects(reg_id, date)
);

export const exportRegistrationDashboard = createAsyncThunk(
    'registration/exportDashboard',
    async (data: any): Promise<void> => {
        const response = await axios.post(
            `${process.env.REACT_APP_PDF_SERVICE_URL}/registration/report`,
            data,
            {
                responseType: 'blob',
            }
        );

        const pdfEncoding = response.data;

        const blob = new Blob([pdfEncoding], { type: 'application/pdf' });

        saveAs(blob, 'LobbyIQ-Registration');
    }
);

const registrationSlice = createSlice({
    name: 'registrationReducer',
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(fetchRegistrationOverview.pending, (state) => {
                state.registration_overview_loading = true;
            })
            .addCase(fetchRegistrationOverview.fulfilled, (state, action) => {
                state.registration_overview = action.payload;
                state.registration_overview_fail = false;
                state.registration_overview_loading = false;
            })
            .addCase(fetchRegistrationOverview.rejected, (state) => {
                state.registration_overview = null;
                state.registration_overview_fail = true;
                state.registration_overview_loading = false;
            })
            .addCase(fetchRegistrationInfo.pending, (state) => {
                state.registration_info_loading = true;
            })
            .addCase(fetchRegistrationInfo.fulfilled, (state, action) => {
                state.registration_info = action.payload;
                state.registration_info_fail = false;
                state.registration_info_loading = false;
            })
            .addCase(fetchRegistrationInfo.rejected, (state) => {
                state.registration_info = null;
                state.registration_info_fail = true;
                state.registration_info_loading = false;
            })
            .addCase(fetchRegistrationActivity.pending, (state) => {
                state.activity_loading = true;
            })
            .addCase(fetchRegistrationActivity.fulfilled, (state, action) => {
                state.activity = action.payload;
                state.activity_fail = false;
                state.activity_loading = false;
            })
            .addCase(fetchRegistrationActivity.rejected, (state) => {
                state.activity = null;
                state.activity_fail = true;
                state.activity_loading = false;
            })
            .addCase(fetchRegistrationTopInstitutions.pending, (state) => {
                state.top_institutions_loading = true;
            })
            .addCase(fetchRegistrationTopInstitutions.fulfilled, (state, action) => {
                state.top_institutions = action.payload;
                state.top_institutions_fail = false;
                state.top_institutions_loading = false;
            })
            .addCase(fetchRegistrationTopInstitutions.rejected, (state) => {
                state.top_institutions = null;
                state.top_institutions_fail = true;
                state.top_institutions_loading = false;
            })
            .addCase(fetchRegistrationTopSubjects.pending, (state) => {
                state.top_subjects_loading = true;
            })
            .addCase(fetchRegistrationTopSubjects.fulfilled, (state, action) => {
                state.top_subjects = action.payload;
                state.top_subjects_fail = false;
                state.top_subjects_loading = false;
            })
            .addCase(fetchRegistrationTopSubjects.rejected, (state, action) => {
                state.top_subjects = null;
                state.top_subjects_fail = true;
                state.top_subjects_loading = false;
            })
            .addCase(exportRegistrationDashboard.pending, (state) => {
                state.export_loading = true;
            })
            .addCase(exportRegistrationDashboard.fulfilled, (state, action) => {
                state.export_loading = false;
                state.export_fail = false;
            })
            .addCase(exportRegistrationDashboard.rejected, (state) => {
                state.export_loading = false;
                state.export_fail = true;
            });
    },
});

export const selectRegistrationOverview = (state: RootState) =>
    state.registrationData.registration_overview;
export const selectRegistrationOverviewLoading = (state: RootState) =>
    state.registrationData.registration_overview_loading;

export const selectRegistrationInfo = (state: RootState) =>
    state.registrationData.registration_info;
export const selectRegistrationInfoLoading = (state: RootState) =>
    state.registrationData.registration_info_loading;

export const selectRegistrationActivity = (state: RootState) => state.registrationData.activity;
export const selectRegistrationActivityLoading = (state: RootState) =>
    state.registrationData.activity_loading;

export const selectRegistrationTopInstitutions = (state: RootState) =>
    state.registrationData.top_institutions;
export const selectRegistrationTopInstitutionsLoading = (state: RootState) =>
    state.registrationData.top_institutions_loading;

export const selectRegistrationTopSubjects = (state: RootState) =>
    state.registrationData.top_subjects;
export const selectRegistrationTopSubjectsLoading = (state: RootState) =>
    state.registrationData.top_subjects_loading;

export const selectRegistrationExportLoading = (state: RootState) =>
    state.registrationData.export_loading;
export const selectRegistrationExportFail = (state: RootState) =>
    state.registrationData.export_fail;

export default registrationSlice.reducer;
