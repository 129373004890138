import React, { useState } from 'react';
import Table from './Table';
import LoadingTable from './loading/LoadingTable';
import { Fade, Grow } from '@mui/material';
import { MissingData } from '../misc/MissingData';
import HelpIcon from '@mui/icons-material/Help';
import Modal from '../misc/Modal';
import { useSelector } from 'react-redux';
import { GATrackDashboardEvent, EVENT_TYPES } from '../../google_analytics/TrackEvent';
import { selectEmail, selectUserProductType } from '../../store/reducers/userSlice';

// Title and subtitle are optional components with defaults specified in function signature below.
interface dashTableProps {
    dashboard?: string;
    topic?: string;
    titleText?: string;
    title?: any;
    subtitle?: string;
    tableData: any;
    loading: boolean;
    tableHeader: any;
    suffixes?: any;
    defaultSort?: string;
    defaultNumRows?: number;
    embedded?: boolean;
    tooltipContent?: JSX.Element;
}

/*
    Component: DashTable.tsx
    Params: title - title of the row, subtitle - secondary title of the row
    Author: Will Brewer
    Desc: Wrapper component for table-based row. Features a full length table
*/
const DashTable = ({
    dashboard = '',
    topic = '',
    titleText = '',
    title = '',
    subtitle = '',
    tableData,
    loading,
    tableHeader,
    suffixes = [],
    defaultSort = tableHeader[0],
    defaultNumRows = 5,
    embedded = false,
    tooltipContent,
}: dashTableProps) => {
    const align = 'left';
    const [modalOpen, setModalOpen] = useState<boolean>(false);

    const userEmail = useSelector(selectEmail);
    const userProductType = useSelector(selectUserProductType);
    const TrackEvent = (name: string) => {
        GATrackDashboardEvent(
            name,
            EVENT_TYPES.CLICK,
            dashboard,
            titleText === '' ? title : titleText,
            topic,
            userEmail ? userEmail : undefined,
            userProductType ? userProductType : undefined
        );
    };

    return (
        <Fade in={true}>
            <div>
                <div className="flex lg:flex-row flex-col justify-between">
                    <div className="text-xl">{title}</div>
                    <div className="flex flex-row items-center">
                        <div className="text-lg font-light">{subtitle}</div>
                        {tooltipContent && (
                            <div
                                className="ml-2 hover:cursor-pointer"
                                onClick={() => setModalOpen(true)}
                            >
                                <HelpIcon color="info" />
                            </div>
                        )}
                    </div>
                </div>
                {tooltipContent && (
                    <Modal
                        title={title}
                        open={modalOpen}
                        width={50}
                        onClose={() => setModalOpen(false)}
                    >
                        {tooltipContent}
                    </Modal>
                )}
                <hr className="h-0.5 my-3 bg-slate-100 rounded" />
                {loading ? (
                    <LoadingTable />
                ) : !loading && !tableData ? (
                    <MissingData />
                ) : tableData ? (
                    <Grow in={true}>
                        <div>
                            <Table
                                rowData={tableData}
                                rowHeaders={tableHeader}
                                alignment={align}
                                paginated={true}
                                suffixes={suffixes}
                                defaultSort={defaultSort}
                                defaultNumRows={defaultNumRows}
                                embedded={embedded}
                                trackClickEvent={TrackEvent}
                            />
                        </div>
                    </Grow>
                ) : (
                    <></>
                )}
            </div>
        </Fade>
    );
};

export default DashTable;
