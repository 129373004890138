import React, { useState } from 'react';
import { default as MUITable } from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import TwitterIcon from '@mui/icons-material/Twitter';
import LanguageRoundedIcon from '@mui/icons-material/LanguageRounded';
import FacebookIcon from '@mui/icons-material/Facebook';
import CircleIcon from '@mui/icons-material/Circle';
import { red, blue, orange } from '@mui/material/colors';
import { TablePagination, TableSortLabel, styled } from '@mui/material';
import { replace_underscores_capitalize } from '../../../helpers/string_formatters';
import { committeeMember } from '../interfaces/committee-entity.interface';
import { Link } from 'react-router-dom';

const StyledTableCell = styled(TableCell)({
    paddingLeft: 20,
    paddingRight: 0,
});

// Title and subtitle are optional components with defaults specified in function signature below.
interface committeeTableProps {
    title?: string;
    subtitle?: string;
    rowData: committeeMember[];
    rowHeaders: Array<string>;
    alignment?: 'right' | 'center' | 'left' | 'inherit' | 'justify' | undefined;
    defaultSort?: string;
    paginated?: boolean;
    trackClickEvent?: (name: string) => void;
}

/*
    Component: CommitteeTable.tsx
    Params: title - title of the row, subtitle - secondary title of the row
    Author: Fred Wang
    Desc: Wrapper component for table-based row. Features a full length table. Specifically for HoC dashboard use.
*/
const CommitteeTable = ({
    rowData,
    rowHeaders,
    alignment = 'left',
    defaultSort = rowHeaders[0],
    trackClickEvent = (name: string) => {},
}: committeeTableProps) => {
    const [orderBy, setOrderBy] = useState<string>(defaultSort);
    const [order, setOrder] = useState<'asc' | 'desc'>('asc');

    const [page, setPage] = React.useState(0);
    const [rowsPerPage, setRowsPerPage] = React.useState(50);

    const handleChangePage = (event: unknown, newPage: number) => {
        trackClickEvent(`Table page change: (${page} to ${newPage})`);
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
        trackClickEvent(`Table row change: (${rowsPerPage} to ${+event.target.value})`);
        setRowsPerPage(+event.target.value);
        setPage(0);
    };

    const handleSort = (property: any) => {
        const isAsc = orderBy === property && order === 'asc';
        setOrderBy(property);
        setOrder(isAsc ? 'desc' : 'asc');
    };

    const sortedRowData = [...rowData].sort((a: any, b: any) => {
        if (order === 'asc') {
            return a[orderBy] > b[orderBy] ? 1 : -1;
        } else {
            return a[orderBy] < b[orderBy] ? 1 : -1;
        }
    });

    // Avoid a layout jump when reaching the last page with empty rows.
    const emptyRows = page > 0 ? Math.max(0, (1 + page) * rowsPerPage - rowData.length) : 0;

    return (
        <div className="bg-white shadow-md rounded-md overflow-auto">
            <TableContainer>
                <MUITable size="small" aria-label="a dense table">
                    <TableHead>
                        <TableRow>
                            {rowHeaders.map((header, idx) => (
                                <StyledTableCell key={idx} align={alignment}>
                                    <TableSortLabel
                                        active={orderBy === header}
                                        onClick={() => {
                                            trackClickEvent('Table sort by: ' + header);
                                            handleSort(header);
                                        }}
                                        direction={orderBy === header ? order : 'asc'}
                                    >
                                        {replace_underscores_capitalize(header)}
                                    </TableSortLabel>
                                </StyledTableCell>
                            ))}
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {sortedRowData
                            .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                            .map((row, idx) => (
                                <TableRow
                                    key={idx}
                                    sx={{
                                        '&:last-child td, &:last-child th': {
                                            border: 0,
                                        },
                                    }}
                                >
                                    <StyledTableCell align="left">
                                        <div className="flex flex-row justify-between">
                                            {row.name.startsWith('URL') ? (
                                                <Link
                                                    className="text-sky-600 hover:underline underline-offset-2"
                                                    to={row.name.split('#')[2]}
                                                    target="_blank"
                                                    rel="noreferrer"
                                                    onClick={() =>
                                                        trackClickEvent(
                                                            `Table redirect by URL: ${
                                                                row.name.split('#')[1]
                                                            }`
                                                        )
                                                    }
                                                >
                                                    {row.name.split('#')[1]}
                                                </Link>
                                            ) : (
                                                <div>{row.name}</div>
                                            )}
                                            <div className="flex flex-row gap-1 items-center">
                                                {row.wiki_id ? (
                                                    <a
                                                        className="text-blue-400 hover:underline underline-offset-2"
                                                        href={
                                                            row.wiki_id.startsWith('http')
                                                                ? row.wiki_id
                                                                : `https://en.wikipedia.org/wiki/${row.name
                                                                      .split('#')[1]
                                                                      .split(' ')
                                                                      .join('_')}`
                                                        }
                                                        target="_blank"
                                                        rel="noreferrer"
                                                        onClick={() =>
                                                            trackClickEvent(
                                                                `Table redirect by icon(Wiki): ${row.name}`
                                                            )
                                                        }
                                                    >
                                                        <LanguageRoundedIcon fontSize="small" />
                                                    </a>
                                                ) : (
                                                    <span className="text-slate-200">
                                                        <LanguageRoundedIcon fontSize="small" />
                                                    </span>
                                                )}
                                                {row.twitter_handle ? (
                                                    <a
                                                        className="text-blue-500 hover:underline underline-offset-2"
                                                        href={
                                                            row.twitter_handle.startsWith('http')
                                                                ? row.twitter_handle
                                                                : `https://www.twitter.com/${row.twitter_handle}`
                                                        }
                                                        target="_blank"
                                                        rel="noreferrer"
                                                        onClick={() =>
                                                            trackClickEvent(
                                                                `Table redirect by icon(Twitter): ${row.name}`
                                                            )
                                                        }
                                                    >
                                                        <TwitterIcon />
                                                    </a>
                                                ) : (
                                                    <span className="text-slate-200">
                                                        <TwitterIcon />
                                                    </span>
                                                )}
                                                {row.facebook_handle ? (
                                                    <a
                                                        className="text-blue-700 hover:underline underline-offset-2"
                                                        href={
                                                            row.facebook_handle.startsWith('http')
                                                                ? row.facebook_handle
                                                                : `https://www.facebook.com/${row.facebook_handle}`
                                                        }
                                                        target="_blank"
                                                        rel="noreferrer"
                                                        onClick={() =>
                                                            trackClickEvent(
                                                                `Table redirect by icon(Facebook): ${row.name}`
                                                            )
                                                        }
                                                    >
                                                        <FacebookIcon />
                                                    </a>
                                                ) : (
                                                    <span className="text-slate-200">
                                                        <FacebookIcon />
                                                    </span>
                                                )}
                                            </div>
                                        </div>
                                    </StyledTableCell>
                                    {row.role && (
                                        <StyledTableCell align="left">{row.role}</StyledTableCell>
                                    )}
                                    <StyledTableCell align="left">
                                        {row.province_territory}
                                    </StyledTableCell>
                                    {row.constituency && (
                                        <StyledTableCell align="left">
                                            {row.constituency}
                                        </StyledTableCell>
                                    )}
                                    <StyledTableCell align="left">
                                        {row.affiliation === 'Liberal' ? (
                                            <span className="flex flex-row items-center gap-2">
                                                <CircleIcon
                                                    sx={{
                                                        color: red[500],
                                                        fontSize: 12,
                                                    }}
                                                />
                                                {row.affiliation}
                                            </span>
                                        ) : row.affiliation === 'Conservative' ? (
                                            <span className="flex flex-row items-center gap-2">
                                                <CircleIcon
                                                    sx={{
                                                        color: blue[500],
                                                        fontSize: 12,
                                                    }}
                                                />
                                                {row.affiliation}
                                            </span>
                                        ) : row.affiliation === 'NDP' ? (
                                            <span className="flex flex-row items-center gap-2">
                                                <CircleIcon
                                                    sx={{
                                                        color: orange[500],
                                                        fontSize: 12,
                                                    }}
                                                />
                                                {row.affiliation}
                                            </span>
                                        ) : (
                                            <span className="flex flex-row items-center gap-2">
                                                {row.affiliation}
                                            </span>
                                        )}
                                    </StyledTableCell>
                                </TableRow>
                            ))}
                        {emptyRows > 0 && (
                            <TableRow
                                style={{
                                    height: 33 * emptyRows,
                                }}
                            >
                                <TableCell colSpan={Object.keys(rowData[0]).length} />
                            </TableRow>
                        )}
                    </TableBody>
                </MUITable>
            </TableContainer>
            <TablePagination
                labelRowsPerPage="Rows:"
                labelDisplayedRows={({ from, to }) => `${from}-${to}`}
                rowsPerPageOptions={[5, 10, 25, 50]}
                component="div"
                count={rowData.length}
                rowsPerPage={rowsPerPage}
                page={page}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
            />
        </div>
    );
};

export default CommitteeTable;
