import React from 'react';
import liq_badge from '../assets/vectors/LIQ_badge.svg';
import { Button } from '@mui/material';
import { Link } from 'react-router-dom';

/*
    Component: Landing.tsx
    Params: N/A
    Author: Will Brewer
    Desc: Main landing page of the app. 
*/
const Landing = () => {
    return (
        <div className="lg:pt-48 pt-32 mb-[200px]">
            <div id="top" className="grid grid-cols-12">
                <div className="col-start-2 col-end-12 flex flex-row items-center justify-between">
                    <div className="max-w-4xl">
                        <h1 className="text-3xl lg:text-7xl mb-8">
                            Lobby<b className="font-black">IQ</b>
                        </h1>
                        <img
                            src={liq_badge}
                            className="h-24 absolute top-[9vh] right-12 lg:hidden"
                            alt="logo"
                        />
                        <div className="flex flex-row justify-between items-center">
                            <div className="text-2xl lg:text-4xl">
                                Real-Time <span className="text-sky-600">Intelligence</span> on
                                Parliamentary Affairs
                            </div>
                        </div>
                        <div className="mt-8 lg:mr-12 text-sm lg:text-lg">
                            LobbyIQ helps organizations effortlessly monitor the complex landscape
                            of federal parliamentary affairs through the power of AI-driven
                            analytics. Unique dashboards offer the most up-to-date, comprehensive
                            view of policy issues, stakeholder lobbying and government actions.
                            Committee trackers provide instant summaries and transcripts of House
                            and Senate committee meetings. Tailored email alerts keep clients
                            informed on who is talking to whom about what.
                        </div>
                        <div className="mt-8 flex flex-row gap-3">
                            <Button component={Link} to="/contact" variant="contained">
                                Request a Call
                            </Button>
                        </div>
                    </div>
                    <img src={liq_badge} className={'w-72 lg:flex hidden'} alt="logo" />
                </div>
                <div className="mt-8 mb-0 lg:mb-8 col-start-2 col-end-12 flex">
                    <div className="px-4 py-3 text-sm lg:text-lg rounded-md font-medium bg-slate-100 text-slate-700">
                        Our{' '}
                        <Link to="/big-picture" className="hover:underline text-blue-500">
                            "Big Picture"
                        </Link>{' '}
                        dashboard gives a 30,000 foot overview of Ottawa&#39;s lobbying landscape.{' '}
                    </div>
                </div>
                <div className="col-start-2 col-end-12 flex flex-col gap-10">
                    <div className="text-2xl lg:text-4xl mt-16">Solutions at a Glance</div>

                    <div className="lg:max-w-[75%]">
                        <h3 className="text-md lg:text-xl font-medium">Issue and Bill Tracking</h3>
                        <hr className="h-0.5 my-4 bg-slate-100 rounded" />
                        <p className="text-sm lg:text-lg">
                            Monitor MP discourse on issues in committee meetings and parliamentary
                            sittings. <br /> Identify organizations that lobby on issues and bills,
                            and which DPOHs are targeted. <br /> Create custom dashboards to track
                            key issues.
                        </p>
                    </div>

                    <div className="lg:max-w-[75%]">
                        <h3 className="text-md lg:text-xl font-medium">Committee Monitoring</h3>
                        <hr className="h-0.5 my-4 bg-slate-100 rounded" />
                        <p className="text-sm lg:text-lg">
                            Receive within-the-hour summaries and transcripts of House and Senate
                            Committee meetings. <br />
                            Track Committee meeting key issues, witnesses, briefs and reports.{' '}
                            <br />
                            Monitor stakeholder lobbying of Committee members.
                        </p>
                    </div>

                    <div className="lg:max-w-[75%]">
                        <h3 className="text-md lg:text-xl font-medium">Stakeholder Monitoring</h3>
                        <hr className="h-0.5 my-4 bg-slate-100 rounded" />
                        <p className="text-sm lg:text-lg">
                            Identify trends and changes in GR activity by sector, institution,
                            organization and DPOH. <br />
                            Map the organizations and stakeholders that are seeking influence.{' '}
                            <br />
                            Find the DPOHs who are key lobby targets. <br />
                            Monitor MP interventions in parliamentary sittings and committee
                            meetings.
                        </p>
                    </div>

                    <div className="lg:max-w-[75%]">
                        <h3 className="text-md lg:text-xl font-medium">Keep Aware</h3>
                        <hr className="h-0.5 my-4 bg-slate-100 rounded" />
                        <p className="text-sm lg:text-lg">
                            Weekly email updates on lobby communications and registrations by
                            sector, organization, DPOH &amp; issue. <br /> Weekly email updates on
                            issue keyword mentions in Committees, Hansard and lobby registrations.{' '}
                            <br />
                            Monthly newsletter summaries for selected issues, sectors,
                            organizations, institutions &amp; DPOHS.
                        </p>
                    </div>

                    <div className="lg:max-w-[75%]">
                        <h3 className="text-md lg:text-xl font-medium">Research Hub</h3>
                        <hr className="h-0.5 my-4 bg-slate-100 rounded" />
                        <p className="text-sm lg:text-lg">
                            Create custom data analyses through flexible queries of cleaned lobby
                            and registration records. <br />
                            Make historical comparisons of GR activity at any time over the last 15
                            years. <br /> Generate presentation-ready charts and tables.
                        </p>
                    </div>

                    <div className="lg:max-w-[75%]">
                        <h3 className="text-md lg:text-xl font-medium">GR Insider</h3>
                        <hr className="h-0.5 my-4 bg-slate-100 rounded" />
                        <p className="text-sm lg:text-lg">
                            Assess market positions of GR firms. <br />
                            Monitor GR firm and consultant client engagements. <br />
                            Identify business development opportunities
                        </p>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Landing;
