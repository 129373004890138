import React from 'react';
import MetaTag from '../components/metatag-component/MetaTag';

const SolutionsFor = () => {
    return (
        <>
            <MetaTag
                title="Solutions"
                keywords="Tailored Solutions, Government Relations, Subscription Packages, Data Needs, Budget, Industries, Agencies, Committees, Dashboards, Text Analytics, Keyword Alerts, Lobby Firms, Committee Meeting Summaries, Issue Landscapes, Public Affairs Firms, Lobbying Activity, Competitors, Emerging Trends, Issue Calendars, Lead Generator, New Clients, Business Development, Canadian Government, Major Players, Policy Makers, Lobby Firms, Government Staffers, Policy Advisors, MP, DPOH"
                description="LobbyIQ offers tailored solutions for government relations executives with customizable subscription packages. Get insights on lobbying activities across sectors, agencies, and issues with dashboards, text analytics, keyword alerts, and more. Ideal for government relations firms, new organizations, and government staffers to track lobbying communications and trends."
            />
            <div className="grid grid-cols-4 grid-rows-auto  md:col-7 py-14 gap-8 justify-center xl:m-24 mx-8 my-24">
                <div className="col-start-1 col-span-4">
                    <h1 className="lg:text-4xl text-3xl">
                        Tailored Solutions for Government Relations
                    </h1>
                </div>

                <div className="col-span-4  col-start-1 xl:col-span-2 m:col-span-4 gap-8 bg-white shadow-md p-6 rounded-xl flex flex-col min-h-[20rem]">
                    <h2 className="text-2xl">Government Relations Executives</h2>
                    <p className="text-md mt-8 font-light">
                        LobbyIQ’s subscription packages can be tailored to each client’s data-needs
                        and budget, in terms of both breadth (how many industries, agencies and
                        committees) and depth (dashboards + text-analytics, keyword alerts, lobby
                        firms, committee meeting summaries, issue landscapes)
                    </p>
                </div>

                <div className="col-span-4 xl:col-span-2 sm:col-span-4 bg-white shadow-md p-6 rounded-xl flex flex-col  min-h-[20rem]">
                    <div>
                        <h2 className="text-2xl">Government Relations and Public Affairs Firms</h2>
                        <p className="text-md mt-8 font-light">
                            Get a 360-degree view of lobbying activity across all sectors, agencies
                            and issues. Provide comparables on competitors’ activity to clients.
                            Uncover unique insights on emerging trends and issues from our
                            issue-landscape and issue-calendars. Find new clients and BD
                            opportunities with our lead-generator tools.
                        </p>
                    </div>
                </div>

                <div className="col-span-4 xl:col-span-2 sm:col-span-4 bg-white shadow-md p-6 rounded-xl flex flex-col min-h-[20rem]">
                    <div>
                        <h2 className="text-2xl">
                            Organizations Considering Lobbying for the First Time
                        </h2>
                        <p className="text-md mt-8 font-light">
                            For both domestic and foreign organizations that interface with the
                            Canadian government for the first time, LobbyIQ provides an intuitive
                            and cost-effective tool to understand the Canadian GR landscape: major
                            players in each industry, relevant policy-makers in each agency, and
                            profiles of all lobby firms including existing client-base and areas of
                            subject-matter focus.
                        </p>
                    </div>
                </div>
                <div className="col-span-4 xl:col-span-2  sm:col-span-4 col-span-12 bg-white shadow-md p-6 rounded-xl flex flex-col min-h-[20rem]">
                    <div>
                        <h2 className="text-2xl">Government Staffers and Policy Advisors</h2>
                        <p className="text-md mt-8 font-light">
                            Trying to keep track of who lobbies your agency, MP, or DPOH and what
                            subjects and issues they are being lobbied on. Our institutions, DPOH,
                            committee and MP dashboards help staffers track the communications that
                            matter to them.
                        </p>
                    </div>
                </div>
            </div>
        </>
    );
};

export default SolutionsFor;
