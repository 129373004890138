import React, { useEffect, useState } from 'react';
import {
    Box,
    Button,
    Checkbox,
    FormControl,
    FormControlLabel,
    Grid,
    InputLabel,
    MenuItem,
    Select,
    TextField,
    Typography,
} from '@mui/material';
import { useAppDispatch } from '../store/store';
import { ALBERTA_COMMITTEES } from '../constants/committees';
import { Link } from 'react-router-dom';
import {
    createUser,
    logIn,
    redirectToPayment,
    selectCompany,
    selectEmail,
    selectFirstName,
    selectJobTitle,
    selectLastName,
    selectPhone,
    signUp,
    SubscriptionRequest,
} from '../store/reducers/userSlice';
import { useSelector } from 'react-redux';
import {
    fetchCommitteeList,
    selectCommitteeList,
} from '../features/committee-dashboards/data/committeeListSlice';
import {
    fetchSenateCommitteeList,
    selectSenateCommitteeList,
} from '../features/senate-dashboards/store/senateListSlice';
import LoadSpinner from '../components/misc/LoadSpinner';
import {
    Selection,
    updateCommitteesEmailsBeforePayment,
    updateCommitteesSelections,
} from '../store/reducers/customSelectionSlice';
import { entityListExtra } from '../interfaces/generic-entity.interface';
import axios from 'axios';

const enum SubscriptionDuration {
    semiAnnual = '6 Months',
    annual = '12 Months',
}

const PricingCommitteePage = () => {
    const dispatch = useAppDispatch();
    const userEmail = useSelector(selectEmail);
    const userFirstName = useSelector(selectFirstName);
    const userLastName = useSelector(selectLastName);
    const userCompanyName = useSelector(selectCompany);
    const userJob = useSelector(selectJobTitle);
    const userPhone = useSelector(selectPhone);

    const hocCommitteeList = useSelector(selectCommitteeList);
    const senateCommitteeList = useSelector(selectSenateCommitteeList);

    const [selectedCommittees, setSelectedCommittees] = useState<Selection[]>([]);
    const [subscriptionPeriod, setSubscriptionPeriod] = useState<SubscriptionDuration>(
        SubscriptionDuration.annual
    );
    const [userDetails, setUserDetails] = useState({
        firstName: userFirstName || '',
        lastName: userLastName || '',
        organization: userCompanyName || '',
        title: userJob || '',
        email: userEmail || '',
        phone: userPhone || '',
    });

    useEffect(() => {
        setUserDetails({
            firstName: userFirstName || '',
            lastName: userLastName || '',
            organization: userCompanyName || '',
            title: userJob || '',
            email: userEmail || '',
            phone: userPhone || '',
        });
    }, [userCompanyName, userEmail, userFirstName, userJob, userLastName, userPhone]);

    useEffect(() => {
        if (!hocCommitteeList) {
            dispatch(fetchCommitteeList());
        }
        if (!senateCommitteeList) {
            dispatch(fetchSenateCommitteeList());
        }
    }, [dispatch, hocCommitteeList, senateCommitteeList]);

    const handleCommitteeChange = (
        fullCommitteeData: entityListExtra | { code: string; title: string; link: string }
    ) => {
        const committee: Selection = {
            code: fullCommitteeData.code.toString(),
            title: fullCommitteeData.title,
        };
        const newSelectedCommittees = selectedCommittees.find(
            (selectedCommittee) => selectedCommittee.code === committee.code
        )
            ? selectedCommittees.filter(
                  (selectedCommittee) => selectedCommittee.code !== committee.code
              )
            : [...selectedCommittees, committee];
        setSelectedCommittees(newSelectedCommittees);
    };

    const handleUserDetailsChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const { name, value } = event.target;
        setUserDetails((prev) => ({
            ...prev,
            [name]: value,
        }));
    };

    const handleLoginRedirect = async () => {
        dispatch(logIn());
    };

    const handleSignUpRedirect = async () => {
        dispatch(signUp());
    };

    const getSubscriptionId = () => {
        const numSelections = selectedCommittees.length;
        if (subscriptionPeriod === SubscriptionDuration.annual) {
            if (numSelections > 20) {
                return 'price_1QEcqFJJ9Ay28yyNcs4Mvb01';
            } else if (numSelections > 10) {
                return 'price_1QEcqDJJ9Ay28yyNQ89kENe9';
            } else if (numSelections > 5) {
                return 'price_1QEcqAJJ9Ay28yyN3GZAN7Lw';
            } else if (numSelections > 1) {
                return 'price_1QEcq7JJ9Ay28yyNDQhrBPqb';
            } else {
                // 1 selection
                return 'price_1QEcq3JJ9Ay28yyNp5mONbWy';
            }
        } else {
            // semi-annual
            if (numSelections > 20) {
                return 'price_1QEcsBJJ9Ay28yyNzp6ZoAtu';
            } else if (numSelections > 10) {
                return 'price_1QEcs9JJ9Ay28yyNFzbrXopo';
            } else if (numSelections > 5) {
                return 'price_1QEcs6JJ9Ay28yyNg3YyZ7S6';
            } else if (numSelections > 1) {
                return 'price_1QEcs4JJ9Ay28yyNKp0S0aAB';
            } else {
                // 1 selection
                return 'price_1QEcs2JJ9Ay28yyNh3kF2IC4';
            }
        }
    };

    const handlePayment = async () => {
        await dispatch(
            createUser({
                firstName: userDetails.firstName,
                lastName: userDetails.lastName,
                company: userDetails.organization,
                job_title: userDetails.title,
                industry: '',
                phone: userDetails.phone,
                email: userDetails.email,
            })
        ).unwrap();

        dispatch(updateCommitteesEmailsBeforePayment(selectedCommittees));
        dispatch(updateCommitteesSelections(selectedCommittees));

        const subscriptionRequest: SubscriptionRequest = {
            productId: getSubscriptionId(),
            quantity: selectedCommittees.length,
        };

        // send internal email to notify us of subscription
        axios.post(
            'https://liq-functions-v2.azurewebsites.net/api/sign_up?code=Cjexl0XqB_eevf0RJzz-AzsBvFFODN7UrZhdk-PIcV88AzFuQsedGA%3D%3D',
            {
                first_name: userDetails.firstName,
                last_name: userDetails.lastName,
                client_email: userDetails.email,
                committees: selectedCommittees.map(committee => committee.code),
                duration: subscriptionPeriod,
            },
            { withCredentials: false }
        ); 
        await dispatch(redirectToPayment(subscriptionRequest)).unwrap();
    };

    const calculateCost = (): string => {
        var cost = 0;
        const numCommittees = selectedCommittees.length;

        if (subscriptionPeriod === SubscriptionDuration.semiAnnual) {
            // calculate variable fee
            if (numCommittees > 20) {
                cost += 500 * numCommittees;
            } else if (numCommittees >= 11) {
                cost += 550 * numCommittees;
            } else if (numCommittees >= 6) {
                cost += 600 * numCommittees;
            } else if (numCommittees >= 2) {
                cost += 700 * numCommittees;
            } else {
                cost += 1000 * numCommittees;
            }
        } else if (subscriptionPeriod === SubscriptionDuration.annual) {
            // calculate variable fee
            if (numCommittees > 20) {
                cost += 700 * numCommittees;
            } else if (numCommittees >= 11) {
                cost += 775 * numCommittees;
            } else if (numCommittees >= 6) {
                cost += 850 * numCommittees;
            } else if (numCommittees >= 2) {
                cost += 1000 * numCommittees;
            } else {
                cost += 1500 * numCommittees;
            }
        }
        return `Total: $${cost.toLocaleString()} - ${numCommittees} committee(s) for ${subscriptionPeriod}`;
    };

    const isDisabled = !userEmail;

    return (
        <Box className="mt-32 mb-24 mx-8 xl:mx-24">
            <Typography variant="h4" gutterBottom>
                Committee Monitoring Subscription
            </Typography>
            {!userEmail && (
                <>
                    <Typography sx={{ marginTop: '2rem' }} variant="h6" gutterBottom>
                        First, please{' '}
                        <span
                            className="text-sky-600 hover:underline pointer"
                            onClick={handleLoginRedirect}
                            style={{
                                cursor: 'pointer',
                            }}
                        >
                            Sign In
                        </span>{' '}
                        to your LobbyIQ account or{' '}
                        <span
                            className="text-sky-600 hover:underline pointer"
                            onClick={handleSignUpRedirect}
                            style={{
                                cursor: 'pointer',
                            }}
                        >
                            Sign Up
                        </span>{' '}
                        to create one.
                    </Typography>
                </>
            )}

            <div style={{ opacity: isDisabled ? 0.3 : 1 }}>
                <Typography sx={{ marginTop: '2rem' }} variant="h6" gutterBottom>
                    Step 1: Select Committees
                </Typography>
                <Typography sx={{ marginBottom: '0.5rem' }}>House Committees</Typography>
                <Grid container spacing={2}>
                    {hocCommitteeList ? (
                        hocCommitteeList.map((committee, index) => (
                            <Grid item xs={3} sm={3} md={2} lg={1} key={index}>
                                <FormControlLabel
                                    control={
                                        <Checkbox
                                            checked={
                                                !!selectedCommittees.find(
                                                    (selectedCommittee) =>
                                                        selectedCommittee.code ===
                                                        committee.code.toString()
                                                )
                                            }
                                            onChange={() => handleCommitteeChange(committee)}
                                            disabled={isDisabled}
                                        />
                                    }
                                    label={
                                        <Link
                                            to={`https://www.ourcommons.ca/Committees/en/${committee.code}`}
                                            className="text-sky-600 hover:underline"
                                            onClick={(e) => {
                                                e.preventDefault();
                                                window
                                                    .open(
                                                        `https://www.ourcommons.ca/Committees/en/${committee.code}`,
                                                        '_blank'
                                                    )
                                                    ?.focus();
                                            }}
                                        >
                                            {committee.code}
                                        </Link>
                                    }
                                />
                            </Grid>
                        ))
                    ) : (
                        <div className="m-3">
                            <LoadSpinner />
                        </div>
                    )}
                </Grid>
                <Typography sx={{ marginTop: '1.5rem', marginBottom: '0.5rem' }}>
                    Senate Committees
                </Typography>
                <Grid container spacing={2}>
                    {senateCommitteeList ? (
                        senateCommitteeList.map((committee, index) => (
                            <Grid item xs={3} sm={3} md={2} lg={1} key={index}>
                                <FormControlLabel
                                    control={
                                        <Checkbox
                                            checked={
                                                !!selectedCommittees.find(
                                                    (selectedCommittee) =>
                                                        selectedCommittee.code ===
                                                        committee.code.toString()
                                                )
                                            }
                                            onChange={() => handleCommitteeChange(committee)}
                                            disabled={isDisabled}
                                        />
                                    }
                                    label={
                                        <Link
                                            to={`https://sencanada.ca/en/committees/${committee.code}/44-1`}
                                            className="text-sky-600 hover:underline"
                                            onClick={(e) => {
                                                e.preventDefault();
                                                window
                                                    .open(
                                                        `https://sencanada.ca/en/committees/${committee.code}/44-1`,
                                                        '_blank'
                                                    )
                                                    ?.focus();
                                            }}
                                        >
                                            {committee.code}
                                        </Link>
                                    }
                                />
                            </Grid>
                        ))
                    ) : (
                        <div className="m-3">
                            <LoadSpinner />
                        </div>
                    )}
                </Grid>
                <Typography sx={{ marginTop: '1.5rem', marginBottom: '0.5rem' }}>
                    Alberta Committees
                </Typography>
                <Grid container spacing={2}>
                    {ALBERTA_COMMITTEES.map((committee, index) => (
                        <Grid item xs={3} sm={3} md={2} lg={1} key={index}>
                            <FormControlLabel
                                control={
                                    <Checkbox
                                        checked={
                                            !!selectedCommittees.find(
                                                (selectedCommittee) =>
                                                    selectedCommittee.code ===
                                                    committee.code.toString()
                                            )
                                        }
                                        onChange={() => handleCommitteeChange(committee)}
                                        disabled={isDisabled}
                                    />
                                }
                                label={
                                    <Link
                                        to={committee.link}
                                        className="text-sky-600 hover:underline"
                                        onClick={(e) => {
                                            e.preventDefault();
                                            window.open(committee.link, '_blank')?.focus();
                                        }}
                                    >
                                        {committee.code}
                                    </Link>
                                }
                            />
                        </Grid>
                    ))}
                </Grid>
                <Typography variant="h6" gutterBottom sx={{ marginTop: '2rem' }}>
                    Step 2: Select Time Period of Subscription
                </Typography>
                <div className="w-[350px]">
                    <FormControl fullWidth>
                        <InputLabel id="subscription-period-label">Time Period</InputLabel>
                        <Select
                            labelId="subscription-period-label"
                            label="Time Period"
                            value={subscriptionPeriod}
                            fullWidth
                            onChange={(event) =>
                                setSubscriptionPeriod(event.target.value as SubscriptionDuration)
                            }
                            disabled={isDisabled}
                        >
                            <MenuItem value={SubscriptionDuration.semiAnnual}>
                                Six (6) months starting today
                            </MenuItem>
                            <MenuItem value={SubscriptionDuration.annual}>
                                Twelve (12) months starting today
                            </MenuItem>
                        </Select>
                    </FormControl>
                </div>
                <Typography variant="h6" gutterBottom sx={{ marginTop: '2rem' }}>
                    Step 3: Subscription Cost
                </Typography>
                <Typography>
                    {selectedCommittees.length !== 0 && subscriptionPeriod !== null
                        ? calculateCost()
                        : 'Please complete Steps 1 and 2'}
                </Typography>
                <Typography variant="h6" gutterBottom sx={{ marginTop: '2rem' }}>
                    Step 4: Enter Your Details
                </Typography>
                <div className="w-2/3">
                    <Grid container spacing={2}>
                        <Grid item xs={12} sm={6}>
                            <TextField
                                label="First Name"
                                name="firstName"
                                value={userDetails.firstName}
                                onChange={handleUserDetailsChange}
                                fullWidth
                                disabled={isDisabled}
                            />
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <TextField
                                label="Last Name"
                                name="lastName"
                                value={userDetails.lastName}
                                onChange={handleUserDetailsChange}
                                fullWidth
                                disabled={isDisabled}
                            />
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <TextField
                                label="Organization"
                                name="organization"
                                value={userDetails.organization}
                                onChange={handleUserDetailsChange}
                                fullWidth
                                disabled={isDisabled}
                            />
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <TextField
                                label="Title"
                                name="title"
                                value={userDetails.title}
                                onChange={handleUserDetailsChange}
                                fullWidth
                                disabled={isDisabled}
                            />
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <TextField
                                label="Email"
                                name="email"
                                value={userDetails.email}
                                onChange={handleUserDetailsChange}
                                fullWidth
                                disabled={isDisabled}
                            />
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <TextField
                                label="Phone"
                                name="phone"
                                value={userDetails.phone}
                                onChange={handleUserDetailsChange}
                                fullWidth
                                disabled={isDisabled}
                            />
                        </Grid>
                    </Grid>
                </div>
                <Typography variant="h6" gutterBottom sx={{ marginTop: '2rem' }}>
                    Step 5: Payment
                </Typography>
                <Button
                    variant="contained"
                    onClick={() => handlePayment()}
                    disabled={isDisabled || selectedCommittees.length === 0}
                >
                    Proceed to Payment
                </Button>
            </div>
        </Box>
    );
};

export default PricingCommitteePage;
