export const OrganizationLobbyingActivity = (
    <div className="flex flex-col gap-2">
        <div>
            A list of the 25 most recent communications of an organization, with a link to the
            underlying primary record on Office of the Commissioner of Lobbying of Canada welcome
            page.
        </div>
        <div>
            Each row corresponds to one DPOH at the meeting, so that meetings with multiple DPOHs
            span multiple rows.
        </div>
        <div>
            The column “Prior Comms (36M)” counts the number of meetings between the given
            organization and DPOH over the last 12 months.
        </div>
    </div>
);
