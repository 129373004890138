import React, { useState } from 'react';
import moment from 'moment';
import DateSelection from './DateSelection';
import { Button } from '@mui/material';
import CalendarMonthRoundedIcon from '@mui/icons-material/CalendarMonthRounded';
import ErrorOutlineRoundedIcon from '@mui/icons-material/ErrorOutlineRounded';
import TaskAltRoundedIcon from '@mui/icons-material/TaskAltRounded';
import ArrowForwardRoundedIcon from '@mui/icons-material/ArrowForwardRounded';

const DateRangeSelection = ({
    isInvalid,
    startDate,
    endDate,
    handleStartDateChange,
    handleEndDateChange,
}: any) => {
    const [dateStartOpen, setDateStartOpen] = useState<boolean>(false);
    const [dateEndOpen, setDateEndOpen] = useState<boolean>(false);

    return (
        <div>
            <DateSelection
                defaultDate={moment(startDate)}
                open={dateStartOpen}
                handleClose={() => setDateStartOpen(false)}
                handleDateChange={handleStartDateChange}
                views={['year', 'month', 'day']}
            />
            <DateSelection
                open={dateEndOpen}
                handleClose={() => setDateEndOpen(false)}
                handleDateChange={handleEndDateChange}
                views={['year', 'month', 'day']}
            />
            <div className="flex flex-row items-center gap-2 mx-2">
                <Button
                    fullWidth
                    onClick={() => setDateStartOpen(true)}
                    variant="outlined"
                    startIcon={<CalendarMonthRoundedIcon />}
                    color={isInvalid ? 'error' : 'success'}
                >
                    Start: {startDate}
                </Button>
                <ArrowForwardRoundedIcon
                    className='m-2'
                    color={isInvalid ? 'error' : 'success'}
                />
                <Button
                    fullWidth
                    onClick={() => setDateEndOpen(true)}
                    variant="outlined"
                    startIcon={<CalendarMonthRoundedIcon />}
                    color={isInvalid ? 'error' : 'success'}
                >
                    End: {endDate}
                </Button>
            </div>
            {isInvalid ? (
                <>
                    <hr className="h-0.5 my-3 bg-red-400 rounded" />
                    <div className="text-sm italic text-red-700 flex flex-row items-center justify-center gap-2">
                        <ErrorOutlineRoundedIcon
                            fontSize="small"
                            color="inherit"
                        />
                        Selected dates must be within one year of eachother
                    </div>
                </>
            ) : (
                <>
                    <hr className="h-0.5 my-3 bg-green-400 rounded" />
                    <div className="text-sm italic text-green-700 flex flex-row items-center justify-center gap-2">
                        <TaskAltRoundedIcon fontSize="small" color="inherit" />
                        Data returned will be between {startDate} and {endDate}
                    </div>
                </>
            )}
        </div>
    );
};

export default DateRangeSelection;
