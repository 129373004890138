import React, { useEffect, useState } from 'react';
import LobbyFirmDashboard from './LobbyFirmDashboard';
import moment from 'moment';
import { useAppDispatch } from '../../store/store';
import { useSelector } from 'react-redux';
import {
    entityOrg,
    entityOverviews,
    entityRegistration,
} from '../../interfaces/generic-entity.interface';
import { useParams } from 'react-router-dom';
import {
    fetchConsultantLatestDeregistrations,
    fetchConsultantLatestRegistrations,
    fetchConsultantOverview,
    fetchConsultantTopInstitutions,
    fetchConsultantTopPartners,
    fetchConsultantTopSectors,
    selectConsultantExportFail,
    selectConsultantExportLoading,
    selectConsultantOverview,
    selectConsultantOverviewLoading,
    selectConsultantTopInstitutions,
    selectConsultantTopInstitutionsLoading,
    selectConsultantTopPartners,
    selectConsultantTopPartnersLoading,
    selectConsultantTopSectors,
    selectConsultantTopSectorsLoading,
    selectDeRegistrations,
    selectDeRegistrationsLoading,
    selectRegistrations,
    selectRegistrationsLoading,
} from '../../store/reducers/consultantSlice';

const LobbyFirmDataLayer = () => {
    const dispatch = useAppDispatch();

    const [date, setDate] = useState<string>(
        moment.utc(moment()).format('YYYY-MM-DD')
    );

    // Get the name of the current firm and consultant
    const url_params = useParams();
    const consultant_name: string | undefined =
        url_params.consultantName !== undefined
            ? url_params.consultantName
            : '';
    const firm_name: string | undefined =
        url_params.entityId !== undefined ? url_params.entityId : '';

    const overview: entityOverviews | null = useSelector(
        selectConsultantOverview
    );
    const overview_loading: boolean = useSelector(
        selectConsultantOverviewLoading
    );

    const registrations: entityRegistration[] | null =
        useSelector(selectRegistrations);
    const registrations_loading: boolean = useSelector(
        selectRegistrationsLoading
    );

    const deregistrations: entityRegistration[] | null = useSelector(
        selectDeRegistrations
    );
    const deregistrations_loading: boolean = useSelector(
        selectDeRegistrationsLoading
    );

    const top_partners: entityOrg[] | null = useSelector(
        selectConsultantTopPartners
    );
    const top_partners_loading: boolean = useSelector(
        selectConsultantTopPartnersLoading
    );

    const top_sectors: entityOrg[] | null = useSelector(
        selectConsultantTopSectors
    );
    const top_sectors_loading: boolean = useSelector(
        selectConsultantTopSectorsLoading
    );

    const top_institutions: entityOrg[] | null = useSelector(
        selectConsultantTopInstitutions
    );
    const top_institutions_loading: boolean = useSelector(
        selectConsultantTopInstitutionsLoading
    );

    const export_loading = useSelector(selectConsultantExportLoading);
    const export_fail = useSelector(selectConsultantExportFail);

    useEffect(() => {
        dispatch(
            fetchConsultantLatestRegistrations({
                firm: firm_name,
                consultant: consultant_name,
                date: date,
            })
        );
        dispatch(
            fetchConsultantLatestDeregistrations({
                firm: firm_name,
                consultant: consultant_name,
                date: date,
            })
        );
        dispatch(
            fetchConsultantOverview({
                firm: firm_name,
                consultant: consultant_name,
                date: date,
            })
        );
        dispatch(
            fetchConsultantTopPartners({
                firm: firm_name,
                consultant: consultant_name,
                date: date,
            })
        );
        dispatch(
            fetchConsultantTopSectors({
                firm: firm_name,
                consultant: consultant_name,
                date: date,
            })
        );
        dispatch(
            fetchConsultantTopInstitutions({
                firm: firm_name,
                consultant: consultant_name,
                date: date,
            })
        );
    }, [dispatch, date, firm_name, consultant_name]);

    const overview_headers = ['count'];

    // Get the current year
    const currentYear = moment(date, 'YYYY-MM-DD').year();

    // Populate the list with the last 8 years
    for (let i = currentYear; i >= currentYear - 6; i--) {
        overview_headers.push(String(i));
    }

    return (
        <LobbyFirmDashboard
            name={consultant_name}
            date={date}
            type="consultants"
            handleDateChange={setDate}
            registrations={registrations || []}
            registrationsLoading={registrations_loading}
            deregistrations={deregistrations || []}
            deregistrationsLoading={deregistrations_loading}
            overview={overview}
            overviewLoading={overview_loading}
            overviewHeaders={overview_headers}
            overviewIndent={['by Consultants', 'of Ministers or DMs']}
            topPartners={top_partners || []}
            topPartnersLoading={top_partners_loading}
            topSectors={top_sectors || []}
            topSectorsLoading={top_sectors_loading}
            topInstitutions={top_institutions || []}
            topInstitutionsLoading={top_institutions_loading}
            exportFail={export_fail}
            exportLoading={export_loading}
        />
    );
};

export default LobbyFirmDataLayer;
