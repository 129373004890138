import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import axios from 'axios';
import { RootState } from '../store';

export interface Selection {
    code: string;
    title: string;
    monthLastModified?: number;
}

export interface DashboardSelections {
    sectors: Selection[];
    institutions: Selection[];
    committees: Selection[];
    newsLetters: Selection[];
    sectorsLoading: boolean;
    institutionsLoading: boolean;
    committeesLoading: boolean;
    issuesLoading: boolean;
    sectorsError: boolean;
    institutionsError: boolean;
    committeesError: boolean;
    issuesError: boolean;
}

const initialState: DashboardSelections = {
    sectors: [],
    institutions: [],
    committees: [],
    newsLetters: [],
    sectorsLoading: false,
    institutionsLoading: false,
    committeesLoading: false,
    issuesLoading: false,
    sectorsError: false,
    institutionsError: false,
    committeesError: false,
    issuesError: false,
};

const baseURI = process.env.REACT_APP_API_BASE_URL;

export const fetchSectorsSelections = createAsyncThunk(
    'selections/sectors',
    async () => {
        const userInfo = await axios.get(`${baseURI}/selections/sectors`);
        return userInfo.data;
    }
);

export const fetchInstitutionsSelections = createAsyncThunk(
    'selections/institutions',
    async () => {
        const userInfo = await axios.get(`${baseURI}/selections/institutions`);
        return userInfo.data;
    }
);

export const fetchCommitteesSelections = createAsyncThunk(
    'selections/committees',
    async () => {
        const userInfo = await axios.get(`${baseURI}/selections/committees`);
        return userInfo.data;
    }
);

export const fetchNewsletterSelections = createAsyncThunk(
    'selections/newsletters',
    async () => {
        const userInfo = await axios.get(`${baseURI}/selections/newsletters`);
        return userInfo.data;
    }
);

export const updateSectorsSelections = createAsyncThunk(
    'selections/sectorsUpdate',
    async (newSectors: Selection[]) => {
        const userInfo = await axios.post(`${baseURI}/selections/sectors`, {
            newSectors,
        });
        return userInfo.data;
    }
);

export const updateInstitutionsSelections = createAsyncThunk(
    'selections/institutionsUpdate',
    async (newInstitutions: Selection[]) => {
        const userInfo = await axios.post(
            `${baseURI}/selections/institutions`,
            { newInstitutions }
        );
        return userInfo.data;
    }
);

export const updateCommitteesSelections = createAsyncThunk(
    'selections/committeesUpdate',
    async (newCommittees: Selection[]) => {
        const userInfo = await axios.post(`${baseURI}/selections/committees`, {
            newCommittees,
        });
        return userInfo.data;
    }
);

export const updateCommitteesEmailsBeforePayment = createAsyncThunk(
    'selections/committeesEmailsPrepayment',
    async (newCommittees: Selection[]) => {
        await axios.post(`${baseURI}/user/committee/prepayment`, {
            newCommittees,
        });
    }
);

export const updateNewsletterSelections = createAsyncThunk(
    'selections/newsletterUpdate',
    async (newCommittees: Selection[]) => {
        const userInfo = await axios.post(`${baseURI}/selections/newsletters`, {
            newCommittees,
        });
        return userInfo.data;
    }
);

const customSelectionSlice = createSlice({
    name: 'selectionReducer',
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(fetchSectorsSelections.pending, (state) => {
                state.sectorsError = false;
                state.sectorsLoading = true;
            })
            .addCase(fetchSectorsSelections.fulfilled, (state, action) => {
                state.sectorsLoading = false;
                state.sectors = action.payload;
            })
            .addCase(fetchSectorsSelections.rejected, (state) => {
                state.sectorsError = true;
                state.sectorsLoading = false;
            })
            .addCase(updateSectorsSelections.pending, (state) => {
                state.sectorsError = false;
                state.sectorsLoading = true;
            })
            .addCase(updateSectorsSelections.fulfilled, (state, action) => {
                state.sectorsLoading = false;
                state.sectors = action.payload;
            })
            .addCase(updateSectorsSelections.rejected, (state) => {
                state.sectorsError = true;
                state.sectorsLoading = false;
            })
            .addCase(fetchInstitutionsSelections.pending, (state) => {
                state.institutionsError = false;
                state.institutionsLoading = true;
            })
            .addCase(fetchInstitutionsSelections.fulfilled, (state, action) => {
                state.institutionsLoading = false;
                state.institutions = action.payload;
            })
            .addCase(fetchInstitutionsSelections.rejected, (state) => {
                state.institutionsError = true;
                state.institutionsLoading = false;
            })
            .addCase(updateInstitutionsSelections.pending, (state) => {
                state.institutionsError = false;
                state.institutionsLoading = true;
            })
            .addCase(
                updateInstitutionsSelections.fulfilled,
                (state, action) => {
                    state.institutionsLoading = false;
                    state.institutions = action.payload;
                }
            )
            .addCase(updateInstitutionsSelections.rejected, (state) => {
                state.institutionsError = true;
                state.institutionsLoading = false;
            })
            .addCase(fetchCommitteesSelections.pending, (state) => {
                state.committeesError = false;
                state.committeesLoading = true;
            })
            .addCase(fetchCommitteesSelections.fulfilled, (state, action) => {
                state.committeesLoading = false;
                state.committees = action.payload;
            })
            .addCase(fetchCommitteesSelections.rejected, (state) => {
                state.committeesError = true;
                state.committeesLoading = false;
            })
            .addCase(updateCommitteesSelections.pending, (state) => {
                state.committeesError = false;
                state.committeesLoading = true;
            })
            .addCase(updateCommitteesSelections.fulfilled, (state, action) => {
                state.committeesLoading = false;
                state.committees = action.payload;
            })
            .addCase(updateCommitteesSelections.rejected, (state) => {
                state.committeesError = true;
                state.committeesLoading = false;
            })
            .addCase(fetchNewsletterSelections.pending, (state) => {
                state.sectorsError = false;
                state.sectorsLoading = true;
            })
            .addCase(fetchNewsletterSelections.fulfilled, (state, action) => {
                state.sectorsLoading = false;
                state.newsLetters = action.payload;
            })
            .addCase(fetchNewsletterSelections.rejected, (state) => {
                state.sectorsError = true;
                state.sectorsLoading = false;
            })
            .addCase(updateNewsletterSelections.pending, (state) => {
                state.sectorsError = false;
                state.sectorsLoading = true;
            })
            .addCase(updateNewsletterSelections.fulfilled, (state, action) => {
                state.sectorsLoading = false;
                state.newsLetters = action.payload;
            })
            .addCase(updateNewsletterSelections.rejected, (state) => {
                state.sectorsError = true;
                state.sectorsLoading = false;
            });
    },
});

export const selectChosenSectors = (state: RootState) =>
    state.customSelections.sectors;
export const selectSectorsLoading = (state: RootState) =>
    state.customSelections.sectorsLoading;
export const selectSectorsErrored = (state: RootState) =>
    state.customSelections.sectorsError;
export const selectChosenInstitutions = (state: RootState) =>
    state.customSelections.institutions;
export const selectInstitutionsLoading = (state: RootState) =>
    state.customSelections.institutionsLoading;
export const selectInstitutionsErrored = (state: RootState) =>
    state.customSelections.institutionsError;
export const selectChosenCommittees = (state: RootState) =>
    state.customSelections.committees;
export const selectCommitteesLoading = (state: RootState) =>
    state.customSelections.committeesLoading;
export const selectCommitteesErrored = (state: RootState) =>
    state.customSelections.committeesError;
    export const selectChosenNewsletters = (state: RootState) =>
    state.customSelections.newsLetters;
export const selectSelectionLoading = (state: RootState) =>
    state.customSelections.committeesLoading ||
    state.customSelections.sectorsLoading ||
    state.customSelections.institutionsLoading;

export default customSelectionSlice.reducer;
