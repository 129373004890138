import { entityOrg } from '../interfaces/generic-entity.interface';

/*

ISSUE LINKS
Generate an issue dashboard link using a given issue code and name

*/
export const getIssueLink = (issue_code: number, issue_name: string): string =>
    `/issues/${encodeURIComponent(issue_code)}?name=${encodeURIComponent(issue_name)}`;
export const issueLinkFormatter = (issue_code: number, issue_name: string): string =>
    `URL#${issue_name}#${getIssueLink(issue_code, issue_name)}`;

/*

COMMITTEE LINKS
Generate a committee dashboard link using a given committee abbreviation code (4 letters) and committee name

*/
export const getCommitteeLink = (cmte_code: string, cmte_name: string): string =>
    `/committees/${encodeURIComponent(cmte_code)}?name=${encodeURIComponent(cmte_name)}`;

export const committeeLinkFormatter = (
    cmte_code: string,
    cmte_name: string,
    combo?: boolean
): string =>
    `URL#${cmte_code}${combo ? ' - ' + cmte_name : ''}#${getCommitteeLink(cmte_code, cmte_name)}`;

/*

SECTOR LINKS
Generate a sector dashboard link using a given sector code and name

*/
export const getSectorLink = (sector_code: string, sector_name: string): string =>
    `/sectors/${encodeURIComponent(sector_code)}?name=${encodeURIComponent(sector_name)}`;

export const sectorLinkFormatter = (sector_code: string, sector_name: string): string =>
    `URL#${sector_name}#${getSectorLink(sector_code, sector_name)}`;

/*

ORGANIZATION LINKS
Generate an organization dashboard link using a given sector (code/name), and org_name

*/
export const getOrganizationLink = (
    sector_code: string,
    sector_name: string,
    org_name: string
): string =>
    `/sectors/${encodeURIComponent(sector_code)}/organizations/${encodeURIComponent(
        org_name
    )}?sector=${encodeURIComponent(sector_name)}`;

export const organizationLinkFormatter = (
    sector_code: string,
    sector_name: string,
    org_name: string
): string => `URL#${org_name}#${getOrganizationLink(sector_code, sector_name, org_name)}`;

/*

DPOH LINKS
Generate a DPOH dashboard link using a given institution (code/name), and DPOH name

*/
export const getDPOHLink = (inst_code: number, inst_name: string, dpoh_name: string): string =>
    `/institutions/${encodeURIComponent(inst_code)}/dpoh/${encodeURIComponent(
        dpoh_name
    )}?institution=${encodeURIComponent(inst_name)}`;

export const DPOHLinkFormatter = (
    inst_code: number,
    inst_name: string,
    dpoh_name: string
): string => `URL#${dpoh_name}#${getDPOHLink(inst_code, inst_name, dpoh_name)}`;

/*

INSTITUTION LINKS
Generate an institution dashboard link using a given institution (code/name)

*/
export const getInstitutionLink = (inst_code: number, inst_name: string): string =>
    `/institutions/${encodeURIComponent(inst_code)}?name=${encodeURIComponent(inst_name)}`;

export const institutionLinkFormatter = (
    inst_code: number,
    inst_name: string,
    short?: boolean
): string =>
    `URL#${
        short && inst_name.includes('(') && inst_name.includes(')')
            ? inst_name.split('(')[1].split(')')[0]
            : inst_name
    }#${getInstitutionLink(inst_code, inst_name)}`;

/*

INSTITUTION LINKS
Generate a lobby firm dashboard link using a given firm name

*/
export const getLobbyFirmLink = (firm_name: string): string =>
    `/firms/${encodeURIComponent(firm_name)}?name=${encodeURIComponent(firm_name)}`;

export const lobbyFirmLinkFormatter = (firm_name: string): string =>
    `URL#${firm_name}#${getLobbyFirmLink(firm_name)}`;

/*

CONSULTANT LINKS
Generate a consultant dashboard link using a given firm name and consultant name

*/
export const getConsultantLink = (firm_name: string, consultant_name: string): string =>
    `/firms/${encodeURIComponent(firm_name)}/consultants/${encodeURIComponent(
        consultant_name
    )}?firm=${encodeURIComponent(firm_name)}`;

export const consultantLinkFormatter = (firm_name: string, consultant_name: string): string =>
    `URL#${consultant_name}#${getConsultantLink(firm_name, consultant_name)}`;

/*
    
MOVERS LINK FORMATTER
Generate an internal dashboard link using a given mover entity of type 'entityOrg'

*/
export const moverLinkFormatter = (
    mover: entityOrg,
    type:
        | 'organizations'
        | 'institutions'
        | 'sectors'
        | 'lobbyfirms'
        | 'dpoh'
        | 'subject'
        | 'committee'
): string => {
    if (type === 'organizations' && mover.sector_id && mover.sector) {
        return organizationLinkFormatter(mover.sector_id, mover.sector, mover.name);
    } else if (type === 'sectors') {
        return sectorLinkFormatter(mover.code, mover.name);
    } else if (type === 'institutions') {
        return institutionLinkFormatter(+mover.code, mover.name);
    } else if (type === 'dpoh' && mover.institution_id && mover.institution) {
        return DPOHLinkFormatter(+mover.institution_id, mover.institution, mover.name);
    } else if (type === 'lobbyfirms') {
        return lobbyFirmLinkFormatter(mover.name);
    } else if (type === 'committee') {
        return committeeLinkFormatter(mover.code, mover.name, true);
    } else return mover.name;
};
