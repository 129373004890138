import React from 'react';
import { Link } from 'react-router-dom';
import { replace_underscores_capitalize } from '../../helpers/string_formatters';
import { EntityDropDown } from '../navigation/UI/EntityDropDown';

interface CatalogueContextProps {
    catalogueType: string;
    catalogueParentCode: string;
    catalogueParentName: string;
}

const EntityCatalogueContext = ({
    catalogueType,
    catalogueParentCode,
    catalogueParentName,
}: CatalogueContextProps) => {
    return (
        <div className="lg:mt-8 mt-6 col-start-2 col-end-12 flex lg:flex-row flex-col justify-between lg:items-center">
            <div className="text-md lg:text-xl">
                Browse{' '}
                {catalogueType === 'committees'
                    ? 'Parliamentary Committees'
                    : replace_underscores_capitalize(catalogueType)}
                {catalogueType === 'organizations' ? ' From ' : ''}
                {catalogueType === 'organizations' ? (
                    <Link
                        className="text-blue-400 font-medium hover:underline"
                        to={`/sectors/${catalogueParentCode}?name=${encodeURIComponent(
                            catalogueParentName
                        )}`}
                    >
                        {catalogueParentName}
                    </Link>
                ) : (
                    ''
                )}
                {catalogueType === 'inactive organizations' ? ' From ' : ''}
                {catalogueType === 'inactive organizations' ? (
                    <Link
                        className="text-blue-400 font-medium hover:underline"
                        to={`/sectors/${catalogueParentCode}?name=${encodeURIComponent(
                            catalogueParentName
                        )}`}
                    >
                        {catalogueParentName}
                    </Link>
                ) : (
                    ''
                )}
                {catalogueType === 'consultants' && ' From '}
                {catalogueType === 'consultants' && (
                    <Link
                        className="text-blue-400 font-medium hover:underline"
                        to={`/firms/${catalogueParentCode}?name=${encodeURIComponent(
                            catalogueParentName
                        )}`}
                    >
                        {catalogueParentName}
                    </Link>
                )}
                {catalogueType === 'DPOHs' && ' From '}
                {catalogueType === 'DPOHs' && (
                    <Link
                        className="text-blue-400 font-medium hover:underline"
                        to={`/institutions/${catalogueParentCode}?name=${encodeURIComponent(
                            catalogueParentName
                        )}`}
                    >
                        {catalogueParentName}
                    </Link>
                )}
                {catalogueType === 'MPs' && ' From '}
                {catalogueType === 'MPs' && (
                    <Link
                        className="text-blue-400 font-medium hover:underline"
                        to={`/committees/${catalogueParentCode}?name=${encodeURIComponent(
                            catalogueParentName
                        )}`}
                    >
                        {catalogueParentName}
                    </Link>
                )}
            </div>
            <div
                className={
                    ['organizations', 'consultants', 'DPOHs', 'MPs'].includes(catalogueType)
                        ? 'hidden'
                        : 'hidden lg:flex'
                }
            >
                <EntityDropDown primaryText={catalogueType} />
            </div>
        </div>
    );
};

export default EntityCatalogueContext;
