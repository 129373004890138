export const ALBERTA_COMMITTEES = [
    { code: "HS", title: "Alberta Heritage Savings Trust Fund", link: "https://www.assembly.ab.ca/assembly-business/committees/HS" },
    { code: "EF", title: "Alberta's Economic Future", link: "https://www.assembly.ab.ca/assembly-business/committees/EF" },
    { code: "CR23", title: "Conflicts of Interest Act Review", link: "https://www.assembly.ab.ca/assembly-business/committees/CR23" },
    { code: "EE", title: "Ethics Commissioner and Chief Electoral Officer Search", link: "https://www.assembly.ab.ca/assembly-business/committees/EE" },
    { code: "FC", title: "Families and Communities", link: "https://www.assembly.ab.ca/assembly-business/committees/FC" },
    { code: "LO", title: "Legislative Offices", link: "https://www.assembly.ab.ca/assembly-business/committees/LO" },
    { code: "MS", title: "Members' Services", link: "https://www.assembly.ab.ca/assembly-business/committees/MS" },
    { code: "PB", title: "Private Bills", link: "https://www.assembly.ab.ca/assembly-business/committees/PB" },
    { code: "PE", title: "Privileges and Elections, Standing Orders and Printing", link: "https://www.assembly.ab.ca/assembly-business/committees/PE" },
    { code: "PA", title: "Public Accounts", link: "https://www.assembly.ab.ca/assembly-business/committees/PA" },
    { code: "RS", title: "Resource Stewardship", link: "https://www.assembly.ab.ca/assembly-business/committees/RS" }
]