import React from 'react';
import {
    Accordion,
    AccordionDetails,
    AccordionSummary,
    Breadcrumbs,
} from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { Link } from 'react-router-dom';

const FAQ = () => {
    return (
        <div className="lg:pt-32 pt-20">
            <div className="grid grid-cols-12">
                <Breadcrumbs className="col-start-2 col-end-12" separator=">">
                    <Link to="/" className="hover:underline">
                        Lobby<b>IQ</b>
                    </Link>
                    <div>FAQ</div>
                </Breadcrumbs>
            </div>

            <div className="grid grid-cols-12 lg:mt-12 mt-4 py-8">
                <div className="col-start-2 col-end-12 flex flex-col">
                    <h1 className="lg:text-4xl text-3xl mb-8">
                        Frequently Asked Questions
                    </h1>
                </div>
                <div className="col-start-2 col-end-12 flex flex-col mt-4">
                    <h2 className="text-lg font-medium mb-8">
                        How our data is cleaned and processed
                    </h2>
                    <div className="col-start-2 col-end-12 shadow-sm rounded-md">
                        <Accordion elevation={0}>
                            <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                                <div className="text-slate-600 my-2">
                                    How does LobbyIQ link records to connect all
                                    communications and registrations that belong
                                    to the same organizations?
                                </div>
                            </AccordionSummary>
                            <hr className="h-0.5 my-1 bg-slate-50 rounded" />
                            <AccordionDetails>
                                <div className="p-4">
                                    This is done through a combination of
                                    machine-learning clustering algorithms and
                                    manual checks of algorithm outputs. Every
                                    raw entry is reviewed by at least two team
                                    members before its assignment is entered
                                    into our database
                                </div>
                            </AccordionDetails>
                        </Accordion>
                    </div>
                    <div className="col-start-2 col-end-12 shadow-sm rounded-md mt-3">
                        <Accordion elevation={0}>
                            <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                                <div className="text-slate-600 my-2">
                                    How does LobbyIQ embed organizations in
                                    sectors?
                                </div>
                            </AccordionSummary>
                            <hr className="h-0.5 my-1 bg-slate-50 rounded" />
                            <AccordionDetails>
                                <div className="p-4">
                                    This is done manually, and verified through
                                    a variety of publicly available
                                    industry-assignments. We use the
                                    North-American Industry Classification
                                    System (NAICS) for the vast majority of
                                    organizations, but we additionally define a
                                    few non-NAICS sectors to reflect the
                                    non-profit and advocacy sectors.
                                </div>
                            </AccordionDetails>
                        </Accordion>
                    </div>
                    <div className="col-start-2 col-end-12 shadow-sm rounded-md mt-3">
                        <Accordion elevation={0}>
                            <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                                <div className="text-slate-600 my-2">
                                    How Frequently is LobbyIQ's Database
                                    updated?
                                </div>
                            </AccordionSummary>
                            <hr className="h-0.5 my-1 bg-slate-50 rounded" />
                            <AccordionDetails>
                                <div className="p-4">
                                    Our ETL (extract-transform-load) pipeline
                                    updates the official records every two days.
                                    For known entities, the records are thus
                                    available in close to real-time. For
                                    not-yet-known raw string entries, the
                                    required manual review and the need to
                                    verify the correct assignment of
                                    sector-codes to new organizations means that
                                    this information is updated with a one or
                                    two week lag.
                                </div>
                            </AccordionDetails>
                        </Accordion>
                    </div>
                </div>
                <div className="col-start-2 col-end-12 flex flex-col mt-8">
                    <h2 className="text-lg font-medium mb-8">
                        Interpreting the data on our dashboards
                    </h2>
                    <div className="col-start-2 col-end-12 shadow-sm rounded-md">
                        <Accordion elevation={0}>
                            <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                                <div className="text-slate-600 my-2">
                                    Why do the pie-chart breakdown on a
                                    dashboard not display the same totals,
                                    despite being based on the same aggregate
                                    communications?
                                </div>
                            </AccordionSummary>
                            <hr className="h-0.5 my-1 bg-slate-50 rounded" />
                            <AccordionDetails>
                                <div className="p-4">
                                    Every communication is associated with a
                                    single organization, which in turn belongs
                                    to a single sector, creating a unique
                                    mapping. The same is not true for
                                    institutions, designated public office
                                    holders (DPOHs), or subjects. A single
                                    communication can involve more than one
                                    DPOH, and different DPOHs can represent
                                    different government institutions. In
                                    addition, one communication often pertains
                                    to more than one subject. Because of this,
                                    the clusters for institutions, DPOHs, and
                                    subjects typically aggregate to larger
                                    totals than the cluster for organizations
                                    and sectors. The opposite is true for the
                                    lobby-firm cluster. Because some fraction of
                                    communications is always done by
                                    organizations in-house, the cluster for
                                    lobby firms aggregates to a smaller total
                                    than the other clusters.
                                </div>
                            </AccordionDetails>
                        </Accordion>
                    </div>
                    <div className="col-start-2 col-end-12 shadow-sm rounded-md mt-3">
                        <Accordion elevation={0}>
                            <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                                <div className="text-slate-600 my-2">
                                    How to interpret lobbying activity on the
                                    House of Commons dashboards?
                                </div>
                            </AccordionSummary>
                            <hr className="h-0.5 my-1 bg-slate-50 rounded" />
                            <AccordionDetails>
                                <div className="p-4">
                                    The majority of lobbying communications
                                    happens with civil servants representing
                                    specific government institutions. These
                                    communications are aggregated into LobbyIQs
                                    Institutions dashboards. However, a sizable
                                    portion of communications also involves MPs.
                                    These communications are aggregated into our
                                    House of Commons (HoC) committee dashboards,
                                    depending on which (if any) committee an MP
                                    belongs to at the time of a communication
                                    occurs. It is important to note that the
                                    mapping of communications into a HoC
                                    committee is less clear-cut than the
                                    mappings to sectors and institutions. This
                                    is because we cannot observe whether an MP
                                    was part of a communication because of their
                                    membership in a specific committee. For
                                    example, it could be instead that an MP was
                                    involved in a meeting because of regional
                                    considerations related to the MP's riding.
                                    The upshot is that the aggregation-clusters
                                    of lobbying activities (by organizations,
                                    sectors, subjects and lobby firms) on the
                                    HoC committee dashboards need to be
                                    interpreted with some caution. By contrast,
                                    the “top issues” bump-charts on the HoC
                                    dashboards are generated from committee
                                    meeting minutes and can therefore be clearly
                                    mapped to committees. (See also: “Q: What
                                    are the issue bump-charts on the HoC
                                    committee dashboards?”)
                                </div>
                            </AccordionDetails>
                        </Accordion>
                    </div>
                    <div className="col-start-2 col-end-12 shadow-sm rounded-md mt-3">
                        <Accordion elevation={0}>
                            <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                                <div className="text-slate-600 my-2">
                                    What are the issue bump-charts on the HoC
                                    committee dashboards?
                                </div>
                            </AccordionSummary>
                            <hr className="h-0.5 my-1 bg-slate-50 rounded" />
                            <AccordionDetails>
                                <div className="p-4">
                                    LobbyIQ creates a mapping from topics
                                    discussed in committee meetings into bigger
                                    “issues,” which more holistically summarize
                                    our subscribers' areas of interest. For
                                    example, we may map the topics of “nuclear
                                    energy,” “small nuclear reactors,” “modular
                                    reactors,” and “nuclear waste” into a single
                                    “nuclear energy” issue. The issue bump-chart
                                    provides a visual overview of the ebb and
                                    flow of topics that dominated the last seven
                                    meetings of a committee. (See also: “Q: How
                                    do I understand the Issue Dashboards?”)
                                </div>
                            </AccordionDetails>
                        </Accordion>
                    </div>
                    <div className="col-start-2 col-end-12 shadow-sm rounded-md mt-3">
                        <Accordion elevation={0}>
                            <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                                <div className="text-slate-600 my-2">
                                    What are TFIDF Key Themes?
                                </div>
                            </AccordionSummary>
                            <hr className="h-0.5 my-1 bg-slate-50 rounded" />
                            <AccordionDetails>
                                <div className="p-4">
                                    TFIDF stands for “text frequency, inverse
                                    document frequency”. This metric extracts
                                    from a text those phrases that
                                    machine-learning algorithms deem to be
                                    central to the text, but that are also
                                    relatively unusual/infrequent relative to
                                    the overall corpus of text. We use this
                                    approach to identify the key phrases that
                                    best characterize lobbying activity in a
                                    given sector or institution in a given
                                    month. Our approach proceeds as follows: we
                                    take all text descriptions of lobbying
                                    activity in, say, one sector in a month,
                                    then we translate any French text into
                                    English, then we run our TFIDF algorithm on
                                    that month's sector-specific text, relative
                                    to a corpus of text (to isolate the “inverse
                                    document frequency” part) that includes all
                                    lobbying in that same sector in the previous
                                    twelve months. (See also: “Q: What is the
                                    Corpus of Text used for TFIDF Key Themes?”)
                                </div>
                            </AccordionDetails>
                        </Accordion>
                    </div>
                    <div className="col-start-2 col-end-12 shadow-sm rounded-md mt-3">
                        <Accordion elevation={0}>
                            <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                                <div className="text-slate-600 my-2">
                                    What is the Corpus of Text used for TFIDF
                                    Key Themes?
                                </div>
                            </AccordionSummary>
                            <hr className="h-0.5 my-1 bg-slate-50 rounded" />
                            <AccordionDetails>
                                <div className="p-4">
                                    We define the text frequency (TF) of a
                                    theme/phrase on the text-descriptions
                                    contained in all registrations that
                                    generated communications/meetings in a
                                    sector in a month. To compare this TF
                                    measure to the relative frequency of a theme
                                    (“inverse document frequency”/ IDF), it is
                                    important how the comparison corpus text is
                                    chosen. Consider for example the “Dairy
                                    cattle and milk production” sector. If the
                                    corpus of text was chosen to be the
                                    registration texts from all sectors, then
                                    TFIDF would primarily pick themes that are
                                    common in the dairy sector but uncommon
                                    elsewhere. With that approach, “milk” would
                                    be likely to show up as a key-theme of
                                    lobbying in the dairy sector every month,
                                    but this would not be a very insightful
                                    finding. We therefore define the corpus of
                                    text as all registrations from the same
                                    sector that generated communications over
                                    the prior 9 months. In this way, TFIDF picks
                                    up on themes becoming more or less important
                                    within the dairy-sector, which is a much
                                    more interesting question. (See also: “Q:
                                    What are TFIDF Key Themes?”)
                                </div>
                            </AccordionDetails>
                        </Accordion>
                    </div>
                    <div className="col-start-2 col-end-12 shadow-sm rounded-md mt-3">
                        <Accordion elevation={0}>
                            <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                                <div className="text-slate-600 my-2">
                                    How do I understand the Issue Dashboards?
                                </div>
                            </AccordionSummary>
                            <hr className="h-0.5 my-1 bg-slate-50 rounded" />
                            <AccordionDetails>
                                <div className="p-4">
                                    LobbyIQ creates a mapping from topics
                                    discussed in committee meetings and on the
                                    parliamentary floor (the “Hansard”) into
                                    bigger “issues,” which more holistically
                                    summarize our subscribers' areas of
                                    interest. For example, we may map the topics
                                    of “nuclear energy,” “small nuclear
                                    reactors,” “modular reactors,” and “nuclear
                                    waste” into a single “nuclear energy” issue.
                                    These issues have their own dashboards on
                                    our website, and our subscribers can see a
                                    calendar view of every occurrence on which a
                                    topic mapped into an issue was discussed,
                                    including information on the exact date and
                                    committee, and hyperlinks to the raw text.
                                    (See also: “Q: What are the issue
                                    bump-charts on the HoC committee
                                    dashboards?”) The committee-cluster provides
                                    a breakdown of the committees in which an
                                    issue came up over the last/trailing twelve
                                    months (TTM).
                                </div>
                            </AccordionDetails>
                        </Accordion>
                    </div>
                    <div className="col-start-2 col-end-12 shadow-sm rounded-md mt-3">
                        <Accordion elevation={0}>
                            <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                                <div className="text-slate-600 my-2">
                                    Why do the aggregate number of
                                    communications across all institutions
                                    appear larger than across all sectors?
                                </div>
                            </AccordionSummary>
                            <hr className="h-0.5 my-1 bg-slate-50 rounded" />
                            <AccordionDetails>
                                <div className="p-4">
                                    Every communication is associated with a
                                    single organization, which in turn belongs
                                    to a single sector, creating a unique
                                    mapping. In contrast, a single communication
                                    can involve more than involve multiple
                                    government institutions (only if it involves
                                    multiple DPOHs), so therefore gets counted
                                    more than once on the institutions
                                    dashboards. (See also: “Q: Why do the
                                    pie-chart breakdown on a dashboard not
                                    display the same totals, despite being based
                                    on the same aggregate communications?”)
                                </div>
                            </AccordionDetails>
                        </Accordion>
                    </div>
                    <div className="col-start-2 col-end-12 shadow-sm rounded-md mt-3">
                        <Accordion elevation={0}>
                            <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                                <div className="text-slate-600 my-2">
                                    What time-dimensions are displayed on a
                                    dashboard?
                                </div>
                            </AccordionSummary>
                            <hr className="h-0.5 my-1 bg-slate-50 rounded" />
                            <AccordionDetails>
                                <div className="p-4">
                                    Our dashboards are designed to generate a
                                    snapshot of the “most recent” lobbying
                                    activity in the selected sector or
                                    institution. “Most recent” means different
                                    things in different parts of a dashboard: at
                                    the top, each dashboard shows aggregate data
                                    in an 18-month bar-chart next to a table
                                    with over a decade of annual aggregates. For
                                    recent lobbying communications and
                                    registrations, the dashboard shows the most
                                    recent 25. And for the breakdown-clusters,
                                    the data focuses on the most recent month
                                    plus the trailing twelve months (TTM before)
                                </div>
                            </AccordionDetails>
                        </Accordion>
                    </div>
                    <div className="col-start-2 col-end-12 shadow-sm rounded-md mt-3">
                        <Accordion elevation={0}>
                            <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                                <div className="text-slate-600 my-2">
                                    What is LobbyIQ's Wayback Machine?
                                </div>
                            </AccordionSummary>
                            <hr className="h-0.5 my-1 bg-slate-50 rounded" />
                            <AccordionDetails>
                                <div className="p-4">
                                    Our wayback machine is the calendar in the
                                    top-right of our dashboards. This feature
                                    allows users to generate an instant snapshot
                                    of a what our dashboard would have looked
                                    like at any time between 2009 and today.
                                    This feature allows users to generate
                                    instant comparison snapshots across time.
                                    For example comparing just-before to
                                    just-after Covid, or comparing the landscape
                                    at the beginnings of the Harper and Trudeau
                                    governments, or a calendar day's snapshot to
                                    one year ago. (See also: “Q: What is the
                                    Time-Dimension displayed on a Dashboard?”)
                                </div>
                            </AccordionDetails>
                        </Accordion>
                    </div>
                    <div className="col-start-2 col-end-12 shadow-sm rounded-md mt-3">
                        <Accordion elevation={0}>
                            <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                                <div className="text-slate-600 my-2">
                                    What does the In-House Dashboard Show?
                                </div>
                            </AccordionSummary>
                            <hr className="h-0.5 my-1 bg-slate-50 rounded" />
                            <AccordionDetails>
                                <div className="p-4">
                                    The in-House dashboard can be used as an
                                    internal monitoring tool to compare the
                                    communications activity generated by each
                                    separate registration. It can also serve as
                                    a compliance tool because it allows our
                                    clients to verify that the official
                                    government lobbying records match up with
                                    clients' internal records.
                                </div>
                            </AccordionDetails>
                        </Accordion>
                    </div>
                </div>
                <div className="col-start-2 col-end-12 flex flex-col mt-8">
                    <h2 className="text-lg font-medium mb-8">
                        How our data is cleaned and processed
                    </h2>
                    <div className="col-start-2 col-end-12 shadow-sm rounded-md">
                        <Accordion elevation={0}>
                            <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                                <div className="text-slate-600 my-2">
                                    Can I get access to LobbyIQ without a credit
                                    card?
                                </div>
                            </AccordionSummary>
                            <hr className="h-0.5 my-1 bg-slate-50 rounded" />
                            <AccordionDetails>
                                <div className="p-4">
                                    Yes! You can subscribe using direct bank
                                    transfer. Please email{' '}
                                    <span>
                                        <a href="mailto:contact@lobbyiq.org">
                                            contact@lobbyiq.org
                                        </a>
                                    </span>{' '}
                                    to begin discussions on your new
                                    subscription.
                                </div>
                            </AccordionDetails>
                        </Accordion>
                    </div>
                    <div className="col-start-2 col-end-12 shadow-sm rounded-md mt-3">
                        <Accordion elevation={0}>
                            <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                                <div className="text-slate-600 my-2">
                                    What if I want to cancel my subscription
                                    before the one year mark?
                                </div>
                            </AccordionSummary>
                            <hr className="h-0.5 my-1 bg-slate-50 rounded" />
                            <AccordionDetails>
                                <div className="p-4">
                                    All LobbyIQ subscriptions are for one year,
                                    we are unable to refund you should you
                                    decide not to continue using our product.
                                </div>
                            </AccordionDetails>
                        </Accordion>
                    </div>
                    <div className="col-start-2 col-end-12 shadow-sm rounded-md mt-3">
                        <Accordion elevation={0}>
                            <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                                <div className="text-slate-600 my-2">
                                    As an Enterprise user, can I add additional
                                    users to my subscription?
                                </div>
                            </AccordionSummary>
                            <hr className="h-0.5 my-1 bg-slate-50 rounded" />
                            <AccordionDetails>
                                <div className="p-4">
                                    You can add additional users under your
                                    subscription by visiting settings {'->'}{' '}
                                    admin each for a fee of $500
                                </div>
                            </AccordionDetails>
                        </Accordion>
                    </div>
                    <div className="col-start-2 col-end-12 shadow-sm rounded-md mt-3">
                        <Accordion elevation={0}>
                            <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                                <div className="text-slate-600 my-2">
                                    As an Enterprise user, can I be refunded for
                                    additional users if they are no longer
                                    needed?
                                </div>
                            </AccordionSummary>
                            <hr className="h-0.5 my-1 bg-slate-50 rounded" />
                            <AccordionDetails>
                                <div className="p-4">
                                    Yes, if you no longer need the number of
                                    accounts you paid for initially, you can
                                    cancel some user accounts and be refunded
                                    the difference from the point you cancel
                                    until the next billing cycle.
                                </div>
                            </AccordionDetails>
                        </Accordion>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default FAQ;
