import React from 'react';
import Arrow from '@mui/icons-material/ArrowUpwardRounded';
import { replace_underscores_capitalize } from '../../helpers/string_formatters';
import { ENTITY_CATALOGUE_NAME_DATA_KEY_MAP } from './EntityCatalogueConstants';
import { months } from '../../constants/date_time';
import moment from 'moment';
import { EntityType } from '../config/Entities';

const inactive_org_headers = ['12M', '36M'];

const mp_headers = ['constituency', 'province / territory', 'affiliation', '12M'];
const senator_headers = ['province / territory', 'affiliation', '12M'];

const curr_date = moment();

curr_date.subtract(1, 'months');
const last_month = months[curr_date.month()].slice(0, 3);

curr_date.subtract(1, 'months');
const two_months_ago = months[curr_date.month()].slice(0, 3);

curr_date.subtract(1, 'months');
const three_months_ago = months[curr_date.month()].slice(0, 3);

interface CatalogueHeaderProps {
    catalogueType: EntityType;
    catalogueLength: number;
    catalogueSortingField: string;
    catalogueSortingDirection: 'asc' | 'desc';
    handleCatalogueSort: (property: any) => void;
}

const EntityCatalogueHeader = ({
    catalogueLength,
    catalogueType,
    catalogueSortingField,
    catalogueSortingDirection,
    handleCatalogueSort,
}: CatalogueHeaderProps) => {
    const catalogueFields = [
        three_months_ago.slice(0, 3),
        two_months_ago.slice(0, 3),
        '+/-',
        '%',
        last_month.slice(0, 3),
        '12M',
        ...(catalogueType === EntityType.Firm || catalogueType === EntityType.Consultant
            ? ['registrations']
            : []),
    ];

    return (
        <div className="mt-8 col-start-2 col-end-12 grid grid-cols-12 lg:gap-0 gap-3 lg:mb-0 mb-6">
            <div className="col-span-12 mb-2 flex flex-row justify-between items-center px-2">
                <div className="text-slate-500">
                    {catalogueLength} {catalogueType}
                </div>
                <div className="text-slate-500">
                    {catalogueType === EntityType.Issues ||
                    catalogueType === EntityType.Committee ||
                    catalogueType === EntityType.SenateCommittee
                        ? ''
                        : 'Number of communication reports'}
                </div>
            </div>

            <button
                className={
                    'flex flex-row items-center lg:justify-start justify-between bg-white lg:bg-slate-50 rounded-xl lg:shadow-none shadow-sm group hover:text-gray-900 lg:col-span-4 col-span-12 p-2 ' +
                    (catalogueType === EntityType.Firm || catalogueType === EntityType.Consultant
                        ? 'lg:col-span-4'
                        : 'lg:col-span-6')
                }
                onClick={() => handleCatalogueSort(catalogueType)}
            >
                <Arrow
                    className={`
                            ${catalogueSortingField === 'title' ? 'opacity-100' : 'opacity-0'}
                            ${catalogueSortingDirection === 'asc' ? 'rotate-0' : 'rotate-180'}
                            transition ease-in-out delay-150 group-hover:opacity-100
                        `}
                    style={{
                        fontSize: '20px',
                        transition: 'all 0.15s ease',
                    }}
                />
                {catalogueType === EntityType.InstitutionMP
                    ? 'MPs'
                    : replace_underscores_capitalize(catalogueType)}
            </button>

            <div
                className={
                    'col-span-12 grid gap-2 ' +
                    (catalogueType === EntityType.Firm || catalogueType === EntityType.Consultant
                        ? 'lg:col-span-8 grid-cols-14'
                        : 'lg:col-span-6 grid-cols-12')
                }
            >
                {catalogueType === EntityType.InactiveOrganization ? (
                    <div className="col-span-8"></div>
                ) : (
                    <></>
                )}
                {(catalogueType === EntityType.InactiveOrganization
                    ? inactive_org_headers
                    : catalogueType === EntityType.InstitutionMP
                    ? mp_headers
                    : catalogueType === EntityType.SenateMember
                    ? senator_headers
                    : catalogueType === EntityType.Issues ||
                      catalogueType === EntityType.Committee ||
                      catalogueType === EntityType.SenateCommittee
                    ? []
                    : catalogueFields
                ).map(
                    (header: string, idx: number) =>
                        !(catalogueType === EntityType.Issues && ['%', '+/-'].includes(header)) && (
                            <button
                                key={idx}
                                className={`flex flex-row items-center lg:justify-end
                            justify-between bg-white lg:bg-slate-50 rounded-xl 
                            lg:shadow-none shadow-sm group hover:text-gray-900 
                            ${
                                catalogueType === EntityType.Issues
                                    ? 'lg:col-span-3 col-span-4'
                                    : catalogueType === EntityType.Firm ||
                                      catalogueType === EntityType.Consultant
                                    ? 'lg:col-span-2 col-span-7'
                                    : catalogueType === EntityType.InactiveOrganization
                                    ? 'lg:col-span-6 col-span-12 lg:grid-cols-4'
                                    : catalogueType === EntityType.InstitutionMP
                                    ? 'lg:col-span-3 col-span-6'
                                    : catalogueType === EntityType.SenateMember
                                    ? 'lg:col-span-4 col-span-6'
                                    : 'lg:col-span-2 col-span-4'
                            } p-2`}
                                onClick={() => handleCatalogueSort(header)}
                            >
                                <Arrow
                                    className={`
                                ${
                                    catalogueSortingField ===
                                    ENTITY_CATALOGUE_NAME_DATA_KEY_MAP.get(header)
                                        ? 'opacity-100'
                                        : 'opacity-0'
                                }
                                ${catalogueSortingDirection === 'asc' ? 'rotate-0' : 'rotate-180'}
                                transition ease-in-out delay-150 group-hover:opacity-100
                            `}
                                    style={{
                                        fontSize: '20px',
                                        transition: 'all 0.15s ease',
                                    }}
                                />
                                {replace_underscores_capitalize(header)}
                            </button>
                        )
                )}
            </div>
        </div>
    );
};

export default EntityCatalogueHeader;
