import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { RootState } from '../store';
import {
    entityOrg,
    entityOverviews,
    entityRegistration,
} from '../../interfaces/generic-entity.interface';
import { lobbyFirmAPI } from '../../API/lobbyFirmAPI';
import axios from 'axios';
import saveAs from 'file-saver';

interface ConsultantState {
    overview: entityOverviews | null;
    overview_fail: boolean;
    overview_loading: boolean;

    registrations: entityRegistration[] | null;
    registrations_fail: boolean;
    registrations_loading: boolean;

    deregistrations: entityRegistration[] | null;
    deregistrations_fail: boolean;
    deregistrations_loading: boolean;

    top_partners: entityOrg[] | null;
    top_partners_loading: boolean;
    top_partners_fail: boolean;

    top_sectors: entityOrg[] | null;
    top_sectors_loading: boolean;
    top_sectors_fail: boolean;

    top_institutions: entityOrg[] | null;
    top_institutions_loading: boolean;
    top_institutions_fail: boolean;

    export_loading: boolean;
    export_fail: boolean;
}

const initialState: ConsultantState = {
    overview: null,
    overview_fail: false,
    overview_loading: false,

    registrations: null,
    registrations_fail: false,
    registrations_loading: false,

    deregistrations: null,
    deregistrations_fail: false,
    deregistrations_loading: false,

    top_partners: null,
    top_partners_loading: false,
    top_partners_fail: false,

    top_sectors: null,
    top_sectors_loading: false,
    top_sectors_fail: false,

    top_institutions: null,
    top_institutions_loading: false,
    top_institutions_fail: false,

    export_loading: false,
    export_fail: false,
};

// Fetch the recent lobbying activity data for the current organization
export const fetchConsultantOverview = createAsyncThunk(
    'fetchConsultantOverview',
    async ({
        firm,
        consultant,
        date,
    }: {
        firm: string;
        consultant: string;
        date: string;
    }): Promise<entityOverviews | null> =>
        lobbyFirmAPI.fetchLobbyOverview('consultant', firm, date, consultant)
);

// Fetch the recent registrations for the current house
export const fetchConsultantLatestRegistrations = createAsyncThunk(
    'fetchConsultantLatestRegistrations',
    async ({
        firm,
        consultant,
        date,
    }: {
        firm: string;
        consultant: string;
        date: string;
    }): Promise<entityRegistration[] | null> =>
        lobbyFirmAPI.fetchLobbyLatestRegistrations(
            'consultant',
            firm,
            date,
            consultant
        )
);

// Fetch the recent registrations for the current house
export const fetchConsultantLatestDeregistrations = createAsyncThunk(
    'fetchConsultantLatestDeregistrations',
    async ({
        firm,
        consultant,
        date,
    }: {
        firm: string;
        consultant: string;
        date: string;
    }): Promise<entityRegistration[] | null> =>
        lobbyFirmAPI.fetchLobbyLatestDeregistrations(
            'consultant',
            firm,
            date,
            consultant
        )
);

// Fetch the top partners lobbied by the current organization
export const fetchConsultantTopPartners = createAsyncThunk(
    'fetchConsultantTopPartners',
    async ({
        firm,
        consultant,
        date,
    }: {
        firm: string;
        consultant: string;
        date: string;
    }): Promise<entityOrg[] | null> =>
        lobbyFirmAPI.fetchLobbyTopPartners('consultant', firm, date, consultant)
);

// Fetch the top sectors lobbied by the current organization
export const fetchConsultantTopSectors = createAsyncThunk(
    'fetchConsultantTopSectors',
    async ({
        firm,
        consultant,
        date,
    }: {
        firm: string;
        consultant: string;
        date: string;
    }): Promise<entityOrg[] | null> =>
        lobbyFirmAPI.fetchLobbyTopSectors('consultant', firm, date, consultant)
);

// Fetch the top institutions lobbied by the current organization
export const fetchConsultantTopInstitutions = createAsyncThunk(
    'fetchConsultantTopInstitutions',
    async ({
        firm,
        consultant,
        date,
    }: {
        firm: string;
        consultant: string;
        date: string;
    }): Promise<entityOrg[] | null> =>
        lobbyFirmAPI.fetchLobbyTopInstitutions(
            'consultant',
            firm,
            date,
            consultant
        )
);

export const exportConsultantDashboard = createAsyncThunk(
    'consultant/exportDashboard',
    async (data: any): Promise<void> => {
        const response = await axios.post(
            `${process.env.REACT_APP_PDF_SERVICE_URL}/consultant/report`,
            data,
            {
                responseType: 'blob',
            }
        );

        const pdfEncoding = response.data;

        const blob = new Blob([pdfEncoding], { type: 'application/pdf' });

        saveAs(blob, 'LobbyIQ-Consultant');
    }
);

const ConsultantSlice = createSlice({
    name: 'consultantReducer',
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(fetchConsultantOverview.pending, (state) => {
                state.overview_loading = true;
            })
            .addCase(fetchConsultantOverview.fulfilled, (state, action) => {
                state.overview_loading = false;
                state.overview = action.payload;
            })
            .addCase(fetchConsultantOverview.rejected, (state) => {
                state.overview_loading = false;
                state.overview_fail = true;
                state.overview = null;
            })
            .addCase(fetchConsultantLatestRegistrations.pending, (state) => {
                state.registrations_loading = true;
            })
            .addCase(
                fetchConsultantLatestRegistrations.fulfilled,
                (state, action) => {
                    state.registrations_loading = false;
                    state.registrations = action.payload;
                }
            )
            .addCase(fetchConsultantLatestRegistrations.rejected, (state) => {
                state.registrations_loading = false;
                state.registrations_fail = true;
                state.registrations = null;
            })
            .addCase(fetchConsultantLatestDeregistrations.pending, (state) => {
                state.deregistrations_loading = true;
            })
            .addCase(
                fetchConsultantLatestDeregistrations.fulfilled,
                (state, action) => {
                    state.deregistrations_loading = false;
                    state.deregistrations = action.payload;
                }
            )
            .addCase(fetchConsultantLatestDeregistrations.rejected, (state) => {
                state.deregistrations_loading = false;
                state.deregistrations_fail = true;
                state.deregistrations = null;
            })
            .addCase(fetchConsultantTopPartners.pending, (state) => {
                state.top_partners_loading = true;
            })
            .addCase(fetchConsultantTopPartners.fulfilled, (state, action) => {
                state.top_partners = action.payload;
                state.top_partners_loading = false;
            })
            .addCase(fetchConsultantTopPartners.rejected, (state) => {
                state.top_partners = null;
                state.top_partners_fail = true;
                state.top_partners_loading = false;
            })
            .addCase(fetchConsultantTopSectors.pending, (state) => {
                state.top_sectors_loading = true;
            })
            .addCase(fetchConsultantTopSectors.fulfilled, (state, action) => {
                state.top_sectors = action.payload;
                state.top_sectors_loading = false;
            })
            .addCase(fetchConsultantTopSectors.rejected, (state) => {
                state.top_sectors = null;
                state.top_sectors_fail = true;
                state.top_sectors_loading = false;
            })
            .addCase(fetchConsultantTopInstitutions.pending, (state) => {
                state.top_institutions_loading = true;
            })
            .addCase(
                fetchConsultantTopInstitutions.fulfilled,
                (state, action) => {
                    state.top_institutions = action.payload;
                    state.top_institutions_loading = false;
                }
            )
            .addCase(fetchConsultantTopInstitutions.rejected, (state) => {
                state.top_institutions = null;
                state.top_institutions_fail = true;
                state.top_institutions_loading = false;
            })
            .addCase(exportConsultantDashboard.pending, (state) => {
                state.export_loading = true;
            })
            .addCase(exportConsultantDashboard.fulfilled, (state, action) => {
                state.export_loading = false;
                state.export_fail = false;
            })
            .addCase(exportConsultantDashboard.rejected, (state) => {
                state.export_loading = false;
                state.export_fail = true;
            });
    },
});

export const selectConsultantOverview = (state: RootState) =>
    state.consultantData.overview;
export const selectConsultantOverviewLoading = (state: RootState) =>
    state.consultantData.overview_loading;

export const selectRegistrations = (state: RootState) =>
    state.consultantData.registrations;
export const selectRegistrationsLoading = (state: RootState) =>
    state.consultantData.registrations_loading;

export const selectDeRegistrations = (state: RootState) =>
    state.consultantData.deregistrations;
export const selectDeRegistrationsLoading = (state: RootState) =>
    state.consultantData.deregistrations_loading;

export const selectConsultantTopPartners = (state: RootState) =>
    state.consultantData.top_partners;
export const selectConsultantTopPartnersLoading = (state: RootState) =>
    state.consultantData.top_partners_loading;

export const selectConsultantTopSectors = (state: RootState) =>
    state.consultantData.top_sectors;
export const selectConsultantTopSectorsLoading = (state: RootState) =>
    state.consultantData.top_sectors_loading;

export const selectConsultantTopInstitutions = (state: RootState) =>
    state.consultantData.top_institutions;
export const selectConsultantTopInstitutionsLoading = (state: RootState) =>
    state.consultantData.top_institutions_loading;

export const selectConsultantExportLoading = (state: RootState) =>
    state.consultantData.export_loading;
export const selectConsultantExportFail = (state: RootState) =>
    state.consultantData.export_fail;

export default ConsultantSlice.reducer;
