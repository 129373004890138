import React from 'react';

const LoadingTable = ({ embedded = false }: { embedded?: boolean }) => {
    return (
        <div
            className={`h-64 ${
                !embedded && 'bg-white shadow-md rounded-md'
            } p-4 flex flex-col justify-between animate-pulse`}
        >
            <div className="bg-slate-100 w-1/3 h-4 rounded-md">&nbsp;</div>
            <div className="grid grid-cols-12">
                <div className="col-span-8 grid grid-cols-12 items-center">
                    <div className="bg-slate-100 rounded-md col-span-3 h-6">
                        &nbsp;
                    </div>
                </div>
            </div>
            <div className="grid grid-cols-12 justify-between mt-2">
                <div className="col-span-4 bg-slate-100 rounded-md h-3">
                    &nbsp;
                </div>
                <div className="col-span-1 col-end-13 bg-slate-100 rounded-md h-3">
                    &nbsp;
                </div>
            </div>
            <div className="grid grid-cols-12 justify-between">
                <div className="col-span-6 bg-slate-100 rounded-md h-3">
                    &nbsp;
                </div>
                <div className="col-span-2 col-end-13 bg-slate-100 rounded-md h-3">
                    &nbsp;
                </div>
            </div>
            <div className="grid grid-cols-12 justify-between">
                <div className="col-span-2 bg-slate-100 rounded-md h-3">
                    &nbsp;
                </div>
                <div className="col-span-3 col-end-13 bg-slate-100 rounded-md h-3">
                    &nbsp;
                </div>
            </div>
            <div className="grid grid-cols-12 justify-between">
                <div className="col-span-3 bg-slate-100 rounded-md h-3">
                    &nbsp;
                </div>
                <div className="col-span-4 col-end-13 bg-slate-100 rounded-md h-3">
                    &nbsp;
                </div>
            </div>
            <div className="grid grid-cols-12 justify-between">
                <div className="col-span-2 bg-slate-100 rounded-md h-3">
                    &nbsp;
                </div>
                <div className="col-span-2 col-end-13 bg-slate-100 rounded-md h-3">
                    &nbsp;
                </div>
            </div>
            <div className="grid grid-cols-12 justify-between">
                <div className="col-span-1 bg-slate-100 rounded-md h-3">
                    &nbsp;
                </div>
                <div className="col-span-3 col-end-13 bg-slate-100 rounded-md h-3">
                    &nbsp;
                </div>
            </div>
        </div>
    );
};

export default LoadingTable;
