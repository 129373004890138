import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { entityListExtra } from '../../interfaces/generic-entity.interface';
import { useAppDispatch } from '../../store/store';
import EntityListPage from './EntityListPage';
import {
    fetchFirmList,
    selectFirmList,
} from '../../store/reducers/lobbyFirmListSlice';

const LobbyFirmListDataLayer = () => {
    const dispatch = useAppDispatch();

    const entity_list: entityListExtra[] | null = useSelector(selectFirmList);

    useEffect(() => {
        dispatch(fetchFirmList());
    }, [dispatch]);

    return <EntityListPage type="firms" list={entity_list || []} />;
};

export default LobbyFirmListDataLayer;
