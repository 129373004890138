import ReactGA from "react-ga4";

const InitializeGoogleAnalytics = () => {
  // Initialize 
  ReactGA.initialize("G-9M1JXGXD84");
};

export const EVENT_TYPES = {
  CLICK : "click",
  PAGE_VIEW: "pageview",
  PAGE_LOAD: "pageload",
};

const GATrackEvent = (
  event_name: string,
  event_type: string,
  path: string,
  user_email?: string,
  user_product_type?: string,
) => {
  const event_params = {
    event_type,
    path,
    user_email,
    user_product_type
  }
  ReactGA.event(event_name,event_params);
};

const GATrackDashboardLoad = (
  dashboard: string,
  topic: string,
  event_type: string,
  user_email?: string,
  user_product_type?: string,
) => {
  const event_params = {
    dashboard,
    topic,
    event_type,
    user_email,
    user_product_type
  }
  ReactGA.event(`Dashboard Load`,event_params);
};

const GATrackDashboardEvent = (
  event_name: string, 
  event_type: string,
  dashboard: string, 
  title : string, 
  topic ?: string,     
  user_email?: string,
  user_product_type?: string,
) => {
  const event_params = {
    event_type,
    user_email,
    user_product_type,
    title,
    topic,
    dashboard,
  }
  ReactGA.event(event_name,event_params);
};


const GATrackPage = (
  path: string,
  user_email: string,
  user_product_type: string
)=>{
  ReactGA.event("page view:"+path, {event_type:EVENT_TYPES.PAGE_VIEW,user_email, user_product_type});
}

export default InitializeGoogleAnalytics;
export { InitializeGoogleAnalytics, GATrackEvent, GATrackPage, GATrackDashboardEvent, GATrackDashboardLoad };