export const COLORS = [
    { primary: '#67A8CC', secondary: '#97C8EC' },
    { primary: '#4B8060', secondary: '#BAD583' },
    { primary: '#8C7BBD', secondary: '#BCABED' },
    { primary: '#d34627', secondary: '#e28383' },
    { primary: '#C1A1CA', secondary: '#F1D1FA' },
    { primary: '#9AB677', secondary: '#CAE6A7' },
    { primary: '#CCCCCC', secondary: '#CCCCCC' },
];

export const COLORS_BUMP = [
    '#FE5000',
    '#CE0058',
    '#E10098',
    '#440099',
    '#10069F',
    '#0085CA',
    '#00AB84',
    '#BF9BDE',
    '#009ACE',
    '#FFAA4D',
    '#00B08B',
    '#890C58',
    '#EB6FBD',
    '#407EC9',
    '#6BBBAE',
    '#97D700',
];
