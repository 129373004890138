export const IssueDebates = (
    <div className="flex flex-col gap-2">
        <div>
            This cluster breaks down which committees' meetings included discussions of topics that
            are mapped to this dashboard’s issue.For simplicity, we include debates on the
            parliamentary floor as one committee under the header “Hansard/Debates”.
        </div>
    </div>
);

export const IssueOverviewTooltip = (
    <div className="flex flex-col gap-2">
        <div>
            The histogram shows a monthly breakdown of all instances of an issue getting discussed
            in any committee or on the parliamentary floor
        </div>
    </div>
);

export const IssueTopicsDiscussed = (
    <div className="flex flex-col gap-2">
        <div>
            This calendar shows all days on which any “topic” was discussed that is mapped to the
            dashboard’s “issue.” When clicking on any calendar-day, the right panel displays the
            combinations in which a topic was tagged to a piece of speech, and that variation’s
            count on the day.
        </div>
    </div>
);

export const IssueActivity = (
    <div className="flex flex-col gap-2">
        <div>
            A list of the most recent communications in an issue, within the last 3 months, with a
            link to the underlying primary record on LobbyCanada.gc.ca
        </div>
        <div>
            Each row corresponds to one DPOH at the meeting, so that meetings with multiple DPOHs
            span multiple rows.
        </div>
        <div>
            The column “Prior Comms (36M)” counts the number of meetings between the client and DPOH
            over the last 36 months
        </div>
    </div>
);

export const IssueRegistrations = (
    <div className="flex flex-col gap-2">
        <div>This exhibit lists all active registrations related to this issue.</div>
        <div>
            Province denotes the province of a client firm’s self-reported address (which can
            verified in the reported link to the primary record).
        </div>
    </div>
);

export const IssueDeregistrations = (
    <div className="flex flex-col gap-2">
        <div>
            This exhibit lists the registrations that were de-registered and not renewed in an issue
            in the last 3 months.
        </div>
        <div>
            Province denotes the province of a client firm’s self-reported address (which can
            verified in the reported link to the primary record).
        </div>
    </div>
);

export const IssueSectors = (
    <div className="flex flex-col gap-2">
        <div>
            This cluster has the same six elements as the Organizations cluster, but aggregates the
            last 12 months of communications up by sectors.
        </div>
    </div>
);

export const IssueOrganizations = (
    <div className="flex flex-col gap-2">
        <div>
            A pie-chart breaking down the last 12 months of communications by organizations,
            twelve-month histograms for the four most lobbying-active organizations, and a Movers
            table. The Movers table includes the two most recent months. The earlier of these two
            months has approximately complete data, whereas the later is tentative and data is still
            incoming. For example, when viewing the data in October, the August column should be
            considered approximately complete, but September is not yet. The +/- column considers
            the difference between the earlier of the two most recent months and the average of the
            twelve months that preceded it. For example: in October, the +/- compares August data
            with an average of data from July back to June of the previous year.
        </div>
    </div>
);

export const IssueMentions = (
    <div className="flex flex-col gap-2">
        <div>
            This cluster has the same six elements as the Organizations cluster, but aggregates the
            last 12 months of mp mentions.
        </div>
    </div>
);
