import React, { useEffect, useState } from 'react';
import { Breadcrumbs } from '@mui/material';
import { Link } from 'react-router-dom';
import QueryForm from '../components/custom-query/QueryForm';
import { useAppDispatch } from '../store/store';
import { entityListSimple } from '../interfaces/generic-entity.interface';
import { useSelector } from 'react-redux';
import {
    clearCommResults,
    clearRegResults,
    fetchQueryFirmList,
    fetchQueryInstitutionList,
    fetchQueryOrgList,
    fetchQuerySectorList,
    fetchQuerySubjectList,
    selectQueryFirmList,
    selectQueryInstitutionList,
    selectQueryOrgList,
    selectQuerySectorList,
    selectQuerySubjectList,
} from '../store/reducers/querySlice';
import QueryResults from '../components/custom-query/QueryResults';
import {
    fetchSectorsSelections,
    selectChosenSectors,
} from '../store/reducers/customSelectionSlice';
import { ProductType, selectUserPermissions } from '../store/reducers/userSlice';

const QueryPage = () => {
    const dispatch = useAppDispatch();

    const [type, setType] = useState<'communications' | 'registrations'>('communications');
    const handleTypeChange = (newType: 'communications' | 'registrations') => {
        setType(newType);
        if (newType === 'communications') {
            dispatch(clearCommResults());
        } else if (newType === 'registrations') {
            dispatch(clearRegResults());
        }
    };

    const sector_list: entityListSimple[] | null = useSelector(selectQuerySectorList);
    const sectorSelections = useSelector(selectChosenSectors);
    const permissions = useSelector(selectUserPermissions);

    const institution_list: entityListSimple[] | null = useSelector(selectQueryInstitutionList);

    const firm_list: entityListSimple[] | null = useSelector(selectQueryFirmList);

    const subject_list: entityListSimple[] | null = useSelector(selectQuerySubjectList);

    const organization_list: entityListSimple[] | null = useSelector(selectQueryOrgList);

    useEffect(() => {
        dispatch(fetchQuerySectorList());
        dispatch(fetchQueryInstitutionList());
        dispatch(fetchSectorsSelections());
        dispatch(fetchQueryFirmList());
        dispatch(fetchQueryOrgList(null));
        dispatch(fetchQuerySubjectList());
    }, [dispatch]);

    const getFilteredSectorList = () => {
        if (!sector_list) {
            return [];
        }

        if (permissions?.productType === ProductType.ENTERPRISE) {
            return sector_list;
        } else {
            if (sectorSelections) {
                return sector_list!.filter((sector) =>
                    sectorSelections.find((selection) => selection.title === sector.title)
                );
            }
            return [];
        }
    };

    return (
        <div className="lg:py-32 py-20 lg:px-20 px-4 grid grid-cols-12">
            <div className="col-start-1 col-end-13 flex flex-row justify-between">
                <div>
                    <Breadcrumbs className="col-start-2 lg:col-end-12 col-end-12" separator=">">
                        <Link to="/" className="hover:underline">
                            Lobby<b>IQ</b>
                        </Link>
                        <div>Custom Query</div>
                    </Breadcrumbs>
                </div>
            </div>

            <div className="mt-8 col-start-1 col-end-13">
                <QueryForm
                    type={type}
                    handleTypeChange={handleTypeChange}
                    sectorList={getFilteredSectorList()}
                    institutionList={institution_list || []}
                    firmList={firm_list || []}
                    organizationList={organization_list || []}
                    subjectList={subject_list || []}
                />
            </div>

            <div className="mt-8 col-start-1 col-end-13">
                <QueryResults type={type} />
            </div>
        </div>
    );
};

export default QueryPage;
