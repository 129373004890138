export const SectorOverview = (
    <div className="flex flex-col gap-2">
        <div>
            On the left, the histogram shows the count of communication filings
            from all organizations in a sector.
        </div>
        <div>
            - “Lobbying Reports“ is the number of communications filed in one
            year
        </div>
        <div>
            - “by Consultants“ is the number of communications in a year filed
            by an external lobbyist
        </div>
        <div>
            - “of Ministers or DMs“ is the number of communications in a year
            that involved a DPOH that was a minister or deputy minister
        </div>
        <div>
            - “Institutions Lobbied“ is the number of unique government
            institutions that were lobbied by all organizations in a sector in a
            year
        </div>
        <div>
            - “Organizations“ is the number of unique organizations that lobbied
            in a sector in a year
        </div>
        <div>
            - “New Registrations“ is the number of new (i.e. not just renewed)
            registrations in a sector in a year
        </div>
    </div>
);
