import axios from 'axios';
import {
    entityOverviews,
    entityOverview,
    entityOrg,
    entityCluster,
    entityOrgExtra,
    entityOrgTrail,
    entityOverviewRefined,
} from '../interfaces/generic-entity.interface';
import { percentage_change_num } from '../helpers/percentage_change';
import { institutionLinkFormatter, moverLinkFormatter } from '../helpers/internal_routing';

// Cluster relevant API path names (item 1) and corresponding data object names (item 2)
const topCountAPIPath = {
    organizations: { path: 'toporganizations', data: 'globalTopOrganizations' },
    sectors: { path: 'topsectors', data: 'globalTopSectors' },
    institutions: { path: 'topinstitutions', data: 'globalTopInstitutions' },
    lobbyfirms: { path: 'toplobbyfirms', data: 'globalTopLobbyFirms' },
    dpoh: { path: 'topdpoh', data: 'globalTopDPOH' },
    subject: { path: 'topsubject', data: 'globalTopSubject' },
};

const topMoversAPIPath = {
    organizations: { path: 'topmovers', data: 'globalTopMovers' },
    sectors: { path: 'topmoverssectors', data: 'globalTopMoversSectors' },
    institutions: {
        path: 'topmoversinstitutions',
        data: 'globalTopMoversInstitutions',
    },
    lobbyfirms: {
        path: 'topmoverslobbyfirms',
        data: 'globalTopMoversLobbyFirms',
    },
    dpoh: { path: 'topmoversdpoh', data: 'globalTopMoversDPOH' },
    subject: { path: 'topmoverssubject', data: 'globalTopMoversSubject' },
};

const TTMAPIPath = {
    organizations: { path: 'org' },
    sectors: { path: 'sector' },
    institutions: { path: 'inst' },
    lobbyfirms: { path: 'firm' },
    dpoh: { path: 'dpoh' },
    subject: { path: 'subject' },
};

// Base URL for accessing the sector API
const baseURL = process.env.REACT_APP_API_BASE_URL;

const invertYearlyOverview = (overview: entityOverviewRefined[]) => {
    const params: (keyof entityOverviewRefined)[] = [
        'Lobbying Reports',
        'by Consultants',
        'of Ministers or DMs',
        'Institutions Lobbied',
        'Organizations',
        'New Registrations',
        'Sectors',
    ];

    const output: any = [];

    params.forEach((param: keyof entityOverviewRefined, idx: number) => {
        const param_data: any = { order: idx, count: param };
        overview.forEach((entry: entityOverviewRefined) => {
            param_data[String(entry.year)] = entry[param];
        });
        output.push(param_data);
    });

    return output;
};

// Fetch an overview of a given sector
const fetchOverview = async (date: string) => {
    const year = date?.split('-')[0];
    const month = date?.split('-')[1];

    const yearly_response = await axios.get(`${baseURL}/global/overview?monthly=false`);
    const yearly_data: entityOverviewRefined[] = yearly_response.data.overview
        .filter((entry: any) => +entry.year <= +year)
        .map((entry: entityOverview) => {
            return {
                ...entry,
                'Lobbying Reports': entry.count,
                'by Consultants': entry.external,
                'of Ministers or DMs': entry.ministers,
                Organizations: entry.organizations,
                Sectors: entry.sectors,
                'Institutions Lobbied': entry.institutions,
                'New Registrations': entry.registrations,
            };
        });

    const inverted_yearly = invertYearlyOverview(yearly_data);

    const monthly_response = await axios.get(`${baseURL}/global/overview?monthly=true`);

    const monthly_data: entityOverview[] = monthly_response.data.overview
        .filter((entry: any) => {
            return (
                +[entry.year, (entry.month + '').padStart(2, '0')].join('') <=
                +[year, month].join('')
            );
        })
        .slice(0, 18);

    const overview: entityOverviews = {
        yearly: inverted_yearly,
        monthly: monthly_data,
    };

    return overview;
};

// Fetch a sector data cluster of the given sector code, under a specific "type"
const fetchCluster = async (
    type: 'organizations' | 'sectors' | 'institutions' | 'lobbyfirms' | 'dpoh' | 'subject',
    date: string | undefined
): Promise<entityCluster> => {
    // Fetch top 6 *type* within the given sector (determined by YTD communication count)
    const top_count_response = await axios.get(
        `${baseURL}/global/${topCountAPIPath[type].path}?date=${date}&limit=6`
    );

    const top_count_data: entityOrg[] =
        // name of data object varies by endpoint, we don't need to know its precise name this way
        top_count_response.data[topCountAPIPath[type].data];

    // Count the total number of comms for orgs outside of the top 6 ytd
    var other_count = 0;
    if (top_count_data.length > 6)
        other_count = top_count_data
            .slice(6)
            .map((item) => item.count)
            .reduce((sum, next) => sum + next);

    const top_six_ytd = top_count_data.slice(0, 6);
    top_six_ytd.push({ name: 'Other', code: 'Other', count: other_count });

    // Get the top 1-4 orgs from the top YTD orgs
    // These will be used for mini bar charts with trailing 12 months of lobbying displayed
    const top_four: entityOrgTrail[] = top_count_data.slice(0, 4).map((entry) => {
        return {
            ...entry,
            ttm: [],
        };
    });

    // Add a trailing twelve months array to the top 1-4 orgs
    await Promise.all(
        top_four.map(async (entry): Promise<void> => {
            const response = await axios.get(
                `${baseURL}/open/${TTMAPIPath[type].path}/${encodeURIComponent(
                    entry.code
                )}/ttmcount/global/a/?date=${date}`
            );
            // Modify the object with the API response
            entry.ttm = response.data.data;
        })
    );

    // Fetch top 10 mover *type* within the given sector
    const top_movers_response = await axios.get(
        `${baseURL}/global/${topMoversAPIPath[type].path}?limit=25&date=${date}`
    );
    const top_movers_data: entityOrg[] = top_movers_response.data[topMoversAPIPath[type].data];

    // Take top movers and add a percentage change + empty trialing twelve month array
    // Sort in descending order
    const converted_movers_data: entityOrgExtra[] = top_movers_data.map((entry) => {
        return {
            ...entry,
            name: moverLinkFormatter(entry, type),
            ...(type === 'dpoh' &&
                entry.institution_id &&
                entry.institution && {
                    institution: institutionLinkFormatter(+entry.institution_id, entry.institution),
                }),
            // Calculate with potential nulls replaced by 0
            change_rel: percentage_change_num(entry.tma || 0, entry.oma || 0),
            change_abs: (entry.oma || 0) - (entry.tma || 0),
            // long decimals returned as string by DB, cast to number
            avg: +(entry.avg || 0),
            deviation: +(entry.deviation || 0),
        };
    });

    const cluster: entityCluster = {
        top_movers: converted_movers_data,
        top_orgs: top_six_ytd,
        top_four: top_four,
        isInstitutionType: false,
    };

    return cluster;
};

export const globalAPI = {
    fetchOverview,
    fetchCluster,
};
