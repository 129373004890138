import React from 'react';
import logo from '../../assets/vectors/LIQ_SLATE.svg';
import { Link } from 'react-router-dom';

const Footer = () => {
    return (
        <div className="bg-white mt-12 px-12 py-12 grid grid-cols-10 gap-8">
            <div className="lg:col-span-2 col-span-10 flex flex-col gap-8">
                <div className="text-lg flex flex-row items-center">
                    <img className="lg:w-8 w-4 mr-2" src={logo} alt="LIQ logo" />
                    Lobby<span className="font-black">IQ</span>
                </div>
                <div className="text-slate-500 font-light">Technology for Government Relations</div>
            </div>
            <div className="lg:col-span-1 col-span-5 text-sm">
                <div className="font-medium text-slate-700 mb-3">Pages</div>
                <div className="flex flex-col gap-2 text-slate-500">
                    <Link className="hover:underline" to="/sectors">
                        Sectors
                    </Link>
                    <Link className="hover:underline" to="/institutions">
                        Institutions
                    </Link>
                    <Link className="hover:underline" to="/committees">
                        Parliamentary Committees
                    </Link>
                    <Link className="hover:underline" to="/subjects">
                        Subjects
                    </Link>
                    <Link className="hover:underline" to="/issues">
                        Issues
                    </Link>
                    <Link className="hover:underline" to="/firms">
                        Lobby Firms
                    </Link>
                </div>
            </div>
            <div className="lg:col-span-1 col-span-5 text-sm">
                <div className="font-medium text-slate-700 mb-3">Tools</div>
                <div className="flex flex-col gap-2 text-slate-500">
                    <Link className="hover:underline" to="/in-house">
                        In-House Dashboard
                    </Link>
                    <Link className="hover:underline" to="/custom-query">
                        Custom Query
                    </Link>
                </div>
            </div>
            <div className="lg:col-span-1 col-span-5 text-sm">
                <div className="font-medium text-slate-700 mb-3">Company</div>
                <div className="flex flex-col gap-2 text-slate-500">
                    <Link className="hover:underline" to="/about">
                        About Us
                    </Link>
                    <Link className="hover:underline" to="/features">
                        Features
                    </Link>
                    <Link className="hover:underline" to="/pricing">
                        Subscriptions
                    </Link>
                    <Link className="hover:underline" to="/solutions">
                        Solutions
                    </Link>
                </div>
            </div>
            <div className="lg:col-span-1 col-span-5 text-sm">
                <div className="font-medium text-slate-700 mb-3">Resources</div>
                <div className="flex flex-col gap-2 text-slate-500">
                    <Link className="hover:underline" to="/contact">
                        Contact
                    </Link>
                    <Link className="hover:underline" to="/faq">
                        FAQ
                    </Link>
                </div>
            </div>
            <hr className="col-span-10 h-0.5 my-3 bg-slate-100 rounded" />
            <div className="col-span-10 text-sm text-center text-slate-400">
                Contains information licensed under the Open Data License Agreement — Office of the
                Commissioner of Lobbying
            </div>
            <div className="col-span-10 text-sm text-center">
                &#169; LobbyIQ 2024. All rights reserved
            </div>
        </div>
    );
};

export default Footer;
