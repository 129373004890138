import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';
import { RootState } from '../../../store/store';
import { entityList, entityListExtra } from '../../../interfaces/generic-entity.interface';
import { percentage_change_num } from '../../../helpers/percentage_change';

interface SubjectListState {
    subject_list: entityListExtra[] | null;
    subject_list_loading: boolean;
}

const initialState: SubjectListState = {
    subject_list: null,
    subject_list_loading: false,
};

const baseURL = process.env.REACT_APP_API_BASE_URL;

export const fetchSubjectList = createAsyncThunk(
    'subject/fetchList',
    async (): Promise<entityListExtra[]> => {
        const response = await axios.get(`${baseURL}/subject/list`);
        const data: entityList[] = response.data.subject;
        const converted_data: entityListExtra[] = data
            .filter((entry) => entry.title !== 'Other')
            .map((entry) => {
                return {
                    ...entry,
                    // Calculate with potential nulls replaced by 0
                    change_abs: (entry.last_month || 0) - (entry.two_months_ago || 0),
                    change_rel: percentage_change_num(
                        entry.two_months_ago || 0,
                        entry.last_month || 0
                    ),
                    available: true,
                };
            });
        return converted_data;
    }
);

const subjectListSlice = createSlice({
    name: 'subjectListReducer',
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(fetchSubjectList.pending, (state) => {
                state.subject_list_loading = true;
            })
            .addCase(fetchSubjectList.fulfilled, (state, action) => {
                state.subject_list_loading = false;
                state.subject_list = action.payload;
            })
            .addCase(fetchSubjectList.rejected, (state) => {
                state.subject_list_loading = false;
                state.subject_list = null;
            });
    },
});

export const selectSubjectList = (state: RootState) => state.subjectList.subject_list;
export const selectSubjectListLoading = (state: RootState) =>
    state.subjectList.subject_list_loading;

export default subjectListSlice.reducer;
