import { useSelector } from 'react-redux';
import { useAppDispatch } from '../../store/store';
import {
    Dialog,
    DialogTitle,
    DialogContent,
    DialogContentText,
    DialogActions,
    Button,
    TextField,
    Autocomplete,
    Fade,
    Divider,
} from '@mui/material';
import React, { useEffect, useState } from 'react';
import LoadSpinner from '../../components/misc/LoadSpinner';
import {
    selectUserLoading,
    selectEmail,
    selectActionLoading,
    selectUserErrored,
    selectCompany,
    selectFirstName,
    selectLastName,
    applyForEmailTrial,
    EmailTrialRequest,
    signUp,
    fetchActiveUser,
} from '../../store/reducers/userSlice';
import LoadPage from '../misc/LoadPage';
import SendIcon from '@mui/icons-material/Send';
import validator from 'validator';
import { entityListExtra } from '../../interfaces/generic-entity.interface';
import {
    CommitteeListItem,
    fetchSenateAndHouseCommitteeList,
    selectCombinedCommitteeList,
} from '../../features/committee-dashboards/data/committeeListSlice';
import {
    clearOrgList,
    fetchMultiOrganizationList,
    fetchSectorList,
    selectOrganizationLists,
    selectSectorList,
} from '../../features/sector-dashboards/store/sectorListSlice';
import HelpIcon from '@mui/icons-material/Help';
import Modal from '../../components/misc/Modal';
import {
    fetchInstitutionList,
    fetchMultiDPOHList,
    selectDPOHLists,
    selectInstitutionList,
} from '../../store/reducers/institutionListSlice';
import logo from '../../assets/vectors/LIQ_badge.png';
import { useNavigate } from 'react-router-dom';

const EmailTrialPage = () => {
    const dispatch = useAppDispatch();
    const navigate = useNavigate();

    const userLoading = useSelector(selectUserLoading);
    const userEmail = useSelector(selectEmail);

    const trialLoading = useSelector(selectActionLoading);
    const trialError = useSelector(selectUserErrored);

    const existingFirstName = useSelector(selectFirstName);
    const existingLastName = useSelector(selectLastName);
    const existingCompany = useSelector(selectCompany);

    const [email, setEmail] = useState(userEmail || '');
    const [firstName, setFirstName] = useState(existingFirstName || '');
    const [lastName, setLastName] = useState(existingLastName || '');
    const [company, setCompany] = useState(existingCompany || '');
    const [jobTitle, setJobTitle] = useState(existingCompany || '');
    const [industry, setIndustry] = useState(existingCompany || '');
    const [additionalEmail1, setAdditionalEmail1] = useState('');
    const [additionalEmail2, setAdditionalEmail2] = useState('');

    const [committeeMeetings, setCommitteeMeetings] = useState<any[]>([null, null, null]);
    const [stakeholderSectors, setStakeholderSectors] = useState<any[]>([null, null, null]);
    const [organizationSelections, setOrganizationSelections] = useState<any[]>([null, null, null]);
    const [institutionSelections, setInstitutionSelections] = useState<any[]>([null, null, null]);
    const [dpohSelections, setDpohSelections] = useState<any[]>([null, null, null]);

    const [emailError, setEmailError] = useState(false);
    const [firstNameError, setFirstNameError] = useState(false);
    const [lastNameError, setLastNameError] = useState(false);
    const [companyError, setCompanyError] = useState(false);
    const [jobTitleError, setJobTitleError] = useState(false);
    const [industryError, setIndustryError] = useState(false);

    const [committeeModalOpen, setCommitteeModalOpen] = useState(false);
    const [sectorModalOpen, setSectorModalOpen] = useState(false);
    const [issueModalOpen, setIssueModalOpen] = useState(false);
    const [keywordModalOpen, setKeywordModalOpen] = useState(false);
    const [grModalOpen, setGRModalOpen] = useState(false);
    const [qpModalOpen, setQPModalOpen] = useState(false);

    const [resultModalOpen, setResultModalOpen] = React.useState(false);

    const committeeList: CommitteeListItem[] | null = useSelector(selectCombinedCommitteeList);
    const sectorList: entityListExtra[] | null = useSelector(selectSectorList);
    const institutionList: entityListExtra[] | null = useSelector(selectInstitutionList);
    const dpohLists: entityListExtra[][] = useSelector(selectDPOHLists);
    const orgLists: entityListExtra[][] = useSelector(selectOrganizationLists);

    useEffect(() => {
        if (!committeeList) dispatch(fetchSenateAndHouseCommitteeList());
        if (!sectorList) dispatch(fetchSectorList());
        if (!institutionList) dispatch(fetchInstitutionList());
    }, [dispatch, committeeList, sectorList, institutionList]);

    useEffect(() => {
        const codes = institutionSelections?.map((institution) => {
            if (institution) {
                return institution.code;
            } else {
                return null;
            }
        });
        if (codes.length > 0) {
            dispatch(fetchMultiDPOHList({ codes }));
        }
    }, [dispatch, institutionSelections]);

    useEffect(() => {
        const codes = stakeholderSectors?.map((sector) => {
            if (sector) {
                return sector.code;
            } else {
                return null;
            }
        });
        if (codes.length > 0) {
            dispatch(fetchMultiOrganizationList({ codes }));
        } else {
            dispatch(clearOrgList());
            setOrganizationSelections([null, null, null]);
        }
    }, [dispatch, stakeholderSectors]);

    const renderOptions = (props: React.HTMLAttributes<HTMLLIElement>, option: entityListExtra | CommitteeListItem) => {
        return (
            <Fade in={true} key={option.code || option.title}>
                <li {...props} style={{ fontSize: 14 }}>
                    {option.title}
                </li>
            </Fade>
        );
    };

    const handleClickOpen = () => {
        setResultModalOpen(true);
    };

    const handleClose = () => {
        setResultModalOpen(false);
    };

    const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault();

        setEmailError(false);

        // check for required fields
        if (!validator.isEmail(email)) {
            setEmailError(true);
        }
        if (firstName === '') {
            setFirstNameError(true);
        }
        if (lastName === '') {
            setLastNameError(true);
        }
        if (company === '') {
            setCompanyError(true);
        }
        if (jobTitle === '') {
            setJobTitleError(true);
        }
        if (industry === '') {
            setIndustryError(true);
        }

        if (
            validator.isEmail(email) &&
            firstName !== '' &&
            lastName !== '' &&
            company !== '' &&
            jobTitle !== '' &&
            industry !== ''
        ) {
            const additionalEmailList = [additionalEmail1, additionalEmail2].filter(
                (email) => email !== ''
            );

            const trialRequest: EmailTrialRequest = {
                email,
                firstName,
                lastName,
                company,
                jobTitle,
                industry,
                additionalEmails: additionalEmailList,
                // selections rely on order, so null is passed to maintain length
                committeeMeetings: committeeMeetings.filter((committee) => committee !== null),
                stakeholderSectors: stakeholderSectors,
                organizations: organizationSelections,
                institutions: institutionSelections,
                dpohs: dpohSelections,
                keywordAlerts: [], // keywordAlerts.filter(keyword => keyword !== ''),
                grInsider: false, // CURRENTLY DISABLED grInsider ? true : false
                questionPeriod: false, // CURRENTLY DISABLED questionPeriod ? true : false
                customIssue: false, // CURRENTLY DISABLED customIssue ? true : false,
            };

            handleClickOpen();

            try {
                await dispatch(applyForEmailTrial(trialRequest)).unwrap();
                await dispatch(fetchActiveUser()).unwrap();
                navigate('/account/custom');
            } catch (e) {}
        } else {
            window.scrollTo(0, 0);
        }
    };

    return userLoading ? (
        <LoadPage />
    ) : !userEmail ? (
        <div className="flex flex-row items-center justify-center text-xl py-[50vh]">
            <img src={logo} className="h-20" alt="logo" />
            <div style={{ marginLeft: '20px', marginRight: '20px' }} />
            <p>Please</p>
            <div
                className="mx-1 text-cyan-500 font-bold hover:underline hover:cursor-pointer"
                onClick={() => dispatch(signUp())}
            >
                create a Lobby IQ account
            </div>
            <p>to apply for a free trial.</p>
        </div>
    ) : (
        <div className="lg:p-16 lg:pt-32 pt-24 p-10 mb-16">
            <Dialog
                open={resultModalOpen}
                onClose={trialLoading ? () => {} : handleClose}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title">
                    {trialLoading
                        ? 'Processing Trial Request'
                        : trialError
                        ? 'Error'
                        : 'Request Sent'}
                </DialogTitle>
                <DialogContent>
                    {trialLoading ? (
                        <div className="flex flex row justify-center items-center">
                            <LoadSpinner />
                        </div>
                    ) : (
                        <DialogContentText id="alert-dialog-description">
                            {trialError
                                ? 'Error submitting trial application. Please refresh the page and try again.'
                                : 'Thank you for applying for a LobbyIQ email trial. Once we process your application, expect to receive an email marking the beginning of your trial.'}
                        </DialogContentText>
                    )}
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose} autoFocus disabled={trialLoading}>
                        Close
                    </Button>
                </DialogActions>
            </Dialog>
            <Modal
                title={'Committee Summaries'}
                open={committeeModalOpen}
                width={30}
                onClose={() => setCommitteeModalOpen(false)}
            >
                {
                    'Three-page summary and transcript of Committee meeting delivered within an hour of meeting conclusion.'
                }
            </Modal>
            <Modal
                title={'Sector Lobbying'}
                open={sectorModalOpen}
                width={30}
                onClose={() => setSectorModalOpen(false)}
            >
                {
                    'Weekly report on recently posted stakeholder lobbying communications and registrations for selected sectors. LobbyIQ subscribers receive weekly lobbying reports additionally for selected organizations, institutions and DPOHs.'
                }
            </Modal>
            <Modal
                title={'Custom Issue Alerts'}
                open={issueModalOpen}
                width={30}
                onClose={() => setIssueModalOpen(false)}
            >
                {
                    'Weekly monitoring report on lobbying activity by Issue. Subscribers create up to 10 custom issues with specific keywords to monitor.'
                }
            </Modal>
            <Modal
                title={'Keyword Alerts'}
                open={keywordModalOpen}
                width={30}
                onClose={() => setKeywordModalOpen(false)}
            >
                {'Daily email with list of keyword mentions during previous day in Hansard, Committee meetings and Question Period. LobbyIQ subscribers also have the option ' +
                    'of receiving real-time alerts of keyword mentions during Committee meetings and Question Period, delivered within an hour of the meeting or QP finishing.'}
            </Modal>
            <Modal
                title={'GR Insider'}
                open={grModalOpen}
                width={30}
                onClose={() => setGRModalOpen(false)}
            >
                {
                    'Weekly email update on new federal lobby registrations and de-registrations by organizations, lobby firms and consultants, and changes in in-house GR teams.'
                }
            </Modal>
            <Modal
                title={'Question Period'}
                open={qpModalOpen}
                width={30}
                onClose={() => setQPModalOpen(false)}
            >
                {
                    'Daily three-page summary report and transcript of Question Period, delivered within an hour of the Question Period conclusion.'
                }
            </Modal>
            <div className="flex flex-col justify-center items-center text-center mb-12">
                <div className="text-2xl">
                    Please complete this form to receive{' '}
                    <b>free Committee Meeting and Stakeholder Lobbying</b> email updates for a{' '}
                    <b>two-week period</b>.
                </div>
            </div>
            <form autoComplete="off" onSubmit={handleSubmit}>
                <div className="flex lg:flex-row md:flex-row flex-col justify-center items-center lg:pb-8 pb-6">
                    <div className="mb-5 lg:mb-0 md:mb-0">
                        <div className="p-3">Business Email</div>
                        <div className="lg:w-96 w-72 lg:pr-4 md:pr-4">
                            <TextField
                                fullWidth
                                id="email"
                                placeholder="Business Email"
                                variant="outlined"
                                required
                                onChange={(e) => setEmail(e.target.value)}
                                value={email}
                                error={emailError}
                                {...(emailError ? { label: 'Invalid email' } : {})}
                            />
                        </div>
                    </div>
                    <div>
                        <div className="lg:pl-6 md:pl-6 p-3">Company/Organization</div>
                        <div className="lg:w-96 w-72 lg:pl-4 md:pl-4">
                            <TextField
                                fullWidth
                                id="comp-org"
                                placeholder="Company/Organization"
                                variant="outlined"
                                required
                                onChange={(e) => setCompany(e.target.value)}
                                value={company}
                                error={companyError}
                            />
                        </div>
                    </div>
                </div>
                <div className="flex lg:flex-row md:flex-row flex-col justify-center items-center lg:pb-12 pb-8">
                    <div className="mb-5 lg:mb-0 md:mb-0">
                        <div className="p-3">First Name</div>
                        <div className="lg:w-96 w-72 lg:pr-4 md:pr-4">
                            <TextField
                                fullWidth
                                id="first-name"
                                placeholder="First Name"
                                variant="outlined"
                                required
                                onChange={(e) => setFirstName(e.target.value)}
                                value={firstName}
                                error={firstNameError}
                            />
                        </div>
                    </div>
                    <div>
                        <div className="lg:pl-6 md:pl-6 p-3">Last Name</div>
                        <div className="lg:w-96 w-72 lg:pl-4 md:pl-4">
                            <TextField
                                fullWidth
                                id="last-name"
                                placeholder="Last Name"
                                variant="outlined"
                                required
                                onChange={(e) => setLastName(e.target.value)}
                                value={lastName}
                                error={lastNameError}
                            />
                        </div>
                    </div>
                </div>
                <div className="flex lg:flex-row md:flex-row flex-col justify-center items-center lg:pb-12 pb-8">
                    <div className="mb-5 lg:mb-0 md:mb-0">
                        <div className="p-3">Job Title</div>
                        <div className="lg:w-96 w-72 lg:pr-4 md:pr-4">
                            <TextField
                                fullWidth
                                id="job-title"
                                placeholder="Job Title"
                                variant="outlined"
                                required
                                onChange={(e) => setJobTitle(e.target.value)}
                                value={jobTitle}
                                error={jobTitleError}
                            />
                        </div>
                    </div>
                    <div>
                        <div className="lg:pl-6 md:pl-6 p-3">Industry</div>
                        <div className="lg:w-96 w-72 lg:pl-4 md:pl-4">
                            <TextField
                                fullWidth
                                id="industry"
                                placeholder="Industry"
                                variant="outlined"
                                required
                                onChange={(e) => setIndustry(e.target.value)}
                                value={industry}
                                error={industryError}
                            />
                        </div>
                    </div>
                </div>
                <div className="flex lg:flex-row md:flex-row flex-col justify-center items-center lg:pb-12 pb-8">
                    <div className="mb-5 lg:mb-0 md:mb-0">
                        <div className="p-3">Additional Email (optional)</div>
                        <div className="lg:w-96 w-72 lg:pr-4 md:pr-4">
                            <TextField
                                fullWidth
                                id="additional-email-1"
                                placeholder="Additional Email"
                                variant="outlined"
                                onChange={(e) => setAdditionalEmail1(e.target.value)}
                                value={additionalEmail1}
                            />
                        </div>
                    </div>
                    <div>
                        <div className="lg:pl-6 md:pl-6 p-3">Additional Email (optional)</div>
                        <div className="lg:w-96 w-72 lg:pl-4 md:pl-4">
                            <TextField
                                fullWidth
                                id="additional-email-2"
                                placeholder="Additional Email"
                                variant="outlined"
                                onChange={(e) => setAdditionalEmail2(e.target.value)}
                                value={additionalEmail2}
                            />
                        </div>
                    </div>
                </div>
                <Divider sx={{ marginBottom: 3 }} />
                <div className="grid grid-cols-12 justify-center items-center md:h-[100px]">
                    <div className="col-span-1" />
                    <div className="flex flex-row justify-start p-2 md:col-span-2 col-span-12 text-xl font-bold">
                        <div
                            className="ml-2 mr-6 hover:cursor-pointer"
                            onClick={() => setCommitteeModalOpen(true)}
                        >
                            <HelpIcon color="info" />
                        </div>
                        Committee Meetings
                    </div>

                    <div className="flex flex-col md:flex-row justify-between col-span-8 mb-5 md:mb-0">
                        {[...Array(3)].map((_, idx) => (
                            <div className="px-5 w-96 mb-5 md:mb-0">
                                <Autocomplete
                                    isOptionEqualToValue={(
                                        option: CommitteeListItem,
                                        value: CommitteeListItem
                                    ) => option.title === value.title}
                                    fullWidth
                                    disableListWrap
                                    size="small"
                                    value={committeeMeetings[idx] || ''}
                                    onChange={(e, newValue) => {
                                        const newCommittees = committeeMeetings.map(
                                            (existingValue, i) => {
                                                if (i === idx) {
                                                    // replace the intended value
                                                    return newValue;
                                                } else {
                                                    // The rest haven't changed
                                                    return existingValue;
                                                }
                                            }
                                        );
                                        setCommitteeMeetings(newCommittees);
                                    }}
                                    renderInput={(params) => (
                                        <TextField {...params} label="Committees" />
                                    )}
                                    options={committeeList || []}
                                    getOptionLabel={(option: any) => option.title ?? ''}
                                    renderOption={renderOptions}
                                />
                            </div>
                        ))}
                    </div>
                </div>
                <div className="grid grid-cols-12 justify-center items-center md:h-[100px]">
                    <div className="col-span-1" />
                    <div className="flex flex-row justify-start p-2 md:col-span-2 col-span-12 text-xl font-bold">
                        <div
                            className="ml-2 mr-6 hover:cursor-pointer"
                            onClick={() => setSectorModalOpen(true)}
                        >
                            <HelpIcon color="info" />
                        </div>
                        Sector Lobbying
                    </div>

                    <div className="flex flex-col md:flex-row justify-between col-span-8 mb-5 md:mb-0">
                        {[...Array(3)].map((_, idx) => (
                            <div className="px-5 w-96 mb-5 md:mb-0">
                                <Autocomplete
                                    isOptionEqualToValue={(
                                        option: entityListExtra,
                                        value: entityListExtra
                                    ) => option.title === value.title}
                                    fullWidth
                                    disableListWrap
                                    size="small"
                                    value={stakeholderSectors[idx] || ''}
                                    onChange={(e, newValue) => {
                                        const newList = stakeholderSectors.map(
                                            (existingValue, i) => {
                                                if (i === idx) {
                                                    // replace the intended value
                                                    return newValue;
                                                } else {
                                                    // The rest haven't changed
                                                    return existingValue;
                                                }
                                            }
                                        );
                                        setStakeholderSectors(newList);
                                    }}
                                    renderInput={(params) => (
                                        <TextField {...params} label="Sectors" />
                                    )}
                                    options={sectorList || []}
                                    getOptionLabel={(option: any) => option.title ?? ''}
                                    renderOption={renderOptions}
                                />
                            </div>
                        ))}
                    </div>
                </div>
                <div className="grid grid-cols-12 justify-center items-center md:h-[100px]">
                    <div className="col-span-1" />
                    <div className="flex flex-row justify-start p-2 md:col-span-2 col-span-12 text-xl font-bold">
                        <div
                            className="ml-2 mr-6 hover:cursor-pointer"
                            //onClick={() => setCommitteeModalOpen(true)}
                        >
                            <HelpIcon color="info" />
                        </div>
                        Organization Lobbying
                    </div>

                    <div className="flex flex-col md:flex-row justify-between col-span-8 mb-5 md:mb-0">
                        {[...Array(3)].map((_, idx) => (
                            <div className="px-5 w-96 mb-5 md:mb-0">
                                <Autocomplete
                                    disabled={!orgLists[idx] || orgLists[idx].length === 0}
                                    isOptionEqualToValue={(
                                        option: entityListExtra,
                                        value: entityListExtra
                                    ) => option.title === value.title}
                                    fullWidth
                                    disableListWrap
                                    size="small"
                                    value={organizationSelections[idx] || ''}
                                    onChange={(e, newValue) => {
                                        const newOrganizations = organizationSelections.map(
                                            (existingValue, i) => {
                                                if (i === idx) {
                                                    // replace the intended value
                                                    return newValue;
                                                } else {
                                                    // The rest haven't changed
                                                    return existingValue;
                                                }
                                            }
                                        );
                                        setOrganizationSelections(newOrganizations);
                                    }}
                                    renderInput={(params) => (
                                        <TextField {...params} label="Organizations" />
                                    )}
                                    options={orgLists[idx]}
                                    getOptionLabel={(option: any) => option.title ?? ''}
                                    renderOption={renderOptions}
                                />
                            </div>
                        ))}
                    </div>
                </div>
                <div className="grid grid-cols-12 justify-center items-center md:h-[100px]">
                    <div className="col-span-1" />
                    <div className="flex flex-row justify-start p-2 md:col-span-2 col-span-12 text-xl font-bold">
                        <div
                            className="ml-2 mr-6 hover:cursor-pointer"
                            //onClick={() => setCommitteeModalOpen(true)}
                        >
                            <HelpIcon color="info" />
                        </div>
                        Institutions Lobbied
                    </div>

                    <div className="flex flex-col md:flex-row justify-between col-span-8 mb-5 md:mb-0">
                        {[...Array(3)].map((_, idx) => (
                            <div className="px-5 w-96 mb-5 md:mb-0">
                                <Autocomplete
                                    isOptionEqualToValue={(
                                        option: entityListExtra,
                                        value: entityListExtra
                                    ) => option.title === value.title}
                                    fullWidth
                                    disableListWrap
                                    size="small"
                                    value={institutionSelections[idx] || ''}
                                    onChange={(e, newValue) => {
                                        const newInstitutions = institutionSelections.map(
                                            (existingValue, i) => {
                                                if (i === idx) {
                                                    // replace the intended value
                                                    return newValue;
                                                } else {
                                                    // The rest haven't changed
                                                    return existingValue;
                                                }
                                            }
                                        );
                                        setInstitutionSelections(newInstitutions);
                                    }}
                                    renderInput={(params) => (
                                        <TextField {...params} label="Institutions" />
                                    )}
                                    options={institutionList || []}
                                    getOptionLabel={(option: any) => option.title ?? ''}
                                    renderOption={renderOptions}
                                />
                            </div>
                        ))}
                    </div>
                </div>
                <div className="grid grid-cols-12 justify-center items-center md:h-[100px]">
                    <div className="col-span-1" />
                    <div className="flex flex-row justify-start p-2 md:col-span-2 col-span-12 text-xl font-bold">
                        <div
                            className="ml-2 mr-6 hover:cursor-pointer"
                            //onClick={() => setCommitteeModalOpen(true)}
                        >
                            <HelpIcon color="info" />
                        </div>
                        DPOHs Lobbied
                    </div>

                    <div className="flex flex-col md:flex-row justify-between col-span-8 mb-5 md:mb-0">
                        {[...Array(3)].map((_, idx) => (
                            <div className="px-5 w-96 mb-5 md:mb-0">
                                <Autocomplete
                                    disabled={!dpohLists[idx] || dpohLists[idx].length === 0}
                                    isOptionEqualToValue={(
                                        option: entityListExtra,
                                        value: entityListExtra
                                    ) => option.title === value.title}
                                    fullWidth
                                    disableListWrap
                                    size="small"
                                    value={dpohSelections[idx] || ''}
                                    onChange={(e, newValue) => {
                                        const newCommittees = dpohSelections.map(
                                            (existingValue, i) => {
                                                if (i === idx) {
                                                    // replace the intended value
                                                    return newValue;
                                                } else {
                                                    // The rest haven't changed
                                                    return existingValue;
                                                }
                                            }
                                        );
                                        setDpohSelections(newCommittees);
                                    }}
                                    renderInput={(params) => (
                                        <TextField {...params} label="DPOHs" />
                                    )}
                                    options={dpohLists[idx]}
                                    getOptionLabel={(option: any) => option.title ?? ''}
                                    renderOption={renderOptions}
                                />
                            </div>
                        ))}
                    </div>
                </div>
                {/* <div className="grid grid-cols-12 justify-center items-center md:h-[100px]">
                    <div className="col-span-1" />
                    <div className="flex flex-row justify-start pl-4 col-span-12 md:col-span-2 text-xl font-bold">
                        <div
                            className="mr-5 hover:cursor-pointer"
                            onClick={(e) => {
                                e.preventDefault();
                                setGRModalOpen(true);
                            }}
                        >
                            <HelpIcon color="info" />
                        </div>
                        GR Insider
                    </div>
                    <div className="flex flex-row justify-between col-span-12 md:col-span-3 px-4 mb-5 mt-2 lg:mb-0 md:my-0">
                        <div className="w-96 pr-8 mb-5 md:mb-0">
                            <Select
                                value={grInsider}
                                fullWidth
                                size="small"
                                MenuProps={{ disableScrollLock: true }}
                                onChange={(e) =>
                                    setGrInsider(e.target.value as number)
                                }
                            >
                                <MenuItem value={1}>Include</MenuItem>
                                <MenuItem value={0}>Exclude</MenuItem>
                            </Select>
                        </div>
                    </div>
                </div> */}
                {/* <div className="grid grid-cols-12 justify-center items-center md:h-[100px]">
                    <div className="col-span-1" />
                    <div className="flex flex-row justify-start pl-4 col-span-12 md:col-span-2 text-xl font-bold">
                        <div
                            className="mr-5 hover:cursor-pointer"
                            onClick={(e) => {
                                e.preventDefault();
                                setIssueModalOpen(true);
                            }}
                        >
                            <HelpIcon color="info" />
                        </div>
                        Custom Issue Alerts
                    </div>
                    <div className="flex flex-row justify-between col-span-12 md:col-span-3 px-4 mb-5 mt-2 lg:mb-0 md:my-0">
                        <div className="w-96 pr-8 mb-5 md:mb-0">
                            <Select
                                value={customIssue}
                                fullWidth
                                size="small"
                                MenuProps={{ disableScrollLock: true }}
                                onChange={(e) => setCustomIssue(e.target.value as number)}
                            >
                                <MenuItem value={1}>Include</MenuItem>
                                <MenuItem value={0}>Exclude</MenuItem>
                            </Select>
                        </div>
                    </div>
                    <div className="flex flex-col md:flex-row justify-between col-span-12 md:col-span-5 mb-5 md:mb-0">
                        If you wish to trial a custom issue alert, our team will reach out to you to
                        create a custom issue.
                    </div>
                </div> */}
                <div className="flex flex-row justify-center items-center mt-4">
                    <Button
                        className="bg-slate-600 w-72"
                        variant="contained"
                        size="large"
                        type="submit"
                        endIcon={<SendIcon />}
                    >
                        <div className="font-sans not-italic font-bold text-normal">Request</div>
                    </Button>
                </div>
            </form>
        </div>
    );
};

export default EmailTrialPage;
