import { useAppDispatch } from '../store/store';
import {
    fetchActiveUser,
    logIn,
    selectUserLoading,
} from '../store/reducers/userSlice';
import { useSelector } from 'react-redux';
import { useEffect } from 'react';
import LoadPage from '../pages/misc/LoadPage';
import { Outlet, useSearchParams } from 'react-router-dom';

// top level wrapper to block user from seeing content until their email is verified
const ValidateEmailWrapper = () => {
    const dispatch = useAppDispatch();
    const [searchParams] = useSearchParams();
    const validationComplete = searchParams.get('success');

    const userLoading = useSelector(selectUserLoading);
    // const userEmail = useSelector(selectEmail);
    // const emailVerified = useSelector(selectVerified);

    // get active user on each render
    useEffect(() => {
        dispatch(fetchActiveUser());
    }, [dispatch]);

    // refresh auth token after redirect from verify link
    useEffect(() => {
        if (validationComplete) {
            dispatch(logIn());
        }
    }, [validationComplete, dispatch]);

    // const handleValidationEmail = () => {
    //     dispatch(resendValidationEmail());
    // };

    return userLoading ? (
        <LoadPage />
    ) : //(userEmail && emailVerified) || !userEmail ? (
        <Outlet />
    // ) : (
    //     <div>
    //         <div className="flex flex-col items-center justify-center py-[30vh] text-lg">
    //             <img src={logo} className="h-[6rem] mb-5" alt="logo" />
    //             <div className="text-2xl mb-10 font-bold text-center">
    //                 Please verify your email
    //             </div>
    //             <div className="flex flex-col sm:flex-row text-center ">
    //                 <p>Please check your email</p>
    //                 <p className="sm:mx-1 font-bold">{userEmail}</p>
    //                 <p>for a message from LobbyIQ with instructions on validating your account.</p>
    //             </div>
    //             <div className="flex flex-col sm:flex-row mt-2 text-center">
    //                 After verifying your email, you will be redirected back to
    //                 LobbyIQ to complete your user profile.
    //             </div>
    //             <div className="flex flex-col sm:flex-row text-center my-7">
    //                 <p>
    //                     Didn't receive the email? Please check your junk/spam
    //                     folder or
    //                 </p>
    //                 <div
    //                     className="mx-1 text-cyan-500 font-bold italic hover:underline hover:cursor-pointer"
    //                     onClick={handleValidationEmail}
    //                 >
    //                     click here
    //                 </div>
    //                 <p>to send another.</p>
    //             </div>
    //         </div>
    //     </div>
    // );
};

export default ValidateEmailWrapper;
