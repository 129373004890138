import React, { useState } from 'react';
import { DateCalendar, DateView } from '@mui/x-date-pickers';
import Button from '@mui/material/Button';
import moment from 'moment';
import Modal from './Modal';

interface DateSelectionProps {
    defaultDate?: moment.Moment | null;
    disabled?: boolean;
    open: boolean;
    handleClose: () => void;
    handleDateChange: (x: string) => void;
    views: readonly DateView[];
    minDate?: moment.Moment;
}

const DateSelection = ({
    defaultDate = null,
    disabled = false,
    open,
    handleClose,
    handleDateChange,
    views,
}: DateSelectionProps) => {
    const [value, setValue] = useState<moment.Moment | null>(defaultDate || moment().utc());

    const handleChange = () => {
        const string_moment: string = moment
            .utc(value)
            .format(`YYYY-MM${views.includes('day') ? '-DD' : ''}`);
        handleDateChange(string_moment);
        handleClose();
    };

    return (
        <Modal open={open} onClose={handleClose} width={30} title="Select a Date">
            <div className="flex bg-slate-100 rounded-xl py-4 h-96">
                <DateCalendar
                    showDaysOutsideCurrentMonth
                    fixedWeekNumber={6}
                    views={views}
                    value={value}
                    onChange={(newValue) => setValue(newValue)}
                    minDate={moment('01-01-2010', 'MM-DD-YYYY')}
                    maxDate={moment()}
                />
            </div>
            <div className="mt-6">
                <Button fullWidth variant="contained" onClick={handleChange}>
                    Set Date
                </Button>
            </div>
        </Modal>
    );
};

export default DateSelection;
