import React, { useEffect } from 'react';
import {
    fetchSubjectList,
    selectSubjectList,
} from '../../features/subject-dashboards/store/subjectListSlice';
import { useSelector } from 'react-redux';
import { entityListExtra } from '../../interfaces/generic-entity.interface';
import { useAppDispatch } from '../../store/store';
import EntityListPage from './EntityListPage';

const SectorListDataLayer = () => {
    const dispatch = useAppDispatch();

    const entity_list: entityListExtra[] | null = useSelector(selectSubjectList);

    useEffect(() => {
        dispatch(fetchSubjectList());
    }, [dispatch]);

    return <EntityListPage type="subjects" list={entity_list || []} />;
};

export default SectorListDataLayer;
