import React from 'react';
import load from '../../assets/vectors/load.svg';

interface spinnerProps {
    size?: number;
    color?: string;
}

const LoadSpinner = ({ size = 40, color = 'slate-600' }: spinnerProps) => {
    return (
        <div style={{ width: size, height: size, color: color }}>
            <img
                src={load}
                className="flex-1 animate-spin"
                alt="loading spinner"
            />
        </div>
    );
};

export default LoadSpinner;
