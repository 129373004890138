import TitleContext from '../../contexts/TitleContext';
import { Helmet } from 'react-helmet';
import { useContext } from 'react';

interface tagProps {
    title?: string;
    keywords?: string;
    description?: string;
}

// Author: Raouf Atabeg
// Component is created to increase search engine optimization (SEO)
// by adding additional meta tags to describe what each page of the website contains in keywords

// params:
// title: what should the title of the page be. This also effects the tab title as well
// keywords: specific keywords that describe the website for the SEO bot to pick up. Insure they are separated by a comma
// description: description of the page itself

function MetaTag({ title, keywords, description }: tagProps) {
    const { defaultTitle } = useContext(TitleContext);

    return (
        <Helmet>
            <title>{title ? `${title} | LobbyIQ` : defaultTitle}</title>
            {description && <meta name="description" content={description} />}
            {keywords && <meta name="keywords" content={keywords} />}
        </Helmet>
    );
}

export default MetaTag;
