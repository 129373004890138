import MetaTag from '../../components/metatag-component/MetaTag';

const StayAheadFeatures = () => {
    return (
        <div className="xl:mx-24 mx-8 ">
            <MetaTag
                title="Stay Ahead Updates"
                keywords="Same-Day Committee Meeting Summaries,Weekly Lobby Monitor Emails,Weekly Issue Emails,Keyword Alerts,
                Custom Lobbying Data, Flexible Queries, Sector, DPOH, AI-Enabled Text-Mining, Government Text Analysis, Legal Text Analysis, Regulatory Text Analysis, Wayback Machine, Historical Data Research, PDF Generator, Presentation-Ready Charts, Presentation-Ready Tables, Key Phrase Extraction, Machine Learning Algorithms, Critical Lobbying Issues, Issue Landscapes, Factor Analysis, Principal Component Analysis, GR Landscape"
                description="Get Same-Day Committee Meeting Summaries with full transcripts and concise 2-page summaries delivered within the hour. Stay informed with Weekly Lobby Monitor Emails and Keyword Alerts for critical mentions. Receive Weekly Issue Emails summarizing lobbying activities and parliamentary discussions relevant to your focus areas."
            />
            <div className="col-12 md:col-7 lg:col-6 py-14 lg:gap-12 gap-y-8 lg:gap-y-0"></div>

            <div className="lg:grid grid-cols-12 grid-rows-auto gap-8 py-8 flex flex-col">
                <h1 className="lg:text-4xl text-3xl col-span-12 row-start-1">Stay Ahead Updates</h1>

                <div className="lg:col-start-1 row-start-2 col-start-2 col-end-7 grid grid-cols-12 gap-6">
                    <div className="lg:col-span-6 col-span-12 bg-white shadow-md p-6 rounded-xl flex flex-col justify-between max-h-[20rem]">
                        <div className="overflow-hidden">
                            <div className="text-2xl">
                                Same-Day{' '}
                                <span className="text-liq-lime hover:underline">
                                    <a href=" https://liqtranscripts.blob.core.windows.net/summary/AGRI_2024-02-27.pdf">
                                        Committee Meeting Summaries
                                    </a>
                                </span>
                            </div>
                            <div className="text-md mt-8 font-light ">
                                Tired of waiting two weeks to obtain transcripts from critical
                                committee minutes? We deliver full meeting transcripts and 2-page
                                summaries to your inbox within the hour of the meeting
                            </div>
                        </div>
                    </div>
                    <div className="lg:col-span-6 col-span-12 bg-white shadow-md p-6 rounded-xl flex flex-col justify-between max-h-[20rem]">
                        <div className="overflow-hidden">
                            <div className="text-2xl">
                                <span className="text-liq-lavender hover:underline">
                                    <a href="https://liqtranscripts.blob.core.windows.net/summary/LobbyIQSampleWeeklyEmail.pdf">
                                        Weekly Lobby Monitor Emails
                                    </a>
                                </span>
                            </div>
                            <div className="text-md mt-8 font-light">
                                Receive a weekly list of all lobby communications and registrations
                                in your core areas of focus: by sector, organization, institution,
                                public office holders
                            </div>
                        </div>
                    </div>
                </div>
                <div className="col-start-7 row-start-2 col-span-6">
                    <a href=" https://liqtranscripts.blob.core.windows.net/summary/AGRI_2024-02-27.pdf">
                        <img
                            src={process.env.PUBLIC_URL + '/Committee_ Summary_pdf.png'}
                            className="w-full bg-slate-50 border border-slate-100 p-6 rounded-xl shadow-md	"
                            height="auto"
                            alt="logo"
                        />
                    </a>
                </div>
                <div className="col-12 md:col-7 lg:col-6 py-14 lg:gap-12 gap-y-8 lg:gap-y-0"></div>

                <div className="lg:col-start-1 row-start-3 col-start-2 col-end-7 grid grid-cols-12 gap-6">
                    <div className="lg:col-span-6 col-span-12 bg-white shadow-md p-6 rounded-xl flex flex-col justify-between max-h-[20rem]">
                        <div className="overflow-hidden">
                            <div className="text-2xl">Keyword Alerts</div>
                            <div className="text-md mt-8 font-light ">
                                We send email alerts for mentions of critical keywords for
                                Reputation Risk and Crisis Management.
                            </div>
                        </div>
                    </div>
                    <div className="lg:col-span-6 col-span-12 bg-white shadow-md p-6 rounded-xl flex flex-col justify-between max-h-[20rem]">
                        <div className="overflow-hidden">
                            <div className="text-2xl">Weekly Issue Emails</div>
                            <div className="text-md mt-8 font-light">
                                We send weekly email summaries of lobbying on your issues, as well
                                as full-sentence extracts of parliamentary discussions.
                            </div>
                        </div>
                    </div>
                </div>
                <div className="col-start-7 row-start-3 col-span-6 ">
                    <img
                        src={process.env.PUBLIC_URL + '/Monitor_Email.png'}
                        className="w-full bg-slate-50 border border-slate-100 p-6 rounded-xl shadow-md	"
                        height="auto"
                        alt="logo"
                    />
                </div>
            </div>
        </div>
    );
};

export default StayAheadFeatures;
