import React, { useEffect, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { Button, Divider, IconButton, Menu } from '@mui/material';
import logo from '../../assets/vectors/LIQ_SLATE.svg';
import { useAppDispatch } from '../../store/store';
import {
    logIn,
    logout,
    selectUserLoading,
    selectEmail,
    selectUserPermissions,
    signUp,
    ProductType,
} from '../../store/reducers/userSlice';
import { useSelector } from 'react-redux';
import Profile from '@mui/icons-material/AccountCircleRounded';
import SearchRoundedIcon from '@mui/icons-material/SearchRounded';
import MobileNavbar from './MobileNavbar';
import AccountPanel from './AccountPanel';
import {
    fetchSectorsSelections,
    selectChosenSectors,
    selectSectorsLoading,
} from '../../store/reducers/customSelectionSlice';
import Modal from '../misc/Modal';
import FeaturesButton from './FeaturesDropDown';
import DashboardsButton from './DashboardsDropDown';

const Navbar = () => {
    const dispatch = useAppDispatch();
    const navigate = useNavigate();

    const userLoading = useSelector(selectUserLoading);
    const userEmail = useSelector(selectEmail);
    const permissions = useSelector(selectUserPermissions);
    const selectedSectors = useSelector(selectChosenSectors);
    const sectorsLoading = useSelector(selectSectorsLoading);

    const link_tran = 'text-slate-600 hover:text-black';

    // Mobile breakpoint is set to 1024 pixels - the "lg" breakpoint of tailwind for consistency
    const mobile_breakpoint = 1024;

    // Manage current desktop state. True if viewport width is greater than the mobile breakpoint.
    const [isDesktop, setDesktop] = useState<boolean>(window.innerWidth > mobile_breakpoint);

    const [modalOpen, setModalOpen] = useState<boolean>(false);

    // Listen for viewport resize. Update media if necessary
    useEffect(() => {
        window.addEventListener('resize', updateMedia);
        return () => window.removeEventListener('resize', updateMedia);
    });

    useEffect(() => {
        if (
            permissions &&
            !(
                permissions?.productType === ProductType.TRIAL ||
                permissions?.productType === ProductType.ENTERPRISE
            )
        ) {
            if (!sectorsLoading && window.location.pathname !== '/account/selections') {
                setModalOpen(selectedSectors.length === 0);
            } else {
                setModalOpen(false);
            }
        }
    }, [selectedSectors, permissions, userLoading, sectorsLoading]);

    useEffect(() => {
        if (permissions) {
            dispatch(fetchSectorsSelections());
        }
    }, [dispatch, permissions]);

    // Report new viewport width as determined by useEffect.
    const updateMedia = () => {
        setDesktop(window.innerWidth > mobile_breakpoint);
    };

    const [anchorElDash, setAnchorElDash] = React.useState<null | HTMLElement>(null);
    const [anchorElAcc, setAnchorElAcc] = React.useState<null | HTMLElement>(null);
    const [anchorElFea, setAnchorElFea] = React.useState<null | HTMLElement>(null);

    const open_dash = Boolean(anchorElDash);
    const open_acc = Boolean(anchorElAcc);
    const open_fea = Boolean(anchorElFea);

    const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
        if (event.currentTarget.id === 'dashboards-button') setAnchorElDash(event.currentTarget);
        if (event.currentTarget.id === 'account-button') setAnchorElAcc(event.currentTarget);
        if (event.currentTarget.id === 'features-button') setAnchorElFea(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorElDash(null);
        setAnchorElAcc(null);
        setAnchorElFea(null);
    };

    const handleLoginRedirect = async () => {
        dispatch(logIn());
    };

    const handleSignUpRedirect = async () => {
        dispatch(signUp());
    };

    const handleLogout = () => {
        handleClose();
        dispatch(logout());
    };

    const handleSettingsRedirect = () => {
        navigate('/account/selections');
        setModalOpen(false);
    };

    return (
        <>
            <header
                className="text-slate-600 fixed left-1/2 -translate-x-1/2 z-10 w-[98%] lg:px-4 rounded-b-xl
                        shadow-sm bg-white"
            >
                {isDesktop ? (
                    <div className="flex flex-row items-center justify-between py-3">
                        <div className="flex flex-row items-center space-x-8">
                            <Link
                                to="/"
                                className=" flex flex-row items-center gap-3 leading-4 text-sky-700"
                            >
                                <img className="w-8" src={logo} alt="LIQ logo" />
                                Technology for Government Relations
                            </Link>
                        </div>
                        <div className="flex text-[1rem] flex-row items-center space-x-8">
                            {!permissions && (
                                <>
                                    <Link to="/pricing">
                                        <div className={link_tran}>Subscriptions</div>
                                    </Link>

                                    <Link to="/solutions">
                                        <div className={link_tran}>Solutions</div>
                                    </Link>
                                </>
                            )}
                            {!permissions && (
                                <Link to="/about">
                                    <div className={link_tran}>About</div>
                                </Link>
                            )}
                            <Link to="/custom-query">
                                <div
                                    className={
                                        'flex flex-row justify-between items-center gap-2 ' +
                                        link_tran
                                    }
                                >
                                    Search <SearchRoundedIcon fontSize="small" />
                                </div>
                            </Link>
                            <FeaturesButton
                                open={open_fea}
                                anchorEl={anchorElFea}
                                handleClick={handleClick}
                                handleClose={handleClose}
                            />
                            <DashboardsButton
                                open={open_dash}
                                anchorEl={anchorElDash}
                                handleClick={handleClick}
                                handleClose={handleClose}
                            />
                            {userLoading || userEmail ? (
                                <div>
                                    <IconButton
                                        id="account-button"
                                        onClick={handleClick}
                                        sx={{
                                            padding: '3px',
                                            margin: 0,
                                        }}
                                        aria-controls={open_acc ? 'account-menu' : undefined}
                                        aria-haspopup="true"
                                        aria-expanded={open_acc ? 'true' : undefined}
                                    >
                                        <Profile
                                            style={{
                                                fontSize: 30,
                                                color: open_acc ? '#67A8CC' : 'rgb(71 85 105)',
                                            }}
                                        />
                                    </IconButton>
                                    <Menu
                                        sx={{
                                            marginTop: '12px',
                                            '& .MuiMenu-paper': {
                                                width: 'auto',
                                                minWidth: '200px',
                                                backgroundColor: 'white',
                                                borderTopWidth: 6,
                                                borderTopColor: '#67A8CC',
                                                borderRadius: '0px 0px 10px 10px',
                                                boxShadow: 'rgba(0, 0, 0, 0.2) 0px 4px 4px -2px',
                                            },
                                        }}
                                        anchorOrigin={{
                                            vertical: 'bottom',
                                            horizontal: 'right',
                                        }}
                                        transformOrigin={{
                                            vertical: 'top',
                                            horizontal: 'right',
                                        }}
                                        anchorEl={anchorElAcc}
                                        open={open_acc}
                                        onClose={handleClose}
                                        disableScrollLock={true}
                                    >
                                        <AccountPanel
                                            userEmail={userEmail}
                                            userLoading={userLoading}
                                            handleLogin={handleLoginRedirect}
                                            handleLogout={handleLogout}
                                            handleClose={handleClose}
                                        />
                                    </Menu>
                                </div>
                            ) : (
                                <div className="flex flex-row">
                                    <button
                                        className="w-24 mr-2 text-center border border-sky-700 text-sky-700 transition ease-in-out hover:bg-sky-100 rounded-md py-1"
                                        onClick={handleLoginRedirect}
                                    >
                                        Sign In
                                    </button>
                                    <Divider
                                        orientation="vertical"
                                        sx={{
                                            borderColor: 'lightgray',
                                        }}
                                        flexItem
                                    />
                                    <button
                                        className="w-24 ml-2 text-center bg-sky-700 text-white transition ease-in-out hover:bg-sky-600 rounded-md py-1"
                                        onClick={handleSignUpRedirect}
                                    >
                                        Sign Up
                                    </button>
                                </div>
                            )}
                        </div>
                    </div>
                ) : (
                    <MobileNavbar
                        userEmail={userEmail}
                        userLoading={userLoading}
                        handleLogin={handleLoginRedirect}
                        handleSignUp={handleSignUpRedirect}
                        handleLogout={handleLogout}
                    />
                )}
                <Modal
                    open={modalOpen}
                    onClose={() => setModalOpen(false)}
                    title="Dashboard Selections Required"
                    width={30}
                >
                    <div className="flex flex-col items-center text-center mb-10">
                        Please select Sector, Institution and Committee dashboards allowed for this
                        account. Continue to the Settings page to make dashboard selections.
                    </div>
                    <Button variant="contained" fullWidth onClick={handleSettingsRedirect}>
                        Continue
                    </Button>
                </Modal>
            </header>
        </>
    );
};

export default Navbar;
