import React from 'react';
import LoadingBarChart from './LoadingBarChart';
import LoadingTable from './LoadingTable';

const LoadingOverview = () => {
    return (
        <div className="flex flex-col lg:grid lg:grid-cols-3 gap-4">
            <div className="col-span-1">
                <LoadingBarChart />
            </div>
            <div className="col-span-2">
                <LoadingTable />
            </div>
        </div>
    );
};

export default LoadingOverview;
