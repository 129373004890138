import React, { useEffect, useState } from 'react';
import { Alert, Breadcrumbs, Button, Slide, Snackbar } from '@mui/material';
import CalendarMonthRoundedIcon from '@mui/icons-material/CalendarMonthRounded';
import DateSelection from '../../components/misc/DateSelection';
import { Link, useParams } from 'react-router-dom';
import {
    entityOrg,
    entityOverviews,
    entityRegistration,
} from '../../interfaces/generic-entity.interface';
import HandshakeRoundedIcon from '@mui/icons-material/HandshakeRounded';
import Overview from '../../components/generic-dashboards/Overview';
import Breakdowns from '../../components/generic-dashboards/Breakdowns';
import DashTable from '../../components/generic-dashboards/DashTable';
import { replace_underscores_capitalize } from '../../helpers/string_formatters';
import { LobbyFirmOverview } from '../../tooltips/lobby-firms/LobbyFirmOverview';
import { LobbyFirmLobbyingCommunications } from '../../tooltips/lobby-firms/LobbyFirmLobbyingCommunications';
import { LobbyFirmRegistrations } from '../../tooltips/lobby-firms/LobbyFirmRegistrations';
import { LobbyFirmDeregistrations } from '../../tooltips/lobby-firms/LobbyFirmDeregistrations';
import { useSelector } from 'react-redux';
import { GATrackDashboardLoad, EVENT_TYPES } from '../../google_analytics/TrackEvent';
import { selectEmail, selectUserProductType } from '../../store/reducers/userSlice';
import SimCardDownloadRoundedIcon from '@mui/icons-material/SimCardDownloadRounded';
import { DashboardDownloadModal } from '../../components/misc/DashboardDownloadModal';

interface inHouseProps {
    name: string;
    date: string;
    type: 'consultants' | 'firms';
    handleDateChange: (x: string) => void;
    registrations: entityRegistration[];
    registrationsLoading: boolean;
    deregistrations: entityRegistration[];
    deregistrationsLoading: boolean;
    overview: entityOverviews | null;
    overviewLoading: boolean;
    overviewHeaders: string[];
    overviewIndent?: string[];
    topPartners: entityOrg[] | null;
    topPartnersLoading: boolean;
    topSectors?: entityOrg[] | null;
    topSectorsLoading?: boolean;
    topInstitutions?: entityOrg[] | null;
    topInstitutionsLoading?: boolean;
    exportLoading: boolean;
    exportFail: boolean;
}

const InhouseDashboard = ({
    name,
    date,
    type,
    handleDateChange,
    registrations,
    registrationsLoading,
    deregistrations,
    deregistrationsLoading,
    overview = { yearly: [], monthly: [] },
    overviewLoading,
    overviewHeaders,
    overviewIndent = [],
    topPartners,
    topPartnersLoading,
    topSectors = [],
    topSectorsLoading = false,
    topInstitutions = [],
    topInstitutionsLoading = false,
    exportLoading = false,
    exportFail = false,
}: inHouseProps) => {
    const [dateOpen, setDateOpen] = useState<boolean>(false);
    const [dateSet, setDateSet] = useState<boolean>(false);

    const handleDateOpen = () => setDateOpen(true);
    const handleDateClose = () => {
        setDateOpen(false);
        setDateSet(true);
    };

    const [scrollPosition, setScrollPosition] = useState(0);
    const [exportModalOpen, setExportModalOpen] = useState<boolean>(false);

    const userEmail = useSelector(selectEmail);
    const userProductType = useSelector(selectUserProductType);

    useEffect(() => {
        GATrackDashboardLoad(
            `${type === 'firms' ? 'Firms' : 'Consultants'}`,
            `${name}`,
            EVENT_TYPES.PAGE_LOAD,
            userEmail ? userEmail : undefined, 
            userProductType ? userProductType : undefined
        );
        const handleScroll = () => {
            const scrollPosition = window.scrollY;
            setScrollPosition(scrollPosition);
        };
        window.addEventListener('scroll', handleScroll);
        // Cleanup the event listener when the component is unmounted
        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, [name, type, userEmail, userProductType]);

    const url_params = useParams();
    const firm_name: string | undefined =
        url_params.entityId !== undefined ? url_params.entityId : '';

    return (
        <div className="py-32 lg:px-20 px-4 grid grid-cols-12 texst-">
            <Snackbar
                anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
                open={dateSet}
                onClose={() => setDateSet(false)}
                autoHideDuration={3000}
                message="Date Set"
            >
                <Alert onClose={() => setDateSet(false)} severity="success">
                    Date set to {date}
                </Alert>
            </Snackbar>

            <Slide direction="left" in={scrollPosition >= 50} mountOnEnter unmountOnExit>
                <div className="fixed z-20 bottom-24 right-5 lg:top-20 lg:right-4 flex flex-col h-0 text-white gap-2">
                    <Button
                        className="group flex"
                        onClick={handleDateOpen}
                        variant="contained"
                        startIcon={<CalendarMonthRoundedIcon />}
                    >
                        {date}
                    </Button>
                    <Button
                        variant="contained"
                        startIcon={<SimCardDownloadRoundedIcon />}
                        onClick={() => setExportModalOpen(true)}
                    >
                        Save PDF
                    </Button>
                </div>
            </Slide>

            <DashboardDownloadModal
                title={name}
                type={type}
                modalOpen={exportModalOpen}
                handleClose={() => setExportModalOpen(false)}
                overview={overview}
                lobbyingBreakdown={
                    (topPartners && topSectors && topInstitutions)
                    ?
                    {cluster_1: topPartners,
                    cluster_2: topSectors,
                    cluster_3: topInstitutions}
                    :
                    null
                }
                registrations={registrations}
                deregistrations={deregistrations}
                exportLoading={exportLoading}
                exportFail={exportFail}
            />

            <DateSelection
                open={dateOpen}
                handleClose={handleDateClose}
                handleDateChange={handleDateChange}
                views={['year', 'month', 'day']}
            />

            <div className=" col-start-1 col-end-13 flex flex-row justify-between">
                <Breadcrumbs className="col-start-2 lg:col-end-12 col-end-12" separator=">">
                    <Link to="/" className="hover:underline">
                        Lobby<b>IQ</b>
                    </Link>
                    {type === 'consultants' && (
                        <Link to={`/firms`} className="hover:underline">
                            Firms
                        </Link>
                    )}
                    {type === 'consultants' && (
                        <Link
                            to={`/firms/${encodeURIComponent(firm_name)}?name=${encodeURIComponent(
                                firm_name
                            )}`}
                            className="hover:underline"
                        >
                            {firm_name}
                        </Link>
                    )}
                    {type === 'consultants' ? (
                        <Link
                            to={`/firms/${encodeURIComponent(
                                firm_name
                            )}/consultants?firm=${encodeURIComponent(firm_name)}`}
                            className="hover:underline"
                        >
                            Consultants
                        </Link>
                    ) : (
                        <Link to={`/${type}`} className="hover:underline">
                            {replace_underscores_capitalize(type)}
                        </Link>
                    )}
                    <div>{name}</div>
                </Breadcrumbs>
                <Slide direction="left" in={true}>
                    <div className="lg:flex hidden flex-row gap-4">
                        <Button
                            onClick={handleDateOpen}
                            variant="outlined"
                            startIcon={<CalendarMonthRoundedIcon />}
                        >
                            Data From: {date}
                        </Button>
                        <Button
                            variant="outlined"
                            startIcon={<SimCardDownloadRoundedIcon />}
                            onClick={() => setExportModalOpen(true)}
                        >
                            Save PDF
                        </Button>
                    </div>
                </Slide>
            </div>

            <div className="mt-4 col-start-1 col-end-13">
                <Overview
                    dashboard={type === 'firms' ? 'Firms' : 'Consultants'}
                    topic={name}
                    title={type === 'firms' ? 'Lobby Firm Overview' : 'Consultant Overview'}
                    subtitle={name}
                    tooltipContent={LobbyFirmOverview}
                    barChartTitle="Communications (18 months)"
                    overviews={overview}
                    overviewIndent={overviewIndent}
                    loading={overviewLoading}
                    tableHeader={overviewHeaders}
                    primaryColor="rgb(14 116 144)"
                    secondaryColor="rgb(6 182 212)"
                />
            </div>

            <div className="mt-16 col-start-1 col-end-13">
                <Breakdowns
                    title="Lobbying Communications"
                    subtitle="Past 12 Months"
                    tooltipContent={LobbyFirmLobbyingCommunications}
                    breakdownTitle1="Lobbying Reports by Client (12M)"
                    breakdownData1={topPartners}
                    loading1={topPartnersLoading}
                    breakdownTitle2="Lobbying Reports by Sector (12M)"
                    breakdownData2={topSectors}
                    loading2={topSectorsLoading}
                    breakdownTitle3="Lobbying Reports by Institution (12M)"
                    breakdownData3={topInstitutions}
                    loading3={topInstitutionsLoading}
                />
            </div>

            <div className="mt-16 col-start-1 col-end-13">
                <DashTable
                    dashboard={type === 'firms' ? 'Firms' : 'Consultants'}
                    topic={name}
                    titleText='Active Registrations'
                    title={
                        <div className="flex flex-row items-center gap-2">
                            <HandshakeRoundedIcon color="info" />
                            Active Registrations
                        </div>
                    }
                    subtitle="Past 12 Months"
                    tooltipContent={LobbyFirmRegistrations}
                    tableData={registrations}
                    loading={registrationsLoading}
                    tableHeader={[
                        'date',
                        'client',
                        'province',
                        'sector',
                        ...(type === 'firms' ? ['consultant'] : []),
                        'Lobbying Reports (12M)',
                        'history',
                        'dashboard',
                    ]}
                    defaultSort="Lobbying Reports (12M)"
                    defaultNumRows={10}
                />
            </div>

            <div className="mt-16 col-start-1 col-end-13">
                <DashTable
                    dashboard={type === 'firms' ? 'Firms' : 'Consultants'}
                    topic={name}
                    titleText='Recent Deregistrations'
                    title={
                        <div className="flex flex-row items-center gap-2">
                            <HandshakeRoundedIcon color="error" />
                            Recent Deregistrations
                        </div>
                    }
                    subtitle="Past 12 Months"
                    tooltipContent={LobbyFirmDeregistrations}
                    tableData={deregistrations}
                    loading={deregistrationsLoading}
                    tableHeader={[
                        'date',
                        'client',
                        'province',
                        'sector',
                        ...(type === 'firms' ? ['consultant'] : []),
                        'Lobbying Reports (12M)',
                        'dashboard',
                    ]}
                    defaultSort="Lobbying Reports (12M)"
                    defaultNumRows={10}
                />
            </div>
        </div>
    );
};

export default InhouseDashboard;
