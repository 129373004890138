import React from 'react';
import { Fade, Grow } from '@mui/material';
import Breakdown from '../../../components/generic-dashboards/Breakdown';
import LoadingBreakdown from '../../../components/generic-dashboards/loading/LoadingBreakdown';
import { MissingData } from '../../../components/misc/MissingData';

// Title and subtitle are optional components with defaults specified in function signature below.
interface breakdownsProps {
    title?: string;
    subtitle?: string;
    breakdownData1: any;
    breakdownData2: any;
    loading1: boolean;
    loading2: boolean;
    embedded?: boolean;
}

const TwoBreakdowns = ({
    title = '',
    subtitle = '',
    breakdownData1,
    breakdownData2,
    loading1,
    loading2,
    embedded = false,
}: breakdownsProps) => {
    return (
        <Fade in={true}>
            <div>
                {breakdownData1 &&
                    breakdownData2 &&
                    breakdownData1.length > 0 &&
                    breakdownData2.length > 0 && (
                        <>
                            <div className="flex lg:flex-row flex-col justify-between">
                                <div className="text-xl">{title}</div>
                                <div className="lg:text-xl text-lg font-light">{subtitle}</div>
                            </div>
                            <hr className="h-0.5 my-3 bg-slate-100 rounded" />
                        </>
                    )}
                <div className="grid grid-cols-2 gap-4">
                    {loading1 ? (
                        <div className="lg:col-span-1 col-span-3">
                            <LoadingBreakdown />
                        </div>
                    ) : !loading1 && !breakdownData1 ? (
                        <MissingData />
                    ) : breakdownData1 && breakdownData1.length > 0 ? (
                        <div className="lg:col-span-1 col-span-3">
                            <Grow in={true}>
                                <div>
                                    <Breakdown
                                        title="Institutions"
                                        breakdownData={breakdownData1}
                                        dataKey="count"
                                        embedded={embedded}
                                    />
                                </div>
                            </Grow>
                        </div>
                    ) : (
                        <></>
                    )}
                    {loading2 ? (
                        <div className="lg:col-span-1 col-span-3">
                            <LoadingBreakdown />
                        </div>
                    ) : !loading2 && !breakdownData2 ? (
                        <MissingData />
                    ) : breakdownData2 && breakdownData2.length > 0 ? (
                        <div className="lg:col-span-1 col-span-3">
                            <Grow in={true}>
                                <div>
                                    <Breakdown
                                        title="Subjects"
                                        breakdownData={breakdownData2}
                                        dataKey="count"
                                        embedded={embedded}
                                    />
                                </div>
                            </Grow>
                        </div>
                    ) : (
                        <></>
                    )}
                </div>
            </div>
        </Fade>
    );
};

export default TwoBreakdowns;
