import { Divider, Menu, MenuItem } from '@mui/material';
import React from 'react';
import { Link } from 'react-router-dom';
import ExpandLessRoundedIcon from '@mui/icons-material/ExpandLessRounded';
import ExpandMoreRoundedIcon from '@mui/icons-material/ExpandMoreRounded';
import { replace_underscores_capitalize } from '../../../helpers/string_formatters';
import { Entity, EntityCategory, EntityRepo } from '../../config/EntityRepo';

const link_tran = 'text-slate-600 hover:text-black';

const EntityLink = ({ entity, handleClose }: { entity: Entity; handleClose: () => void }) => {
    return (
        <div className={link_tran}>
            <MenuItem onClick={handleClose} component={Link} to={entity.path}>
                {entity.getFormattedTitle()}
            </MenuItem>
        </div>
    );
};

const EntityDropDown = ({ primaryText }: { primaryText: string }) => {
    const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
    const open = Boolean(anchorEl);

    const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };

    React.useEffect(() => {
        const handleScroll = () => {
            handleClose();
        };
        window.addEventListener('scroll', handleScroll);
        // Cleanup the event listener when the component is unmounted
        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, []);

    const coreEntities = EntityRepo.getEntityListByCategory(EntityCategory.CORE);
    const personalEntities = EntityRepo.getEntityListByCategory(EntityCategory.PERSONAL);
    const globalEntities = EntityRepo.getEntityListByCategory(EntityCategory.GLOBAL);

    return (
        <div>
            <button
                className="py-1 px-3 flex flex-row justify-center"
                id="dashboards-button"
                aria-controls={open ? 'basic-menu' : undefined}
                aria-haspopup="true"
                aria-expanded={open ? 'true' : undefined}
                onClick={handleClick}
            >
                {replace_underscores_capitalize(primaryText)}
                {open ? <ExpandLessRoundedIcon /> : <ExpandMoreRoundedIcon />}
            </button>
            <Menu
                sx={{
                    '& .MuiMenu-paper': {
                        backgroundColor: 'white',
                        borderWidth: '#ededed',
                        borderColor: 'rgba(168,168,168,1)',
                        borderRadius: '10px 10px 10px 10px',
                        boxShadow: 'rgba(168,168,168,1) 10px 10px 35px -10px',
                    },
                }}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'center',
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'center',
                }}
                anchorEl={anchorEl}
                open={open}
                onClose={handleClose}
                disableScrollLock={true}
            >
                {coreEntities.map((entity: Entity, i: number) => (
                    <EntityLink key={i} entity={entity} handleClose={handleClose} />
                ))}
                <Divider sx={{ marginY: 1 }} />
                {personalEntities.map((entity: Entity, i: number) => (
                    <EntityLink key={i} entity={entity} handleClose={handleClose} />
                ))}
                <Divider sx={{ marginY: 1 }} />
                {globalEntities.map((entity: Entity, i: number) => (
                    <EntityLink key={i} entity={entity} handleClose={handleClose} />
                ))}
            </Menu>
        </div>
    );
};

export { EntityLink, EntityDropDown };
