import React from 'react';

const LoadingUpdates = () => {
    return (
        <div className="animate-pulse lg:h-auto h-72 overflow-auto flex flex-col gap-2 text-slate-700 bg-white p-4 rounded-md shadow-md">
            <div className="grid grid-cols-12 mt-2">
                <div className="col-span-8 bg-slate-100 rounded-md h-8">&nbsp;</div>
            </div>
            <div className="grid grid-cols-12 mt-2">
                <div className="col-span-7 bg-slate-100 rounded-md h-4">&nbsp;</div>
            </div>
            <div className="grid grid-cols-12 mt-2">
                <div className="col-span-4 bg-slate-100 rounded-md h-2">&nbsp;</div>
            </div>
            <div className="grid grid-cols-12 mt-2">
                <div className="col-span-10 bg-slate-100 rounded-md h-8">&nbsp;</div>
            </div>
            <div className="grid grid-cols-12 mt-2">
                <div className="col-span-11 bg-slate-100 rounded-md h-6">&nbsp;</div>
            </div>
            <div className="grid grid-cols-12 mt-2">
                <div className="col-span-10 bg-slate-100 rounded-md h-3">&nbsp;</div>
            </div>
        </div>
    );
};

export default LoadingUpdates;
