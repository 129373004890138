import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { RootState } from '../store';
import {
    entityLobbyingActivity,
    entityOrg,
    entityOverviews,
    entityRegistration,
} from '../../interfaces/generic-entity.interface';
import { orgAPI } from '../../API/orgAPI';
import { entityActivity } from '../../interfaces/organization-entity.interface';
import axios from 'axios';
import saveAs from 'file-saver';

interface OrganizationState {
    overview: entityOverviews | null;
    overview_loading: boolean;
    overview_fail: boolean;

    lobbying_activity: entityLobbyingActivity[] | null;
    lobbying_activity_loading: boolean;
    lobbying_activity_fail: boolean;

    registrations: entityRegistration[] | null;
    registrations_loading: boolean;
    registrations_fail: boolean;

    deregistrations: entityRegistration[] | null;
    deregistrations_loading: boolean;
    deregistrations_fail: boolean;

    dpoh_activity: entityActivity[] | null;
    dpoh_activity_loading: boolean;
    dpoh_activity_fail: boolean;

    top_institutions: entityOrg[] | null;
    top_institutions_loading: boolean;
    top_institutions_fail: boolean;

    top_subjects: entityOrg[] | null;
    top_subjects_loading: boolean;
    top_subjects_fail: boolean;

    internal_external_split: entityOrg[] | null;
    internal_external_split_loading: boolean;
    internal_external_split_fail: boolean;

    export_loading: boolean;
    export_fail: boolean;
}

const initialState: OrganizationState = {
    overview: null,
    overview_loading: false,
    overview_fail: false,

    lobbying_activity: null,
    lobbying_activity_loading: false,
    lobbying_activity_fail: false,

    registrations: null,
    registrations_loading: false,
    registrations_fail: false,

    deregistrations: null,
    deregistrations_loading: false,
    deregistrations_fail: false,

    dpoh_activity: null,
    dpoh_activity_loading: false,
    dpoh_activity_fail: false,

    top_institutions: null,
    top_institutions_loading: false,
    top_institutions_fail: false,

    top_subjects: null,
    top_subjects_loading: false,
    top_subjects_fail: false,

    internal_external_split: null,
    internal_external_split_loading: false,
    internal_external_split_fail: false,

    export_loading: false,
    export_fail: false,
};

// Fetch the recent lobbying activity data for the current organization
export const fetchOrganizationOverview = createAsyncThunk(
    'organization/fetchOrganizationOverview',
    async ({
        org,
        date,
        inactive = false,
    }: {
        org: string;
        date: string;
        inactive?: boolean;
    }): Promise<entityOverviews> => orgAPI.fetchOverview(org, date, inactive)
);

// Fetch the recent lobbying activity data for the current organization
export const fetchOrganizationRecentLobbying = createAsyncThunk(
    'organization/fetchOrganizationRecentLobbying',
    async ({
        org,
        date,
        inactive = false,
    }: {
        org: string;
        date: string;
        inactive?: boolean;
    }): Promise<entityLobbyingActivity[]> => orgAPI.fetchRecentLobbyingActivity(org, date, inactive)
);

// Fetch the recent registrations for the current house
export const fetchOrganizationLatestRegistrations = createAsyncThunk(
    'organization/fetchOrganizationLatestRegistrations',
    async ({
        org,
        date,
        inactive = false,
    }: {
        org: string;
        date: string;
        inactive?: boolean;
    }): Promise<entityRegistration[]> =>
        orgAPI.fetchOrganizationLatestRegistrations(org, date, inactive)
);

// Fetch the recent lobbying registrations in the current organization
export const fetchOrganizationLatestDeregistrations = createAsyncThunk(
    'organization/fetchOrganizationLatestDeregistrations',
    async ({
        org,
        date,
        inactive = false,
    }: {
        org: string;
        date: string;
        inactive?: boolean;
    }): Promise<entityRegistration[]> =>
        orgAPI.fetchOrganizationLatestDeregistrations(org, date, inactive)
);

// Fetch the recent DPOH activity data for the current organization
export const fetchOrganizationDPOHActivity = createAsyncThunk(
    'organization/fetchOrganizationDPOHActivity',
    async ({
        org,
        date,
        inactive = false,
    }: {
        org: string;
        date: string;
        inactive?: boolean;
    }): Promise<entityActivity[]> => orgAPI.fetchRecentDPOHActivity(org, date, inactive)
);

// Fetch the top institutions lobbied by the current organization
export const fetchTopInstitutions = createAsyncThunk(
    'organization/fetchOrganizationTopInstitutions',
    async ({
        org,
        date,
        inactive = false,
    }: {
        org: string;
        date: string;
        inactive?: boolean;
    }): Promise<entityOrg[]> => orgAPI.fetchTopInstitutions(org, date, inactive)
);

// Fetch the top subjects lobbied by the current organization
export const fetchTopSubjects = createAsyncThunk(
    'organization/fetchOrganizationTopSubjects',
    async ({
        org,
        date,
        inactive = false,
    }: {
        org: string;
        date: string;
        inactive?: boolean;
    }): Promise<entityOrg[]> => orgAPI.fetchTopSubjects(org, date, inactive)
);

// Fetch the top institutions lobbied by the current organization
export const fetchInternalExternalSplit = createAsyncThunk(
    'organization/fetchOrganizationSplit',
    async ({
        org,
        date,
        inactive = false,
    }: {
        org: string;
        date: string;
        inactive?: boolean;
    }): Promise<entityOrg[]> => orgAPI.fetchInternalExternalSplit(org, date, inactive)
);

export const exportOrganizationDashboard = createAsyncThunk(
    'organization/exportDashboard',
    async (data: any): Promise<void> => {
        const response = await axios.post(
            `${process.env.REACT_APP_PDF_SERVICE_URL}/organization/report`,
            data,
            {
                responseType: 'blob',
            }
        );

        const pdfEncoding = response.data;

        const blob = new Blob([pdfEncoding], { type: 'application/pdf' });

        saveAs(blob, 'LobbyIQ-Institution');
    }
);

const issueSlice = createSlice({
    name: 'organizationReducer',
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(fetchOrganizationOverview.pending, (state) => {
                state.overview_loading = true;
            })
            .addCase(fetchOrganizationOverview.fulfilled, (state, action) => {
                state.overview_loading = false;
                state.overview_fail = false;
                state.overview = action.payload;
            })
            .addCase(fetchOrganizationOverview.rejected, (state) => {
                state.overview_loading = false;
                state.overview_fail = true;
                state.overview = null;
            })
            .addCase(fetchOrganizationRecentLobbying.pending, (state) => {
                state.lobbying_activity_loading = true;
            })
            .addCase(fetchOrganizationRecentLobbying.fulfilled, (state, action) => {
                state.lobbying_activity_loading = false;
                state.lobbying_activity_fail = false;
                state.lobbying_activity = action.payload;
            })
            .addCase(fetchOrganizationRecentLobbying.rejected, (state) => {
                state.lobbying_activity_loading = false;
                state.lobbying_activity_fail = true;
                state.lobbying_activity = null;
            })
            .addCase(fetchOrganizationLatestRegistrations.pending, (state) => {
                state.registrations_loading = true;
            })
            .addCase(fetchOrganizationLatestRegistrations.fulfilled, (state, action) => {
                state.registrations = action.payload;
                state.registrations_loading = false;
                state.registrations_fail = false;
            })
            .addCase(fetchOrganizationLatestRegistrations.rejected, (state) => {
                state.registrations_loading = false;
                state.registrations_fail = true;
                state.registrations = null;
            })
            .addCase(fetchOrganizationLatestDeregistrations.pending, (state) => {
                state.deregistrations_loading = true;
            })
            .addCase(fetchOrganizationLatestDeregistrations.fulfilled, (state, action) => {
                state.deregistrations_loading = false;
                state.deregistrations_fail = false;
                state.deregistrations = action.payload;
            })
            .addCase(fetchOrganizationLatestDeregistrations.rejected, (state) => {
                state.deregistrations_loading = false;
                state.deregistrations_fail = true;
                state.deregistrations = null;
            })
            .addCase(fetchOrganizationDPOHActivity.pending, (state) => {
                state.dpoh_activity_loading = true;
            })
            .addCase(fetchOrganizationDPOHActivity.fulfilled, (state, action) => {
                state.dpoh_activity_loading = false;
                state.dpoh_activity_fail = false;
                state.dpoh_activity = action.payload;
            })
            .addCase(fetchOrganizationDPOHActivity.rejected, (state) => {
                state.dpoh_activity_loading = false;
                state.dpoh_activity_fail = true;
                state.dpoh_activity = null;
            })
            .addCase(fetchTopInstitutions.pending, (state) => {
                state.top_institutions_loading = true;
            })
            .addCase(fetchTopInstitutions.fulfilled, (state, action) => {
                state.top_institutions_loading = false;
                state.top_institutions_fail = false;
                state.top_institutions = action.payload;
            })
            .addCase(fetchTopInstitutions.rejected, (state) => {
                state.top_institutions_loading = false;
                state.top_institutions_fail = true;
                state.top_institutions = null;
            })
            .addCase(fetchTopSubjects.pending, (state) => {
                state.top_subjects_loading = true;
            })
            .addCase(fetchTopSubjects.fulfilled, (state, action) => {
                state.top_subjects_loading = false;
                state.top_subjects_fail = false;
                state.top_subjects = action.payload;
            })
            .addCase(fetchTopSubjects.rejected, (state) => {
                state.top_subjects_loading = false;
                state.top_subjects_fail = true;
                state.top_subjects = null;
            })
            .addCase(fetchInternalExternalSplit.pending, (state) => {
                state.internal_external_split_loading = true;
            })
            .addCase(fetchInternalExternalSplit.fulfilled, (state, action) => {
                state.internal_external_split_loading = false;
                state.internal_external_split_fail = false;
                state.internal_external_split = action.payload;
            })
            .addCase(fetchInternalExternalSplit.rejected, (state) => {
                state.internal_external_split_loading = false;
                state.internal_external_split_fail = true;
                state.internal_external_split = null;
            })
            .addCase(exportOrganizationDashboard.pending, (state) => {
                state.export_loading = true;
            })
            .addCase(exportOrganizationDashboard.fulfilled, (state, action) => {
                state.export_loading = false;
                state.export_fail = false;
            })
            .addCase(exportOrganizationDashboard.rejected, (state) => {
                state.export_loading = false;
                state.export_fail = true;
            });
    },
});

export const selectOrganizationOverview = (state: RootState) => state.organizationData.overview;
export const selectOrganizationOverviewLoading = (state: RootState) =>
    state.organizationData.overview_loading;

export const selectOrganizationRecentLobbying = (state: RootState) =>
    state.organizationData.lobbying_activity;
export const selectOrganizationRecentLobbyingLoading = (state: RootState) =>
    state.organizationData.lobbying_activity_loading;

export const selectOrganizationLatestRegistrations = (state: RootState) =>
    state.organizationData.registrations;
export const selectOrganizationLatestRegistrationsLoading = (state: RootState) =>
    state.organizationData.registrations_loading;

export const selectOrganizationLatestDeregistrations = (state: RootState) =>
    state.organizationData.deregistrations;
export const selectOrganizationLatestDeregistrationsLoading = (state: RootState) =>
    state.organizationData.deregistrations_loading;

export const selectOrganizationDPOHActivity = (state: RootState) =>
    state.organizationData.dpoh_activity;
export const selectOrganizationDPOHActivityLoading = (state: RootState) =>
    state.organizationData.dpoh_activity_loading;

export const selectOrganizationTopInstitutions = (state: RootState) =>
    state.organizationData.top_institutions;
export const selectOrganizationTopInstitutionsLoading = (state: RootState) =>
    state.organizationData.top_institutions_loading;

export const selectOrganizationTopSubjects = (state: RootState) =>
    state.organizationData.top_subjects;
export const selectOrganizationTopSubjectsLoading = (state: RootState) =>
    state.organizationData.top_subjects_loading;

export const selectOrganizationInteralExternal = (state: RootState) =>
    state.organizationData.internal_external_split;
export const selectOrganizationInteralExternalLoading = (state: RootState) =>
    state.organizationData.internal_external_split_loading;

export const selectOrganizationExportLoading = (state: RootState) =>
    state.organizationData.export_loading;
export const selectOrganizationExportFail = (state: RootState) =>
    state.organizationData.export_fail;

export const organizationData = (state: RootState) => state.organizationData;

export default issueSlice.reducer;
