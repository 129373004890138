import React from 'react';
import LoadingIssueRankings from '../../../components/generic-dashboards/loading/LoadingIssueRankings';
import { MissingData } from '../../../components/misc/MissingData';
import { issueInfo } from '../interface/issue-entity.interface';

// Title and subtitle are optional components with defaults specified in function signature below.
interface keywordCompositionProps {
    title?: string;
    subtitle?: string;
    keywordData: issueInfo | null;
    loading: boolean;
}

const KeywordComposition = ({
    title = '',
    subtitle = '',
    keywordData,
    loading,
}: keywordCompositionProps) => {
    return (
        <div>
            <div className="flex lg:flex-row flex-col justify-between">
                <h2 className="text-xl">{title}</h2>
                <div className="flex flex-row items-center">
                    <div className="lg:text-xl text-lg font-light">{subtitle}</div>
                </div>
            </div>
            <hr className="h-0.5 my-3 bg-slate-100 rounded" />
            {loading ? (
                <LoadingIssueRankings />
            ) : !loading && !keywordData ? (
                <MissingData />
            ) : keywordData ? (
                <div className="text-lg">Issue keywords: {keywordData.keywords.join(', ')}</div>
            ) : (
                <></>
            )}
        </div>
    );
};

export default KeywordComposition;
