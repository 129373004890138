export const REDIRECT_LOCATION = 'location';

export const setCurrentUrl = () => {
    const searchParams = new URLSearchParams(window.location.search);
    searchParams.delete('success');

    sessionStorage.setItem(
        REDIRECT_LOCATION,
        window.location.pathname + '?' + searchParams
    );
};

export const getRedirectLocation = () => {
    const location = sessionStorage.getItem(REDIRECT_LOCATION);
    sessionStorage.removeItem(REDIRECT_LOCATION);

    if (location === '/redirect') {
        return '/';
    }
    return location;
};
