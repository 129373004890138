import React from 'react';

interface LiqExhibitTitleContainerProps {
    title: string;
    subtitle: string;
}

const LiqExhibitTitleContainer = ({ title, subtitle }: LiqExhibitTitleContainerProps) => {
    return (
        <div className="flex lg:flex-row flex-col justify-between">
            <div className="text-xl">{title}</div>
            <div className="text-lg font-light mr-2">{subtitle}</div>
        </div>
    );
};

export default LiqExhibitTitleContainer;
